import { Image } from "expo-image";
import React, { useState } from "react";
import {
  View,
  Text,
  Dimensions,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Keyboard,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { Ionicons } from "@expo/vector-icons";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { useNavigation, useRoute } from "@react-navigation/native";

type NorthstarMyWhyPresentationProps = {
  title: string;
  description: string;
  onTitle: (text: string) => void;
  onDescription: (text: string) => void;
  onSubmit: () => void;
  onDelete: () => void;
};

const data = [
  { label: "Month(s)", value: "1" },
  { label: "Day(s)", value: "2" },
  { label: "Week(s)", value: "3" },
];
const { height } = Dimensions.get("window");
const NorthstarMyWhyPresentation: React.FC<NorthstarMyWhyPresentationProps> = ({
  title,
  description,
  onTitle,
  onDescription,
  onSubmit,
  onDelete,
}) => {
  const [value, setValue] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const navigation = useNavigation<HomeScreenNavigationProps>();

  return (
    <View style={styles.notehstarContent}>
      <View style={styles.notehstarContentBox}>
        <TextInput
          style={{
            lineHeight: 30,
            textAlign: "center",
            fontSize: 32,
            fontWeight: "bold",
            borderColor: "#707070",
            borderWidth: 1,
            height: 48,
            borderRadius: 8,
            width: "86%",
            marginLeft: "7%",
          }}
          onChangeText={onTitle}
          value={title}
          placeholder="Title"
        />

        <TextInput
          style={styles.notehstarContentBoxInput}
          onChangeText={onDescription}
          multiline
          numberOfLines={12}
          value={description}
          placeholder="description"
        />

        <View
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "row",
          }}
        >
          <TouchableOpacity
            style={styles.letsGoButton}
            onPress={() => {
              onSubmit();
            }}
          >
            <Text style={styles.letsGoButtonText}>Submit</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onDelete} style={{ marginBottom: 10 }}>
            <Ionicons name="trash-sharp" size={35} color="#FF0000" />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  letsGoButtonText: {
    textAlign: "center",
    fontSize: 30,
    color: "#fff",
    fontWeight: "900",
  },
  letsGoButton: {
    width: "60%",
    height: 46,
    textAlign: "center",
    backgroundColor: "#5CFF88",
    color: "#fff",
    borderRadius: 8,
    marginBottom: 10,
  },

  notehstarContent: {
    width: "100%",
    marginLeft: 0,
  },
  notehstarContentBox: {
    marginTop: 20,
    backgroundColor: "#fff",
    borderRadius: 4,
    maxWidt: 390,
    paddingTop: 20,
    width: "96%",
    marginLeft: "2%",
  },
  notehstarContentBoxInput: {
    borderColor: "#707070",
    borderWidth: 1,

    borderRadius: 6,
    paddingHorizontal: 8,
    fontSize: 18,
    fontFamily: "RajdahaniMedium",
    color: "#3E3F5E",
    margin: 25,
    padding: 10,
    height: height - 370,
    textAlignVertical: "top",
  },
});

export default NorthstarMyWhyPresentation;
