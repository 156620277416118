import React, { useState } from "react";
import { View } from "react-native";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import BadgeSystemListPresentation from "../presentation/BadgeSystemListPresentation";
import BadgetSystemDetailContainerPresentation from "../presentation/BadgetSystemDetailContainerPresentation";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { useGetAllAchiveBadgesQuery } from "../api/apiBadgeSystem";
import { BadgeSystem } from "../type/type";

type BadgeSystemAchivedListContainerProps = {
  itemBagdeProp: any;
  popUpOpen: boolean | undefined;
};

const BadgeSystemAchievedListContainer: React.FC<
  BadgeSystemAchivedListContainerProps
> = ({ itemBagdeProp, popUpOpen }) => {
  const accountSelected = useAppSelector(selectDefaultAccount);

  const {
    data: allBadgesList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllAchiveBadgesQuery({
    currentSystem: JSON.stringify(accountSelected),
  });

  const [itemBadge, setItemBadge] = useState<BadgeSystem | null>(
    itemBagdeProp ? itemBagdeProp : null
  );
  const [openDetails, setOpenDetails] = useState<boolean>(
    popUpOpen ? popUpOpen : false
  );

  const onItemDetail = (item: BadgeSystem | null) => {
    setOpenDetails(true);
    setItemBadge(item);
  };
  const onClose = () => {
    setOpenDetails(false);
  };
  let content;

  if (isLoading) {
    content = (
      <View style={{ height: "80%", justifyContent: "center" }}>
        <LoadingScreen iconSize={50} />
      </View>
    );
  } else if (isSuccess && allBadgesList) {
    content = (
      <>
        <View>
          <BadgeSystemListPresentation
            badgesList={allBadgesList!}
            onItemDetail={onItemDetail}
          />
        </View>
        {openDetails && (
          <BadgetSystemDetailContainerPresentation
            openPopup={openDetails}
            onClose={onClose}
            item={itemBadge}
          />
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default BadgeSystemAchievedListContainer;
