import React, { useEffect, useState } from "react";
import { FlatList, View } from "react-native";
import { ExperienceCard } from "../types/types";
import { EntityState } from "@reduxjs/toolkit";
import { ReviveCard } from "../../reviveCard/types/type";
import ExperienceitemGradePresentation from "./ExperienceitemGradePresentation";
import { GradeReward } from "../../gradeReward/types/GradeReward";

type ExperienceReviveListPresentationProps = {
  gradeScoreList: GradeReward[];
  onSetOpenPopup: (status: boolean) => void;
  image: string;
};

const ExperienceGradeListPresentation: React.FC<
  ExperienceReviveListPresentationProps
> = ({ gradeScoreList, onSetOpenPopup, image }) => {
  const [gradeScore, setGradeScore] = useState<GradeReward | null>(null);

  useEffect(() => {
    if (gradeScoreList.length > 0) {
      let scoreTotal = gradeScoreList.reduce((acc, result) => {
        return (acc += Number(result.course_completion_percentage));
      }, 0);

   
      setGradeScore({
        _id: `Xp earned for your ${gradeScoreList.length} course(s)`,
        id: "not Important",
        course_completion_percentage: String(scoreTotal),
        totalXpEarn: 0,
      });
    } else {
      setGradeScore(null);
    }
  }, [gradeScoreList]);

  return (
    <View style={{ maxHeight: "27%" }}>
      {gradeScore && (
        <ExperienceitemGradePresentation
          item={gradeScore!}
          onItemClick={() => {}}
          openDetailsHandler={() => {}}
          onItemClickHandlerRevive={() => {}}
          maxChartValue={60000}
          onSetOpenPopup={onSetOpenPopup}
          image={image}
        />
      )}

      <View
        style={{
          overflow: "hidden",
          marginBottom: 8,
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 5,
        }}
      >
        <View
          style={{
            borderStyle: "dashed",
            borderWidth: 3,
            borderColor: "#777777",
            margin: -3,
            marginTop: 0,
          }}
        />
      </View>
    </View>
  );
};

export default ExperienceGradeListPresentation;
