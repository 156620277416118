import React from "react";
import { View, Text ,Image} from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";
import { images } from "../../../constants";


const PrizeMenuTextPresentation = () => {

  return (

        <View style={styles.ProfileSettingMenuTextContainer}>
          <View style={styles.shopHeaderTitleIconContainer}>
            <Image
                style={styles.shopHeaderTitleIcon}
                source={images.StoreShop}
              />
          </View>
           
          <TextStroke stroke={ 2 } color={ '#FFF' } >
              <Text style={ {
                fontSize: 59,
                color: '#000',
              } }> Shop </Text>

            </TextStroke>

        </View>

  );

};


const { styles } = StyleSheet.create({
    ProfileSettingMenuTextContainer:{
      top:-100,
      width:"50%",
      position:"absolute",
      marginLeft:"25%",
      marginRight:"25%",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"row",
 
   },
   shopHeaderTitleIcon: {
    height: 25,
    width: 30,
  },
  shopHeaderTitleIconContainer:{
    height: 48,
    width: 48,
    borderColor:"#000",
    borderStyle:"solid",
    borderWidth:4,
    borderRadius:50,
    backgroundColor:"#fff",
    justifyContent:"center",
    alignItems:"center"
  }

});

export default PrizeMenuTextPresentation;
