import React from "react";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import {
  useAcceptRequestMutation,
  useRequestdListQuery,
} from "../api/friendsApiSlice";
import RequestFriendsPresentation from "../presentation/RequestFriendsPresentation/RequestFriendsPresentation";
type RequestFriendsContainerProps = {
  account: Partial<StudentSystemType>;
};
const RequestFriendsContainer: React.FC<RequestFriendsContainerProps> = ({
  account,
}) => {
  const {
    data: requestdList,
    isLoading,
    isError,
    isSuccess,
  } = useRequestdListQuery({
    currentSystem: JSON.stringify(account!),
  });

  const [
    acceptRequest,
    {
      isLoading: acceptLoading,
      isError: acceptError,
      isSuccess: acceptSuccess,
    },
  ] = useAcceptRequestMutation();

  const onAcceptFriend = async (student: Partial<AuthUser>) => {
    try {
      await acceptRequest({
        friend: student,
        project: account.project,
      }).unwrap();
    } catch (error) {}
  };

  return (
    <RequestFriendsPresentation
      isLoading={isLoading}
      isSuccess={isSuccess}
      requestList={requestdList!}
      onAcceptFriend={onAcceptFriend}
    />
  );
};

export default RequestFriendsContainer;
