import React from "react";
import FriendsTextPresentation from "../presentation/FriendsPresentation/FriendsTextPresentation";




const FriendsTextContainer= () => {

  return (
    <FriendsTextPresentation/>
  );
};

export default FriendsTextContainer;
