import { apiSlice } from "../../redux/api/apiSlice";
import { AcademicScore } from "./types";
import { GradeAvgScore } from "./types";

export const academicScoreApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAcademicScore: builder.query<
      AcademicScore,
      {
        currentSystem: string;
      }
    >({
      query: (data) =>
        `/academic-score?currentSystemString=${data.currentSystem}`,
      transformResponse: (resonse: AcademicScore) => {
        return resonse;
      },
      providesTags: ["AcademicScore"],
    }),
    getAllGradeAvgScore: builder.query<
      GradeAvgScore,
      {
        currentSystem: string;
      }
    >({
      query: (data) =>
        `/course-enrollment-grade/avg?currentSystemString=${data.currentSystem}`,
      transformResponse: (resonse: GradeAvgScore) => {
        return resonse;
      },
      providesTags: ["GradeAvgScore"],
    }),
  }),
});

export const {
  useGetAllAcademicScoreQuery,
  useLazyGetAllAcademicScoreQuery,
  useGetAllGradeAvgScoreQuery,
  useLazyGetAllGradeAvgScoreQuery,
} = academicScoreApi;
