import React, { useEffect, useState } from "react";
import ProfileAccountSettingPresentation from "../presentation/ProfileAccountSettingPresentation";
import ProfileSettingHeaderContainer from "../../profileSetting/container/ProfileSettingHeaderContainer";
import ProfileSettingImageContainer from "../../profileSetting/container/ProfileSettingImageContainer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  editAdmin,
  selectAdmin,
  selectDefaultAccount,
} from "../../auth/slice/authSlice";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import * as ImagePicker from "expo-image-picker";
import axios, { AxiosError } from "axios";
import { useUpdateUserMutation } from "../../auth/authApiSlice/authApiSlice";
import { updateUser } from "../../auth/authThunks/authThunks";
import { ScrollView } from "react-native";
import { startActivityAsync, ActivityAction } from "expo-intent-launcher";
import ProfileAccountSettingTextContainer from "./ProfileAccountSettingTextContainer";
import * as ImageManipulator from "expo-image-manipulator";

const ProfileAccountsettingMainContainer = () => {
  const systemSelected = useAppSelector(selectDefaultAccount);
  const admin = useAppSelector(selectAdmin);
  const user = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();
  const notificationAnimations = React.createRef();
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const goBackHandler = () => {
    navigation.goBack();
  };
  const [xps, setXps] = useState<number>(0);
  const [studentLevel, setStudentLevel] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showBgBanner, setShowBgBanner] = useState<boolean>(false);
  const [showNoti, setShowNoti] = useState<boolean>(true);
  useEffect(() => {
    if (notificationAnimations && notificationAnimations?.current)
      // @ts-ignore
      notificationAnimations.current.play();
  }, []);

  useEffect(() => {
    // setSystem(systemSelected);
    if (admin) {
      const projectReport = admin.projectReport.find(
        (item) => item.project === systemSelected!.project
      );

      if (projectReport) {
        setXps(projectReport.xps);
        setStudentLevel(Math.floor(projectReport.xps / 3500));
      }
      let valueNumber = 0;

      if (admin?.image !== "https://api.gradify.app/static/download.ff.png") {
        valueNumber = 0 + 33;
        setValue(valueNumber);
        setShowProfile(true);
      }

      if (
        admin?.backgroundBanner !==
        "https://api.gradify.app/static/resizefile-1684825339302-277566432.png"
      ) {
        valueNumber = valueNumber + 33;
        setValue(valueNumber);

        setShowBgBanner(true);
      }
    }
  }, [admin, systemSelected]);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      const resizedPhoto = await ImageManipulator.manipulateAsync(
        result.assets[0].uri,
        [
          {
            resize: {
              width: 350, // Or whatever size you want
            },
          },
        ],
        { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
      );

      const formData = new FormData();

      formData.append("file", {
        uri: resizedPhoto.uri,
        type: "image/jpg",
        name: "image.jpg",
      } as any);
      try {
        const response = await axios.post(
          `https://api.gradify.app/api/upload-image/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        await dispatch(updateUser({ ...user!, image: response.data.uri }));
      } catch (error: any) {}
    }
  };

  const pickBanner = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      const resizedPhoto = await ImageManipulator.manipulateAsync(
        result.assets[0].uri,
        [
          {
            resize: {
              width: 720, // Or whatever size you want
            },
          },
        ],
        { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
      );

      const formData = new FormData();

      formData.append("file", {
        uri: resizedPhoto.uri,
        type: "image/jpg",
        name: "image.jpg",
      } as any);
      try {
        const response = await axios.post(
          `https://api.gradify.app/api/upload-image/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        await dispatch(
          updateUser({ ...user!, backgroundBanner: response.data.uri })
        );
      } catch (error: any) {}
    }
  };

  const onOpenNotificationSetting = async () => {
    setValue(value + 50);
    setShowNoti(false);
    startActivityAsync(ActivityAction.APP_NOTIFICATION_REDACTION);
  };
  return (
    <>
      <ScrollView>
        <ProfileSettingHeaderContainer
          backgroundProfile={user?.backgroundBanner!}
          goBackHandler={goBackHandler!}
          showSetting={true}
        />
        <ProfileAccountSettingTextContainer />

        {/* <ProfileSettingImageContainer
          profileImage={user?.image!}
          firstName={user?.firstName!}
          lastName={user?.lastName!}
          level={xps}
        /> */}
        <ProfileAccountSettingPresentation
          user={user!}
          pickImage={pickImage}
          pickBanner={pickBanner}
          notificationAnimations={notificationAnimations}
          onOpenNotificationSetting={onOpenNotificationSetting}
          value={value}
          showProfile={showProfile}
          showBgBanner={showBgBanner}
          showNotification={showNoti}
        />
      </ScrollView>
    </>
  );
};

export default ProfileAccountsettingMainContainer;
