import React, { useEffect, useState } from "react";
import { View, Dimensions, StatusBar } from "react-native";
import { Image } from "expo-image";
import StyleSheet from "react-native-media-query";
import { images } from "../../../constants";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import FeedDetailHeader from "./FeedDetailHeader";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import DotContainer from "../../../components/ImageCarousel/pagination/Pagination";
import Carousel, { Pagination } from "../../../components/ImageCarousel";
const { width: viewportWidth, height: viewportHeight } =
  Dimensions.get("window");
const { width, height } = Dimensions.get("window");

// [
//   {
//     title: "Beautiful and dramatic Antelope Canyon",
//     subtitle: "Lorem ipsum dolor sit amet et nuncat mergitur",
//     illustration: "https://i.imgur.com/UYiroysl.jpg",
//   },
//   {
//     title: "Earlier this morning, NYC",
//     subtitle: "Lorem ipsum dolor sit amet",
//     illustration: "https://i.imgur.com/UPrs1EWl.jpg",
//   },
//   {
//     title: "White Pocket Sunset",
//     subtitle: "Lorem ipsum dolor sit amet et nuncat ",
//     illustration: "https://i.imgur.com/MABUbpDl.jpg",
//   },
//   {
//     title: "Acrocorinth, Greece",
//     subtitle: "Lorem ipsum dolor sit amet et nuncat mergitur",
//     illustration: "https://i.imgur.com/KZsmUi2l.jpg",
//   },
//   {
//     title: "The lone tree, majestic landscape of New Zealand",
//     subtitle: "Lorem ipsum dolor sit amet",
//     illustration: "https://i.imgur.com/2nCt3Sbl.jpg",
//   },
//   {
//     title: "Middle Earth, Germany",
//     subtitle: "Lorem ipsum dolor sit amet",
//     illustration: "https://i.imgur.com/lceHsT6l.jpg",
//   },
// ];

const slideWidth = wp(100);
const itemHorizontalMargin = wp(2);

export const sliderWidth = viewportWidth;
export const itemWidth = slideWidth + itemHorizontalMargin * 2;
export type FeedDetailsPresentationProp = {
  photo: Partial<ExperiencePhoto>;
};
const FeedDetailPresentation: React.FC<FeedDetailsPresentationProp> = ({
  photo,
}) => {
  const [sliderSlide, setSliderSlide] = useState(0);
  const [dataPhoto, setDataPhoto] = useState<
    {
      title?: string;
      subtitle?: string;
      illustration?: string;
    }[]
  >([]);
  useEffect(() => {
    if (photo?.photoData) {
      setDataPhoto(
        photo?.photoData!.map((item) => {
          return {
            title: item.experience.title,
            subtitle: item.experience.subTitle,
            illustration: item.photo,
          };
        })
      );
    }
  }, [photo]);
  return (
    <View style={styles.feedDetailContainer}>
      {dataPhoto.length > 0 ? (
        <>
          <Carousel
            data={dataPhoto}
            renderItem={({ item }: any) => (
              <Image
                contentFit="contain"
                source={{ uri: item.illustration }}
                style={styles.feedDetailImage}
              />
            )}
            sliderWidth={slideWidth}
            itemWidth={itemWidth}
            inactiveSlideScale={0.95}
            inactiveSlideOpacity={1}
            enableMomentum={true}
            activeSlideAlignment={"start"}
            onSnapToItem={(index: React.SetStateAction<number>) =>
              setSliderSlide(index)
            }
            activeAnimationType={"spring"}
          />
          <View style={styles.paginationContainer}>
              <DotContainer
                activeDotColor={'#fff'}
                curPage={sliderSlide}
                maxPage={dataPhoto.length}
                sizeRatio={1.3}
       
            />
          </View>

        </>
      ) : (
        <Image
          contentFit="contain"
          source={{ uri: photo.photo }}
          style={styles.feedDetailImage}
        />
      )}
    </View>
  );
};

const { styles } = StyleSheet.create({
  feedDetailContainer: {
    backgroundColor: "#000",

    display: "flex",
    justifyContent: "center",
    height: height,
    overflow: "hidden",
  },
  paginationContainer: {
    position: "absolute",
    bottom: 70,
    width:"100%",
    alignItems:"center"


  },



  feedDetailImage: {
    flex: 1,
    width: "100%",
    height: undefined,
    aspectRatio: "auto",
  },
});

export default FeedDetailPresentation;
