import React, { FC, useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Modal,
  ScrollView,
  Pressable,
  Dimensions,
  Platform,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { LinearGradient } from "expo-linear-gradient";
import { ExperienceCard } from "../types/types";
import { FONTS, images } from "../../../constants";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { CameraCapturedPicture } from "expo-camera";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import { EntityState } from "@reduxjs/toolkit";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { useReTakePhotoMutation } from "../../cameraFeatures/api/experiencePhotoApiSlice";
import { ReviveCard } from "../../reviveCard/types/type";
const { width, height } = Dimensions.get("window");
export interface IFExperienceDetails {
  open: boolean;
  onClose: () => void;
  item: Partial<ExperienceCard> | null;
  completeHandler: () => void;
  complete?: boolean;
  completeLoading?: boolean;
  disableComplete?: boolean;
  passDue?: boolean;
  onDisableLoading?: () => void;
  onDisableLoadingOff?: () => void;
  photoList?: EntityState<ExperiencePhoto>;
  reviveItem?: Partial<ReviveCard> | null;
  // navigation:any;
  // completionDetails:any;
  // openBrowserButton:any;
  // passDue:any;
}
const ExperienceDetailsPresentation: FC<IFExperienceDetails> = ({
  open,
  onClose,
  item,
  completeHandler,
  complete,
  completeLoading,
  disableComplete,
  passDue,
  onDisableLoading,
  onDisableLoadingOff,
  photoList,
  reviveItem,
}) => {
  const selectedSystem = useAppSelector(selectDefaultAccount);
  const user = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [prevPhoto, setPrevPhoto] = useState<ExperiencePhoto | null>(null);
  const [
    reTakePhoto,
    {
      isLoading: reTakeLoading,
      isError: reTakeError,
      isSuccess: reTakeSuccess,
    },
  ] = useReTakePhotoMutation();
  useEffect(() => {
    if (complete) {
      const prevImages = Object.values(
        photoList!.entities!
      ) as ExperiencePhoto[];
      const prevImage = prevImages.find(
        (elem) =>
          elem.student?._id === item?.completion?.student &&
          elem.experience?._id === item?.completion?.experience
      );

      setPrevPhoto(prevImage!);
    }
  }, [photoList, item]);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      // allowsEditing: true,
      // aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      const resizedPhoto = await ImageManipulator.manipulateAsync(
        result.assets[0].uri,
        [
          {
            resize: {
              width: 720, // Or whatever size you want
            },
          },
        ],
        { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
      );

      navigation.navigate("PreviewPicture", {
        item: item!,
        currentSystem: selectedSystem!,
        photo: resizedPhoto,
        from: "exp",
        reviveItem,
      });
      onClose();
    }
  };

  return (
    <Modal
      transparent={true}
      animationType="slide"
      style={styles.experiencePopup}
      visible={open}
      onRequestClose={() => {
        onClose();
      }}
    >
      <Pressable
        style={styles.modelContainer}
        onPress={(event) => {
          if (event.target == event.currentTarget) {
            onDisableLoadingOff!();
            onClose();
          }
        }}
      >
        <ScrollView style={(Platform.OS === "web") ? styles.detailsContentContainerWeb : styles.detailsContentContainer}>
          <LinearGradient
            colors={[`${item!.experienceType!.color}`, "#fff"]}
            style={styles.detailsContainer}
          >
            <View style={styles.detailsContent}>
              <View style={styles.closeHeader}>
                <TouchableOpacity
                  onPress={() => {
                    onClose();
                  }}
                >
                  <Image  style={(Platform.OS === "web") ? styles.closeWeb : styles.close} source={images.iconClose} />
                </TouchableOpacity>
              </View>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>{item!.title}</Text>
                <Text style={styles.subtitle}>{item!.subTitle}</Text>
                <ScrollView style={styles.descriptionContainer}>
                  <Text style={styles.description}>
                    {item!.description
                      ?.replace("<fullname>", user?.fullName!)
                      .replace("<firstname>", user?.firstName!)}
                  </Text>
                </ScrollView>
                {item?.text_url && (
                  <TouchableOpacity
                    style={styles.linkContent}
                    onPress={async () => {
                      await WebBrowser.openBrowserAsync(item.link_url!);
                    }}
                  >
                    <Image source={images.titleLink} />
                    <Text style={styles.linkTitle}>{item.text_url}</Text>
                  </TouchableOpacity>
                )}

                {/* Photo Delete my profile */}
                {complete && item?.completionType?.title === "Photo" ? (
                  <Image
                    resizeMode="stretch"
                    style={styles.currentImage}
                    source={{ uri: prevPhoto?.photo }}
                  />
                ) : (
                  <Image
                    style={styles.image}
                    source={{
                      uri: item!.image,
                    }}
                  />
                )}
              </View>
            </View>

            <View style={styles.bottomContent}>
              <View style={styles.bottomContentBackContainer}>
                <Image
                  style={styles.bottomContentBack}
                  resizeMode="contain"
                  source={images.expereinceBack}
                />
              </View>
              <Image
                style={styles.icon}
                source={{
                  uri: item!.experienceType!.icon!,
                }}
              />
              <View style={styles.xpContianer}>
                <Text style={styles.xpNumber}>{item!.xpCompletion}</Text>
                <Text style={styles.xpText}>XP</Text>
                <View style={styles.coinNumberContainer}>
                  <Text style={styles.coinNumber}>{item!.gem}</Text>
                  <Image
                    source={images.gemImage}
                    style={styles.coinNumberImage}
                  />
                </View>
              </View>
              <View style={styles.message}>
                <Text style={styles.cardMessage}>
                  {item!.completionType!.message}
                </Text>
              </View>
              <View style={styles.completeContainer}>
                {item!.completionType!.title === "Photo" &&
                  !item?.completion?.complete && (
                    <TouchableOpacity
                      style={styles.cameraIconContainer}
                      onPress={pickImage}
                    >
                      <Image
                        source={images.cameraIcon}
                        style={styles.cameraIcon}
                        resizeMode="contain"
                      />
                    </TouchableOpacity>
                  )}
                <View>
                  {passDue && item?.experienceType?.title === "Events" && (
                    <TouchableOpacity
                      style={styles.completeButton}
                      onPress={async () => {
                        {
                          item?.completionType?.title === "Link" &&
                            (await WebBrowser.openBrowserAsync(
                              item?.button_link_url!
                            ));
                        }

                        onClose();
                      }}
                    >
                      <Text style={styles.buttonText}>
                        {item?.completionType?.title === "Link"
                          ? "Link"
                          : "Close"}
                      </Text>
                    </TouchableOpacity>
                  )}

                  {((passDue && item?.experienceType?.title === "Gradegy") ||
                    (passDue && item?.experienceType?.title === "Mission")) && (
                    <TouchableOpacity
                      style={styles.completeButton}
                      onPress={completeHandler}
                      disabled={disableComplete}
                    >
                      {completeLoading ? (
                        <LoadingScreen iconSize={30} />
                      ) : (
                        <Text style={styles.buttonText}>
                          {item!.completionType!.buttonTitle}
                        </Text>
                      )}
                    </TouchableOpacity>
                  )}
                  {!complete &&
                    !passDue &&
                    item?.experienceType?.title === "Events" &&
                    new Date() < new Date(item.startDate!) && <></>}
                  {complete ? (
                    <TouchableOpacity
                      style={styles.completeButton}
                      onPress={async () => {
                        if (item?.completionType?.title === "Link") {
                          await WebBrowser.openBrowserAsync(
                            item?.button_link_url!
                          );
                          onClose();
                        } else if (item?.completionType?.title === "Photo") {
                          onClose();
                          navigation.navigate("Camera", {
                            item: item!,
                            currentSystem: selectedSystem!,
                            prevItem: prevPhoto!,
                          });
                        }
                      }}
                    >
                      <Text style={styles.buttonText}>
                        {item?.completionType?.title === "Link"
                          ? "Link"
                          : item?.completionType?.title === "Photo"
                          ? "Re-Take"
                          : "Close"}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    !passDue &&
                    (item?.experienceType?.title !== "Events" ||
                      new Date() > new Date(item.startDate!)) && (
                      <TouchableOpacity
                        style={styles.completeButton}
                        onPress={completeHandler}
                        disabled={disableComplete}
                      >
                        {completeLoading ? (
                          <LoadingScreen iconSize={30} />
                        ) : (
                          <Text style={styles.buttonText}>
                            {item!.completionType!.buttonTitle}
                          </Text>
                        )}
                      </TouchableOpacity>
                    )
                  )}
                </View>
              </View>
            </View>
          </LinearGradient>
        </ScrollView>
      </Pressable>
    </Modal>
  );
};
const { styles } = StyleSheet.create({
  cameraIcon: {
    width: 60,
    height: 60,
  },
  cameraIconContainer: {
    position: "absolute",
    right: "4%",
    bottom: -7,
  },
  modelContainer: {
    flex: 1,
  },

  experiencePopup: {
    backgroundColor: "white",
  },
  bottomContentBack: {
    position: "relative",
  },
  coinNumberContainer: {
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    backgroundColor: "#000",
    borderRadius: 18,
    paddingLeft: 10,
    height: 25,
    marginLeft: "3%",
    paddingRight: 20,
  },
  coinNumber: {
    ...FONTS.h4,
    fontSize: 16,
    color: "#fff",
    "@media (max-width: 350px)": {
      fontSize: 14,
    },
  },
  coinNumberImage: {
    marginLeft: 2,
    height: 18,
    width: 20,
    left: 10,
  },
  bottomContentBackContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 20,
  },
  detailsContainer: {
    flex: 1,
    position: "relative",

    borderRadius: 18,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  detailsContentContainerWeb:{
    width: 581,
    marginLeft:"auto",
    marginRight:"auto",
    marginTop: 60,
  },
  
  detailsContentContainer: {
    marginTop: 60,

    "@media (max-width: 350px)": {
      marginTop: 20,
    },
  },
  detailsContent: {
    flex: 1,
    marginTop: 20,
    alignItems: "center",
    "@media (max-width: 350px)": {
      marginTop: 10,
    },
  },
  closeHeader: {
    marginTop: 5,
    alignSelf: "flex-start",
    width: 80,
    "@media (max-width: 350px)": {
      height: 5,
    },
  },
  close: {
    marginLeft: 17,
    "@media (max-width: 350px)": {
      width: 15,
      height: 15,
    },
  },
  closeWeb:{
    width: 17,
      height: 17,
      marginLeft: 17,
  },
  titleContainer: {
    alignItems: "center",
    marginTop: 0,
    maxWidth: "85%",
    alignSelf: "center",
    minHeight: height - 107,
    flex: 1,
    "@media (max-width: 350px)": {
      //   minHeight: height - 200,
    },
  },
  title: {
    paddingTop: 12,
    color: "white",
    marginBottom: 10,
    ...FONTS.h2,
    fontSize: 36,
    textAlign: "center",
    "@media (max-width: 350px)": {
      fontSize: 27,
      marginBottom: 1,
    },
  },
  subtitle: {
    color: "white",
    ...FONTS.h2,
    fontSize: 24,

    marginBottom: 15,
    textAlign: "center",
    maxWidth: "95%",
    "@media (max-width: 350px)": {
      fontSize: 18,
      marginBottom: 8,
    },
  },
  descriptionContainer: {
    maxHeight: 85,
  },
  description: {
    color: "white",
    ...FONTS.h2,
    fontSize: 16,
    textAlign: "left",
    marginBottom: 10,
    lineHeight: 19,

    "@media (max-width: 350px)": {
      fontSize: 12,

      textAlign: "center",
      marginBottom: 5,
    },
  },
  image: {
    width: 200,
    height: 200,
    marginBottom: 200,

    "@media (max-width: 350px)": {
      width: 120,
      height: 120,
      marginBottom: 150,
    },
  },
  currentImage: {
    maxHeight: 260,
    maxWidth: 230,
    borderColor: "#fff",
    borderWidth: 5,
    borderStyle: "solid",
    flex: 1,
    width: "100%",
    aspectRatio: 1,

    "@media (max-width: 350px)": {
      maxWidth: 180,

      marginBottom: 150,
    },
  },
  bottomContent: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 219,
    backgroundColor: "white",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    "@media (max-width: 350px)": {
      height: 160,
    },
  },
  icon: {
    width: 60,
    height: 60,
    position: "absolute",
    top: -25,
    right: 30,
    zIndex: 10000,
    "@media (max-width: 350px)": {
      width: 45,
      height: 45,
    },
  },
  xpContianer: {
    flexDirection: "row",
    marginTop: 5,
    alignItems: "center",
  },
  xpNumber: {
    color: "black",
    fontSize: 20,
    paddingLeft: 20,
    fontWeight: "bold",
    "@media (max-width: 350px)": {
      fontSize: 17,
    },
  },
  xpText: {
    color: "#FF0000",
    fontSize: 20,
    paddingLeft: 3,
    fontWeight: "bold",
    "@media (max-width: 350px)": {
      fontSize: 17,
    },
  },
  message: {
    alignItems: "center",
    marginTop: 30,
    "@media (max-width: 350px)": {
      marginTop: 15,
    },
  },
  cardMessage: {
    color: "black",
    ...FONTS.h2,
    fontSize: 18,
    "@media (max-width: 350px)": {
      fontSize: 16,
    },
  },
  completeContainer: {
    alignItems: "center",
    marginTop: 15,
  },
  completeButton: {
    backgroundColor: "#5CFF88",
    borderRadius: 15,
  },
  buttonText: {
    color: "white",
    fontSize: 23,
    fontWeight: "bold",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 10,
    paddingBottom: 10,
    "@media (max-width: 350px)": {
      fontSize: 18,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
  linkContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  linkTitle: {
    margin: 0,
    paddingLeft: 10,
    color: "#fff",
    fontSize: 19,

    textShadowColor: "#5cff88",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 1,
    "@media (max-width: 350px)": {
      fontSize: 17,
    },
  },
});
export default ExperienceDetailsPresentation;
