import React from "react";
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  Platform,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";
import images from "../../../constants/images";
import { MyWhy } from "../../northstarMyWhy/api/type";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
type NorthstarWhyItem = {
  item: MyWhy;
};
const NorthstarWhyItem: React.FC<NorthstarWhyItem> = ({ item }) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  return (
    <>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("NotehstarMyWhy", {
            titleref: item.title,
            descriptionref: item.description,
            myWhyIdref: item._id,
            editref: true,
          });
        }}
      >
        <ImageBackground
          source={images.northstaritems}
          resizeMode="contain"

          style={(Platform.OS === "web") ? [styles.northstarWhyItem , styles.northstarWhyItemWeb ] : styles.northstarWhyItem}
        >
          <View style={styles.content}>
            <View style={styles.box}>
              <Text numberOfLines={1} style={styles.title}>
                {item.title}
              </Text>
              <Text numberOfLines={1} style={styles.subTitle}>
                {item.description}
              </Text>
            </View>
          </View>
        </ImageBackground>
      </TouchableOpacity>
      {/* <TouchableOpacity
      onPress={() => {
        navigation.navigate("NotehstarMyWhy", {
          titleref: item.title,
          descriptionref: item.description,
          myWhyIdref: item._id,
          editref: true,
        });
      }}
    >
      <ImageBackground
        source={images.northstaritems}
        resizeMode="contain"
        style={styles.northstarWhyItem}
      >
        <View style={styles.content}>
          <View style={styles.box}>
            <Text numberOfLines={1} style={styles.title}>
              {item.title}
            </Text>
            <Text numberOfLines={1} style={styles.subTitle}>
              {item.description}
            </Text>
          </View>
        </View>
      </ImageBackground>
    </TouchableOpacity>
    <TouchableOpacity
      onPress={() => {
        navigation.navigate("NotehstarMyWhy", {
          titleref: item.title,
          descriptionref: item.description,
          myWhyIdref: item._id,
          editref: true,
        });
      }}
    >
      <ImageBackground
        source={images.northstaritems}
        resizeMode="contain"
        style={styles.northstarWhyItem}
      >
        <View style={styles.content}>
          <View style={styles.box}>
            <Text numberOfLines={1} style={styles.title}>
              {item.title}
            </Text>
            <Text numberOfLines={1} style={styles.subTitle}>
              {item.description}
            </Text>
          </View>
        </View>
      </ImageBackground>
    </TouchableOpacity> */}
    </>
  );
};

const { styles } = StyleSheet.create({
  title: {
    fontFamily: "Roboto-Bold",
    fontSize: 13,
    color: "#000",
    fontWeight: "bold",
    marginLeft: 20,
  },
  subTitle: {
    fontSize: 10,
    lineHeight: 15,
    marginLeft: 20,
  },
  northstarWhyItem: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    resizeMode: "cover",
    height: 62,
  },
  northstarWhyItemWeb:{
    height: 85,
    minHeight:85
  },
  box: {
    maxWidth: "95%",
    justifyContent: "center",
  },
  content: {
    width: "84%",
    height: 58,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    paddingRight: 20,
  },
});

export default NorthstarWhyItem;
