import React from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";

const ProfileSettingSwitchAccountTextPresentation = () => {

  return (

        <View style={styles.ProfileSettingMenuTextContainer}>

          <TextStroke stroke={ 2 } color={ '#FFF' } >
              <Text style={ {
                fontSize: 52,
                color: '#000',
              } }> Accounts </Text>

            </TextStroke>

        </View>

  );

};


const { styles } = StyleSheet.create({
    ProfileSettingMenuTextContainer:{
      top:60,
      width:"70%",
      position:"absolute",
      marginLeft:"15%",
      marginRight:"15%"
   },


});

export default ProfileSettingSwitchAccountTextPresentation;
