import React from "react";
import { Text, View } from "react-native";
import { COLORS, FONTS, SIZES } from "../constants";

type TabIconType = {
  focused: boolean;
  Icon: React.ReactNode;
  focusedIcon: React.ReactNode;
  title: string;
};
const TabIcon: React.FC<TabIconType> = ({
  focused,
  Icon,
  focusedIcon,
  title,
}) => {
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: 80,
        width: 50,
      }}
    >
      {focused ? focusedIcon : Icon}

      {focused && <></>}
    </View>
  );
};

export default TabIcon;
