import React, { useEffect, useState } from "react";
import {
  Image,
  Text,
  View,
  Animated,
  Dimensions,
  ActivityIndicator,
  TouchableHighlight,
  Platform,
} from "react-native";
import { images } from "../../constants";
import StyleSheet from "react-native-media-query";

type TimerSwiperPorps = {
  focuseCrystal: number;
  fuses: number;
  isMakeGem: boolean;
  onMakeGem: (status: boolean) => void;
  isSliderMakeGemOpen: boolean;
  onSetIsSliderMakeGemOpen: (status: boolean) => void;
};

const TimerSwiper: React.FC<TimerSwiperPorps> = ({
  focuseCrystal,
  fuses,
  isMakeGem,
  onMakeGem,
  isSliderMakeGemOpen,
  onSetIsSliderMakeGemOpen,
}) => {
  const [index, setIndex] = useState<boolean>(true);
  const [gemActive, setGemActive] = useState<boolean>(false);
  const [sliderIsOpen, setSliderIsOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState(new Animated.Value(-200));
  useEffect(() => {
    onMakeGem(gemActive);
  }, [gemActive]);
  const makeGemsPress = () => {
    if (sliderIsOpen) {
      if (gemActive) {
        setGemActive(true);
        setGemActive((gemActive) => !gemActive);
        // console.log("make gem open");
      } else {
        setGemActive(false);
        setGemActive((gemActive) => !gemActive);
        // console.log("make close gem");
      }
      setIndex(false);
    } else {
      setIndex(false);
      handlePress();
    }
  };

  const handlePress = () => {
    setIndex((index) => !index);
    // console.log(index , '11111')
    if (index) {
      // console.log(index , '2222')
      Animated.parallel([
        Animated.timing(current, {
          toValue: -4,
          duration: 400,
          useNativeDriver: false,
        }),
      ]).start();
      onSetIsSliderMakeGemOpen(true);
      setSliderIsOpen(true);
    } else {
      Animated.parallel([
        Animated.timing(current, {
          toValue: -200,
          duration: 400,
          useNativeDriver: false,
        }),
      ]).start();
      onSetIsSliderMakeGemOpen(false);
      setSliderIsOpen(false);
    }
  };

  const renderElement = () => {
    return (
      <View style={styles.mainView}>
        <TouchableHighlight
          activeOpacity={1}
          underlayColor="transparent"
          style={styles.timerSwipr}
          onPress={handlePress}
        >
          <Animated.View style={(Platform.OS === "web") ? [styles.timerBar, { left: current , width:"100%" }] : [styles.timerBar, { left: current }]}>
            <View style={styles.switerItem}>
              <Image style={styles.item} source={images.item} />
              <Text style={styles.itemText}>
                {isMakeGem && focuseCrystal >= 6000
                  ? focuseCrystal - 6000
                  : focuseCrystal}
              </Text>
            </View>
            <View style={styles.switerfuze}>
              <Image style={styles.fuze} source={images.fuze} />
              <Text style={styles.itemText}>
                {isMakeGem && fuses >= 1 ? fuses - 1 : fuses}
              </Text>
            </View>
            <TouchableHighlight
              onPress={makeGemsPress}
              style={[
                styles.switermMake,
                [gemActive ? styles.switermMakeCancel : null],
              ]}
            >
              <Text style={styles.itemMakeText}>
                {gemActive ? "CANCEL GEMS" : "MAKE GEMS"}{" "}
              </Text>
            </TouchableHighlight>
            <Image style={styles.arrow} source={images.arrow} />
          </Animated.View>
        </TouchableHighlight>
      </View>
    );
  };

  return renderElement();
};

const { styles } = StyleSheet.create({
  mainView: {
    marginTop: 20,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  itemText: {
    fontFamily: "HelveticaBold",
    fontSize: 20,
    color: "#fff",
    paddingLeft: 4,
    "@media (max-height: 720px)": {
      fontSize: 16,
    },
  },
  itemMakeText: {
    fontFamily: "HelveticaBold",
    fontSize: 11,
    color: "#fff",
    "@media (max-height: 720px)": {
      fontSize: 7,
    },
  },
  switerItem: {
    minWidth: 80,
    height: 39,
    borderColor: "#46E6FF",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "space-around",
    "@media (max-height: 720px)": {
      height: 23,
      minWidth: 70,
    },
  },
  switerfuze: {
    minWidth: 85,
    height: 39,
    marginLeft: 10,
    borderColor: "#fff",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 50,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: "center",
    justifyContent: "space-around",
    "@media (max-height: 720px)": {
      height: 23,
      minWidth: 60,
    },
  },
  switermMake: {
    width: 110,
    height: 39,
    marginLeft: 8,
    borderColor: "#fff",
    backgroundColor: "#23A9E1",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 50,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: "center",
    justifyContent: "space-around",
    "@media (max-height: 720px)": {
      height: 23,
      width: 68,
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  switermMakeCancel: {
    backgroundColor: "#D34E4E",
  },
  timerBar: {
    backgroundColor: "#000",
    width: "85%",
    height: 56,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    borderColor: "#fff",
    borderWidth: 3,
    borderStyle: "solid",
    justifyContent: "center",
    flexDirection: "row",
    paddingTop: 5,
    paddingRight: 8,

    "@media (max-height: 720px)": {
      height: 40,
      width: "90%",
      borderWidth: 2,
    },
  },

  timerSwipr: {
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    width: 20,
    height: 30,
    resizeMode: "contain",
    "@media (max-height: 720px)": {
      width: 17,
      height: 17,
    },
  },
  fuze: {
    width: 36,
    height: 20,
    resizeMode: "contain",
    "@media (max-height: 720px)": {
      width: 17,
      height: 17,
    },
  },
  arrow: {
    width: 16,
    height: 14,
    justifyContent: "center",
    alignItems: "center",
    top: 10,
    marginLeft: 8,
    "@media (max-height: 720px)": {
      width: 12,
      height: 12,
      top: 6,
    },
  },
});

export default TimerSwiper;
