import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { TeamAndScore } from "../types/type";

const teamScoreAdaptor = createEntityAdapter<TeamAndScore>({
  selectId: (teamscore) => teamscore.id,
});

interface TeamScoreInitialState extends EntityState<TeamAndScore> {}

const initialState: TeamScoreInitialState = teamScoreAdaptor.getInitialState();

export const extendsTeamScoreApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeamScore: builder.query<
      EntityState<TeamAndScore>,
      {
        currentSystem: string;
        month: string;
      }
    >({
      query: (data) =>
        `/student/proejctTeam?currentSystem=${data.currentSystem}&month=${data.month}`,
      transformResponse: (response: TeamAndScore) => {
        return teamScoreAdaptor.setOne(initialState, response);
      },
      providesTags: (result, error, arg) => [
        ...result!.ids.map((id) => ({ type: "TeamAndScore" as const, id })),
      ],
    }),
    getTeamCategoryScore: builder.query<
      EntityState<TeamAndScore>,
      {
        currentSystem: string;
        month: string;
      }
    >({
      query: (data) =>
        `/student/categoryteam?currentSystem=${data.currentSystem}&month=${data.month}`,
      transformResponse: (response: TeamAndScore) => {
        return teamScoreAdaptor.setOne(initialState, response);
      },
      providesTags: (result, error, arg) => [
        ...result!.ids.map((id) => ({ type: "TeamAndScore" as const, id })),
      ],
    }),
  }),
});

export const { useGetTeamScoreQuery, useGetTeamCategoryScoreQuery } =
  extendsTeamScoreApi;
