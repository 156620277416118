import React from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Text,
  Image,
  Platform,
  TouchableOpacity,
} from "react-native";
import { ExperienceCard } from "../../experience/types/types";
import { images } from "../../../constants";
const isAndroid = Platform.OS === "android" ? true : false;
type ExperienceCardItemPresentationProp = {
  experience: ExperienceCard;
  onOpenDetails: (item: Partial<ExperienceCard>) => void;
  onItemClickHanlder: (card: Partial<ExperienceCard>) => void;
};
const ExperienceCardItemPresentation: React.FC<
  ExperienceCardItemPresentationProp
> = ({ experience, onOpenDetails, onItemClickHanlder }) => {
  return (
    <View style={{ marginBottom: 20 }}>
      <View>
        <TouchableOpacity
          style={{}}
          onPress={() => {
            onOpenDetails(experience);
            onItemClickHanlder(experience);
          }}
        >
          <View
            style={[
              styles.missionsContainer,
              { backgroundColor: experience.experienceType.color },
            ]}
          >
            <Text numberOfLines={1} style={styles.missionsTitle}>
              {experience.title}
            </Text>
            <View style={styles.missionsDay}>
              <Image
                source={images.ClanderTime}
                resizeMode="contain"
                style={{ width: 12, height: 12 }}
              />
              <Text style={styles.missionsDate}>
                {new Date() < new Date(experience.endDate)
                  ? "Active"
                  : new Date("").toLocaleDateString("en-US")}
              </Text>
            </View>
            <Image
              source={{
                uri: experience.image!,
              }}
              resizeMode="contain"
              style={styles.missionsImage}
            />
            <View style={styles.xp}>
              <Text style={styles.xpNumber}>{experience.xpCompletion}</Text>
              <Text style={styles.xpStyle}>XP</Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  missionsContainer: {
    width: 120,

    borderRadius: 4,
    alignItems: "center",
    marginRight: 5,
    marginLeft: 5,
    height: isAndroid ? 170 : 165,
  },
  missionsTitle: {
    marginTop: 7,
    fontSize: 15,
    lineHeight: 20,
    color: "#fff",
  },
  missionsDay: {
    flexDirection: "row",
    width: "100%",
    marginLeft: 6,
    marginTop: 6,
    alignItems: "center",
  },
  missionsDate: {
    fontSize: 16,
    paddingLeft: 5,
    color: "#fff",
  },
  xp: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    marginRight: 5,
  },
  xpNumber: {
    fontSize: 22,

    textShadowColor: "#fff",
    textShadowOffset: { width: -1, height: 2 },
    textShadowRadius: 0,
  },
  xpStyle: {
    fontSize: 22,
    textShadowColor: "#fff",
    textShadowOffset: { width: -1, height: 2 },
    textShadowRadius: 0,
    color: "#FF0000",
  },
  missionsImage: {
    width: "100%",
    height: 85,
  },
  feedTitle: {
    fontSize: 15,

    marginTop: 6,
    marginBottom: 6,
    paddingLeft: 10,
  },
});

export default ExperienceCardItemPresentation;
