import { LinearGradient } from "expo-linear-gradient";
import React, { useRef, useEffect } from "react";
import {
  Modal,
  Pressable,
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  Animated,
  Platform,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { images } from "../../constants";
import { Image } from "expo-image";
import LottieView from "lottie-react-native";
import changeSVGColor from "@killerwink/lottie-react-native-color";
import AnimatedLottieView from "lottie-react-native";
import { Shop } from "../../features/shop/types/types";
const { width } = Dimensions.get("window");
type LockPopupProps = {
  open: boolean;
  onClose: () => void;
  item: Partial<Shop> | null;
  onPressUnlock: (item: Partial<Shop>) => void;
};
const LockPopup: React.FC<LockPopupProps> = ({
  open,
  onClose,
  item,
  onPressUnlock,
}) => {
  const pageStatus = "UNLOCK_LOADING";

  const lockAnimation = React.createRef();
  const lockProgress = useRef(new Animated.Value(0)).current;

  const backAnimations = React.createRef<LottieView>();
  const backProgress = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    // @ts-ignore
    if (lockAnimation && lockAnimation?.current) lockAnimation.current.play();
    if (backAnimations && backAnimations?.current)
      // @ts-ignore
      backAnimations.current.play();
  });
  return (
    <Modal transparent={true} visible={open} animationType="fade">
      <Pressable style={styles.modelContainer} onPress={onClose}>
        <View style={styles.imageContainer}>
          <LinearGradient
            colors={["#13C4B8", "#fff"]}
            style={styles.modalImageContainer}
            start={{ x: 0.5, y: 0 }}
            end={{ x: 0.5, y: 1 }}
          >
            <TouchableOpacity onPress={onClose} style={styles.popupClose}>
              <Image
                source={images.iconClose}
                style={{
                  width: 25,
                  height: 25,
                }}
              />
            </TouchableOpacity>
            <Text style={styles.title}>New Item</Text>
            {(Platform.OS === "web") ?   <></>  :  <>
            <LottieView
                   style={{ width: "100%", flexGrow: 1 }}
                   //@ts-ignore
                   ref={backAnimations}
                   loop
                   source={changeSVGColor(images.BackAnimattion, "#000")}
                   progress={backProgress}
                 />
                 <LottieView
                   style={{
                     width: "70%",
                     top: 30,
                     position: "absolute",
                     marginLeft: "9%",
                   }}
                   //@ts-ignore
                   ref={lockAnimation}
                   loop={false}
                   source={changeSVGColor(images.UnLockAnimation, "#000")}
                   progress={lockProgress}
                 />
            </>

             }
      
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={[styles.popupCloseButton]}
                onPress={() => {
                  onPressUnlock(item!);
                }}
              >
                <Text style={styles.buttonText}>
                  UnLock
                  {/* {pageStatus === "UNLOCK_LOADING" ? "Loading..." : "Unlock"} */}
                </Text>
              </TouchableOpacity>
            </View>
          </LinearGradient>
        </View>
      </Pressable>
    </Modal>
  );
};

const { styles } = StyleSheet.create({
  buttonContainer: {
    height: 35,
    backgroundColor: "#fff",
    borderRadius: 30,
    justifyContent: "flex-end",
    alignContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#fff",
    paddingLeft: 20,
    paddingRight: 20,
    "@media (max-width: 350px)": {
      fontSize: 28,
    },
  },
  popupCloseButton: {
    backgroundColor: "#4ED386",
    height: 53,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: 8,
    width: "70%",
    zIndex: 2,
    shadowColor: "rgba(0,0,0,1)",
    marginBottom: 10,
    shadowOffset: {
      width: -2,
      height: 5,
    },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 1,
  },
  userContent: {
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    width: 77,
  },
  popupClose: {
    position: "absolute",
    top: 14,
    left: 17,
    zIndex: 10,
  },
  modalImageContainer: {
    position: "relative",

    width: "95%",
    backgroundColor: "#5e5e5e",

    borderWidth: 10,
    borderStyle: "solid",
    borderColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },

    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
  },

  modelContainer: {
    backgroundColor: "#000000aa",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",

    maxWidth: "100%",
    width: width,
  },

  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    maxWidth: 374,
    maxHeight: 480,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "red",
    alignSelf: "center",
  },
  title: {
    fontSize: 40,
    paddingTop: 20,
    color: "#fff",
    alignItems: "center",
    textAlign: "center",

    "@media (max-width: 350px)": {
      fontSize: 30,
    },
  },
});
export default LockPopup;
