import { EntityState } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import {
  useGetAllExperienceCompleteListQuery,
  useLazyGetAllExperienceCompleteListQuery,
  useLazyGetAllPassDueExperienceQuery,
} from "../api/experienceApiSlice";
import { ExperienceCard } from "../types/types";
import ExperienceListPresentation from "../presentation/ExperienceListPresentation";
import ExperienceDetailsPresentation from "../presentation/ExperienceDetailsPresentation";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { View } from "react-native";
import { useGetAllCourseGradeWithXpQuery } from "../../gradeReward/api/gradeRewardapi";
import { GradeReward } from "../../gradeReward/types/GradeReward";
import { useGetAllPhotosQuery } from "../../cameraFeatures/api/experiencePhotoApiSlice";

const ExperienceListCompleteContainer = () => {
  const systemSelected = useAppSelector(selectDefaultAccount);
  const {
    data: completeListExp,
    isLoading: ceLoading,
    isSuccess: ceSuccess,
    isError: ceError,
  } = useGetAllExperienceCompleteListQuery({
    currentSystem: JSON.stringify(systemSelected!),
  });

  const [getAllExperienceCompleteList, { isLoading, isError, isSuccess }] =
    useLazyGetAllExperienceCompleteListQuery();
  const {
    data: gradeCourses,
    isLoading: gradeCoursesLoading,
    isError: gradeCoursesError,
    isSuccess: gradeCoursesSuccess,
  } = useGetAllCourseGradeWithXpQuery({
    currentSystem: JSON.stringify(systemSelected),
  });

  const {
    data: photosList,
    isLoading: photoListLoading,
    isError: photoListError,
    isSuccess: photoListSuccess,
  } = useGetAllPhotosQuery({
    currentSystem: JSON.stringify(systemSelected!),
    sort: "-updatedAt",
  });

  const [expList, setExpList] = useState<EntityState<ExperienceCard> | null>(
    null
  );

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [item, setItem] = useState<Partial<ExperienceCard> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [gradeCourseTotal, setGradeCourseTotal] = useState<
    GradeReward[] | null
  >(null);

  useEffect(() => {}, [photosList]);
  const onItemClickHanlder = (card: Partial<ExperienceCard>) => {
    setItem(card);
  };

  const openDetailsHandler = () => {
    setOpenDetails(true);
  };

  const closeDetailsHandler = () => {
    setOpenDetails(false);
  };

  useEffect(() => {
    if (completeListExp) {
      setExpList(completeListExp!);
    }
  }, [completeListExp]);

  useEffect(() => {
    (async () => {
      if (systemSelected?._id) {
        setLoading(true);
        try {
          const list = await getAllExperienceCompleteList({
            currentSystem: JSON.stringify(systemSelected!),
          }).unwrap();

          setExpList(list);
          setLoading(false);
        } catch (error) {}
      }
    })();
  }, [systemSelected]);

  useEffect(() => {
    if (gradeCourses) {
      const scores = Object.values(gradeCourses.entities) as GradeReward[];
      const result = scores.filter(
        (item) => Number(item.course_completion_percentage) === 100
      );
      setGradeCourseTotal(result);
    }
  }, [gradeCourses]);

  let content;

  if (loading) {
    content = (
      <View style={{ height: "80%", justifyContent: "center" }}>
        <LoadingScreen iconSize={50} />
      </View>
    );
  } else if (isSuccess) {
    content = (
      <>
        {isSuccess && expList && (
          <>
            <ExperienceListPresentation
              cards={expList!}
              onItemClick={onItemClickHanlder}
              openDetailsHandler={openDetailsHandler}
              gradeCourseTotal={gradeCourseTotal}
              complete={true}
            />
            {openDetails && photoListSuccess && (
              <ExperienceDetailsPresentation
                open={openDetails}
                item={item}
                onClose={closeDetailsHandler!}
                completeHandler={() => {
                  // setOpenDetails(false);
                }}
                complete={true}
                photoList={photosList!}
              />
            )}
          </>
        )}
      </>
    );
  }
  return <>{content}</>;
};

export default ExperienceListCompleteContainer;
