import React, { FC, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  Modal,
  Dimensions,
  Pressable,

} from "react-native";
import StyleSheet from "react-native-media-query";
import { LinearGradient } from "expo-linear-gradient";
import { images } from "../../../constants";


const { width, height } = Dimensions.get("window");
export interface IFExperienceHotspotItemSelectPopup {
  onClose: () => void;
}

const ExperienceHotspotItemSelectPopup: FC<
  IFExperienceHotspotItemSelectPopup
> = ({ onClose }) => {

  return (
    <Modal
      transparent={true}
      animationType="fade"
      onRequestClose={() => {
        // onClose();
      }}
    >
      <Pressable
        style={styles.modelContainer}
        onPress={(event) => {
          if (event.target == event.currentTarget) {
            // onClose();
          }
        }}
      >
        <LinearGradient
          colors={["#fff", "#fff"]}
          style={styles.modalImageContainer}
        >
          <View style={styles.detailsContentContainer}>
            <View style={styles.hotspotPopupHeader}>
              <View style={styles.hotspotPopupIconContainer}>
                <Image
                  source={images.hotspotPopupIcon}
                  style={styles.hotspotPopupIcon}
                />
              </View>

              <Text style={styles.hotspotPopupHeadeTitle}>Revive</Text>
            </View>
            <View style={styles.hotspotPopupContent}>
              <Text style={styles.hotspotPopupContentTitle}>
                You Have 3 days
              </Text>
              <Image
              resizeMode="contain"
                style={{ width: 120, height: 120, padding:10, display:"flex", alignItems:"center" }}
          
                source={images.reviveGem}

              />
            </View>
          </View>
        </LinearGradient>
      </Pressable>
    </Modal>
  );
};
const { styles } = StyleSheet.create({
  hotspotPopupContentTitle: {
    fontSize: 35,
    color: "#fff",
    textAlign: "center",
    fontWeight: "600",
    letterSpacing: -1,
    
    lineHeight: 50,
  },

  hotspotPopupContent: {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    alignContent:"center",
    paddingBottom:15
  },

  modelContainer: {
    flex: 1,
    backgroundColor: "#000000aa",

    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    maxWidth: "100%",
    width: width,
  },
  hotspotPopupIconContainer: {
    width: 70,
    height: 70,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottomRightRadius: 4,
  },
  hotspotPopupHeader: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailsContentContainer: {
    backgroundColor: "#000",
  },
  hotspotPopupHeadeTitle: {
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: "#fff",
    backgroundColor: "#000",
    color: "#fff",
    fontSize: 50,
    textAlign: "center",
    justifyContent: "center",
    height: 62,
    width: "76%",
    borderRadius: 7,
    lineHeight: 55,
    overflow: "hidden",
    right: -5,
  },
  modalImageContainer: {
    position: "relative",
    maxWidth: 374,
    maxHeight: 320,
    width: "95%",
    backgroundColor: "#000",
    borderRadius: 15,
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    marginTop: "3%",
    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
  },
  hotspotPopupIcon: {
    width: 56,
    height: 56,
  },
});
export default ExperienceHotspotItemSelectPopup;
