import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin } from "../../auth/slice/authSlice";
import {} from "react-native";
import ProfileSettingHeaderPresentation from "../presentation/ProfileSettingHeaderPresentation";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";

type ProfileSettingHeaderContainerProps = {
  backgroundProfile: string;
  goBackHandler: () => void;
  showSetting: boolean;
};

const ProfileSettingHeaderContainer: React.FC<
  ProfileSettingHeaderContainerProps
> = ({ backgroundProfile, goBackHandler, showSetting }) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();

  const goAccountSetting = () => {
    navigation.navigate("Setting");
  };
  return (
    <ProfileSettingHeaderPresentation
      backgroundProfile={backgroundProfile}
      goBackHandler={goBackHandler}
      goAccountSetting={goAccountSetting}
      showSetting={showSetting}
    />
  );
};

export default ProfileSettingHeaderContainer;
