import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { LearnerSetting } from "../type/LearnerSetting";
import { Project } from "../../auth/types/types";

const learnerSettingAdaptor = createEntityAdapter<LearnerSetting>({
  selectId: (learnerSettig) => learnerSettig._id,
});

interface ILearnerSettingInitialState extends EntityState<LearnerSetting> {}

const initialState: ILearnerSettingInitialState =
  learnerSettingAdaptor.getInitialState();

export const extendLearnerSettingApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getALearnerSetting: builder.query<EntityState<LearnerSetting>, EntityId>({
      query: (project) => `/learner-setting/${project}`,
      transformResponse: (response: LearnerSetting) => {
        return learnerSettingAdaptor.setOne(initialState, response);
      },
      providesTags: ["LearnerSetting"],
    }),
  }),
});

export const { useGetALearnerSettingQuery } = extendLearnerSettingApi;
