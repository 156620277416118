import React from "react";
import ProfileSettingMenuTextPresentation from "../presentation/ProfileSettingMenuTextPresentation";
import ProfileSettingSwitchAccountTextPresentation from "../presentation/ProfileSettingSwitchAccountTextPresentation";


const ProfileSettingSwitchAccountTextContainer= () => {

  return (
    <ProfileSettingSwitchAccountTextPresentation/>
  );
};

export default ProfileSettingSwitchAccountTextContainer;
