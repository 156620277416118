import React, { useEffect } from "react";
import {
  TouchableOpacity,
  Dimensions,
  Pressable,
  Text,
  View,
  FlatList,
  TextInput,
  Platform,
  ScrollView,
  Alert,
  KeyboardAvoidingView,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { Ionicons } from "@expo/vector-icons";
import Modal from "react-native-modal";
import { EntityState } from "@reduxjs/toolkit";
import { Comment } from "../types";
import { Image } from "expo-image";
import { formatDistance, subDays } from "date-fns";
import {
  useAddLikeCommentMutation,
  useGetAllCommentQuery,
} from "../api/commentApi";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { images } from "../../../constants";
import { AntDesign } from "@expo/vector-icons";
import { Swipeable } from "react-native-gesture-handler";
import CommentListItem from "./CommentListItem";

const { width, height } = Dimensions.get("window");
interface GestureState {
  dx?: number;
  // other gesture state properties
}
type CommentPopupProps = {
  openPopup?: boolean;
  onClose?: () => void;
  commentList: EntityState<Comment> | null;
  comment: string;
  onChangeComment: (value: string) => void;
  onAddComment: () => void;
  photoSelected: Partial<ExperiencePhoto> | null;
  commentLength: number;
};
const CommentPopup: React.FC<CommentPopupProps> = ({
  openPopup,
  onClose,
  commentList,
  comment,
  onChangeComment,
  onAddComment,
  photoSelected,
  commentLength,
}) => {
  const [
    addLikeComment,
    {
      isLoading: addLikeLoading,
      isError: addLikeError,
      isSuccess: addlikeSuccess,
    },
  ] = useAddLikeCommentMutation();
  const {
    data: getcommentsList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllCommentQuery(photoSelected?._id! as string, {
    refetchOnMountOrArgChange: true,
  });

  const addCommetnLikeHandler = async (item: Comment) => {
    try {
      await addLikeComment({
        comment: item._id as string,

        like: !item.commentLike?.like,
      }).unwrap();
    } catch (error) {}
  };

  return (
    <Modal
      statusBarTranslucent
      style={{
        height: height - 150,
        backgroundColor: "rgba(0,0,0,0.1)",
        margin: 0,
        padding: 0,
        width: width,
        flex: 1,
        justifyContent: "flex-end",
      }}
      // style={{
      //   margin: 0,
      //   height: height - 50,
      //   backgroundColor: "rgba(0,0,0,0.1)",
      // }}
      swipeDirection="down"
      onSwipeComplete={() => {
        onClose!();
      }}
      isVisible={openPopup}
      animationIn="slideInUp"
      propagateSwipe
    >
      {isSuccess && getcommentsList && (
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "padding"}
          style={styles.container}
        >
          <View
            style={{
              backgroundColor: "#272626",
              width: width,
              height: height - 150,
              borderTopLeftRadius: 25,
              borderTopEndRadius: 25,
              overflow: "hidden",
              margin: 0,
              paddingTop: 20,
            }}
          >
            <View
              style={{
                backgroundColor: "#585858",
                width: "36%",
                height: 6,
                borderRadius: 40,
                marginLeft: "32%",
                marginBottom: 20,
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            ></View>

            {isSuccess && getcommentsList && (
              <FlatList
                style={{
                  width: width,

                  paddingLeft: 4,
                  paddingRight: 0,
                }}
                data={Object.values(getcommentsList!.entities) as Comment[]}
                keyExtractor={(item) => `${item._id}`}
                renderItem={({ item }) => <CommentListItem item={item} />}
              />
            )}

            <View>
              <TextInput
                style={{
                  marginBottom: 20,
                  backgroundColor: "white",
                  borderRadius: 30,
                  minHeight: 50,
                  width: "96%",
                  marginLeft: "3%",
                  color: "#585858",
                  marginTop: 10,
                  padding: 5,
                  paddingLeft: 20,
                  paddingRight: 40,
                  lineHeight: 14,
                  fontSize: 12,
                  fontFamily: "HelveticaBold",
                }}
                value={comment}
                maxLength={140}
                multiline={true}
                onChangeText={(text: string) => {
                  onChangeComment(text);
                }}
              />
              <TouchableOpacity
                onPress={onAddComment}
                accessible={false}
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 0,
                  height: "100%",
                  justifyContent: "center",
                  marginTop: 5,
                  paddingRight: 10,
                }}
              >
                <Ionicons name="send" size={30} color="black" />
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "HelveticaBold",
                    color: "#585858",
                  }}
                >
                  {commentLength}/140
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAvoidingView>
      )}
    </Modal>
  );
};

const { styles } = StyleSheet.create({
  container: {},
  backTextWhite: {
    color: "#FFF",
  },
  rowFront: {
    alignItems: "center",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    height: 50,
  },
  rowBack: {
    alignItems: "center",
    backgroundColor: "#DDD",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: "blue",
    right: 75,
  },
  backRightBtnRight: {
    backgroundColor: "red",
    right: 0,
  },
});

export default CommentPopup;
