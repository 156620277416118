import React, { useEffect, useState } from "react";
import ProfileSettingHeaderContainer from "./ProfileSettingHeaderContainer";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { useNavigation } from "@react-navigation/native";
import ProfileSettingListMenu from "../presentation/ProfileSettingListMenu";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import ProfileSettingMenuTextContainer from "./ProfileSettingMenuTextContainer";

const ProfileSettingContainer = () => {
  const systemSelected = useAppSelector(selectDefaultAccount);
  const admin = useAppSelector(selectAdmin);
  const user = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const goBackHandler = () => {
    navigation.goBack();
  };
  const [xps, setXps] = useState<number>(0);
  const [studentLevel, setStudentLevel] = useState<number>(0);

  useEffect(() => {
    // setSystem(systemSelected);
    if (admin) {
      const projectReport = admin.projectReport.find(
        (item) => item.project === systemSelected!.project
      );

      if (projectReport) {
        setXps(projectReport.xps);
        setStudentLevel(Math.floor(projectReport.xps / 3500));
      }
    }
  }, [admin, systemSelected]);

  const onProfileSettingClick = () => {
    navigation.navigate("ProfileAccountSetting");
  };

  const onPrizesClick = () => {
    navigation.navigate("Prizes");
  };

  const onFriendsClick = () => {
    navigation.navigate("Friend");
  };

  const onTeamsAndScoreClick = () => {
    navigation.navigate("TeamsScore");
  };

  const onBadgesClick = () => {
    navigation.navigate("Badges", {
      student: admin!,
    });
  };
  const onBadgeSystemClick = () => {
    navigation.navigate("BadgeSystem", {
      student: admin!,
    });
  };

  const onNotehstarClick = () => {
    navigation.navigate("Notehstar");
  };


  return (
    <>
      <ProfileSettingHeaderContainer
        backgroundProfile={user?.backgroundBanner!}
        goBackHandler={goBackHandler!}
        showSetting={true}
      />

      <ProfileSettingMenuTextContainer />

      <ProfileSettingListMenu
        onProfileSettingClick={onProfileSettingClick}
        onPrizesClick={onPrizesClick}
        onFriendsClick={onFriendsClick}
        onTeamAndScore={onTeamsAndScoreClick}
        onBadgesClick={onBadgesClick}
        onBadgeSystemClick={onBadgeSystemClick}
        onNotehstarClick={onNotehstarClick}
      />
    </>
  );
};

export default ProfileSettingContainer;
