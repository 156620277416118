import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";
import CircleChart from "../../../components/CircleChart/CircleChart";
import CircleLevelChart from "../../../components/CircleLevelChart/CircleLevelChart";
import { ProfilePost } from "../api/types/types";
import { calulateCircleChartPercent } from "../../../helper/chart.helper";
type BadgesSocialConnectionPresentationProps = {
  posts: ProfilePost;
  friendsCount: number;
  likeCount: number;
  loginCount: number;
  onSocialPoint: () => {
    socialPoint: number;
    lv: number;
  };
  rank: number;
};
const BadgesSocialConnectionPresentation: React.FC<
  BadgesSocialConnectionPresentationProps
> = ({ posts, friendsCount, likeCount, loginCount, onSocialPoint, rank }) => {
  const [level, setLevel] = useState<number>(0);
  const [percent, setPercent] = useState<number>(0);
  useEffect(() => {
    const respnose = onSocialPoint();

    setLevel(respnose.lv);
    setPercent(respnose.socialPoint);
  }, [onSocialPoint().lv, onSocialPoint().socialPoint]);
  return (
    <View style={styles.socialConnection}>
      <View>
        <CircleLevelChart
          chartPercentCal={calulateCircleChartPercent(380, (percent % 50) * 2)}
          level={level}
          percent={percent}
          rank={rank}
        />
      </View>
      <View>
        <Text numberOfLines={1} style={styles.badgesText}>
          {" "}
          Social Connection
        </Text>
        <View style={styles.badgesDetailList}>
          <View style={styles.badgesDetailItem}>
            <Text style={styles.badgesDetailItemTitle}>Posts</Text>
            <Text style={styles.badgesDetailItemValue}>
              {posts
                ? posts.count
                  ? posts?.count?.postStudnetCount
                  : "0"
                : "0"}
            </Text>
          </View>

          <View style={styles.badgesDetailItem}>
            <Text style={styles.badgesDetailItemTitle}>Friends</Text>
            <Text style={styles.badgesDetailItemValue}>{friendsCount}</Text>
          </View>

          <View style={styles.badgesDetailItem}>
            <Text style={styles.badgesDetailItemTitle}>Reactions</Text>
            <Text style={styles.badgesDetailItemValue}>{likeCount}</Text>
          </View>

          <View style={styles.badgesDetailItem}>
            <Text style={styles.badgesDetailItemTitle}>Logins</Text>
            <Text style={styles.badgesDetailItemValue}>{loginCount}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  badgesDetailItemTitle: {
    fontFamily: "RajdahaniBold",
    fontSize: 18,
    color: "#3E3F5E",
  },
  badgesDetailItemValue: {
    fontFamily: "RajdahaniBold",
    fontSize: 18,
    color: "#B7B7BC",
  },
  badgesDetailList: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    width: "77%",
    justifyContent: "center",
  },
  badgesDetailItem: {
    width: "45%",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    paddingTop: 6,
  },
  socialConnection: {
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 5,
    borderRadius: 4,
    shadowColor: "#000",
    alignSelf: "center",
    width: "100%",
    top: -195,
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.12,
    shadowRadius: 6.84,
    elevation: 7,
    padding: 10,
  },
  badgesText: {
    fontSize: 22,
    color: "#000",
    letterSpacing: 1,
    fontFamily: "RajdahaniBold",
  },
});

export default BadgesSocialConnectionPresentation;
