import React from "react";
import { View, Text , Image} from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";
import images from "../../../constants/images";

const NotehstarTextPresentation = () => {

  return (

        <View style={styles.ProfileSettingMenuTextContainer}>

          <TextStroke stroke={ 2 } color={ '#FFF' } >
              <Text style={ {
                fontSize: 50,
                color: '#FFCE35',
                letterSpacing:3
              } }> Northstar </Text>

            </TextStroke>
            <Image
                source={images.northstar}
                style={{
                    maxWidth:85,
                    maxHeight:85,
                    marginLeft:-15,
                    marginTop:-20
                }}
              />

        </View>

  );

};


const { styles } = StyleSheet.create({
    ProfileSettingMenuTextContainer:{
      marginTop:-10,
      width:"100%",
      flexDirection:"row",
      marginLeft:20

   },


});

export default NotehstarTextPresentation;
