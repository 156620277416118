import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";

import { Dimensions, ImageBackground, ScrollView } from "react-native";

import NotehstarTextContainer from "./NotehstarTextContainer";
import NotehstarPresentation from "../presentation/NorthstarPresentation";
import NorthstarBackButton from "../presentation/NorthstarBackButton";
import { images } from "../../../constants";
import {
  useAddNorthStarMutation,
  useGetNorthStarQuery,
} from "../api/northStar.api";
import { Project } from "../../auth/types/types";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import {
  useGetMyWhyQuery,
  useLazyGetMyWhyQuery,
} from "../../northstarMyWhy/api/myWhy";
import { MyWhy } from "../../northstarMyWhy/api/type";

const { width, height } = Dimensions.get("window");

const NotehstarMainContainer = () => {
  const user = useAppSelector(selectAdmin);
  const account = useAppSelector(selectDefaultAccount);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [addedNorth, setAddNorth] = useState<boolean>(false);

  const {
    data: getNorth,
    isLoading: northLoading,
    isError: northError,
    isSuccess: northSuccess,
  } = useGetNorthStarQuery();
  const [addNorthStar, { isLoading, isError, isSuccess }] =
    useAddNorthStarMutation();
  const goBackHandler = () => {
    navigation.goBack();
  };

  const [amount, setAmount] = useState<number>(5);
  const [unit, setUnit] = useState<string>("Month(s)");
  const [title, setTitle] = useState<string | null>(null);
  const [northId, setNorthId] = useState<EntityId | null>(
    getNorth ? getNorth?.entities[getNorth.ids[0]]?._id! : null
  );
  const [myWhyItems, setMyWhyItems] = useState<EntityState<MyWhy> | null>(null);

  const [
    getMyWhy,
    { isLoading: getMyLoading, isError: getMyError, isSuccess: getMySuccess },
  ] = useLazyGetMyWhyQuery();

  const {
    data: myWy,
    isLoading: mLoading,
    isError: mError,
    isSuccess: mSuccess,
  } = useGetMyWhyQuery({
    northStar: String(northId!),
  });

  useEffect(() => {
    if (myWy) {
      setMyWhyItems(myWy);
    }
  }, [myWy]);

  useEffect(() => {
    //get my why
    (async () => {
      if (getNorth) {
        try {
          setAmount(getNorth?.entities[getNorth.ids[0]]?.amount!);
          setUnit(getNorth?.entities[getNorth.ids[0]]?.unit!);
          setTitle(getNorth?.entities[getNorth.ids[0]]?.title!);
          setNorthId(getNorth?.entities[getNorth.ids[0]]?._id!);
          setAddNorth(true);

          const my = await getMyWhy({
            northStar: String(getNorth?.entities[getNorth.ids[0]]?._id!),
          }).unwrap();

          setMyWhyItems(my);
        } catch (error) {}
      }
    })();
  }, [getNorth, northId]);

  useEffect(() => {}, [getNorth?.entities[getNorth.ids[0]]?._id!]);

  const onAmount = (a: number) => {
    setAmount(a);
  };

  const onUnit = (u: string) => {
    setUnit(u);
  };

  const onTitle = (t: string) => {
    setTitle(t);
  };

  const onLetsGo = async () => {
    let res;
    try {
      if (!addedNorth) {
        if (title && amount && unit) {
          res = await addNorthStar({
            amount,
            unit,
            title: title!,
            project: account!.project! as Project,
          }).unwrap();
          setAddNorth(true);
          setNorthId(res.ids[0]!);
          navigation.navigate("NotehstarMyWhy", {
            northStar: res.ids[0]!,
          });
        } else {
          alert("Please fill the inputs");
        }
      } else {
        navigation.navigate("NotehstarMyWhy", {
          northStar: northId!,
        });
      }
    } catch (error) {}
  };

  return (
    <>
      <ScrollView>
        <ImageBackground
          source={images.northstarback}
          resizeMode="cover"
          style={{
            minHeight: height,
          }}
        >
          <NorthstarBackButton goBackHandler={goBackHandler!} />

          <NotehstarTextContainer />

          <NotehstarPresentation
            amount={amount}
            onAmount={onAmount}
            unit={unit}
            onUnit={onUnit}
            onTitle={onTitle}
            title={title!}
            onLetsGo={onLetsGo}
            addedNorth={addedNorth}
            myWhyItems={myWhyItems}
          />
        </ImageBackground>
      </ScrollView>
    </>
  );
};

export default NotehstarMainContainer;
