import React, { useEffect, useState } from "react";
import SwitchAccountPresentation from "../features/profileSetting/presentation/SwitchAccountPresentation";
import SwitchAccountContainer from "../features/profileSetting/container/SwitchAccountContainer";
import { useNavigation } from "@react-navigation/native";
import {
  selectAdmin,
  selectDefaultAccount,
} from "../features/auth/slice/authSlice";
import ProfileSettingHeaderContainer from "../features/profileSetting/container/ProfileSettingHeaderContainer";
import ProfileSettingImageContainer from "../features/profileSetting/container/ProfileSettingImageContainer";
import { HomeScreenNavigationProps } from "../navigation/types";
import { useAppSelector } from "../redux/hooks";
import { SafeAreaView, ScrollView } from "react-native";
import ProfileSettingSwitchAccountTextContainer from "../features/profileSetting/container/ProfileSettingSwitchAccountTextContainer";

const SwitchAccontScreen = () => {
  const user = useAppSelector(selectAdmin);
  const systemSelected = useAppSelector(selectDefaultAccount);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [xps, setXps] = useState<number>(0);
  const [studentLevel, setStudentLevel] = useState<number>(0);
  useEffect(() => {
    // setSystem(systemSelected);
    if (user) {
      const projectReport = user.projectReport.find(
        (item) => item.project === systemSelected!.project
      );

      if (projectReport) {
        setXps(projectReport.xps);
        setStudentLevel(Math.floor(projectReport.xps / 3500));
      }
    }
  }, [user, systemSelected]);

  const goBackHandler = () => {
    navigation.goBack();
  };
  return (
    <>
      <ProfileSettingHeaderContainer
        backgroundProfile={user?.backgroundBanner!}
        goBackHandler={goBackHandler!}
        showSetting={true}
      />
      <ProfileSettingSwitchAccountTextContainer/>
      {/* <ProfileSettingImageContainer
        profileImage={user?.image!}
        firstName={user?.firstName!}
        lastName={user?.lastName!}
        level={xps}
      /> */}

      <SwitchAccountContainer user={user!} />
    </>
  );
};

export default SwitchAccontScreen;
