import { formatDistance } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  Button,
  TouchableOpacity,
  Dimensions,
  Pressable,
} from "react-native";
import { Swipeable } from "react-native-gesture-handler";
import { Image } from "expo-image";
import { images } from "../../../constants";
import { Comment } from "../types";
import { AntDesign } from "@expo/vector-icons";
import {
  useAddLikeCommentMutation,
  useDeleteCommentMutation,
} from "../api/commentApi";

import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin } from "../../auth/slice/authSlice";
import Swipeout from "../../../components/swiperComponent/src";

interface CommentListItemPresentation {
  item: Comment;
}

const { width } = Dimensions.get("window");

const CommentListItem: React.FC<CommentListItemPresentation> = ({ item }) => {
  const admin = useAppSelector(selectAdmin);
  const [
    addLikeComment,
    {
      isLoading: addLikeLoading,
      isError: addLikeError,
      isSuccess: addlikeSuccess,
    },
  ] = useAddLikeCommentMutation();

  const [
    deleteComment,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
    },
  ] = useDeleteCommentMutation();
  const [deleteLoad, setDeleteLoad] = useState<boolean>(false);
  const [imLike, setImLike] = useState<boolean>(false);
  const refSwap = useRef<any>();

  useEffect(() => {
    if (item?.commentLike?.like) {
      setImLike(item.commentLike.like);
    }
  }, [item]);

  const addCommetnLikeHandler = async (item: Comment) => {
    try {
      //   item!.commentLike!.like = !item.commentLike?.like;
      setImLike(!imLike);
      await addLikeComment({
        comment: item._id as string,

        like: !item.commentLike?.like,
      }).unwrap();
    } catch (error) {}
  };

  const renderRightActions = (onClick: any) => [
    {
      backgroundColor: "#272626",
      component: (
        <>
          {item.student._id === admin!._id ? (
            <View
              style={{
                margin: 0,
                alignContent: "center",
                justifyContent: "center",
                width: 80,
                height: 70,
                alignItems: "center",
              }}
            >
              <Pressable
                onPress={onClick}
                disabled={deleteLoad ? true : false}
                style={{
                  height: "100%",
                  maxHeight: 53,
                  width: 55,
                  backgroundColor: "red",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    color: "#fff",
                    fontFamily: "HelveticaBold",
                    fontSize: 12,
                  }}
                >
                  {deleteLoad ? "Loading" : "Delete"}
                </Text>
              </Pressable>
            </View>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];
  return (
    <Swipeout
      right={
        item.student._id === admin!._id
          ? renderRightActions(async () => {
              try {
                setDeleteLoad(true);
                await deleteComment(item!._id!).unwrap();
              } catch (error) {}
            })
          : undefined
      }
      backgroundColor="#272626"
      style={{ marginBottom: 20 }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: 70,
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 45,
            height: 42,
            borderRadius: 17,
            overflow: "hidden",
            zIndex: 1,
            marginBottom: 13,
            backgroundColor: "#fff",
            top: 7,
            left: 20,
          }}
          source={{ uri: item.student.image }}
          contentFit="cover"
          transition={1000}
        />
        <Image
          source={images.commentBack}
          style={{
            width: 57,
            height: 60,
            overflow: "hidden",
            position: "absolute",
            top: 8,
            left: 15,
            zIndex: 1,
          }}
        />

        <View
          style={{
            backgroundColor: "#272626",
            width: width - 150,
            marginTop: 0,
            overflow: "hidden",
            paddingRight: 15,
            paddingTop: 5,
            marginLeft: 40,
          }}
        >
          <View
            style={{
              width: "100%",
              display: "flex",

              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontSize: 13,
                color: "white",
                fontFamily: "HelveticaBold",
              }}
            >
              {item.student.fullName}
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: "white",
                fontFamily: "HelveticaBold",
              }}
            >
              {formatDistance(new Date(item.createdAt), new Date(), {
                addSuffix: true,
              })}
            </Text>
          </View>
          <View style={{ width: "100%", paddingTop: 3 }}>
            <Text
              style={{
                fontSize: 11,
                lineHeight: 13,
                color: "white",
                letterSpacing: 1,
                fontFamily: "HelveticaBold",
              }}
            >
              {item.comment!}
            </Text>
          </View>
        </View>
        <View style={{ paddingRight: 10 }}>
          <TouchableOpacity
            onPress={() => {
              addCommetnLikeHandler(item);
            }}
            style={{ backgroundColor: "transparent", padding: 10 }}
          >
            {imLike ? (
              <AntDesign name="like1" size={24} color="red" />
            ) : (
              <AntDesign name="like1" size={24} color="white" />
            )}
          </TouchableOpacity>
        </View>
      </View>
    </Swipeout>
  );
};

export default CommentListItem;
