import React from "react";
import {
  View,
  Text,
  ImageBackground,
  StatusBar,
  ScrollView,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ProfileSettingContainer from "../features/profileSetting/container/ProfileSettingContainer";

const ProfileSettingScreen = () => {
  return (
    <>
      <ScrollView>
        <StatusBar barStyle="light-content"  />
        <ProfileSettingContainer />
      </ScrollView>
    </>
  );
};

export default ProfileSettingScreen;
