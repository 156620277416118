import React from "react";
import { TouchableOpacity, View, Text, Image, Platform } from "react-native";
import StyleSheet from "react-native-media-query";
import { SvgUri } from "react-native-svg";

import { FONTS, images } from "../../../constants";
import { BadgeSystem } from "../type/type";

type BadeSystemItemPresentationProp = {
  item: BadgeSystem;
  onItemDetail: (item: BadgeSystem | null) => void;
};
const BadgeSystemItemPresentation: React.FC<BadeSystemItemPresentationProp> = ({
  item,
  onItemDetail,
}) => {
  return (
    <>
      {item && item.showItem && (
        <TouchableOpacity
          // styles.deactive   FOR DEACTIVE
          
          style={(Platform.OS === "web") ? [ styles.badgeItemContainer,
            { opacity: item?.locked ? 0.5 : 1 }, , styles.badgeItemContainerWeb ] : [
            styles.badgeItemContainer,
            { opacity: item?.locked ? 0.5 : 1 },
          ]}
          onPress={() => {
            onItemDetail(item);
          }}
        >
          <View style={styles.iconContainer}>
           
            {(Platform.OS === "web") ?   <Image style={[styles.badgeIcon ]}  source={ {
              uri: item?.image} }/>  :  
              <SvgUri
              style={styles.badgeIcon}
              width="50px"
              height="50px"
              uri={item?.image}
              />
             }
          </View>

          <View style={styles.content}>
            <Text style={styles.title}>{item?.title}</Text>
            <Text style={styles.subTitle}>
              {item?.locked ? item?.how : item?.description}
            </Text>
          </View>
        </TouchableOpacity>
      )}
    </>
  );
};

const { styles } = StyleSheet.create({
  deactive: {
    opacity: 0.5,
    pointerEvents: "none",
  },

  badgeItemContainer: {
    paddingRight: 10,
    flexDirection: "row",
    
    justifyContent: "space-between",

    width: "95%",

    backgroundColor: "#fff",
    elevation: 5,

    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginLeft: "2.5%",
    marginBottom: 11,
    borderRadius: 8,
  },
  badgeItemContainerWeb:{
    minWidth:400
  },
  iconContainer: {
    padding: 15,
    position: "relative",
  },
  badgeIcon: {
    width: 50,
    height: 50,
    borderRadius: 0,
    top: 0,
    left: -5,
    "@media (max-width: 350px)": {
      width: 30,
      height: 30,
      top: 3,
    },
  },
  content: {
    marginTop: 15,
    width: "80%",
  },

  title: {
    fontFamily: "HelveticaBold",
    fontSize: 14,
    color: "#3E3F5E",
    paddingBottom: 4,
  },
  subTitle: {
    fontFamily: "HelveticaBold",
    fontSize: 11,
    color: "#3E3F5E",
    lineHeight: 12,
  },
});
export default BadgeSystemItemPresentation;
