import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { Dimensions, FlatList, Text, View } from "react-native";
import { AuthUser } from "../../../auth/types/types";
import { Image } from "expo-image";
import { images } from "../../../../constants";
import StyleSheet from "react-native-media-query";
import FriendsItemPresentation from "../FriendItemPresentation/FriendsItemPresentation";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
type RequestFriendsPresenationProps = {
  requestList: EntityState<AuthUser>;
  isLoading: boolean;
  isSuccess: boolean;
  onAcceptFriend?: (student: Partial<AuthUser>) => void;
};
const { height } = Dimensions.get("window");
const RequestFriendsPresentation: React.FC<RequestFriendsPresenationProps> = ({
  requestList,
  isLoading,
  isSuccess,
  onAcceptFriend,
}) => {
  let content;
  if (isLoading) {
    content = (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: 300,
        }}
      >
      <LoadingScreen iconSize={40} />
      </View>
    );
  } else if (isSuccess) {
    content = (
      <View style={styles.cardList}>
        <FlatList
          data={Object.values(requestList!.entities!) as AuthUser[]}
          numColumns={1}
          keyExtractor={(item) => `${item._id}`}
          keyboardDismissMode="on-drag"
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{}}
          renderItem={({ item }) => (
            <FriendsItemPresentation
              item={item}
              status="accept"
              onAcceptFriend={onAcceptFriend} 
              getRankFriends={function (item: AuthUser): number {
                throw new Error("Function not implemented.");
              } }           
               />
          )}
          ListFooterComponent={
            <View
              style={{
                marginBottom: 150,
              }}
            ></View>
          }
        />
      </View>
    );
  }
  return <>{content}</>;
};

const { styles } = StyleSheet.create({
  searchInputContainerStyle: {
    backgroundColor: "#EBEBEB",
    borderRadius: 20,
    borderColor: "#707070",
    borderWidth: 1,
    borderStyle: "solid",
    borderBottomWidth: 1,
    height: 35,
    maxWidth: 240,
    width: "100%",

    alignSelf: "center",
    marginTop: 10,
  },
  searchInput: {
    backgroundColor: "#fff",
    borderColor: "#fff",
    height: 78,
  },
  friendsContainer: {
    flex: 1,
  },
  searchInputStyle: {
    fontSize: 12,
    lineHeight: 15,

    color: "#847D7D",
    letterSpacing: 0.2,
    alignItems: "center",
  },
  cardList: {
    width: "100%",
    minWidth:"100%",
    // flex: 1,
    // flexGrow: 1,
    maxWidth: 390,
    paddingTop: 20,
    paddingBottom: 15,
    shadowColor: "#000",
    marginTop:-20,
    overflow: "visible",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 3.84,
    backgroundColor: "#fff",
    elevation: 10,
    borderRadius: 15,

    height: height - 155 ,
  },
  cardHeader: {
    width: "100%",

    height: 100,
    backgroundColor: "#1766C1",
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
  },
  profileXpCoinsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  headerxpcontainer: {
    flex: 1,
    paddingBottom: 10,
    paddingRight: 5,
  },
  xpNumbrt: {
    fontSize: 28,
    color: "#fff",
    alignSelf: "center",
    fontWeight: "bold",
  },
  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#000",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#000",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
  },
  xp: {
    color: "white",
    fontSize: 26,
  },
  friendsTabFriendText: {
    "@media (max-width: 350px)": {
      fontSize: 33,
    },
  },
  friendTabText: {
    "@media (max-width: 350px)": {
      fontSize: 15,
    },
  },
});
export default RequestFriendsPresentation;
