export const calculatePastDate = (days: string) => {
  let intDays = parseInt(days, 10);
  const date = new Date();
  date.setDate(date.getDate() - intDays);

  return date;
};

export const calulateCircleChartPercent = (total: number, percent: number) => {
  const calPercent = (total * percent) / 100;
  const result = total - calPercent;
  return result;
};
