import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Image } from "expo-image";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";
import { images } from "../../../constants";
import { EntityId } from "@reduxjs/toolkit";
import { BadgeSystem } from "../../badgeSystem/type/type";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { selectAdmin } from "../../auth/slice/authSlice";
import { useAppSelector } from "../../../redux/hooks";

type BadgesListProps = {
  lastBadges: {
    student: EntityId;
    badge: BadgeSystem;
    complete: boolean;
  }[];
};
const BadgesList: React.FC<BadgesListProps> = ({ lastBadges }) => {
  const admin = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  return (
    <TouchableOpacity
      style={styles.BadgesListContainer}
      onPress={() =>
        navigation.navigate("BadgeSystem", {
          student: admin!,
        })
      }
    >
      <View style={styles.BadgesTextContainer}>
        <TextStroke stroke={1} color={"#FFF"}>
          <Text numberOfLines={1} style={styles.badgesText}>
            Badges
          </Text>
        </TextStroke>
      </View>

      {lastBadges?.map((item) => (
        <Image
          key={item.badge._id}
          contentFit="contain"
          style={styles.BadgesImage}
          source={{
            uri: item.badge.image,
          }}
        />
      ))}

      {/* <Image
        style={styles.BadgesImage}
        source={{
          uri: "https://api.gradify.app/static/file-1695392116779-953320389.svg",
        }}
      />
      <Image
        resizeMode="contain"
        style={styles.BadgesImage}
        source={images.badge1}
      />
      <Image
        resizeMode="contain"
        style={styles.BadgesImage}
        source={images.badge}
      />
      <Image
        style={styles.BadgesImage}
        source={images.badge1}
        resizeMode="contain"
      /> */}
    </TouchableOpacity>
  );
};

const { styles } = StyleSheet.create({
  BadgesListContainer: {
    top: -20,
    position: "absolute",
    right: 15,
    backgroundColor: "white",
    height: 55,
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 5,
    borderRadius: 4,
    shadowColor: "#000",
    alignSelf: "center",
    minWidth: 165,
    zIndex:999,
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.12,
    shadowRadius: 6.84,

    elevation: 7,
  },
  BadgesImage: {
    width: 35,
    height: 35,
    marginLeft: 5,
    marginTop: 5,
  },
  BadgesTextContainer: {
    padding: 3,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "#F7941C",
    borderRadius: 11,
    top: -17,
    position: "absolute",
    right: 50,
  },
  badgesText: {
    fontSize: 15,
    color: "#000",
    letterSpacing: 2,
    fontFamily: "RajdahaniBold",
  },
});

export default BadgesList;
