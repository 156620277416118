import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { Review } from "../../cameraFeatures/types/types";

const reviewAdaptor = createEntityAdapter<Review>({
  selectId: (review) => review._id,
});

interface ReviewInitialState extends EntityState<Review> {}

const initialState: ReviewInitialState = reviewAdaptor.getInitialState();

export const extendedReviewApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLike: builder.mutation<EntityState<Review>, Partial<Review>>({
      query: (review) => ({
        url: "/review",
        method: "PUT",
        body: review,
      }),
      transformResponse: (response: Review) => {
        return reviewAdaptor.setOne(initialState, response);
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Review", id: "LIST" },
        ...result!.ids.map((id) => ({
          type: "Review" as const,
          id: arg.photo?._id,
        })),
        ...result!.ids.map((id) => ({
          type: "ExperiencePhoto" as const,
          id: arg.photo?._id,
        })),
        // { type: "ExperiencePhoto", id: "LIST" },
        // { type: "ExperiencePhoto", id:  },
        "PhotoCount",
      ],
    }),
  }),
});

export const { useAddLikeMutation } = extendedReviewApi;
