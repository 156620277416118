import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import {
  Dimensions,
  Image,
  ImageBackground,
  ScrollView,
  View,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { COLORS, SIZES, icons, images } from "../constants";
import LoginContainer from "../features/auth/containers/LoginContainers/LoginContainer";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

const { width , height } = Dimensions.get("window");
const windowHeight = Dimensions.get("window").height;

const LoginScreen = () => {
  return (
    <ScrollView style={styles.container}>
      <ImageBackground
        source={images.onBoardingbackground}
        style={styles.backgroundImage}
      >
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
          colors={[COLORS.transparent, COLORS.black]}
          style={ styles.imageOverlay}
        >
          <View style={styles.content}>
            <Image
              source={icons.gradegyTextIcon}
              style={styles.image}
              resizeMode="contain"
            />

            <View style={styles.contentContainer}>
              <LoginContainer />
            </View>
          </View>
        </LinearGradient>
      </ImageBackground>
    </ScrollView>
  );
};

const { styles } = StyleSheet.create({
  imageOverlay:{
    height:height,
    "@media  (max-width: 768px)": {
      minHeight: height + 40,
    },
  },
  tabContainer: {
    flexDirection: "row",
    marginTop: 35,
    height: 50,
    position: "relative",
    overflow: "hidden",
    borderRadius: 14,
    width: 300,
    marginBottom: 20,
    alignSelf: "center",
  },
  contentContainer: {
    width: "100%",
    height: "60%",
    paddingHorizontal: SIZES.h3,

    // "@media (max-width: 360px)": {
    //   height: "72%",
    // },
  },

  content: {
    justifyContent: "center",
    height:height,
    alignContent: "center",
    alignItems: "center",
  },
  leftTouchable: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#fff",
    borderRadius: 14,
    borderRightWidth: 0,
    borderTopRightRadius: 0,
    overflow: "hidden",
    borderBottomRightRadius: 0,
  },
  rightTouchable: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#fff",
    borderRadius: 14,
    borderLeftWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  tab: {
    width: "40%",
    height: 40,
    backgroundColor: "#fff",
  },
  title: {
    color: "#fff",

    fontSize: 14,
  },
  backgroundImage: {
    resizeMode: "cover",
    minHeight: windowHeight,
    width: "100%",
    height:"100%",
    flex:1,
    "@media  (max-width: 768px)": {
      minHeight: windowHeight + 40,
    },
  },

  image: {
    width: "70%",
    height:100,
    "@media (max-width: 360px)": {
      width: "60%",
    },
    "@media  (max-width: 768px)": {
      height:90
    },
  },

  gradify: {
    color: "#fff",
    fontSize: 60,
    marginTop: 10,
    fontWeight: "700",
    lineHeight: 57,
  },
  desc: {
    color: "#fff",
    fontSize: 18,
    marginTop: 19,
    maxWidth: 273,
    textAlign: "center",

    lineHeight: 23,
  },
  container: {
    flex: 1,
    height:height,
  },
});

export default LoginScreen;
