import React, { FC, useEffect, useState } from "react";
import {
  View,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  Pressable,
  ImageBackground,
  TouchableHighlight,
  Platform,
} from "react-native";
import { Image } from "expo-image";
import { MaterialIcons } from "@expo/vector-icons";
import { images } from "../../../constants";
const { width } = Dimensions.get("window");
import { SharedValue } from "react-native-reanimated";
import { Item } from "react-native-paper/lib/typescript/components/Drawer/Drawer";
import { BadgeSystem } from "../type/type";
import { useGetAllLevelBadgeQuery } from "../api/apiBadgeSystem";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { SvgUri } from "react-native-svg";

export type PostFeedPopupProps = {
  rotate: SharedValue<number>;
  openPopup?: boolean;
  onClose?: () => void;
  item: BadgeSystem | null;
};

const BadgeSystemDetailsItemPresentation: FC<PostFeedPopupProps> = ({
  rotate,
  openPopup,
  onClose,
  item,
}) => {
  const accountSelected = useAppSelector(selectDefaultAccount);
  const [badgeSystemLevel, setBadgeSystemLevel] = useState<BadgeSystem[]>([]);
  const {
    data: getBadgeLevel,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllLevelBadgeQuery({
    currentSystem: JSON.stringify(accountSelected),
    badgeId: item!._id,
  });
  useEffect(() => {
    if (getBadgeLevel) {
      setBadgeSystemLevel(
        Object.values(getBadgeLevel?.entities!) as BadgeSystem[]
      );
    }
  }, [getBadgeLevel]);
  return (
    <TouchableHighlight
      activeOpacity={0.6}
      underlayColor="#DDDDDD"
      style={styles.modalImageContainer}
      onPress={() => {
        rotate.value = rotate.value ? 0 : 1;
      
      }}
    >
      <>
        <ImageBackground
          source={images.BadgedetailBackground}
          style={styles.image}
          resizeMode="cover"
        />

        <View style={styles.BadgedetailHeader}>
          <View style={styles.BadgedetailHeaderContent}>
            <Text style={styles.BadgedetailHeaderText}>
              {badgeSystemLevel[0]?.level_title}
            </Text>
          </View>
          <Image
            style={styles.BadgedetailHeaderImage}
            source={images.badgeDetail}
          />
        </View>
        {isSuccess && badgeSystemLevel && (
          <View style={styles.BadgedetailContent}>
            <View style={[styles.BadgedetailItem]}>
        
          {(Platform.OS === "web") ?   <Image style={[styles.BadgedetailItemImage ]}  source={ {
              uri: badgeSystemLevel[0]?.image} }/>  :  
              <SvgUri
              style={styles.BadgedetailItemImage}
              uri={badgeSystemLevel[0]?.image}
            /> }

              <View style={styles.BadgedetailItemContent}>
                <Text style={styles.BadgedetailItemTitle}>
                  {badgeSystemLevel[0]?.title}
                </Text>
                <Text style={styles.BadgedetailItemSubTitle}>
                  {badgeSystemLevel[0]?.locked
                    ? badgeSystemLevel[0]?.how
                    : badgeSystemLevel[0]?.description}
                </Text>
              </View>
              {!badgeSystemLevel[0]?.locked ? (
                <Image
                  style={styles.BadgedetailItemAccept}
                  source={images.CheckIcon}
                />
              ) : null}
            </View>

            <View style={styles.BadgedetailItem}>
            
        {(Platform.OS === "web") ?   <Image style={[styles.BadgedetailItemImage , {width:80 }]}  source={ {
              uri: badgeSystemLevel[1]?.image} }/>  :  
              <SvgUri
                style={styles.BadgedetailItemImage}
                uri={badgeSystemLevel[1]?.image}
                width="80px"
              /> }

              
              <View style={styles.BadgedetailItemContent}>
                <Text style={styles.BadgedetailItemTitle}>
                  {badgeSystemLevel[1]?.title}
                </Text>
                <Text style={styles.BadgedetailItemSubTitle}>
                  {badgeSystemLevel[1]?.locked
                    ? badgeSystemLevel[1]?.how
                    : badgeSystemLevel[0]?.description}
                </Text>
              </View>
              {!badgeSystemLevel[1]?.locked ? (
                <Image
                  style={styles.BadgedetailItemAccept}
                  source={images.CheckIcon}
                />
              ) : null}
            </View>

            <View style={styles.BadgedetailItem}>
          

          {(Platform.OS === "web") ?   <Image style={[styles.BadgedetailItemImage , {width:80 }]}  source={ {
              uri: badgeSystemLevel[2]?.image} }/>  :  
              <SvgUri
              style={styles.BadgedetailItemImage}
              width="80px"
              uri={badgeSystemLevel[2]?.image}
            /> }


              <View style={styles.BadgedetailItemContent}>
                <Text style={styles.BadgedetailItemTitle}>
                  {badgeSystemLevel[2]?.title}
                </Text>
                <Text style={styles.BadgedetailItemSubTitle}>
                  {badgeSystemLevel[2]?.locked
                    ? badgeSystemLevel[2]?.how
                    : badgeSystemLevel[2]?.description}
                </Text>
              </View>
              {!badgeSystemLevel[2]?.locked ? (
                <Image
                  style={styles.BadgedetailItemAccept}
                  source={images.CheckIcon}
                />
              ) : null}
            </View>
          </View>
        )}
      </>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  disable: {
    opacity: 0.6,
    pointerEvents: "none",
  },
  BadgedetailItem: {
    height: 58,
    backgroundColor: "#fff",
    borderRadius: 8,
    width: "75%",
    marginLeft: "18%",
    flexDirection: "row",
    paddingLeft: "15%",
  },
  BadgedetailItemImage: {
    left: "-20%",
    top: -18,
    position: "absolute",
    zIndex: 2,
    width: 85,
    height: 95,
  },
  BadgedetailItemContent: {
    textAlign: "center",
    justifyContent: "center",
  },
  BadgedetailItemTitle: {
    fontFamily: "HelveticaBold",
    fontSize: 14,
    paddingBottom: 4,
  },
  BadgedetailItemSubTitle: {
    fontFamily: "HelveticaBold",
    fontSize: 10,
    maxWidth: "88%",
    lineHeight: 11,
  },
  BadgedetailItemAccept: {
    width: 32,
    height: 25,
    objectFit: "contain",
    top: 12,
    right: 10,
    position: "absolute",
  },
  BadgedetailHeader: {
    height: 56,
    backgroundColor: "#033C56",
    borderRadius: 6,
    borderWidth: 8,
    borderColor: "#000",
    width: "80%",
    marginLeft: "9%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  BadgedetailHeaderContent: {},
  BadgedetailHeaderText: {
    fontSize: 21,
    color: "#fff",
  },
  BadgedetailHeaderImage: {
    height: 66,
    width: 66,
    borderColor: "#000",
    borderWidth: 8,
    borderRadius: 50,
    position: "absolute",
    right: -30,
  },
  BadgedetailContent: {
    justifyContent: "space-around",
    flex: 1,
    marginTop: "10%",
    marginBottom: "5%",
  },
  modalImageContainer: {
    position: "relative",
    maxWidth: 425,
    maxHeight: 585,
    height: "80%",
    width: width - 30,
    backgroundColor: "#fff",
    borderRadius: 15,
    borderWidth: 5,
    borderColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },

    shadowOpacity: 0.5,
    shadowRadius: 2.62,
    elevation: 8,
    zIndex: 2,
  },

  modelContainer: {
    backgroundColor: "#000000aa",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    maxWidth: "100%",
    width: width,
    alignSelf: "center",
  },

  image: {
    height: "100%",
    width: "100%",
    position: "absolute",
    borderRadius: 15,
    borderWidth: 6,
    borderColor: "#000",
    overflow: "hidden",
    objectFit: "cover",
  },
});

export default BadgeSystemDetailsItemPresentation;
