import React, { useEffect, useState } from "react";
import SchoolTabItemPresentation from "../presentation/SchoolTabItemPresentation";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { useGetCategoryLeaderBoardQuery } from "../../categoryLeaderboard/api/categoryLeadboardApi";
import CategoryLeaderboardItemPresentatin from "../../categoryLeaderboard/categoryLeaderboardItemPresentation/CategoryLeaderboardItemPresentatin";
import { EntityState } from "@reduxjs/toolkit";
import { CategoryLeaderboard } from "../../categoryLeaderboard/types/type";
type SchoolTabItemContainerProps = {
  month:string;
}
const SchoolTabItemContainer:React.FC<SchoolTabItemContainerProps> = ({month}) => {
  const systemSelected = useAppSelector(selectDefaultAccount);
  const {
    data: categoryLeaderboardList,
    isLoading,
    isError,
    isSuccess,
  } = useGetCategoryLeaderBoardQuery({
    currentSystem:JSON.stringify(systemSelected),
    month:month
  });

  

  return (
    <>
      <SchoolTabItemPresentation
        categoryLeaderboard={categoryLeaderboardList!}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </>
  );
};

export default SchoolTabItemContainer;
