import React, { FC, useEffect, useState } from "react";
import {
  View,
  Modal,
  Text,
  TouchableOpacity,
  ImageBackground,
  ScrollView,
  Dimensions,
  Pressable,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { MaterialIcons } from "@expo/vector-icons";
// @ts-ignore
import xpBackground from "../../../assets/xplevel.png";
import { Image } from "expo-image";
import PolygonProfile from "../../../components/PolygonProfile";
import { AuthUser } from "../../auth/types/types";
import { images } from "../../../constants";
// import PolygonProfile from "./polygonProfile";
// import ProfileName from "./profileName";
// import PostLike from "../feed/postLike";
// import LoadingScreen from "./loadingScreen";
// import CachImage from "./cachImage";

const { width, height } = Dimensions.get("window");

type FriendItemPopupProps = {
  friend: Partial<AuthUser>;
  openPopup: boolean;
  onClose: () => void;
  friendXp: number;
};
const FriendItemPopup: React.FC<FriendItemPopupProps> = ({
  friend,
  openPopup,
  onClose,
  friendXp,
}) => {
  //   const [leaderBoardXp, setLeaderBoardXp] = useState(0);
  //   const [userRank, setUserRank] = useState(0);

  return (
    <Modal transparent={true} style={{ backgroundColor: "red" }}>
      <>
        <Pressable style={styles.friendsListPopupBox} onPressIn={undefined}>
          <View style={[styles.profileInfoImage]}>
            <TouchableOpacity
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: 3,
                top: 3,
                zIndex: 999,
              }}
              onPress={onClose}
            >
              <MaterialIcons
                style={{
                  textShadowColor: "#000",
                  textShadowOffset: { width: 0, height: 1 },
                  textShadowRadius: 3,
                }}
                name="close"
                size={30}
                color="white"
              />
            </TouchableOpacity>
            <View style={styles.backProfilePic}>
              <ImageBackground
                source={{
                  uri: friend.backgroundBanner!,
                }}
                style={styles.backProfileImage}
              ></ImageBackground>
            </View>
            <View style={styles.profileInfoShapeContainer}>
              <PolygonProfile showXp={false} xp={friendXp} studentPic={friend.image} />

              {/* <ImageBackground
                source={images.xpLevelBackground}
                resizeMode="contain"
                style={styles.images}
              >
                <Text style={styles.xpNumbrt}>25666</Text>
              </ImageBackground> */}
            </View>
            <View style={styles.profileNameContainer}>
              {/* <ProfileName
                        studentFirstName={""}
                        studentLastName={""} style={undefined}                    /> */}
            </View>

            <View style={styles.profileRdXp}>
              <View style={styles.profileRd}>
                <Text style={styles.profileRdNumber}>{}</Text>
                <Text style={styles.profileRdText}>
                  {/* {userRank == 1
                    ? "st"
                    : userRank == 2
                    ? "nd"
                    : userRank === 3
                    ? "rd"
                    : "th"} */}
                </Text>
              </View>
              <Text style={styles.profileXpText}>{friendXp} XP</Text>
            </View>
          </View>
        </Pressable>
      </>
    </Modal>
  );
};

const { styles } = StyleSheet.create({
  friendsListPopupBox: {
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    position: "absolute",
    width: width,
    height: height,
    top: 0,
    left: 0,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
  },
  loadingImage: {
    width: 50,
    height: 50,
    position: "absolute",
    top: 400,
  },
  profileNameContainer: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    alignContent: "center",
    alignSelf: "center",
    width: "100%",
    "@media (max-width: 350px)": {
      marginTop: -30,
    },
  },
  popupLoading: {
    width: width,
    height: height,
    position: "absolute",
    top: 0,
    backgroundColor: "#5e5e5e",
  },
  userContent: {
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    width: 77,
  },
  firendsList: {
    flex: 1,
    backgroundColor: "red",
  },
  modalImageContainer: {
    position: "relative",
    maxWidth: 374,
    maxHeight: 526,
    height: "60%",
    width: "92%",
    borderRadius: 15,
    backgroundColor: "#5e5e5e",
  },

  likeNumber: {
    fontSize: 22,
    color: "#fff",
    fontFamily: "Roboto-Regular",
    "@media (max-width: 350px)": {
      fontSize: 19,
    },
  },
  likeStyle: {
    fontSize: 22,
    color: "#fff",
    marginLeft: 3,
    fontFamily: "Roboto-Regular",
    "@media (max-width: 350px)": {
      fontSize: 19,
    },
  },
  image: {
    height: "100%",
    width: "100%",
    position: "absolute",
    borderRadius: 15,
    overflow: "hidden",
  },
  userImage: {
    transform: [{ scale: 0 }],
    width: "40%",
    marginTop: 0,
    marginRight: 6,
  },
  likeContent: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: "5%",
    flex: 1,
  },
  imageContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    maxWidth: "100%",
    width: width,
    alignSelf: "center",
  },
  title: {
    marginTop: "7%",
    fontSize: 22,

    color: "#fff",
    textAlign: "center",
    width: "100%",
    "@media (max-width: 350px)": {
      fontSize: 19,
    },

    textShadowColor: "#000",
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 0,
  },
  userContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: 90,
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  like: {
    flexDirection: "row",
    alignItems: "center",
    width: "35%",
    marginLeft: 12,
    height: "100%",
    textAlign: "center",
    marginTop: 4,
  },

  profileInfoImage: {
    position: "relative",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    maxWidth: 360,
    maxHeight: 401,
    borderRadius: 15,
    backgroundColor: "#fff",
    overflow: "hidden",
    width: "90%",
    height: "70%",
  },
  images: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 54,
    position: "absolute",
    height: 55,
    left: 165,
    top: 140,
    zIndex: 9999,
  },
  xpNumbrt: {
    fontSize: 28,
    color: "#fff",
    alignSelf: "center",

    "@media (max-width: 350px)": {
      fontSize: 22,
    },
  },
  profileRd: {
    width: 50,
    height: 50,
    borderRadius: 50,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#0BC4B8",
    flexDirection: "row",
  },
  profileRdNumber: {
    color: "#fff",
    fontSize: 22,

    "@media (max-width: 350px)": {
      fontSize: 19,
    },
  },
  profileRdText: {
    color: "#fff",
  },
  backProfilePic: {
    width: "100%",
    height: 145,
    position: "absolute",
    top: 0,
    "@media (max-width: 350px)": {
      height: 100,
    },
  },
  backProfileImage: {
    width: "100%",
    height: 145,
    "@media (max-width: 350px)": {
      height: 100,
    },
  },
  profileRdXp: {
    flexDirection: "row",
    alignItems: "center",
    width: "96%",
    textAlign: "center",
    justifyContent: "space-between",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: "white",
    elevation: 5,
    marginTop: 10,
    padding: 7,
    borderRadius: 10,
  },
  profileXpText: {
    fontSize: 40,
    fontWeight: "bold",
    "@media (max-width: 350px)": {
      fontSize: 30,
    },
  },
  profileInfoShapeContainer: {
    "@media (max-width: 350px)": {
      transform: [{ scale: 0.64 }],
    },
  },
  likeImage: {
    paddingLeft: 5,
  },
});

export default FriendItemPopup;
