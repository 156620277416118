import React, { useState } from "react";
import LoginPresentation from "../../presentation/LoginPresentation/LoginPresentation";
import { useLoginMutation } from "../../authApiSlice/authApiSlice";
import { useNavigation } from "@react-navigation/native";
import { LoginScreenNavigationProps } from "../../../../navigation/types";
import { getAdminStatus } from "../../authThunks/authThunks";
import { store } from "../../../../redux/store";

const LoginContainer = () => {
  const navigation = useNavigation<LoginScreenNavigationProps>();
  const [login, { isLoading, isSuccess, isError }] = useLoginMutation();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onEmailChange = (text: string) => {
    setEmail(text);
  };

  const onPasswordChange = (text: string) => {
    setPassword(text);
  };

  const onLogin = async () => {
    try {
      const log = await login({
        email: email.toLowerCase().trim(),
        password,
      }).unwrap();
      store.dispatch(getAdminStatus());

      if (log.confirm) {
        navigation.navigate("Auth");
      } else {
        navigation.navigate("TermAndCondition");
      }
    } catch (error) {}
  };
  return (
    <LoginPresentation
      email={email}
      password={password}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onLogin={onLogin}
      isLoading={isLoading}
    />
  );
};

export default LoginContainer;
