import React, { FC, useEffect } from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import PolygonProfile from "../../../components/PolygonProfile";
import { TeamData } from "../types/type";
import { images } from "../../../constants";
import { Image } from "expo-image";
import TrianglesBox from "../../../components/TriAngleTeamScore/TrianglesBox";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin } from "../../auth/slice/authSlice";
// import PolygonProfile from "../general/polygonProfile";
// import TrianglesBox from "../general/trianglesBox";
// import ProfileAvatar from "../../../assets/ProfileAvatar.png";
type ShowTeamDataItemProps = {
  item: TeamData;
  idx: number;
  outOfBound: number;
};
const ShowTeamsDataItem: React.FC<ShowTeamDataItemProps> = ({
  item,
  idx,
  outOfBound,
}) => {
  const user = useAppSelector(selectAdmin);

  return (
    <>
      <View style={styles.teamsTabContainer}>
        {/* {schoolDistrict?.data.map((item: any, index: number) => {
          if (index > 3) {
            return ( */}

        {idx === 0 && (
          <>
            <View style={styles.firstTeamsTabContent}>
              <View
                style={[
                  { transform: [{ scale: 0.8 }] },
                  styles.teamTabTriangleShapeOne,
                ]}
              >
                <PolygonProfile
                  xp={3499}
                  strokeColor={item!.team!.color}
                  rank={1}
                  showXp={true}
                  strokeWidth={15}
                  rankText="st"
                  ContainerStyle={styles.teamTabShapeOne}
                />
              </View>
              <TrianglesBox
                teamColor={item!.team!.color!}
                arrowAlign="left"
                total={
                  user?.systemProject[0]?.project_type === "Event"
                    ? item.teamTotalXp
                      ? item.teamTotalXp
                      : 0
                    : item.teamAvgXp
                    ? item.teamAvgXp
                    : 0
                }
                outOfBound={outOfBound}
                item={item!}
                showAvg={
                  user?.systemProject[0]?.project_type === "Event"
                    ? true
                    : false
                }
              />
            </View>
          </>
        )}
        {idx === 1 && (
          <View style={styles.secoundTeamsTabContent}>
            <TrianglesBox
              teamColor={item!.team!.color!}
              arrowAlign="right"
              total={
                user?.systemProject[0]?.project_type === "Event"
                  ? item.teamTotalXp
                    ? item.teamTotalXp
                    : 0
                  : item.teamAvgXp
                  ? item.teamAvgXp
                  : 0
              }
              outOfBound={outOfBound}
              item={item!}
              showAvg={
                user?.systemProject[0]?.project_type === "Event" ? true : false
              }
            />
            <View
              style={[{ transform: [{ scale: 0.8 }] }, styles.teamTabShapeTwo]}
            >
              <PolygonProfile
                xp={3499}
                strokeColor={item!.team!.color}
                rank={2}
                showXp={true}
                strokeWidth={15}
                rankText="nd"
                // ContainerStyle={styles.teamTabShapeTwo}
              />
            </View>
          </View>
        )}
        {idx === 2 && (
          <View style={styles.thiredTeamsTabContent}>
            <View
              style={[
                { transform: [{ scale: 0.8 }] },
                styles.teamTabTriangleShapeTwo,
              ]}
            >
              <PolygonProfile
                xp={3499}
                strokeColor={item!.team!.color}
                rank={3}
                showXp={true}
                strokeWidth={15}
                rankText="rd"

                // ContainerStyle={styles.teamTabShapeOne}
              />
            </View>
            <TrianglesBox
              teamColor={item!.team!.color!}
              arrowAlign="left"
              total={
                user?.systemProject[0]?.project_type === "Event"
                  ? item.teamTotalXp
                    ? item.teamTotalXp
                    : 0
                  : item.teamAvgXp
                  ? item.teamAvgXp
                  : 0
              }
              outOfBound={outOfBound}
              item={item!}
              showAvg={
                user?.systemProject[0]?.project_type === "Event" ? true : false
              }
            />
          </View>
        )}
      </View>
    </>
  );
};

const { styles } = StyleSheet.create({
  teamTabTriangleShapeOne: {
    "@media (max-width: 375px)": {
      transform: [{ scale: 0.8 }],
    },
  },
  teamTabTriangleShapeTwo: {
    right: -20,
    "@media (max-width: 375px)": {
      transform: [{ scale: 0.7 }],
    },
  },
  teamTabTriangleShapeThree: {
    "@media (max-width: 375px)": {
      transform: [{ scale: 0.6 }],
    },
  },
  teamTabShapeThree: {},
  teamTabShapeTwo: {
    right: -15,
    "@media (max-width: 375px)": {
      maxWidth: 200,
      right: 20,
    },
  },
  teamTabShapeOne: {
    left: 0,
    "@media (max-width: 375px)": {
      maxWidth: 210,
    },
  },
  firstTeamsTabContent: {
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    left: -35,
    maxHeight: 180,
    top: -10,
    "@media (max-width: 360px)": {
      top: 0,
    },
  },
  secoundTeamsTabContent: {
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    right: -10,
    maxHeight: 200,
    top: -15,

    "@media (max-width: 375px)": {
      right: -30,
    },
    "@media (max-width: 360px)": {
      top: -10,
    },
  },
  thiredTeamsTabContent: {
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    left: -65,
    maxHeight: 160,
    top: -20,

    "@media (max-width: 375px)": {
      maxHeight: 160,
    },
    "@media (max-width: 360px)": {
      top: -10,
    },
  },
  teamsTabContainer: {
    marginTop: -30,
    width: "100%",
  },
});

export default ShowTeamsDataItem;
