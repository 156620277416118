import React, { FC } from "react";

import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import PolygonProfile from "../../../components/PolygonProfile";
import { AuthUser } from "../../auth/types/types";
type FriendsItemFeedProps = {
  item: Partial<AuthUser>;
  onOpenFriendPopup: (friend: Partial<AuthUser>) => void;
  onFriendItemClick?: (student: Partial<AuthUser>) => void;
};
const FriendsItemsFeeds: React.FC<FriendsItemFeedProps> = ({
  item,
  onOpenFriendPopup,
  onFriendItemClick,
}) => {
  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          onFriendItemClick!(item);
        }}
      >
        <View style={styles.userContent}>
          <View style={styles.userImage}>
            <PolygonProfile showXp={false} xp={3450} studentPic={item.image} />
          </View>
          <Text style={styles.userName}>{item.firstName}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};
const styles = StyleSheet.create({
  feedTitle: {
    fontSize: 15,

    marginTop: 6,
    marginBottom: 6,
    paddingLeft: 10,
  },
  feedCenter: {
    textAlign: "center",
    marginTop: 0,
  },
  userContent: {
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    width: 77,
  },
  userImage: {
    transform: [{ scale: 0.4 }],
    height: 125,
    marginBottom: 13,
    marginTop: -35,
  },
  firendsList: {
    width: "100%",
    marginLeft: 0,
  },
  userName: {
    fontSize: 12,
    fontFamily: "RajdahaniBold",
    color: "#000",
    width: "100%",
    position: "absolute",
    bottom: 0,
    textAlign: "center",
  },
});
export default FriendsItemsFeeds;
