import { apiSlice } from "../../../redux/api/apiSlice";
import { IResponseList } from "../../../redux/types/types";
import { AuthUser } from "../../auth/types/types";
import { CommentLike } from "../../feedDetail/types";
import { ProfilePost } from "./types/types";

export const extendBadgesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStudentPost: builder.query<
      ProfilePost,
      {
        student: string;
        currentsystemstring: string;
      }
    >({
      query: (data) =>
        `experience-photo/studentpostcount?student=${data.student}&currentsystemstring=${data.currentsystemstring}&sort=-createdAt`,
      transformResponse: (response: ProfilePost) => {
        return response;
      },
      providesTags: ["PhotoCount"],
    }),
    friendsListCount: builder.query<
      IResponseList<AuthUser[]>,
      {
        student: string;
        currentSystem: string;
        page?: string;
        limit?: string;
        sort?: string;
      }
    >({
      query: (data) =>
        `/friends/friendListv2?student=${data.student}&currentSystem=${data.currentSystem}`,
      transformResponse: (response: IResponseList<AuthUser[]>) => {
        return response;
      },
      providesTags: ["FriendsCount"],
    }),
    studentReviewCount: builder.query<number, string>({
      query: (id) => `review/${id}`,
      transformResponse: (response: number) => {
        return response;
      },
      providesTags: ["ReviewCount"],
    }),
    getLoginCount: builder.query<
      number,
      {
        student: string;
        currentSystem: string;
      }
    >({
      query: (data) =>
        `/login/logincount?student=${data.student}&currentsystem=${data.currentSystem}`,
      transformResponse: (response: number) => {
        return response;
      },
      providesTags: ["LoginCount"],
    }),
    getLevelRank: builder.mutation<
      number,
      {
        student: Partial<AuthUser>;
        project: string;
        level: number;
      }
    >({
      query: (data) => ({
        url: `/rank-profile`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: number) => {
        return response;
      },
      invalidatesTags: ["ProfileRank"],
    }),

    getCommentLike: builder.query<number, void>({
      query: () => `/comment-like`,
      transformResponse: (response: number) => {
        return response;
      },
      providesTags: ["CommentLikeCount"],
    }),
  }),
});

export const {
  useGetStudentPostQuery,
  useLazyGetStudentPostQuery,
  useFriendsListCountQuery,
  useStudentReviewCountQuery,
  useGetLoginCountQuery,
  useGetLevelRankMutation,
  useGetCommentLikeQuery,
} = extendBadgesApi;
