import React, { FC } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { Image } from "expo-image";
import { LinearGradient } from "expo-linear-gradient";
import { COLORS, images } from "../../../constants";
import PolygonProfile from "../../../components/PolygonProfile";
import PostLike from "../../../components/PostLike/PostLike";
import { FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";

// import PolygonProfile from "../general/polygonProfile";
// import PostFeedPopup from "./postFeedPopup";
// import PostLike from "./postLike";
// import CachBackImage from "../general/cachBackImage";

export interface IFPostFeedsItems {
  item: ExperiencePhoto;
  onPhotoClick: (item: Partial<ExperiencePhoto> | null) => void;
  applyLike: (item: Partial<ExperiencePhoto>) => void;
  onPhotoCommentClick: (experiencePhoto: Partial<ExperiencePhoto>) => void;
}

const PostFeedsItems: FC<IFPostFeedsItems> = ({
  item,
  onPhotoClick,
  applyLike,
  onPhotoCommentClick,
}) => {
  console.log(item.experience?.experienceType);
  return (
    <>
      <TouchableOpacity
        style={{
          width: "48.4%",
          marginLeft: "0.8%",
          marginRight: "0.8%",
          marginBottom: 5,
          borderRadius: 4,
          flexDirection: "column",
          flex: 1,
          justifyContent: "space-around",
        }}
        onPress={() => {
          onPhotoCommentClick(item);
        }}
      >
        <View>
          <ImageBackground
            source={{ uri: item?.photo }}
            style={styles.image}
            resizeMode="cover"
          >
            <LinearGradient
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                paddingBottom: 10,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 1 }}
              colors={[COLORS.transparent, COLORS.black]}
            >
              <View style={styles.topContainer}>
                <View style={styles.userImage}>
                  <PolygonProfile xp={3400} studentPic={item?.student?.image} />
                </View>
                <Text style={styles.title} numberOfLines={1}>
                  {" "}
                  {item?.experience?.title!}
                </Text>
              </View>

              {(item?.experience?.experienceType as string) ===
                "645b54a297486c69e11f2781" && (
                <View style={{ flex: 1, padding: 15, paddingLeft: 20 }}>
                  <Image
                    source={images.groupImage}
                    style={styles.groupImageIcon}
                  />
                </View>
              )}

              <View style={styles.userContainer}>
                <PostLike item={item} applyLike={applyLike} />
                <TouchableOpacity
                  onPress={() => {
                    onPhotoCommentClick(item);
                  }}
                  style={styles.userComment}
                >
                  <FontAwesome name="comment" size={24} color="white" />
                </TouchableOpacity>
                <View></View>
              </View>
            </LinearGradient>
          </ImageBackground>
          {/* </Image> */}
        </View>
      </TouchableOpacity>
    </>
  );
};
const styles = StyleSheet.create({
  groupImageIcon: {
    width: 24,
    height: 24,
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    with: "100%",
  },
  postFeedsContainer: {
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "space-around",
  },
  likeNumber: {
    fontSize: 12,
    color: "#fff",
    fontFamily: "Roboto-Regular",
  },
  likeStyle: {
    fontSize: 12,
    color: "#fff",
    marginLeft: 3,
    fontFamily: "Roboto-Regular",
  },
  image: {
    width: "100%",
    justifyContent: "space-between",
    height: 340,
    backgroundColor: "#5e5e5e",
  },
  userImage: {
    transform: [{ scale: 0.2 }],
    width: "40%",
    marginTop: -10,
    marginRight: 6,
    height: 40,
    left: -20,
  },
  userComment: {
    width: "40%",
    marginTop: 0,
    marginRight: 6,
    display: "flex",
    paddingTop: 15,
    paddingRight: 12,
    alignItems: "flex-end",
  },
  likeContent: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: 14,
    marginTop: 10,
    flex: 1,
  },
  imageContainer: {
    width: "42%",
    marginLeft: "2%",
    marginRight: "2%",
    marginBottom: 20,
    borderRadius: 4,
    overflow: "hidden",
  },
  title: {
    fontSize: 15,
    height: 30,
    color: "#fff",
    textAlign: "center",
    left: -25,
    marginTop: 15,
    maxWidth: 120,
  },
  userContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    marginBottom: 6,
  },
  like: {
    flexDirection: "row",
    alignItems: "center",
    width: "35%",
    marginLeft: 12,
    height: "100%",
    textAlign: "center",
    marginTop: 4,
  },
});
export default PostFeedsItems;
