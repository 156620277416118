import React from "react";
import {
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  Platform,
} from "react-native";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import { COLORS, SIZES, images } from "../../../constants";
import StyleSheet from "react-native-media-query";
import { LearnerSetting } from "../../learnerSetting/type/LearnerSetting";

type GradeHeaderPersentationProps = {
  backgroundProfile: string;
  goBackHandler: () => void;
  goAccountSetting: () => void;
  showSetting: boolean;
  learnerData?: LearnerSetting;
};
const GradeHeaderPersentation: React.FC<GradeHeaderPersentationProps> = ({
  backgroundProfile,
  goBackHandler,
  goAccountSetting,
  showSetting,
}) => {
  return (
    <ImageBackground
      source={images.experienceTop}
      style={styles.cardHeader}
      resizeMode="cover"
      borderBottomLeftRadius={40}
      borderBottomRightRadius={40}
    >
      <View
        style={{
          height: 40,
          backgroundColor: "rgba(255,255,255,0.75)",
          padding: 3,
          borderRadius: 50,
          marginLeft: 20,
          marginTop: 40,
        }}
      >
        <TouchableOpacity onPress={goBackHandler}>
          <FontAwesome5 name="arrow-circle-left" size={32} color="black" />
        </TouchableOpacity>
      </View>
      {showSetting && (
        <View
          style={{
            height: 40,
            backgroundColor: "rgba(255,255,255,0.75)",
            padding: 3,
            borderRadius: 50,
            marginTop: 40,
            marginRight: 20,
          }}
        >
          <TouchableOpacity onPress={goAccountSetting}>
            <Ionicons name="md-settings" size={32} color="black" />
          </TouchableOpacity>
        </View>
      )}
    </ImageBackground>
  );
};

const { styles } = StyleSheet.create({
  cardHeader: {
    width: "100%",
    flexDirection: "row",
    height: Platform.OS === "android" ? 110 : 150,
    justifyContent: "space-between",
    backgroundColor: "#1766C1",
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
  },
});
export default GradeHeaderPersentation;
