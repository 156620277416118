import {
  View,
  Text,
  ImageBackground,
  Pressable,
  ScrollView,
  Linking,
  TouchableOpacity,
} from "react-native";
import GradegyCheckBox from "../components/gradegyCheckBox";
import { Image } from "expo-image";
import React, { useState, useCallback, FC } from "react";
import StyleSheet from "react-native-media-query";
import { Dimensions } from "react-native";
import { images } from "../constants";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../navigation/types";

type OpenURLButtonProps = {
  url: string;
  children: any;
};

const { height } = Dimensions.get("window");
const supportedURL = "https://gradegy.com/privacy-policy/";
const termsURL = "https://gradegy.com/terms-conditions/";

const TermAndConditionScreen = () => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  //here we should get the student

  const OpenURLButton: React.FC<OpenURLButtonProps> = ({ url, children }) => {
    const handlePress = useCallback(async () => {
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url);
      }
    }, [url]);

    return (
      <TouchableOpacity onPress={handlePress}>
        <View>{children}</View>
      </TouchableOpacity>
    );
  };
  return (
    <ScrollView style={styles.container}>
      <ImageBackground
        source={images.onBoardingbackgroundDark}
        resizeMode="cover"
        style={styles.backgroundImage}
      >
        <View style={[styles.LogoContainers, styles.LogoContainerEnd]}>
          <Image source={images.AuthLog} />
        </View>
        <View style={{ justifyContent: "space-between", flex: 1 }}>
          <View style={styles.content}>
            <View style={styles.LogoContainer}>
              <Image
                source={images.GradegyWaterMark}
                style={styles.imageGradegy}
                contentFit="contain"
              />
            </View>
            <View style={styles.boxes}>
              <Text style={styles.boxesText}>
                Terms and Conditions
              </Text>
              <View style={{ width: "100%" }}>
                <View style={styles.checkboxContainer}>
                <GradegyCheckBox
                isChecked={isSelected}
                onClick={()=>setIsSelected(!isSelected)}
                leftText={"CheckBox"}
                style={styles.checkbox}
                checkBoxColor="#23D2E2"
                checkedCheckBoxColor="#23D2E2"
                uncheckedCheckBoxColor="#23D2E2"
                />
             
                  <Text style={styles.label}>I Accept</Text>
                </View>
                <View
                  style={{ flexDirection: "row", justifyContent: "center" }}
                >
                  <OpenURLButton url={termsURL}>
                    <Text style={styles.linkButton}>
                      {" "}
                      Read{" "}
                      <Text style={{ color: "#27D3E3" }}>
                        terms and conditions
                      </Text>{" "}
                      and{" "}
                    </Text>
                  </OpenURLButton>
                  <OpenURLButton url={supportedURL}>
                    <Text style={styles.linkButton}>
                      {" "}
                      <Text style={{ color: "#27D3E3" }}>privacy policy</Text>
                    </Text>
                  </OpenURLButton>
                </View>

                <Pressable
                  onPress={() => {
                    navigation.navigate("PermissionNotification");
                    // disabled={option && option.length > 0 ? false : true}
                  }}
                  style={[styles.buttonNext, styles.buttonLogin]}
                  disabled={!isSelected === false ? false : true}
                >
                  <Text style={styles.nextButtonText}>Continue</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      </ImageBackground>
    </ScrollView>
  );
};

const { styles } = StyleSheet.create({
  buttonLogin: {
    backgroundColor: "#615DFA",
    borderRadius: 13,
    justifyContent: "center",
    alignItems: "center",
    padding: 13,
    marginTop: "5%",
    "@media (max-width: 360px)": {
      marginTop: "8%",
    },
  },

  linkButton: {
    fontSize: 10,
    textAlign: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 20,
    minWidth: "100%",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    paddingLeft: "10%",
    paddingRight: "10%",
    "@media (max-width: 360px)": {
      height: 50,
      marginBottom: 10,
    },
  },
  checkbox: {
    borderRadius: 4,
    color:"red"
  },
  label: {
    margin: 8,
    paddingLeft: 10,
    fontSize: 12,
    fontWeight: "600",
  },

  imageGradegy: {
    width: 300,
    height: 50,
  },

  boxes: {
    backgroundColor: "#fff",
    shadowColor: "#000",

    shadowOffset: {
      width: 0,
      height: 5,
    },

    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
    borderRadius: 12,
    padding: 24,
    paddingTop: 24,
    paddingBottom: 45,
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "20%",
    "@media (max-width: 360px)": {
      marginTop: 10,
      paddingTop: 20,
      paddingBottom: 20,
    },
  },

  boxesText: {
    fontSize: 22,
    fontWeight:"bold",
    flexDirection: "row",
    paddingBottom: 20,
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    width: "80%",
    marginLeft:"10%",
    marginRight:"10%",
    paddingLeft: "10%",
    paddingRight: "10%",
    "@media (max-width: 360px)": {
      paddingBottom: 10,
    },
  },
  buttonNext: {
    width: 240,
    height: 45,
    borderRadius: 15,
    backgroundColor: "#34269E",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonAction: {
    backgroundColor: "#5CFF88",
  },
  nextButtonText: {
    color: "white",
    fontSize: 18,
  },
  tabContainer: {
    flexDirection: "row",
    marginTop: 25,
    height: 50,
    position: "relative",
    overflow: "hidden",
    borderRadius: 19,
    width: 263,
    marginBottom: 20,
    alignSelf: "center",
  },
  textInput: {
    marginBottom: 15,
  },
  contentContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },

  container: {
    minHeight: height,
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10%",
    textAlign: "center",
    maxWidth: "90%",
    alignSelf: "center",
    "@media (max-width: 360px)": {
      marginTop: 5,
    },
  },

  title: {
    flexDirection: "row",
    textAlign: "center",
  },
  description: {
    fontSize: 18,
    color: "#fff",
    textAlign: "center",
    marginBottom: "15%",
  },
  titleText: {
    color: "#fff",
    fontSize: 40,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    marginBottom: "10%",
  },
  desc: {
    color: "#fff",
    fontSize: 48,
    textAlign: "center",
    marginBottom: "15%",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    minHeight: height,
    width: "100%",
    alignItems: "center",
  },

  image: {
    width: 39,
    height: 35,
    marginTop: 30,
    alignItems: "flex-end",
    marginRight: 20,
  },
  LogoContainer: {
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  buttonContainer: {
    backgroundColor: "#23d2e2",
    borderRadius: 12,
  },
  buttonText: {
    fontSize: 14,
    paddingLeft: 20,
    paddingRight: 20,
  },
  progressContainer: {
    flexDirection: "row",
    marginBottom: "10%",
    justifyContent: "space-around",
  },
  progressBar: {
    height: 31,
    width: "100%",
    backgroundColor: "#b1adad",
    borderRadius: 20,
    minWidth: 230,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
  },
  progressValue: {
    color: "#fff",
    fontSize: 25,
    marginLeft: "4%",
  },
  errorText: {
    paddingTop: "5%",
    color: "red",
  },
  LogoContainers: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 20,
    paddingTop: 40,
    "@media (max-width: 360px)": {
      paddingTop: 30,
      paddingBottom: 10,
    },
  },
  LogoContainerEnd: {
    justifyContent: "flex-end",
  },
});
export default TermAndConditionScreen;
