import React from "react";
import { View, Text, ScrollView, Dimensions, FlatList } from "react-native";
import StyleSheet from "react-native-media-query";
import SmallLinearChart from "../../../components/SmallLinearChart/SmallLinearChart";
import { EntityState } from "@reduxjs/toolkit";
import { GradeType } from "../types/grade";

const { width, height } = Dimensions.get("window");
type GradeItemPresentationProps = {
  grades: EntityState<GradeType>;
};
const GradeItemPresentation: React.FC<GradeItemPresentationProps> = ({
  grades,
}) => {
  return (
    <View style={styles.teamsTabContentContainer}>
      <FlatList
        style={styles.container}
        data={Object.values(grades!.entities!) as GradeType[]}
        keyExtractor={(item) => `${item._id!}`}
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{}}
        // style={{ maxHeight: "100%", paddingBottom: 10 }}
        renderItem={({ item }) => (
          <View style={styles.gradeItemContainer}>
            <View>
              <Text style={styles.gradeItemNum}>{item.current_grade}%</Text>
            </View>
            <View style={styles.gradeItemContent}>
              <View style={styles.gradeItemContentLeft}>
                <View>
                  <Text style={styles.gradeItemDesc}>{item.course_name}</Text>
                </View>

                <View>
                  <Text style={styles.gradeItemAcademic}>
                    Academic Progress
                  </Text>
                  <SmallLinearChart
                    value={item.course_completion_percentage}
                    lastValue={Number(item.where_you_should_be)}
                    maxValue={100}
                    minValue={0}
                  />
                </View>
              </View>
              <View style={styles.gradeItemContentRight}>
                <Text style={styles.gradeItemXpText}>XP Earned</Text>
                <Text style={styles.gradeItemXp}>{item.xpEran}</Text>
              </View>
            </View>
          </View>
        )}
      />

      {/* <View style={styles.gradeItemContainer}>
              <View>
                <Text style={styles.gradeItemNum}>75%</Text>
              </View>
              <View style={styles.gradeItemContent}>
                <View style={styles.gradeItemContentLeft}>
                  <View>
                  <Text style={styles.gradeItemDesc}>Computer Applications and Information Technology (self-paced)</Text>
                  </View>

                    <View>
                      <Text style={styles.gradeItemAcademic}>Academic Progress</Text>
                      <SmallLinearChart
                      value={70}
                      lastValue={80}
                      maxValue={100}
                      minValue={0}
                        />
                    </View>

                </View>
                <View style={styles.gradeItemContentRight}>
                  <Text style={styles.gradeItemXpText}>XP Earned</Text>
                  <Text style={styles.gradeItemXp}>7300</Text>
                </View>
       
              </View>
            </View>




            <View style={styles.gradeItemContainer}>
              <View>
                <Text style={styles.gradeItemNum}>75%</Text>
              </View>
              <View style={styles.gradeItemContent}>
                <View style={styles.gradeItemContentLeft}>
                  <View>
                  <Text style={styles.gradeItemDesc}>Computer Applications and Information Technology (self-paced)</Text>
                  </View>

                    <View>
                      <Text style={styles.gradeItemAcademic}>Academic Progress</Text>
                      <SmallLinearChart
                      value={70}
                      lastValue={80}
                      maxValue={100}
                      minValue={0}
                        />
                    </View>

                </View>
                <View style={styles.gradeItemContentRight}>
                  <Text style={styles.gradeItemXpText}>XP Earned</Text>
                  <Text style={styles.gradeItemXp}>7300</Text>
                </View>
       
              </View>
            </View> */}
    </View>
  );
};

const { styles } = StyleSheet.create({
  gradeItemContainer: {
    flexDirection: "row",
    height: 82,
  },
  gradeItemNum: {
    fontFamily: "RajdahaniBold",
    fontSize: 36,
  },
  gradeItemContent: {
    width: width - 100,
    flexDirection: "row",
    alignItems: "flex-end",

    paddingLeft: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#BABABA",
  },
  gradeItemContentLeft: {
    justifyContent: "space-between",
    width: "81%",
    height: 80,
    paddingTop: 10,
  },
  gradeItemDesc: {
    fontFamily: "Roboto-Bold",
    fontSize: 10,
    lineHeight: 17,
  },
  gradeItemAcademic: {
    fontFamily: "RajdahaniBold",
    fontSize: 7,
  },
  gradeItemContentRight: {},
  gradeItemXpText: {
    fontFamily: "Roboto-Bold",
    fontSize: 7,
  },
  gradeItemXp: {
    fontFamily: "Roboto-Bold",
    fontSize: 12,
    color: "#FF4C00",
  },
  teamsTabContainer: {
    minWidth: "100%",

    // minHeight: "80%",
  },
  container: {
    minHeight: height,
  },
  teamsTabContentContainer: {
    height: height - 125,
    marginTop: 35,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    shadowRadius: 10,
    shadowOffset: {
      width: 0,
      height: -3,
    },
    shadowColor: "#000000",
    elevation: 4,
    shadowOpacity: 0.2,
    padding: 30,
  },
});

export default GradeItemPresentation;
