import React, { FC } from "react";
import {
  View,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  Pressable,
  ImageBackground,
} from "react-native";
// import PolygonProfile from "../general/polygonProfile";
import { MaterialIcons } from "@expo/vector-icons";
import { EntityState } from "@reduxjs/toolkit";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import PolygonProfile from "../../../components/PolygonProfile";
import PostLike from "../../../components/PostLike/PostLike";
// import PostLike from "./postLike";
// import CachImage from "../general/cachImage";
const { width } = Dimensions.get("window");

export type PostFeedPopupProps = {
  openPopup: boolean;
  onClose: () => void;
  item: Partial<ExperiencePhoto> | null;
  applyLike: (item: Partial<ExperiencePhoto>) => void;
};

const PostFeedPopup: FC<PostFeedPopupProps> = ({
  openPopup,
  onClose,
  item,
  applyLike,
}) => {
  return (
    <>
      <Modal transparent={true} visible={openPopup} animationType="fade">
        <Pressable style={styles.modelContainer} onPress={onClose}>
          <View style={styles.modalImageContainer}>
            <ImageBackground
              source={{
                uri: item?.photo!,
              }}
              style={styles.image}
            />
            <TouchableOpacity
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                left: 0,
                top: 0,
                zIndex: 999,
              }}
              onPress={onClose}
            >
              <MaterialIcons
                style={{
                  textShadowColor: "#000",
                  textShadowOffset: { width: 0, height: 1 },
                  textShadowRadius: 3,
                }}
                name="close"
                size={30}
                color="white"
              />
            </TouchableOpacity>
            <View style={[styles.userImage]}>
              <PolygonProfile
           
                strokeWidth={7}
                xp={3490}
                studentPic={item?.student?.image}
              />
            </View>
            <View style={styles.userContainer}>
              <PostLike
                item={item!}
                applyLike={() => {
                  applyLike(item!);
                }}
              />
            </View>
            <View style={styles.postFeedPopupTilte}>
              <Text style={styles.postFeedPopupTilteText} numberOfLines={1}>
                {item?.experience?.title}
              </Text>
            </View>
          </View>
        </Pressable>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  userContent: {
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    width: 77,
  },
  firendsList: {
    flex: 1,
  },
  postFeedPopupTilte: {
    position: "absolute",
    bottom: -40,
    width: "100%",
    alignItems: "center",
  },
  postFeedPopupTilteText: {
    fontSize: 34,

    color: "#fff",
    textShadowColor: "#000",
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 3,
  },
  modalImageContainer: {
    position: "relative",
    maxWidth: 374,
    maxHeight: 526,
    height: "60%",
    width: "92%",
    backgroundColor: "#5e5e5e",
    borderRadius: 15,
  },

  modelContainer: {
    backgroundColor: "#000000aa",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    maxWidth: "100%",
    width: width,
    alignSelf: "center",
  },

  likeNumber: {
    fontSize: 22,
    color: "#fff",
    fontFamily: "Roboto-Regular",
  },
  likeStyle: {
    fontSize: 22,
    color: "#fff",
    marginLeft: 3,
    fontFamily: "Roboto-Regular",
  },
  image: {
    height: "100%",
    width: "100%",
    position: "absolute",
    borderRadius: 15,
  },
  userImage: {
    transform: [{ scale: 0.8 }],
    width: "100%",
    marginRight: 6,
    top: -150,
    fontSize: 22,
    position: "absolute",
    alignItems: "center",
  },
  likeContent: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: "5%",
    flex: 1,
  },
  imageContainer: {
    backgroundColor: "green",
  },

  userContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: 90,
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  like: {
    flexDirection: "row",
    alignItems: "center",
    width: "35%",
    marginLeft: 12,
    height: "100%",
    textAlign: "center",
    marginTop: 4,
  },

  profileInfoImage: {
    position: "relative",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    maxWidth: 360,
    maxHeight: 401,
    borderRadius: 15,
    backgroundColor: "#fff",
    overflow: "hidden",
    width: "90%",
    height: "70%",
  },
  images: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 54,
    position: "absolute",
    height: 55,
    left: 165,
    top: 140,
    zIndex: 9999,
  },
  xpNumbrt: {
    fontSize: 28,
    color: "#fff",
    alignSelf: "center",
  },
  profileRd: {
    width: 50,
    height: 50,
    borderRadius: 50,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#0BC4B8",
    flexDirection: "row",
  },
  profileRdNumber: {
    color: "#fff",
    fontSize: 22,
  },
  profileRdText: {
    color: "#fff",
  },
  backProfilePic: {
    width: "100%",
    height: 145,
    position: "absolute",
    top: 0,
  },
  backProfileImage: {
    width: "100%",
    height: 145,
  },
  profileRdXp: {
    flexDirection: "row",
    alignItems: "center",
    width: "96%",
    textAlign: "center",
    justifyContent: "space-between",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: "white",
    elevation: 5,
    marginTop: 10,
    padding: 7,
    borderRadius: 10,
  },
  profileXpText: {
    fontSize: 40,
    fontWeight: "bold",
  },
  profileInfoShapeContainer: {
    marginTop: 10,
  },
  likeImage: {
    paddingLeft: 5,
  },
});

export default PostFeedPopup;
