import React, { useEffect, useState } from "react";
import {
  View,
  Animated,
  TouchableOpacity,
  Dimensions,
  Text,
  ImageBackground,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { FONTS, images } from "../../../constants";
import BadgeSystemAllListContainer from "../container/BadgeSystemAllListContainer";
import BadgeSystemLockedListContainer from "../container/BadgeSystemLockedListContainer";
import BadgeSystemAchievedListContainer from "../container/BadgeSystemAchievedListContainer";

const { width } = Dimensions.get("window");
type BadgeSystemTabPresentationProps = {
  activeProp: number | undefined;
  xTwoButton: number | undefined;
  itemBadge: any;
  popUpOpen: boolean | undefined;
};
const BadgeSystemTabPresentation: React.FC<BadgeSystemTabPresentationProps> = ({
  activeProp,
  xTwoButton,
  itemBadge,
  popUpOpen,
}) => {
  const today = new Date();
  today.setDate(today.getDate() + 14);

  const [active, setActive] = useState(activeProp ? activeProp : 0);
  const [xTabOne, setXTabOne] = useState(0);
  const [xTabTwo, setXTabTwo] = useState(xTwoButton ? xTwoButton : 0); //110.85713958740234
  const [xTabThree, setXTabThree] = useState(0);
  const [translateX, setTranslateX] = useState(new Animated.Value(0));
  const [translateXTabOne, setTranslateXTabOne] = useState(
    new Animated.Value(0)
  );
  const [translateXTabTwo, setTranslateXTabTwo] = useState(
    new Animated.Value(width)
  );
  const [translateXTabThree, setTranslateXTabThree] = useState(
    new Animated.Value(width * 2)
  );
  const [translateY, setTranslateY] = useState(-1000);

  const handleSlide = (type: number) => {
    Animated.spring(translateX, {
      toValue: type,
      speed: 200,
      useNativeDriver: true,
    }).start();
    if (active === 0) {
      Animated.parallel([
        Animated.spring(translateXTabOne, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(translateXTabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(translateXTabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else if (active === 1) {
      Animated.parallel([
        Animated.spring(translateXTabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(translateXTabTwo, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(translateXTabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.spring(translateXTabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(translateXTabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(translateXTabThree, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    }
  };

  useEffect(() => {
    if (active === 0) {
      handleSlide(xTabOne);
    } else if (active === 1) {
      handleSlide(xTabTwo);
    } else {
      handleSlide(xTabThree);
    }
  }, [active]);
  return (
    <View style={styles.cardContainer}>
      <View
        style={{
          width: "100%",
          height: "100%",
          flex: 1,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: -21,
            marginBottom: 20,
            height: 40,
            position: "relative",
            backgroundColor: "#F8C586",
            borderRadius: 36,
            width: "80%",
            marginLeft: "10%",
            borderColor: "#fff",
            borderWidth: 3,
          }}
        >
          <Animated.View
            style={{
              position: "absolute",
              width: "33%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "#F7941C",

              borderRadius: 36,
              transform: [
                {
                  translateX,
                },
              ],
            }}
          />
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 36,
              overflow: "hidden",

              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onLayout={(event) => setXTabOne(event.nativeEvent.layout.x)}
            onPress={() => {
              setActive(0);
            }}
          >
            <Text
              style={{
                color: active === 0 ? "#fff" : "#fff",
                fontFamily: "Roboto-Bold",
                fontSize: 14,
              }}
            >
              All
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              borderLeftWidth: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onLayout={(event) => {
              setXTabTwo(event.nativeEvent.layout.x);
            }}
            onPress={() => {
              setActive(1);
              handleSlide(xTabTwo);
            }}
          >
            <Text
              style={{
                color: active === 1 ? "#fff" : "#fff",
                fontFamily: "Roboto-Bold",
                fontSize: 14,
              }}
            >
              Achieved
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 36,
              borderLeftWidth: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onLayout={(event) => setXTabThree(event.nativeEvent.layout.x)}
            onPress={() => {
              setActive(2);
              handleSlide(xTabThree);
            }}
          >
            <Text
              style={{
                color: active === 2 ? "#fff" : "#fff",
                fontFamily: "Roboto-Bold",
                fontSize: 14,
              }}
            >
              Locked
            </Text>
          </TouchableOpacity>
        </View>

        <View
          style={{
            height: "100%",
          }}
        >
          <Animated.View
            style={{
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "100%",
              height: "100%",
              flex: 1,
              flexGrow: 1,
              opacity: translateXTabOne,
              zIndex: translateXTabOne,
            }}
            onLayout={(event) => setTranslateY(event.nativeEvent.layout.height)}
          >
            <View style={{ flex: 1 }}>
              <BadgeSystemAllListContainer />
            </View>
          </Animated.View>

          <Animated.View
            style={{
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "100%",
              height: "100%",
              flex: 1,
              flexGrow: 1,
              opacity: translateXTabTwo,
              zIndex: translateXTabTwo,

              transform: [
                {
                  translateY: 0,
                },
              ],
            }}
          >
            <View style={{ flex: 1 }}>
              <BadgeSystemAchievedListContainer
                itemBagdeProp={itemBadge}
                popUpOpen={popUpOpen}
              />
            </View>
          </Animated.View>

          <Animated.View
            style={{
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "100%",
              height: "100%",
              flex: 1,
              flexGrow: 1,
              opacity: translateXTabThree,
              zIndex: translateXTabThree,
              transform: [
                {
                  translateY: 0,
                },
              ],
            }}
          >
            <View style={{ flex: 1 }}>
              <BadgeSystemLockedListContainer />
            </View>
          </Animated.View>
        </View>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  cardContainer: {
    flex: 1,
    backgroundColor: "#fff",
    top: 0,
  },
  cardList: {
    flex: 1,
  },
  cardHeader: {
    width: "100%",
    height: 130,
    backgroundColor: "#1766C1",
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 100,
  },
  profileXpCoinsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  headerxpcontainer: {
    flex: 1,
    paddingBottom: 10,
    paddingRight: 5,
  },
  xpNumbrt: {
    fontSize: 28,
    color: "#fff",
    alignSelf: "center",
    fontWeight: "bold",
  },
  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#0F3363",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#0F3363",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,

    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
  },
  xp: {
    color: "white",
    fontSize: 26,
  },
});

export default BadgeSystemTabPresentation;
