import React from "react";
import {
  View,
  Text,
  TextInput,
  Animated,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { LoginPresentationProps } from "../../types/types";
import { COLORS, FONTS, SIZES } from "../../../../constants";
import { CustomButton } from "../../../../components";
import StyleSheet from "react-native-media-query";
import InputComponent from "../../../../components/InputComponent/InputComponent";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { scale, verticalScale, moderateScale } from 'react-native-size-matters';
const LoginPresentation: React.FC<LoginPresentationProps> = ({
  email,
  password,
  onEmailChange,
  onLogin,
  onPasswordChange,
  isLoading,
}) => {
  return (
    <Animated.View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.title}>Account Login</Text>
        <View style={styles.input}>
          <InputComponent
            value={email}
            onInputChange={onEmailChange}
            label="Email"
          />
        </View>
        <View style={styles.input}>
          <InputComponent
            value={password}
            onInputChange={onPasswordChange}
            label="Password"
            textContentType="password"
            secureTextEntry
          />
        </View>
        <TouchableOpacity onPress={() => { }}>
          <Text style={styles.forgotPassword}>Forgot Password?</Text>
        </TouchableOpacity>
        <Pressable
          onPress={onLogin}
          style={styles.buttonLogin}
          disabled={isLoading}
        >
          {isLoading ? (
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Text style={styles.loginText}>Loading...</Text>
              <View
                style={{
                  width: 20,
                  height: 20,
                  position: "absolute",
                  right: 0,
                }}
              >
                <LoadingScreen iconSize={20} />
              </View>
            </View>
          ) : (
            <Text style={styles.loginText}>Login To Your Account</Text>
          )}
        </Pressable>
      </View>
    </Animated.View>
  );
};
const { styles } = StyleSheet.create({
  buttonLogin: {
    backgroundColor: "#34269E",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    padding: 13,
    marginTop: 15,
  },
  loginText: {
    color: "white",
    fontSize: moderateScale(12),
    fontFamily: "RajdahaniBold",
    "@media (min-width: 900px)": {
      fontSize: moderateScale(9),
    },
  },
  input: {
    height: hp('8%'),
    overflow: "hidden",
    "@media (max-width: 360px)": {
      height: hp('12%'),
    },
  },
  textInput: {
    borderRadius: 50,
    backgroundColor: "transparent",
    marginBottom: 15,
    "@media (max-width: 360px)": {
      marginBottom: 10,
    },
  },
  container: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  content: {
    backgroundColor: "#fff",
    width:wp('80%'),
    maxWidth: 380,
    borderRadius: 12,
    padding: 45,
    marginTop: 40,
    textAlign: "center",
    paddingBottom: "20%",
    maxHeight:400,
    "@media (max-width: 360px)": {
      padding: 25,
      marginTop: 10,
      paddingBottom: 30,
    },
    "@media (min-width: 900px)": {
      paddingBottom: "6%",
      maxWidth: 420,
    },
  },
  title: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: moderateScale(18),

    paddingBottom: moderateScale(32),
    fontFamily: "RajdahaniBold",
    "@media (min-width: 900px)": {
      paddingBottom: "6%",
      maxWidth: 420,
    },
    
  },
  chebox: {
    backgroundColor: "#fff",
    borderWidth: 0,
    marginLeft: 0,
    paddingLeft: 0,

    fontSize: 20,
    "@media (max-width: 360px)": {
      fontSize: 18,
    },
  },
  checkboxTextStyle: {
    fontSize: 13,
    fontFamily: "RajdahaniBold",
  },

  forgotText: {
    fontSize: 13,
    color: "#afb0c0",
    textAlign: "right",
    alignItems: "flex-end",
    fontFamily: "RajdahaniBold",
    "@media (max-width: 360px)": {
      fontSize: 11,
    },
  },
  buttonContainer: {
    backgroundColor: "#615dfa",
    fontFamily: "RajdahaniMedium",
    height: 39,
    borderRadius: 12,
    overflow: "hidden",
    borderWidth: 3,
    borderColor: "red",
    "@media (max-width: 360px)": {
      height: 32,
    },
  },
  buttonText: {
    fontFamily: "RajdahaniMedium",
    fontSize: 10,
  },
  errorText: {
    paddingTop: "5%",
    color: "red",
  },

  forgotPassword: {
    color: "#615dfa",
    fontFamily: "RajdahaniBold",
    fontSize: moderateScale(12),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    top: hp('-1%'),
    "@media (max-width: 360px)": {

      top:  hp('-0.7%'),
    },
  },
});
export default LoginPresentation;
