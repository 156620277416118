import React from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";

const ProfileSettingMenuTextPresentation = () => {

  return (

        <View style={styles.ProfileSettingMenuTextContainer}>

          <TextStroke stroke={ 2 } color={ '#FFF' } >
              <Text style={ {
                fontSize: 59,
                color: '#000',
              } }> Menu </Text>

            </TextStroke>

        </View>

  );

};


const { styles } = StyleSheet.create({
    ProfileSettingMenuTextContainer:{
      top:50,
      width:"50%",
      position:"absolute",
      marginLeft:"25%",
      marginRight:"25%"
   },


});

export default ProfileSettingMenuTextPresentation;
