import React from "react";
import { TouchableOpacity, View, Text } from "react-native";
import { Image } from "expo-image";
import { NotificationCenterType } from "../types/types";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import StyleSheet from "react-native-media-query";
import PolygonProfile from "../../../components/PolygonProfile";
import { FontAwesome5 } from "@expo/vector-icons";
import { useCompleteNotificationMutation } from "../api/notificationCenterApi";
import { useAcceptRequestMutation } from "../../friend/api/friendsApiSlice";
import { formatDistance } from "date-fns";
type NotificationCenterItemPresentationProps = {
  item: NotificationCenterType;
  onClose?: () => void;
};
const NotificationCenterItemPresentation: React.FC<
  NotificationCenterItemPresentationProps
> = ({ item, onClose }) => {
  const admin = useAppSelector(selectAdmin);
  const account = useAppSelector(selectDefaultAccount);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [completeNotification, { isLoading, isError, isSuccess }] =
    useCompleteNotificationMutation();
  const [
    acceptRequest,
    {
      isLoading: acceptLoading,
      isError: acceptError,
      isSuccess: acceptSuccess,
    },
  ] = useAcceptRequestMutation();
  const onNotificationSystemClick = async () => {
    onClose!();
    switch (item.typeNotifi) {
      case "badges":
        navigation.navigate("BadgeSystem", {
          student: admin!,
          active: 1,
          xTwoButton: 110.85713958740234,
          itemBadge: item.typeItem,
          popUpOpen: true,
        });
        break;
      case "experiencecards":
        await completeNotification({
          notificationCenter: item,
        }).unwrap();
        onClose!();
        navigation.navigate("ExperienceMap", {
          item: item.typeItem,
          openPopup: true,
        });
        break;

      case "commentLike":
        try {
          await completeNotification({
            notificationCenter: item,
          }).unwrap();
          onClose!();
          navigation.navigate("FeedDetail", {
            photo: item.typeItem.experiencePhoto!,
            student: item.typeItem.student!,
            open: true,
          });
          break;
        } catch (error) {}

      case "friend":
        break;
      case "likePost":
        try {
          await completeNotification({
            notificationCenter: item,
          }).unwrap();
          onClose!();
          navigation.navigate("FeedDetail", {
            photo: item.typeItem,
          });
          break;
        } catch (error) {}
    }
  };

  const renderNotificationExperience = (item: NotificationCenterType) => {
    if (
      item.typeNotifi === "experiencecards" &&
      new Date().getTime() > new Date(item.typeItem.startDate).getTime()
    ) {
      return (
        <>
          <Image
            source={{ uri: item.image }}
            style={styles.notificationItemImage}
          />
          <View style={styles.notificationItemContent}>
            <Text style={styles.notificationItemTitle}>{item.title}</Text>
            <Text style={styles.notificationItemDesc}>{item.description}</Text>
          </View>
          <View style={styles.notificationItemAddFriends}>
            <Text style={styles.notificationTime}>
              {formatDistance(new Date(item.createdAt), new Date(), {
                addSuffix: true,
              })}
            </Text>
          </View>
        </>
      );
    }
    return <></>;
  };

  const renderItems = () => {
    switch (item.typeNotifi) {
      case "badges":
        return (
          <>
            <Image
              source={{ uri: item.image }}
              style={styles.notificationItemImage}
            />
            <View style={styles.notificationItemContent}>
              <Text style={styles.notificationItemTitle}>{item.title}</Text>
              <Text style={styles.notificationItemDesc}>
                {item.description}
              </Text>
            </View>
            <View style={styles.notificationItemAddFriends}>
              <Text style={styles.notificationTime}>
                {formatDistance(new Date(item.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </Text>
            </View>
          </>
        );

      case "experiencecards":
        return <>{renderNotificationExperience(item)}</>;
        break;

      case "commentLike":
        return (
          <>
            <View style={styles.userImage}>
              <PolygonProfile
                showXp={false}
                xp={3450}
                studentPic={item.image}
                strokeColor={"white"}
                ContainerStyle={{
                  transform: [{ scale: 0.26 }],
                  left: -85,
                  top: -92,
                }}
              />
            </View>
            <View style={styles.notificationItemContent}>
              <Text style={styles.notificationItemTitle}>{item.title}</Text>
              <Text style={styles.notificationItemDesc}>
                {item.description}
              </Text>
            </View>
            <View style={styles.notificationItemAddFriends}>
              <Text style={styles.notificationTime}>
                {formatDistance(new Date(item.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </Text>
            </View>
          </>
        );
        break;
      case "likePost":
        return (
          <>
            <View style={styles.userImage}>
              <PolygonProfile
                showXp={false}
                xp={3450}
                studentPic={item.image}
                strokeColor={"white"}
                ContainerStyle={{
                  transform: [{ scale: 0.26 }],
                  left: -85,
                  top: -92,
                }}
              />
            </View>
            <View style={styles.notificationItemContent}>
              <Text style={styles.notificationItemTitle}>{item.title}</Text>
              <Text style={styles.notificationItemDesc}>
                {item.description}
              </Text>
            </View>
            <View style={styles.notificationItemAddFriends}>
              <Text style={styles.notificationTime}>
                {formatDistance(new Date(item.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </Text>
            </View>
          </>
        );
        break;

      case "friend":
        return (
          <>
            <View style={styles.userImage}>
              <PolygonProfile
                showXp={false}
                xp={3450}
                studentPic={item.image}
                strokeColor={"#08c4b9"}
                ContainerStyle={{
                  transform: [{ scale: 0.26 }],
                  left: -85,
                  top: -92,
                }}
              />
            </View>
            <View style={styles.notificationItemContent}>
              <Text style={styles.notificationItemTitle}>{item.title}</Text>
              <Text style={styles.notificationItemDesc}>
                {item.description}
              </Text>
            </View>
            <View style={styles.notificationItemAddFriends}>
              <TouchableOpacity
                style={styles.notificationItemAddFriendsButton}
                onPress={async () => {
                  try {
                    await acceptRequest({
                      friend: item.typeItem,
                      project: account?.project,
                    }).unwrap();
                    await completeNotification({
                      notificationCenter: item,
                    }).unwrap();
                    onClose!();
                    navigation.navigate("Friend");
                  } catch (error) {}
                }}
              >
                <Text style={{ color: "#fff", fontSize: 18 }}>Add</Text>
              </TouchableOpacity>
            </View>
          </>
        );
        break;
      case "schoolrankup":
        return (
          <>
            <Image
              source={{ uri: item.image }}
              style={styles.notificationItemImage}
            />
            <View style={styles.notificationItemContent}>
              <Text style={styles.notificationItemTitle}>{item.title}</Text>
              <Text style={styles.notificationItemDesc}>
                {item.description}
              </Text>
            </View>
            <View style={styles.notificationItemAddFriends}>
              <Text style={styles.notificationTime}>3 mins ago</Text>
              <FontAwesome5 name="arrow-up" size={24} color="#34C38F" />
            </View>
          </>
        );
        break;
      case "schoolrankdown":
        return (
          <>
            {item.showInNotificiation && !item.complete?.complete && (
              <>
                <Image
                  source={{ uri: item.image }}
                  style={styles.notificationItemImage}
                />
                <View style={styles.notificationItemContent}>
                  <Text style={styles.notificationItemTitle}>{item.title}</Text>
                  <Text style={styles.notificationItemDesc}>
                    {item.description}
                  </Text>
                </View>
                <View style={styles.notificationItemAddFriends}>
                  <Text style={styles.notificationTime}>3 mins ago</Text>
                  <FontAwesome5 name="arrow-down" size={24} color="#FF0000" />
                </View>
              </>
            )}
          </>
        );
        break;
      case "shop":
        return (
          <>
            <Image
              source={{ uri: item.image }}
              style={styles.notificationItemImageShop}
            />
            <View style={styles.notificationItemContent}>
              <TouchableOpacity
                onPress={async () => {
                  await completeNotification({
                    notificationCenter: item,
                  }).unwrap();
                  onClose!();
                  navigation.navigate("Prizes");
                }}
              >
                <Text style={styles.notificationItemTitle}>{item.title}</Text>
                <Text style={styles.notificationItemDesc}>
                  {item.description}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.notificationItemAddFriends}>
              <Text style={styles.notificationTime}>
                {formatDistance(new Date(item.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </Text>
            </View>
          </>
        );
        break;
      case "raffle":
        return (
          <>
            <Image
              source={{ uri: item.image }}
              style={styles.notificationItemImageShop}
            />
            <View style={styles.notificationItemContent}>
              <TouchableOpacity
                onPress={async () => {
                  await completeNotification({
                    notificationCenter: item,
                  }).unwrap();
                  onClose!();
                  navigation.navigate("Prizes");
                }}
              >
                <Text style={styles.notificationItemTitle}>{item.title}</Text>
                <Text style={styles.notificationItemDesc}>
                  {item.description}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.notificationItemAddFriends}>
              <Text style={styles.notificationTime}>
                {formatDistance(new Date(item.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </Text>
            </View>
          </>
        );
        break;
      case "raffleover":
        return (
          <>
            <Image
              source={{ uri: item.image }}
              style={styles.notificationItemImageShop}
            />
            <View style={styles.notificationItemContent}>
              <Text style={styles.notificationItemTitle}>{item.title}</Text>
              <Text style={styles.notificationItemDesc}>
                {item.description}
              </Text>
            </View>
            <View style={styles.notificationItemAddFriends}>
              <Text style={styles.notificationTime}>3 mins ago</Text>
            </View>
          </>
        );
        break;
    }
  };
  return (
    <TouchableOpacity
      style={styles.notificationItemContainer}
      onPress={onNotificationSystemClick}
    >
      {renderItems()}
    </TouchableOpacity>
  );
};

const { styles } = StyleSheet.create({
  notificationTime: {
    fontSize: 11,
    alignItems: "center",
    paddingRight: 4,
  },
  notificationItemContainer: {
    minHeight: 46,
    flexDirection: "row",
    marginTop: 12,
    paddingLeft: 20,
    paddingRight: 20,
  },
  notificationItemTitle: {
    fontFamily: "HelveticaBold",
    fontSize: 12,
  },
  notificationItemDesc: {
    fontFamily: "Roboto-Regular",
    fontSize: 11,
    fontWeight: "200",
  },
  notificationItemImage: {
    width: 39,
    height: 39,
  },
  notificationItemImageShop: {
    width: 39,
    height: 39,
    borderRadius: 50,
    borderWidth: 4,
    borderColor: "#000",
  },

  notificationItemContent: {
    borderBottomWidth: 0.5,
    borderBottomColor: "#9D9A9A",
    flex: 1,
    paddingLeft: 10,
    marginLeft: 10,
  },
  notificationItemAddFriends: {
    borderBottomWidth: 0.5,
    borderBottomColor: "#9D9A9A",
    width: "28%",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 5,
    flexDirection: "row",
  },
  notificationItemAddFriendsButton: {
    width: 58,
    height: 32,
    backgroundColor: "#08c4b9",
    borderRadius: 10,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 18,
    color: "#fff",
  },
  userImage: {
    height: 47,
    width: 47,
    left: -10,
  },
});
export default NotificationCenterItemPresentation;
