import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { FlatList, View, Text } from "react-native";
import { RaffelTicket } from "../types/types";
import RaffelTicketItemPresentation from "./RaffelTicketItemPresentation";

type RaffleTicketListPresentationProps = {
  raffelList: EntityState<RaffelTicket>;
  onPressShopItem: (item: Partial<RaffelTicket>) => void;
};
const RaffelTicketListPresentation: React.FC<
  RaffleTicketListPresentationProps
> = ({ raffelList, onPressShopItem }) => {
  const raffleRender = (item: RaffelTicket) => {
    if (new Date().getTime() > new Date(item.startDate).getTime()) {
      return (
        <RaffelTicketItemPresentation
          raffelTicket={item!}
          onPressShopItem={onPressShopItem}
        />
      );
    }
    return <></>;
  };
  return (
    <>
      <FlatList
        data={Object.values(raffelList!.entities!) as RaffelTicket[]}
        horizontal
        keyExtractor={(item) => `${item._id}`}
        keyboardDismissMode="on-drag"
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{}}
        renderItem={({ item }) => <>{raffleRender(item)}</>}
        ListFooterComponent={
          <View
            style={{
              marginBottom: 150,
            }}
          ></View>
        }
      />
    </>
  );
};

export default RaffelTicketListPresentation;
