import React from "react";
import ProfileSettingHeaderContainer from "../../profileSetting/container/ProfileSettingHeaderContainer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { emptyAdmin, selectAdmin } from "../../auth/slice/authSlice";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import SettingTitlePresentation from "../presentation/SettingTitlePresentation";
import SettingMenuPresentation from "../presentation/SettingMenuPresentation";
import { getAdminStatus, loguot } from "../../auth/authThunks/authThunks";

const SettingMainContainer = () => {
  const user = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<HomeScreenNavigationProps>();

  const goBackHandler = () => {
    navigation.goBack();
  };

  const switchAccountHandler = () => {
    navigation.navigate("SwitchAccount");
  };

  const logoutHandler = () => {
    try {
      dispatch(loguot());
      dispatch(emptyAdmin());

      navigation.navigate("Auth");
    } catch (error) {
      dispatch(loguot());
      // console.log(error)
    } finally {
      dispatch(loguot());
    }
  };
  return (
    <>
      <ProfileSettingHeaderContainer
        backgroundProfile={user?.backgroundBanner!}
        goBackHandler={goBackHandler}
        showSetting={false}
      />
      <SettingTitlePresentation />
      <SettingMenuPresentation
        switchAccountHandler={switchAccountHandler}
        logoutHanlder={logoutHandler}
      />
    </>
  );
};

export default SettingMainContainer;
