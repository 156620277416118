import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  ImageBackground,
  Image,
  Platform,
} from "react-native";
import StyleSheet from "react-native-media-query";

import { FONTS, images } from "../../../constants";
import { ExperienceCard } from "../types/types";

import { ReviveCard } from "../../reviveCard/types/type";

import { LinearGradient, Stop } from "react-native-svg";
import SmallLinearChart from "../../../components/SmallLinearChart/SmallLinearChart";
import GradeLinearChart from "../../../components/GradeLinearChart/GradeLinearChart";
import { GradeReward } from "../../gradeReward/types/GradeReward";

type ExperienceitemGradePresentationProps = {
  item: GradeReward;
  onItemClick: (card: Partial<ExperienceCard>, isRevive?: boolean) => void;
  openDetailsHandler?: () => void;
  completeHandler?: () => void;
  onItemClickHandlerRevive?: (rItem: Partial<ReviveCard> | null) => void;
  maxChartValue?: number;
  onSetOpenPopup: (status: boolean) => void;
  image: string;
};
const ExperienceitemGradePresentation: React.FC<
  ExperienceitemGradePresentationProps
> = ({
  item,
  onItemClick,
  openDetailsHandler,
  onItemClickHandlerRevive,
  maxChartValue,
  onSetOpenPopup,
  image,
}) => {
  return (
    <>
      <TouchableOpacity
        style= {(Platform.OS === "web") ? styles.expItemContainerWeb : styles.expItemContainer}
        onPress={() => {
          onSetOpenPopup(true);
        }}
      >
        <ImageBackground
          source={images.GradeBack}
          resizeMode="contain"
          style={styles.hotspotBack}
        >
          <Image source={{ uri: image }} style={styles.gradeImage} />
          <View style={styles.content}>
            <View style={(Platform.OS === "web") ? styles.boxWeb : styles.box}>
              <Text style={styles.title}>{item._id}</Text>

              <GradeLinearChart
                value={Number(item.course_completion_percentage) * 100}
                maxValue={maxChartValue!}
                minValue={0}
                showXpValue={13000}
              />
            </View>
          </View>
        </ImageBackground>
      </TouchableOpacity>
    </>
  );
};

const { styles } = StyleSheet.create({
  gradeImage: {
    width: 43,
    height: 43,
    position: "absolute",
    left: 7,
    top: 16,
  },
  hotspotBack: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    resizeMode: "cover",
    height: 82,
  },
  hotspotRev: {
    alignSelf: "center",
    width: 85,
    height: 30,
  },
  box: {
    justifyContent: "center",
  },
  boxWeb:{
    justifyContent: "center",
    width: "85%"
  },
  expItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "98%",
    minWidth: "98%",
    marginLeft: "1%",
  },

  expItemContainerWeb:{
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "98%",
    minWidth: 340,
    marginLeft: "1%",
  },

  content: {
    width: "82%",
    height: 58,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    paddingRight: 20,
  },
  xp: {
    width: 85,
    height: 30,
    backgroundColor: "green",
  },

  title: {
    fontFamily: "Roboto-Bold",
    fontSize: 13,
    color: "#000",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  subTitle: {
    ...FONTS.body3,
    fontSize: 10,
    lineHeight: 15,
  },
  Date: {
    ...FONTS.body5,
    fontSize: 10,
    lineHeight: 10,
    paddingTop: 3,
  },
  image: {
    position: "absolute",
    left: 33,
    top: 0,
    flex: 1,
    width: 3,
    height: 98,
  },
  completedIcon: {
    flex: 1,
    minWidth: 50,
    paddingRight: 20,
    justifyContent: "center",
    "@media (max-width: 350px)": {
      minWidth: 30,
      flex: 0.5,
    },
  },
  completeImage: {
    alignSelf: "center",
    width: 15,
    height: 15,
  },
  TimerText: {
    fontSize: 12,
    fontFamily: "HelveticaBold",
  },
  Timer: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});
export default ExperienceitemGradePresentation;
