export function calculateRemaining(target: Date, current: Date) {
  const diffTime = target.getTime() - current.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  return {
    days: diffDays,
    hours: diffHours,
  };
}
