import { createSlice } from "@reduxjs/toolkit";
import { onBoardingDetails } from "../types/onBoadingDetails";
import { RootState } from "../../../redux/store";

export interface IOnBoardingInitialState {
  studentDetails: onBoardingDetails | null;
}

export const initialState: IOnBoardingInitialState = {
  studentDetails: null,
};

export const onBoardingSlice = createSlice({
  name: "onBoarding",
  initialState,
  reducers: {
    editDetails: (state, action) => {
      state.studentDetails = { ...state.studentDetails, ...action.payload };
    },
  },
});

export const { editDetails } = onBoardingSlice.actions;
export default onBoardingSlice.reducer;

export const studentDetails = (state: RootState) =>
  state.onBoarding.studentDetails;
