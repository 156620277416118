import React from "react";
import { FlatList, View } from "react-native";
import { ExperienceCard } from "../types/types";
import ExperienceItemHotspotPresentation from "./ExperienceItemHotspotPresentation";
import { EntityState } from "@reduxjs/toolkit";
import { getMicrophonePermissionsAsync } from "expo-camera";

type ExpereinceHotSpotListPresenationProps = {
  cards: EntityState<ExperienceCard>;
  onItemClick: (hotspotGroupId: string) => void;
  loadPassDueHotspotHanldler: (hotspotGroupId: string) => Promise<number>;
};

const ExperienceHotSpotListPresentation: React.FC<
  ExpereinceHotSpotListPresenationProps
> = ({ cards, onItemClick, loadPassDueHotspotHanldler }) => {
  return (
    <View style={{ maxHeight: "65%" }}>
      <FlatList
        data={Object.values(cards!.entities!) as ExperienceCard[]}
        keyExtractor={(item) => `${item._id!}`}
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{}}
        style={{ maxHeight: "100%", paddingBottom: 10 }}
        renderItem={({ item }) => (
          <ExperienceItemHotspotPresentation
            item={item}
            onItemClick={onItemClick!}
            loadPassDueHotspotHanldler={loadPassDueHotspotHanldler}
            // openDetailsHandler={openDetailsHandler}
            // passDue={passDue}
            // complete={complete}
          />
        )}
      />
    </View>
  );
};

export default ExperienceHotSpotListPresentation;
