import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { ProfileChart } from "../types/profileCharts.type";
import { StudentSystemType } from "../../auth/types/types";

export const exendedProfileChartApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileChart: builder.query<ProfileChart, string>({
      query: (currentsystemstring) =>
        `/experience-card/chartcalc?currentsystemstring=${currentsystemstring}`,
      providesTags: ["ProfileChart"],
    }),
  }),
});

export const { useLazyGetProfileChartQuery } = exendedProfileChartApi;
