import React, { useState, useEffect, useRef, CSSProperties } from "react";
import Svg, { Circle } from "react-native-svg";
import { View, Text, Animated, TouchableOpacity } from "react-native";
import StyleSheet from "react-native-media-query";
// import { createIconSetFromFontello } from "react-native-vector-icons";
type CircleChartProps = {
  left?: number;
  top?: number;
  title?: string;
  description?: string;
  scale?: number;
  style?: CSSProperties;
  percent?: number;
  chartPercentCal?: number;
  numbericCircle?: number;
  lastChartPercentCal?: number;
  textWeight?: string;
  dotCircle?: boolean;
  showAvg?: boolean;
};
const CircleChart: React.FC<CircleChartProps> = ({
  left,
  top,
  title,
  description,
  scale,
  style,
  percent,
  chartPercentCal,
  numbericCircle,
  lastChartPercentCal,
  textWeight,
  dotCircle,
  showAvg,
}) => {
  const scaleAnim = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(scaleAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,

      //   toValue: 1,
      //   duration: 1000,
    }).start();
  }, [scaleAnim]);
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        left: left,
        top: top,
        margin: 2,
        width: 140,
        maxWidth: "47%",
      }}
    >
      <Svg
        width="140"
        height="140"
        style={{ transform: [{ rotate: "-90deg" }], position: "relative" }}
      >
        <Circle
          r="59"
          cx="70"
          cy="70"
          stroke="#e5e6e9"
          strokeWidth="18"
          fill="none"
          strokeLinecap={dotCircle ? "round" : "butt"}
          strokeDasharray={dotCircle ? "2 27" : ""}
        ></Circle>
        {!!lastChartPercentCal && (
          <>
            <Circle
              r="59"
              cx="70"
              cy="70"
              fill="none"
              strokeWidth="18"
              stroke="#E679A6"
              strokeLinecap="round"
              strokeDasharray="380"
              strokeDashoffset={
                lastChartPercentCal >= 0 ? lastChartPercentCal : 380
              }
            ></Circle>
            <Circle
              r="59"
              cx="70"
              cy="70"
              fill="none"
              strokeWidth="18"
              stroke="#34C38F"
              strokeLinecap="round"
              strokeDasharray="380"
              strokeDashoffset={chartPercentCal! >= 0 ? chartPercentCal : 380}
            ></Circle>
          </>
        )}
        {!lastChartPercentCal && (
          <>
            <Circle
              r="59"
              cx="70"
              cy="70"
              fill="none"
              strokeWidth="18"
              stroke="#34C38F"
              strokeLinecap="round"
              strokeDasharray="380"
              strokeDashoffset={chartPercentCal! >= 0 ? chartPercentCal : 380}
            ></Circle>
          </>
        )}
      </Svg>
      <Text style={styles.percentText}>{percent ? percent : 0}%</Text>

      {/* avg tag element */}
      {showAvg && (
        <TouchableOpacity style={styles.avgTag}>
          <Text style={styles.avgTagText}>AVG</Text>
        </TouchableOpacity>
      )}

      {numbericCircle && !isNaN(numbericCircle) && (
        <Animated.View
          style={[
            styles.numbericCircleStyle,
            {
              transform: [{ scale: scaleAnim }],
            },
          ]}
        >
          <Text style={styles.numbericCircleText}>{numbericCircle}</Text>
        </Animated.View>
      )}
      <Text style={styles.titleText}>{title}</Text>
      <Text style={styles.descriptionText} numberOfLines={1}>
        {description}
      </Text>
    </View>
  );
};

const { styles } = StyleSheet.create({
  numbericCircleStyle: {
    position: "absolute",
    padding: 7,
    borderRadius: 50,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    top: -5,
    right: -9,
    backgroundColor: "#E50966",
    width: 40,
    height: 40,
  },
  numbericCircleText: {
    color: "#fff",
    fontSize: 13,
  },
  avgTag: {
    position: "absolute",
    top: 92,
    left: 80,
    width: 26,
    textAlign: "center",
    borderColor: "#000",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 4,
    alignItems: "center",
  },
  avgTagText: {
    fontFamily: "RajdahaniBold",
    fontSize: 9,
    lineHeight: 12,
  },
  percentText: {
    position: "absolute",
    top: 50,
    left: 20,
    width: 100,
    textAlign: "center",
    fontSize: 34,
    "@media (max-width: 350px)": {
      fontSize: 30,
    },
  },
  titleText: {
    top: 0,
    left: 0,
    maxWidth: 150,
    fontSize: 24,
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 21,
    },
  },
  descriptionText: {
    top: 0,
    maxWidth: 150,
    fontSize: 24,
    color: "#B7B7BC",
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 21,
    },
  },
});

export default CircleChart;
