import React from "react";

import NorthstarMyWhyMainContainer from "../features/northstarMyWhy/container/NorthstarMyWhyMainContainer";

const NorthstarMyWhyScreen = () => {
  return (
    <>
      <NorthstarMyWhyMainContainer />
    </>
  );
};

export default NorthstarMyWhyScreen;