import React from "react";
import {
  View,
  Text,
  Dimensions,
  ImageBackground,
  ScrollView,
  FlatList,
  Platform,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { images } from "../../../constants";
import ProfileSettingHeaderContainer from "../../profileSetting/container/ProfileSettingHeaderContainer";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import PrizeHeaderPresentation from "./PrizeHeaderPresentation";
import AccountTab from "../../../components/AccountTab/AccountTab";
import { EntityState } from "@reduxjs/toolkit";
import { Shop } from "../../shop/types/types";
import ShopItemPresentation from "../../shop/presentation/ShopItemPresentation";
import { RaffelTicket } from "../../raffelTicket/types/types";
import RaffelTicketListPresentation from "../../raffelTicket/presentation/RaffelTicketListPresentation";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
const { height } = Dimensions.get("window");
type PrizesPresentationProp = {
  user: AuthUser;
  goBackHandler: () => void;
  currentSystem: Partial<StudentSystemType>;
  onSelecteTab: (system: Partial<StudentSystemType>) => void;
  shopList: EntityState<Shop>;
  raffelList: EntityState<RaffelTicket>;
  studentLevel: number;
  onPressShopItem: (item: Partial<Shop>) => void;
  gem: number;
  onBag: () => void;
  freeTicket: number;
};
const PrizesPresentation: React.FC<PrizesPresentationProp> = ({
  user,
  goBackHandler,
  currentSystem,
  onSelecteTab,
  shopList,
  studentLevel,
  raffelList,
  onPressShopItem,
  gem,
  onBag,
  freeTicket,
}) => {
  const account = useAppSelector(selectDefaultAccount);
  const shopListitems = Object.values(shopList!.entities!) as Shop[];
  const shopListResult = [
    {
      _id: 5,
      title: "Revive gem",
      image: "",
      inital_stock: Infinity,
      unlock_level: 0,
      description: "",
      cost_per_item: 1,
      gem: 1,
      store: false,
      prize_wheel: false,
      project: account?.project,
      category: null,
      subCategory: null,
      current_stock: Infinity,
      purchase_limit_state: false,
      purchase_limit: Infinity,
    },
    ...shopListitems,
  ] as Shop[];
  return (
    <View style={styles.shopContainers}>
      <ImageBackground
        source={images.experienceTop}
        style={styles.cardHeader}
        resizeMode="cover"
        borderBottomLeftRadius={40}
        borderBottomRightRadius={40}
      ></ImageBackground>
      <View style={styles.shopPageImage}>
        <PrizeHeaderPresentation
          gem={gem}
          onBag={onBag}
          freeTicket={freeTicket}
        />

        <View
          style={{
            height: height - 190,
            top: -20,
          }}
        >
          <FlatList
            data={

              shopListResult
       
            }
            numColumns={3}
            keyExtractor={(item) => `${item._id}`}
            keyboardDismissMode="on-drag"
            showsVerticalScrollIndicator={false}
            ListHeaderComponent={
              <>
                {raffelList.ids.length > 0 ? (
                  <RaffelTicketListPresentation
                    raffelList={raffelList!}
                    onPressShopItem={onPressShopItem}
                  />
                ) : null}
              </>
            }
            contentContainerStyle={{}}
            renderItem={({ item }) => (
              <ShopItemPresentation
                shop={item}
                studentLevel={studentLevel}
                onPressShopItem={onPressShopItem}
              />
            )}
            ListFooterComponent={
              <View
                style={{
                  marginBottom: 150,
                }}
              ></View>
            }
          />
        </View>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  shopContainer: {
    flex: 1,
    maxHeight: height - 200,
  },
  raffleContainer: {
    flex: 1,
    maxHeight: height - 420,
  },
  shopContainers: {
    flex: 1,
    backgroundColor: "#fff",
    top: 0,
  },
  backProfileImage: {
    width: "100%",
    height: "120%",

    position: "absolute",
  },
  shopPageImage: {
    flex: 1,
  },
  cardHeader: {
    width: "100%",
    height: Platform.OS === "android" ? 130 : 160,
    backgroundColor: "#1766C1",
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 100,
  },
});
export default PrizesPresentation;
