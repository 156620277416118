import { CameraCapturedPicture } from "expo-camera";
import { Image } from "expo-image";
import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { FONTS } from "../../../constants";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
type PreviewPciturePresentationProps = {
  photo: CameraCapturedPicture;
  onCancel: () => void;
  onSubmit: () => void;
  buttonLoading: boolean;
  disableButton: boolean;
};
const PreviwPictruePresentation: React.FC<PreviewPciturePresentationProps> = ({
  photo,
  onCancel,
  onSubmit,
  buttonLoading,
  disableButton,
}) => {
  return (
    <View style={{ flex: 1 , backgroundColor:"#000"}}>
      <Image
        source={photo.uri}
        contentFit="contain"
        style={{
          flex: 1,
          width: "100%"
         
        }}
      />
      <View style={styles.savePictureForm}>
        <View style={styles.container}>
          <TouchableOpacity
            style={styles.submission}
            onPress={onSubmit}
            disabled={disableButton}
          >
            <Text style={styles.buttonText}>
              {buttonLoading ? <LoadingScreen iconSize={40} /> : "Submit?"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.close}
            onPress={onCancel}
            disabled={buttonLoading}
          >
            <Text style={styles.closeText}>
              {buttonLoading ? "  ...  " : "Cancel"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  savePictureForm: {
    flex: 1,
    position: "absolute",

    bottom: 70,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    width: 230,
    height: 60,
    backgroundColor: "#5cff88",
    borderRadius: 10,
    overflow: "hidden",
  },
  container: {
    position: "relative",
    width: 230,
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  submission: {
    width: 140,
    height: "100%",
    color: "white",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: 50,
  },
  buttonText: {
    color: "white",
    ...FONTS.h3,
    fontSize: 18,
  },
  close: {
    backgroundColor: "#ff4c00",
    padding: 19,
    borderRadius: 10,
    width: 90,
    height: "100%",

    color: "white",

    justifyContent: "center",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3.84,
    shadowColor: "#F4274F",
    elevation: 5,
  },
  closeText: {
    color: "white",
    ...FONTS.h3,
    fontSize: 18,

    width: 60,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 8,
    borderTopLeftRadius: 14,
    borderBottomLeftRadius: 14,
  },
});

export default PreviwPictruePresentation;
