import React, { useEffect, useState } from "react";
import { useGetNotificationMutation } from "../api/notificationCenterApi";
import { EntityState } from "@reduxjs/toolkit";
import { NotificationCenterType } from "../types/types";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { View } from "react-native";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import NotificationCenterPersentation from "../presentation/NotificationCenterPersentation";
type NotificationCenterContainerProps = {
  openNotification: boolean;
  notificationList: EntityState<NotificationCenterType>;
  onClose: () => void;
};
const NotificationCenterContainer: React.FC<
  NotificationCenterContainerProps
> = ({ openNotification, onClose, notificationList }) => {
  const systemSelected = useAppSelector(selectDefaultAccount);

  // const [open, setOpen] = useState<boolean>(false);

  // const [notificationList, setNotficationList] =
  //   useState<EntityState<NotificationCenterType> | null>(null);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const notiList = await getNotificationCenter({
  //         currentSystem: JSON.stringify(systemSelected),
  //       }).unwrap();

  //       //
  //       setNotficationList(notiList);
  //     } catch (error) {
  //
  //     }
  //   })();
  // }, []);

  let content;
  // if (isLoading) {
  //   content = (
  //     <></>
  //     //model loading should goes here
  //     // <View>
  //     //   <LoadingScreen />
  //     // </View>
  //   );
  // } else if (isSuccess && notificationList) {
  // content = (

  //   );
  // }

  return (
    <View>
      {notificationList.ids.length > 0 && (
        <NotificationCenterPersentation
          notificationList={notificationList}
          openPopup={openNotification}
          onClose={onClose}
        />
      )}
    </View>
  );
};

export default NotificationCenterContainer;
