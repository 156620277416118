import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  StatusBar,
  Platform,
  Dimensions,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { CustomButton } from "../components";
import { COLORS, SIZES } from "../constants";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { loguot } from "../features/auth/authThunks/authThunks";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../navigation/types";
import {
  selectAdmin,
  selectDefaultAccount,
} from "../features/auth/slice/authSlice";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { useLazyGetAllActiveExperienceQuery } from "../features/experience/api/experienceApiSlice";
import StyleSheet from "react-native-media-query";
import HomeContainer from "../features/home/container/HomeContainer";
import SevenDayPopup from "../components/SevenDaysPopup/SevenDaysPopup";
import {
  useAddRewardMutation,
  useGetAllSevenDaysLoginQuery,
} from "../features/sevenDays/api/sevenDaysApi";
import NotificationTest from "../components/Notification/NotificationTest";
import GradeItemPopup from "../features/grade/container/GradeItemPopup";
import { useGetALearnerSettingQuery } from "../features/learnerSetting/api/learnerSetting";
import { EntityId } from "@reduxjs/toolkit";

const isAndroid = Platform.OS === "android" ? true : false;
const { width } = Dimensions.get("window");
const HomeScreen = () => {
  const systemSelected = useAppSelector(selectDefaultAccount);
  console.log(systemSelected);

  const user = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<HomeScreenNavigationProps>();

  const [openSevenDays, setOpenSevenDays] = useState<boolean>(false);
  const [openGradePopup, setOpenGradePopup] = useState<boolean>(false);
  const [openGrade, setOpenGrade] = useState<boolean>(false);

  const [
    addReward,
    { isLoading: awardLoading, isError: awardError, isSuccess: awardSuccess },
  ] = useAddRewardMutation();

  const {
    data: days,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllSevenDaysLoginQuery();

  useEffect(() => {
    // console.log(days?.loginPerDay)
    if (days?.loginPerDay) {
      if (days?.loginPerDay! <= 1) {
        setOpenSevenDays(true);
      } else {
        setOpenSevenDays(false);
        setOpenGrade(true);
      }
    }
  }, [days?.loginPerDay]);

  const onAddReward = async () => {
    try {
      await addReward({
        currentSystem: systemSelected!,
        day: days?.count!,
      }).unwrap();
    } catch (error) {}
  };

  const onCloseSevenDay = () => {
    setOpenSevenDays(false);
  };

  const onSetOpenGradePopup = (status: boolean) => {
    setOpenGradePopup(status);
  };

  const onSetOpenGrade = () => {
    setOpenGrade(true);
  };

  return (
    <>
      <HeaderBar profileImage={user?.image!} student={user!} />
      <HomeContainer />
      {openSevenDays && (
        <SevenDayPopup
          days={days?.count! % 7}
          openPopup={true}
          setOpenPopup={() => {}}
          onAddReward={onAddReward}
          onCloseSevenDay={onCloseSevenDay}
          setOpenGrade={onSetOpenGrade}
        />
      )}

      {openGrade && <GradeItemPopup />}
    </>
  );
};

const { styles } = StyleSheet.create({
  leaderBoardImage: {
    width: 45,
    height: 45,
  },
  academicChartText: {
    color: "#3E3F5E",

    fontSize: 36,
    textAlign: "center",
    marginTop: 15,
    "@media (max-width: 350px)": {
      fontSize: 28,
    },
  },
  profileProgressCharts: {
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    top: -140,
    justifyContent: "space-around",
    padding: 10,

    "@media (max-width: 350px)": {
      width: width,
      transform: [{ scale: 0.8 }],
      top: -170,
      justifyContent: "space-between",
    },
  },

  profileInfoShapeStyle: {
    "@media (max-width: 350px)": {
      transform: [{ scale: 0.75 }],
    },
  },
  profileContainer: {
    backgroundColor: "#fff",
    "@media (max-width: 350px)": {
      zIndex: 2,
    },
    // marginTop: isAndroid ? 25 : 0,
  },
  profileHeader: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  profileXpCoinsContainer: {
    marginTop: 15,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerxpcontainer: {
    flex: 1,

    paddingBottom: 10,
    paddingRight: 5,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },

  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#1069C1",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#1069C1",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
    "@media (max-width: 350px)": {
      fontSize: 13,
    },
  },
  xp: {
    color: "white",
    fontSize: 26,

    "@media (max-width: 350px)": {
      fontSize: 24,
    },
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  profileInfoContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    flexWrap: "wrap",
  },
  profileInfo: {
    width: "95%",
    height: 550,
    borderRadius: 12,
    alignSelf: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.84,
    backgroundColor: "#fff",
    elevation: 3,
    "@media (max-width: 350px)": {
      height: 450,
    },
  },
  profileInfoHeight: {
    height: 600,
    "@media (max-width: 350px)": {
      height: 500,
    },
  },
  leaderBoardContainer: {
    width: "95%",
    height: 600,
    marginBottom: 10,
    marginTop: 30,
    alignItems: "center",
    backgroundColor: "#033C56",

    borderWidth: 10,
    borderRadius: 15,
    borderColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    "@media (max-width: 350px)": {
      height: 350,
    },
  },
  leaderBoardHeader: {
    width: "92%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 12,
    "@media (max-width: 350px)": {
      marginTop: 18,
    },
  },
  leaderBoardHeaderText: {
    fontSize: 35,

    color: "#fff",
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "white",
    width: "80%",
    textAlign: "center",
    "@media (max-width: 350px)": {
      fontSize: 33,
      paddingTop: 8,
    },
  },
  leaderColumn: {
    width: "80%",
  },
  leaderBoardContent: {
    width: "100%",
    height: 500,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 10,
    "@media (max-width: 350px)": {
      height: "78%",
    },
  },
  scrollView: {
    height: "20%",
    width: "80%",
    margin: 20,
    alignSelf: "center",
    padding: 20,
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "black",
    backgroundColor: "lightblue",
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgrey",
    paddingBottom: 50,
  },

  profilePicContainer: {
    alignItems: "center",
    width: "100%",
  },
});
export default HomeScreen;
