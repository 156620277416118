import Svg, { Polygon } from "react-native-svg";
import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Image } from "expo-image";
type PoylgonShapeProps = {
  teamColor: string;
  profileImage: string;
  sourceImage?: string;
};
const PolygonShape: React.FC<PoylgonShapeProps> = ({
  teamColor,
  profileImage,
  sourceImage,
}) => {
  return (
    <View style={styles.profilePicContainer}>
      <Svg style={styles.profilePicSvg}>
        <Polygon
          points="20 2,2 14,2 36,20 46,40 36,40 14"
          fill="rgba(52, 52, 52,0)"
          stroke={teamColor}
          strokeWidth={5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>

      {sourceImage ? (
        <Image source={sourceImage} style={styles.profilePic} />
      ) : (
        <Image source={{ uri: profileImage }} style={styles.profilePic} />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  profilePicContainer: {
    width: 50,
    height: 60,
    position: "relative",
    zIndex: 1000,
  },
  profilePic: {
    position: "absolute",
    width: 40,
    height: 39,
    left: 1,
    top: 5,
    zIndex: 20,
    borderRadius: 50,
    backgroundColor:"#fff"
  },
  profilePicSvg: {
    zIndex: 22,
  },
});
export default PolygonShape;
