import React from "react";
import { useFriendsListQuery } from "../api/friendsApiSlice";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import FriendsListPresentation from "../presentation/FriendsListPresentation/FriendsListPresentation";
import {
  useGetLeaderBoardQuery,
  useLazyGetLeaderBoardQuery,
} from "../../leaderborad/api/leaderboardApi";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
type FriendsListContaibnerProps = {
  account: Partial<StudentSystemType>;
};
const FriendsListContainer: React.FC<FriendsListContaibnerProps> = ({
  account,
}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const systemSelected = useAppSelector(selectDefaultAccount);
  const {
    data: friendsList,
    isLoading,
    isError,
    isSuccess,
  } = useFriendsListQuery({
    currentSystem: JSON.stringify(account!),
  });
  const {
    data: leaders,
    isLoading: leaderLoading,
    isError: leadersError,
    isSuccess: leaderSuccess,
  } = useGetLeaderBoardQuery(JSON.stringify(systemSelected));

  const getRankFriends = (item: AuthUser): number => {
    const finadrank = leaders?.ids.findIndex((f, i) => f === item._id);

    return finadrank! + 1;
  };

  const onFriendItemClick = (student: Partial<AuthUser>) => {
    navigation.navigate("Badges", {
      student: student!,
    });
  };

  return (
    <FriendsListPresentation
      friendsList={friendsList!}
      isLoading={isLoading}
      isSuccess={isSuccess}
      getRankFriends={getRankFriends}
      onFriendItemClick={onFriendItemClick}
    />
  );
};

export default FriendsListContainer;
