import { Image } from "expo-image";
import React, { useState } from "react";
import {
  View,
  Text,
  Dimensions,
  TextInput,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { Dropdown } from "react-native-element-dropdown";

import { Entypo } from "@expo/vector-icons";
import NorthstarWhyItem from "./NorthstarWhyItem";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { images } from "../../../constants";
import { EntityState } from "@reduxjs/toolkit";
import { MyWhy } from "../../northstarMyWhy/api/type";

type NotehstarPresentationProps = {
  amount: number;
  onAmount: (a: number) => void;
  unit: string;
  onUnit: (u: string) => void;
  title: string;
  onTitle: (t: string) => void;
  onLetsGo: () => void;
  addedNorth: boolean;
  myWhyItems: EntityState<MyWhy> | null;
};

const data = [
  { label: "Month(s)", value: "Month(s)" },
  { label: "Day(s)", value: "Day(s)" },
  { label: "Week(s)", value: "Week(s)" },
];
const { height } = Dimensions.get("window");
const NotehstarPresentation: React.FC<NotehstarPresentationProps> = ({
  amount,
  unit,
  title,
  onAmount,
  onLetsGo,
  onTitle,
  onUnit,
  addedNorth,
  myWhyItems,
}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [value, setValue] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  console.log("myWhy", myWhyItems);

  return (
    <View style={{ justifyContent: "space-between", flex: 1 }}>
      <View style={styles.notehstarContent}>
        <View style={styles.notehstarContentBox}>
          <Text style={styles.notehstarContentBoxTitle}>
            What goal do you want to achieve?{" "}
          </Text>
          <View style={styles.notehstarContentBoxWrap}>
            <Text>In the next </Text>
            <TextInput
              style={{
                marginLeft: 5,
                marginRight: 9,
                lineHeight: 30,
                textAlign: "center",
                fontSize: 27,
                fontWeight: "bold",
                borderColor: "#707070",
                borderWidth: 1,
                height: 30,
                borderRadius: 8,
                width: 30,
              }}
              keyboardType="numeric"
              onChangeText={(text: string) => {
                onAmount(Number(text));
              }}
              value={String(amount)}
            />
            <Dropdown
              style={[styles.dropdown]}
              placeholderStyle={styles.placeholderStyle}
              selectedTextStyle={styles.selectedTextStyle}
              iconStyle={styles.iconStyle}
              data={data}
              maxHeight={300}
              labelField="label"
              valueField="value"
              placeholder={!isFocus ? "Select" : "..."}
              value={unit}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              onChange={(e) => {
                onUnit(e.value);
              }}
            />
            <Text>I want to </Text>
          </View>
          <TextInput
            style={styles.notehstarContentBoxInput}
            onChangeText={(text: string) => {
              onTitle(text);
            }}
            value={title}
          />
        </View>
        <Text style={styles.notehstarContentMyWhy}>My Why</Text>

        {!myWhyItems && (
          <View style={styles.notehstarContentBoxWhy}>
            <Text
              style={{ textAlign: "center", maxWidth: 300, lineHeight: 20 }}
            >
              List 1 to 5 reasons why you were trying to achieve this goal. Add
              a WHY
            </Text>
          </View>
        )}

        {myWhyItems && (
          <FlatList
            data={Object.values(myWhyItems?.entities) as MyWhy[]}
            keyExtractor={(item) => `${item._id}`}
            renderItem={({ item }) => <NorthstarWhyItem item={item} />}
            style={styles.whyListContainer}
          />
        )}
      </View>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View style={{ alignItems: "center", marginTop: 15, marginBottom: 20 }}>
          <TouchableOpacity
            style={styles.addWhyButton}
            onPress={() => {
              onLetsGo();
            }}
          >
            <Entypo name="plus" size={25} color="white" />
            <Text style={styles.addWhyButtonText}>Add a why</Text>
          </TouchableOpacity>
        </View>
        {!myWhyItems ? (
          <Image source={images.avatars} style={styles.avatarsPic} />
        ) : null}
        {!myWhyItems && (
          <Image source={images.popups} style={styles.popupsPic} />
        )}
        <TouchableOpacity
          style={styles.letsGoButton}
          onPress={() => {
            navigation.navigate("youthScreen");
          }}
        >
          <Text style={styles.letsGoButtonText}>Let's Go!</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  avatarsPic: {
    width: 110,
    height: 130,
    position: "absolute",
    bottom: 0,
    left: 0,
    resizeMode: "contain",
  },
  popupsPic: {
    width: 216,
    height: 108,
    resizeMode: "contain",
  },
  letsGoButtonText: {
    textAlign: "center",
    fontSize: 38,
    color: "#fff",
    fontWeight: "900",
  },
  letsGoButton: {
    width: 250,
    height: 52,
    textAlign: "center",
    backgroundColor: "#5CFF88",
    color: "#fff",
    borderRadius: 8,
    marginBottom: 35,
  },
  addWhyButtonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "900",
    paddingLeft: "20%",
  },
  addWhyButton: {
    backgroundColor: "#34C38F",
    borderRadius: 77,
    borderWidth: 5,
    borderColor: "#fff",
    width: 210,
    height: 35,

    alignItems: "center",
    flexDirection: "row",
    alignContent: "center",
  },
  notehstarContentMyWhy: {
    fontFamily: "RajdahaniBold",
    fontSize: 38,
    textAlign: "center",
    color: "#fff",
    margin: 5,
  },
  notehstarContentBoxInput: {
    height: 43,
    borderColor: "#707070",
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 8,
    width: "90%",
    marginLeft: "5%",
    fontSize: 32,
    fontFamily: "RajdahaniBold",
    marginRight: 10,
    color: "#3E3F5E",
    textAlign: "center",
    margin: 15,
  },
  notehstarContentBoxWrap: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "RajdahaniBold",
    fontSize: 20,
  },
  notehstarContentBoxTitle: {
    fontFamily: "RajdahaniBold",
    fontSize: 25,
    textAlign: "center",
    paddingTop: 5,
    marginBottom: 5,
  },
  notehstarContentBox: {
    backgroundColor: "#fff",
    borderRadius: 4,
    maxWidt: 390,
    width: "96%",
    marginLeft: "2%",
  },
  notehstarContentBoxWhy: {
    backgroundColor: "#fff",
    borderRadius: 4,
    maxWidt: 390,
    width: "84%",
    marginLeft: "8%",
    padding: 15,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center",
    fontFamily: "RajdahaniMedium",
  },
  notehstarContent: {
    width: "100%",
    marginLeft: 0,
  },
  container: {
    backgroundColor: "white",
    padding: 16,
  },
  dropdown: {
    height: 32,
    borderColor: "#707070",
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 8,
    width: 105,
    fontSize: 14,
    fontFamily: "RajdahaniBold",
    marginRight: 10,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: "absolute",
    backgroundColor: "white",
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
    width: 100,
  },
  placeholderStyle: {
    fontSize: 14,
  },
  selectedTextStyle: {
    fontSize: 14,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  whyListContainer: {
    maxHeight: height - 520,
    minHeight: 100,
  },
});

export default NotehstarPresentation;
