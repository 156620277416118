import React, { useState, useEffect, useCallback } from "react";
import ExperienceListPassduePresentation from "../presentation/ExperienceListPassduePresentation";
import { View, Text, Platform } from "react-native";
import StyleSheet from "react-native-media-query";
import {
  useGetAllPassDueExperienceQuery,
  useLazyGetAllPassDueExperienceQuery,
  useLazyGetAllPassDueHotSpotExperienceQuery,
  useLazyGetAllPassDueHotSpotExperienceSecondChanceQuery,
  useReturnBacktoExpereinceListMutation,
  useUpdateMissionReviveMutation,
} from "../api/experienceApiSlice";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { ExperienceCard } from "../types/types";
import ExperienceListPresentation from "../presentation/ExperienceListPresentation";
import ExperienceDetailsPresentation from "../presentation/ExperienceDetailsPresentation";
import ExperienceHotSpotListPresentation from "../presentation/ExperienceHotSpotListPresentation";
import ExperienceHotspotDetailsPresentation from "../presentation/ExperienceHotspotDetailsPresentation";
import ExperienceHotspotItemSelectPopup from "../presentation/ExperienceHotspotItemSelectPopup";
import {
  useAddReviveGemMutation,
  useGetRevivGemStudentQuery,
} from "../../revieGem/api/reviveGemApi";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { useCreateReviveCardMutation } from "../../reviveCard/api/reviveCardApi";
import {
  useEditOrAddExperienceCompletionForStudentMutation,
  useUpdateReviveShowMutation,
} from "../../experienceComplete/api/experienceCompleteApiSlice";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import * as webBrowser from "expo-web-browser";
import { useGetAllPhotosQuery } from "../../cameraFeatures/api/experiencePhotoApiSlice";

const ExpereinceListPassdueContainer = () => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const systemSelected = useAppSelector(selectDefaultAccount);
  const admin = useAppSelector(selectAdmin);
  const [
    editOrAddExperienceCompletionForStudent,
    {
      isLoading: addViewLoading,
      isError: addViewError,
      isSuccess: addViewSueccess,
    },
  ] = useEditOrAddExperienceCompletionForStudentMutation();
  const [getAllPassDueExperience, { isLoading, isError, isSuccess }] =
    useLazyGetAllPassDueExperienceQuery();

  const [
    getAllPassDueHotSpotExperience,
    {
      isLoading: hotSpotLoading,
      isError: hotSpotError,
      isSuccess: hotSpotSuccess,
    },
  ] = useLazyGetAllPassDueHotSpotExperienceQuery();

  const [
    getAllPassDueHotSpotExperienceSecondChance,
    {
      isLoading: secondChanceLoading,
      isSuccess: secondChanceSuccess,
      isError: secondChanceError,
    },
  ] = useLazyGetAllPassDueHotSpotExperienceSecondChanceQuery();

  const [
    updateReviveShow,
    {
      isLoading: showReviveLoading,
      isError: showReviveError,
      isSuccess: showReviveSuccess,
    },
  ] = useUpdateReviveShowMutation();

  const [
    createNewRevive,
    {
      isLoading: reviveLoading,
      isSuccess: reviveSuccess,
      isError: reviveError,
    },
  ] = useCreateReviveCardMutation();

  // const [
  //   returnBacktoExpereinceList,
  //   {
  //     isLoading: returnLoading,
  //     isSuccess: returnSuccess,
  //     isError: returnError,
  //   },
  // ] = useReturnBacktoExpereinceListMutation();

  const { data: revieGemTotal } = useGetRevivGemStudentQuery({
    currentSystem: JSON.stringify(systemSelected),
  });

  const [
    updateMissionRevie,
    {
      isLoading: missionRevieUpdateLoading,
      isError: missionRevieUpdateError,
      isSuccess: missionRevieUpdateSuccess,
    },
  ] = useUpdateMissionReviveMutation();

  const {
    data: getAllPassdueData,
    isLoading: passdueDataLoading,
    isError: passDueError,
    isSuccess: passDueSuccess,
  } = useGetAllPassDueExperienceQuery({
    currentSystem: JSON.stringify(systemSelected)!,
  });

  const [
    addReviveGem,
    {
      isLoading: addReviveLoading,
      isError: addReviveError,
      isSuccess: addReviveSuccess,
    },
  ] = useAddReviveGemMutation();
  console.log(systemSelected);
  const [completeLoading, setCompleteLoading] = useState<boolean>(false);
  const [disableComplete, setDisableComplete] = useState<boolean>(false);

  const [expList, setExpList] = useState<EntityState<ExperienceCard> | null>(
    null
  );
  const [hotSpotList, setHotSpotList] =
    useState<EntityState<ExperienceCard> | null>(null);

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [item, setItem] = useState<Partial<ExperienceCard> | null>(null);

  const [openPassDuePop, setOpenPassduePop] = useState<boolean>(false);
  const [hotSpotPassDueListPop, setHotSpotPassDueListPop] =
    useState<EntityState<ExperienceCard> | null>(null);

  const [openRevive, setOpenRevive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reLoadeList, setReloadList] = useState<boolean>(false);
  useEffect(() => {
    setExpList(getAllPassdueData!);
  }, [getAllPassdueData]);

  useEffect(() => {
    (async () => {
      if (systemSelected?._id) {
        try {
          setLoading(true);
          const list = await getAllPassDueExperience({
            currentSystem: JSON.stringify(systemSelected!),
          }).unwrap();

          const hotSpotList = await getAllPassDueHotSpotExperience({
            currentSystem: JSON.stringify(systemSelected!),
          }).unwrap();
          console.log("hotspot", hotSpotList);
          setExpList(list);
          setHotSpotList(hotSpotList);
          setLoading(false);
        } catch (error) {}
      }
    })();
  }, [systemSelected, reLoadeList]);

  const onOpenPassDuePop = () => {
    setOpenPassduePop(true);
  };

  const onClosePassDuePop = () => {
    setOpenPassduePop(false);
  };

  const onItemClickPassDueHotspotHandler = async (hotspotGroupId: string) => {
    try {
      const listSecondChange = await getAllPassDueHotSpotExperienceSecondChance(
        {
          groupId: hotspotGroupId,
          currentSystem: JSON.stringify(systemSelected!)!,
        }
      ).unwrap();
      setHotSpotPassDueListPop(listSecondChange);
      setOpenPassduePop(true);
    } catch (error) {}
  };

  const loadPassDueHotspotHanldler = async (
    hotspotGroupId: string
  ): Promise<number> => {
    try {
      const listSecondChange = await getAllPassDueHotSpotExperienceSecondChance(
        {
          groupId: hotspotGroupId,
          currentSystem: JSON.stringify(systemSelected!)!,
        }
      ).unwrap();
      console.log(listSecondChange);

      return listSecondChange.ids.length;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const onItemClickHanlder = async (card: Partial<ExperienceCard>) => {
    setItem(card);
    // try {
    //   const response = await editOrAddExperienceCompletionForStudent({
    //     experience: card!,
    //     complete: false,
    //     currentSystem: systemSelected!,
    //   }).unwrap();
    // } catch (error) {}
  };

  const openDetailsHandler = () => {
    setOpenDetails(true);
  };

  const closeDetailsHandler = () => {
    setOpenDetails(false);
  };

  const onCloseReviveHandler = () => {
    setOpenRevive(false);
  };

  const completeHandlerExperience = async () => {
    setCompleteLoading(true);
    setDisableComplete(true);
    if (item!.completionType!.title === "Student") {
      try {
        const list = await editOrAddExperienceCompletionForStudent({
          experience: item!,
          complete: true,
          currentSystem: systemSelected!,
        }).unwrap();

        // if (revive) {
        //   await updateRevive({
        //     _id: reviveItem?._id,
        //     complete: true,
        //   }).unwrap();
        // }

        closeDetailsHandler();
      } catch (error) {}
    } else if (item!.completionType!.title === "Photo") {
      closeDetailsHandler();
      try {
        // if (revive) {
        //   await updateRevive({
        //     _id: reviveItem?._id,
        //     complete: true,
        //   }).unwrap();
        // }
        navigation.navigate("Camera", {
          item: item!,
          currentSystem: systemSelected!,
        });
      } catch (error) {}
    } else if (item!.completionType?.title === "Link") {
      try {
        const list = await editOrAddExperienceCompletionForStudent({
          experience: item!,
          complete: true,
          currentSystem: systemSelected!,
        }).unwrap();
        // if (revive) {
        //   await updateRevive({
        //     _id: reviveItem?._id,
        //     complete: true,
        //   }).unwrap();
        // }

        // Linking.openURL(item!.button_link_url!);

        await webBrowser.openBrowserAsync(item!.button_link_url!);

        closeDetailsHandler();
      } catch (error) {}
    } else if (item!.completionType!.title === "Admin") {
      // try {
      //   if (revive) {
      //     await updateRevive({
      //       _id: reviveItem?._id,
      //       complete: true,
      //     }).unwrap();
      //   }
      // } catch (error) {}
    }
    setCompleteLoading(false);
    setDisableComplete(false);
  };

  const onCompleteHandler = async (item: ExperienceCard) => {
    if (revieGemTotal?.revivGemTotal! > 0) {
      setOpenPassduePop(false);
      setOpenRevive(true);
      try {
        const newEndDate = new Date(
          new Date().getTime() + 3 * 24 * 60 * 60 * 1000
        );
        await createNewRevive({
          currentSystem: JSON.stringify(systemSelected),
          revive: {
            experienceCard: item,
            complete: false,
            endDate: newEndDate,
            startDate: new Date(),
          },
        });

        await updateReviveShow({
          experience: item,
          complete: false,
          reviePassDue: true,
          student: admin!,
        }).unwrap();

        setOpenPassduePop(false);
        setTimeout(() => {
          setOpenRevive(false);
        }, 3000);
      } catch (error) {}
    }
  };

  const onOpenReviePopupMission = async (item: Partial<ExperienceCard>) => {
    try {
      const threeDaysAfter = new Date();
      threeDaysAfter.setDate(threeDaysAfter.getDate() + 3);

      if (revieGemTotal?.revivGemTotal! > 0) {
        setOpenRevive(true);

        await updateMissionRevie({
          data: { ...item, endDate: threeDaysAfter },
          cacheData: {
            currentSystem: JSON.stringify(systemSelected!),
          },
        }).unwrap();
        await addReviveGem({
          amount: -1,
          student: admin?._id!,
          project: systemSelected?.project! as EntityId,
        }).unwrap();
        setReloadList(true);
        setTimeout(() => {
          setReloadList(false);

          setOpenRevive(false);
        }, 3000);
      }
    } catch (error) {}
  };

  let content;

  if (loading) {
    content = (
      <View style={{ height: "80%", justifyContent: "center" }}>
        <LoadingScreen iconSize={50} />
      </View>
    );
  } else if (hotSpotList || expList) {
    content = (
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={(Platform.OS === "web") ? styles.PastdueContainerWeb : styles.PastdueContainer }>
          {hotSpotSuccess && hotSpotList?.ids.length! > 0 && (
            <ExperienceHotSpotListPresentation
              cards={hotSpotList!}
              onItemClick={onItemClickPassDueHotspotHandler!}
              loadPassDueHotspotHanldler={loadPassDueHotspotHanldler}
            />
          )}

          {isSuccess && expList && (
            <View style={{ flex: 1 }}>
              <ExperienceListPresentation
                cards={expList!}
                onItemClick={onItemClickHanlder}
                openDetailsHandler={openDetailsHandler}
                passDue={true}
                onOpenReviePopupMission={onOpenReviePopupMission}
              />
            </View>
          )}
        </View>

        {openDetails && (
          <ExperienceDetailsPresentation
            open={openDetails}
            item={item}
            onClose={closeDetailsHandler!}
            completeHandler={completeHandlerExperience}
            passDue={true}
          />
        )}
        {openPassDuePop && (
          <ExperienceHotspotDetailsPresentation
            itemList={hotSpotPassDueListPop!}
            onClose={onClosePassDuePop}
            completeHandler={onCompleteHandler}
            revieGemTotal={revieGemTotal?.revivGemTotal!}
          />
        )}
        {openRevive && (
          <ExperienceHotspotItemSelectPopup onClose={onCloseReviveHandler} />
        )}
      </View>
    );
  }

  return <>{content}</>;
};
const { styles } = StyleSheet.create({
   PastdueContainer:{
    width: 400, 
    flex: 1 
   },
   PastdueContainerWeb:{
    width: 540, 
    flex: 1 
   }
})
export default ExpereinceListPassdueContainer;
