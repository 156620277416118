import React from "react";
import ProfileAccountsettingMainContainer from "../features/profileAccountSetting/container/ProfileAccountSettingMainContainer";

const ProfileAccountSettingScreen = () => {
  return (
    <>
      <ProfileAccountsettingMainContainer />
    </>
  );
};

export default ProfileAccountSettingScreen;
