import React from "react";
import { View, Text } from "react-native";
import PrizeWheelFortune from "../components/PrizeWheel/PrizeWheel";
const PrizeWheelScreen = () => {
  return (
    <>
      <PrizeWheelFortune
        items={[]}
        setPopupType={() => {}}
        setShowPopup={false}
      />
    </>
  );
};

export default PrizeWheelScreen;
