import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { View, Text, FlatList, Dimensions, Platform } from "react-native";
import { NotificationCenterType } from "../types/types";
import NotificationCenterItemPresentation from "./NotificationCenterItemPresentation";
import Modal from "react-native-modal";

type NotificationCenterPresentationProps = {
  notificationList: EntityState<NotificationCenterType>;
  openPopup?: boolean;
  onClose?: () => void;
};

const { width, height } = Dimensions.get("window");

const NotificationCenterPersentation: React.FC<
  NotificationCenterPresentationProps
> = ({ notificationList, openPopup, onClose }) => {
  return (
    <>
      <Modal
        style={{
          backgroundColor: "rgba(0,0,0,0.3)",
          margin: 0,
          padding: 0,
          width: width,
          flex: 1,

          justifyContent: "flex-start",
        }}
        backdropColor={"rgba(0,0,0,0)"}
        swipeDirection="up"
        coverScreen={true}
        useNativeDriver={true}
        onSwipeComplete={() => {
          onClose!();
        }}
        onBackdropPress={() => {
          onClose!();
        }}
        isVisible={openPopup}
        animationIn="slideInDown"
        animationOut="slideOutUp"
        propagateSwipe
      >
        <View
          style={{
            backgroundColor: "#fff",
            width: width,
            height: height - 200,
            borderBottomLeftRadius: 25,
            borderBottomEndRadius: 25,
            overflow: "hidden",
            margin: 0,
            paddingTop: Platform.OS === "android" ? 30 : 40,
          }}
        >
          <FlatList
            data={
              Object.values(
                notificationList.entities
              ) as NotificationCenterType[]
            }
            keyExtractor={(item) => `${item._id}`}
            renderItem={({ item }) => (
              <>
                {item.showInNotificiation && !item.complete ? (
                  <NotificationCenterItemPresentation
                    item={item!}
                    onClose={onClose}
                  />
                ) : null}
              </>
            )}
          />
          <View
            style={{
              backgroundColor: "#E3E3E3",
              width: "36%",
              height: 6,
              borderRadius: 40,
              marginLeft: "32%",
              marginBottom: 16,
              marginTop: 25,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              pointerEvents: "none",
            }}
          ></View>
        </View>
      </Modal>
    </>
  );
};

export default NotificationCenterPersentation;
