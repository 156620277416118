import { useNavigation } from "@react-navigation/native";
import { EntityId } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import { useGetLastAchivesQuery } from "../../badgeAchivement/api/badgeAchivmentApi";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { useAddLikeMutation } from "../../review/api/reviewApi";
import {
  useFriendsListCountQuery,
  useGetCommentLikeQuery,
  useGetLoginCountQuery,
  useGetStudentPostQuery,
  useLazyGetStudentPostQuery,
  useStudentReviewCountQuery,
} from "../api/badgesApi";
import { ProfilePost } from "../api/types/types";
import BadgesContentContainer from "./BadgesContentContainer";
import BadgesTextContainer from "./BadgesTextContainer";

type BadgesContainerProp = {
  student: Partial<AuthUser>;
  photolist: ProfilePost | undefined;
};

const BadgesContainer: React.FC<BadgesContainerProp> = ({
  student,
  photolist,
}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const systemSelected = useAppSelector(selectDefaultAccount);
  const user = useAppSelector(selectAdmin);
  const [
    getStudentpost,
    {
      isLoading: lazyPostLoading,
      isError: lazyPostError,
      isSuccess: lazyPostSuccess,
    },
  ] = useLazyGetStudentPostQuery();

  const {
    data: friendsCount,
    isLoading: friendCountLoading,
    isError: friendsError,
    isSuccess: friendsSuccess,
  } = useFriendsListCountQuery({
    student: String(student._id),
    currentSystem: JSON.stringify(systemSelected),
  });

  const {
    data: studentReviewCount,
    isLoading: reviewLoding,
    isError: reviewError,
    isSuccess: reviewSuccess,
  } = useStudentReviewCountQuery(student._id as string);

  const {
    data: loginCont,
    isLoading: loginCountLoading,
    isError: loginCountError,
    isSuccess: loginCountSuccess,
  } = useGetLoginCountQuery({
    student: student!._id! as string,
    currentSystem: JSON.stringify(systemSelected),
  });

  const {
    data: commentLikeCount,
    isLoading: commentLikeLoading,
    isError: commentLikeError,
    isSuccess: commentLikeSuccess,
  } = useGetCommentLikeQuery();

  const {
    data: lastAchives,
    isLoading: lastAchivesLoading,
    isError: lastAchivesError,
    isSuccess: lastAchivesSuccess,
  } = useGetLastAchivesQuery({
    student: student._id as EntityId,
  });

  const [
    addLike,
    { isLoading: addLoading, isError: addError, isSuccess: addSuccess },
  ] = useAddLikeMutation();
  const [postItems, setPostItems] = useState<ProfilePost | null>(null);
  const [postItem, setPostItem] = useState<Partial<ExperiencePhoto> | null>(
    null
  );
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [system, setSystem] = useState<Partial<StudentSystemType> | null>(
    systemSelected
  );

  const [xps, setXps] = useState<number>(0);
  const [studentLevel, setStudentLevel] = useState<number>(0);

  useEffect(() => {
    setSystem(systemSelected);
    if (student) {
      const projectReport = student?.projectReport!.find(
        (item) => item.project === systemSelected!.project
      );

      if (projectReport) {
        setXps(projectReport.xps);
        setStudentLevel(Math.floor(projectReport.xps / 3500));
      }
    }
  }, [student, systemSelected]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getStudentpost({
          student: String(student!._id!),
          currentsystemstring: JSON.stringify(systemSelected),
        }).unwrap();

        setPostItems(result);
      } catch (error: any) {}
    })();
  }, [postItem]);

  const onClose = () => {
    setOpenPopup(false);
  };

  const applyLike = async (item: Partial<ExperiencePhoto>) => {
    try {
      const result = await addLike({
        photo: item!,
      }).unwrap();
      let itemss: any = [];
      const likeStatus = result.entities[result.ids[0]]?.like;

      if (likeStatus) {
        let newReview = item.reviews![0]?.likeCount
          ? item.reviews![0]?.likeCount + 1
          : 1;
        itemss = [
          {
            likeCount: newReview,
          },
        ];
      } else {
        let newReview = item.reviews![0]?.likeCount
          ? item.reviews![0]?.likeCount - 1
          : 0;
        itemss = [
          {
            likeCount: newReview,
          },
        ];
      }

      setPostItem({
        ...postItem,
        like: result.entities[result.ids[0]],
        reviews: itemss,
      });
    } catch (error) {}
  };

  const onPhotoClick = (item: Partial<ExperiencePhoto> | null) => {
    setOpenPopup(true);
    setPostItem(item);
  };
  const onPhotoCommentClick = (experiencePhoto: Partial<ExperiencePhoto>) => {
    navigation.navigate("FeedDetail", {
      photo: experiencePhoto!,
      photos: postItems?.data,
      student: student,
    });
  };

  return (
    <>
      <BadgesTextContainer student={student} />
      {postItems && friendsSuccess && lastAchives && (
        <>
          <BadgesContentContainer
            student={student}
            posts={photolist!}
            friendsCount={friendsCount.count!.frinedsCount}
            likeCount={studentReviewCount!}
            loginCount={loginCont!}
            onPhotoClick={onPhotoClick}
            applyLike={applyLike}
            studentLevel={studentLevel}
            commentLikeCount={commentLikeCount!}
            lastBadges={lastAchives!}
            onPhotoCommentClick={onPhotoCommentClick}
          />
          {/* <PostFeedPopup
            openPopup={openPopup}
            onClose={onClose}
            item={postItem}
            applyLike={applyLike}
          /> */}
        </>
      )}
    </>
  );
};

export default BadgesContainer;
