import React from "react";
import { View, Text, ImageBackground, TouchableOpacity } from "react-native";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { COLORS, SIZES } from "../../../constants";

type ProfileSettingHeaderPresentationProps = {
  backgroundProfile: string;
  goBackHandler: () => void;
  goAccountSetting: () => void;
  showSetting: boolean;
};
const ProfileSettingHeaderPresentation: React.FC<
  ProfileSettingHeaderPresentationProps
> = ({ backgroundProfile, goBackHandler, goAccountSetting, showSetting }) => {
  return (
    <ImageBackground
      source={{
        uri: backgroundProfile,
      }}
      style={{
        width: "100%",

       
      }}
      resizeMode="cover"
    >
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
        colors={[COLORS.transparent, COLORS.transparent]}
        style={{
          height: 170,
          paddingTop:15,
          paddingHorizontal: SIZES.padding,

          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            height: 40,
            backgroundColor: "rgba(255,255,255,0.75)",
            padding: 4,
            borderRadius: 50,
            marginTop: 32,
          }}
        >
          <TouchableOpacity onPress={goBackHandler}>
            <FontAwesome5 name="arrow-circle-left" size={32} color="black" />
          </TouchableOpacity>
        </View>
        {showSetting && (
          <View
            style={{
              height: 40,
              backgroundColor: "rgba(255,255,255,0.75)",
              padding: 3,
              borderRadius: 50,
              marginTop: 32,
            }}
          >
            <TouchableOpacity onPress={goAccountSetting}>
              <Ionicons name="md-settings" size={32} color="black" />
            </TouchableOpacity>
          </View>
        )}
      </LinearGradient>
    </ImageBackground>
  );
};

export default ProfileSettingHeaderPresentation;
