import React, { FC, useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  Image,
  Modal,
  Dimensions,
  Pressable,
  FlatList,
  Animated,
  TouchableOpacity,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { scale, verticalScale, moderateScale } from "react-native-size-matters";
import LottieView from "lottie-react-native";
import StyleSheet from "react-native-media-query";
import { LinearGradient } from "expo-linear-gradient";
import SmallLinearChart from "../../../components/SmallLinearChart/SmallLinearChart";
import { images } from "../../../constants";
import {
  useGetAllCourseGradeWithXpQuery,
  useUpdateCourseStudentIdMutation,
} from "../../gradeReward/api/gradeRewardapi";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { GradeReward } from "../../gradeReward/types/GradeReward";
const { width, height } = Dimensions.get("window");
export interface IFGradeItemPopup {
  onClose?: () => void;
}

const GradeItemPopup: FC<IFGradeItemPopup> = ({ onClose }) => {
  const systemSelected = useAppSelector(selectDefaultAccount);

  const {
    data: gradRewardList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllCourseGradeWithXpQuery({
    currentSystem: JSON.stringify(systemSelected),
  });

  const [
    updateCourseStudentId,
    {
      isLoading: updateLoading,
      isError: updateError,
      isSuccess: updateSuccess,
    },
  ] = useUpdateCourseStudentIdMutation();
  const [open, setOpen] = useState<boolean>(false);

  const lockAnimation = React.createRef();
  const lockProgress = useRef(new Animated.Value(0)).current;

  const fadeAnim = useRef(new Animated.Value(0)).current;
  const fadeButtonAnim = useRef(new Animated.Value(1)).current;
  const fadeTextAnim = useRef(new Animated.Value(0)).current;

  const [newValue, setNewValue] = useState(40);
  const [lastValue, setLastValue] = useState(50);
  const [disable, setDisabel] = useState<boolean>(false);

  const whenLoadPopup = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
    if (lockAnimation && lockAnimation?.current)
    // @ts-ignore
    lockAnimation.current.play();
  }, []);


  useEffect(() => {



    setTimeout(() => {
      whenLoadPopup();

      setNewValue(60);
      setLastValue(70);
    }, 1000);
  },[]);

  // useEffect(() => {
  //   return () => {
  //     setDisabel(false);
  //   };
  // }, []);

  const handlePressIn = () => {
    onClose!();

    // Animated.timing(fadeButtonAnim,{
    //   toValue:0,
    //   duration:500,
    //   useNativeDriver:true,
    // }).start();

    // Animated.timing(fadeTextAnim,{
    //   toValue:1,
    //   duration:500,
    //   useNativeDriver:true,
    //   delay:400
    // }).start();
  };

  useEffect(() => {
    if (gradRewardList?.ids.length! > 0) {
      const gradRewardListArray: GradeReward[] = Object.values(
        gradRewardList?.entities!
      ) as GradeReward[];
      const result = gradRewardListArray.filter(function findShowGradePopup(
        item: GradeReward
      ) {
        return (
          Number(item.course_completion_percentage) > item.totalXpEarn / 100
        );
      });

      if (result.length > 0) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [gradRewardList]);

  const renderGradeItem = (item: GradeReward) => {
    return (
      <>
        {Number(item.course_completion_percentage) > item.totalXpEarn / 100 ? (
          <View style={styles.modalImageContentContainer}>
            <LinearGradient
              colors={["#13C4B8", "#FFFFFF"]}
              style={styles.modalImageContainer}
            >
              <Text style={styles.yourProgressText}>Your progress…</Text>
              <View style={{ paddingLeft: 20, marginTop: 10 }}>
                <SmallLinearChart
                  value={Number(item.course_completion_percentage)}
                  lastValue={Number(item.where_you_should_be)}
                  minValue={0}
                  maxValue={100}
                  borderType={true}
                />
              </View>

              <Text style={styles.yourProgressText}>in…</Text>
              <Text style={styles.modalText}>{item._id}</Text>
              <Text style={styles.hasEarnText}>Has earn you</Text>
              {Number(item.course_completion_percentage) >
                item.totalXpEarn / 100 && (
                <Animated.View style={{ opacity: fadeAnim }}>
                  <View style={styles.xpContainer}>
                    <Text style={styles.xpValue}>
                      {Math.floor(
                        (Number(item.course_completion_percentage) -
                          item.totalXpEarn / 100) /
                          10
                      ) * 1000}
                    </Text>
                    <Text style={styles.xpText}>XP</Text>
                  </View>
                </Animated.View>
              )}

              <Animated.View style={{ opacity: fadeButtonAnim }}>
                {Number(item.course_completion_percentage) >
                  item.totalXpEarn / 100 && (
                  <TouchableOpacity
                    disabled={disable}
                    onPress={async () => {
                      setDisabel(true);
                      try {
                        await updateCourseStudentId({
                          id: item.id,
                          xp:
                            Math.floor(
                              (Number(item.course_completion_percentage) -
                                item.totalXpEarn / 100) /
                                10
                            ) * 1000,
                          currentSystem: JSON.stringify(systemSelected),
                          completegrade: Number(
                            item.course_completion_percentage
                          ),
                        });
                        setDisabel(false);

                        //goes iman code
                      } catch (error) {}
                    }}
                    style={{
                      position: "relative",
                      marginLeft: "10%",
                      width: "80%",
                      height: moderateScale(80),
                      marginBottom:30
                    }}
                  >
                    <LottieView
                      style={{
                        width: "100%",
                      }}
                      //@ts-ignore
                      ref={lockAnimation}
                      loop
                      source={images.claimButton}
                      progress={lockProgress}

                    />
                    <Text style={styles.modalClaimButtonText}>
                      Claim Points
                    </Text>
                  </TouchableOpacity>
                )}
              </Animated.View>
              <Animated.View style={{ opacity: fadeTextAnim }}>
                <TouchableOpacity onPress={() => {}}>
                  <Text style={styles.modalClaimText}>Tab here to close</Text>
                </TouchableOpacity>
              </Animated.View>
            </LinearGradient>
          </View>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      {open && (
        <Modal
          transparent={true}
          animationType="fade"
          onRequestClose={() => {
            onClose!();
          }}
        >
          {isSuccess && (
            <View style={styles.modelContainer}>
              <FlatList
                windowSize={3}
                initialNumToRender={5}
                maxToRenderPerBatch={5}
                horizontal
                pagingEnabled={true}
                decelerationRate="fast"
                bounces={false}
                showsHorizontalScrollIndicator={false}
                data={Object.values(gradRewardList?.entities!) as GradeReward[]}
                keyExtractor={(item) => `${item._id!}`}
                renderItem={({ item }) => <>{renderGradeItem(item)}</>}
              />
            </View>
          )}
        </Modal>
      )}
    </>
  );
};
const { styles } = StyleSheet.create({
  hasEarnText: {
    color: "#F4294F",
    fontSize: moderateScale(28),
    textAlign: "center",
    fontWeight: "500",
    textShadowColor: "#fff",
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 3,
    marginTop: hp("3%"),
    marginBottom: 5,
  },
  yourProgressText: {
    color: "#fff",
    fontWeight: "bold",
    paddingTop: 8,
    fontSize: moderateScale(36),
    textAlign: "center",
  },
  modalClaimText: {
    fontSize: 20,
    color: "#696969",
    fontFamily: "Roboto-Bold",
    textAlign: "center",
    bottom: 10,
    width: "100%",
    position: "absolute",
  },
  xpContainer: {
    flexDirection: "row",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: moderateScale(60),
  },
  modalText: {
    color: "#fff",
    fontSize: moderateScale(20),
    textAlign: "left",
    padding: 10,
    fontFamily: "Roboto-Bold",
  },
  modalImageContentContainer: {
    width: width,
    height: height,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  xpValue: {
    fontSize: moderateScale(36),
    backgroundColor: "#000",
    borderColor: "#fff",
    minWidth:120,
    borderRadius: 40,
    borderStyle: "solid",
    borderWidth: 3,
    color: "#fff",
    maxWidth: 185,
    width: "auto",
    minHeight: moderateScale(50),
    padding: 5,
    paddingLeft: 30,
    overflow: "hidden",
    paddingRight: 30,
    fontWeight: "bold",
  },
  xpText: {
    color: "#F4284F",
    fontSize: moderateScale(80),
    fontWeight: "bold",
    textShadowColor: "#fff",
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 3,
    left: -28,
    lineHeight: moderateScale(78),
  },
  modalClaimButtonText: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    alignSelf: "center",
    fontSize: moderateScale(25),
    paddingTop: moderateScale(26),
    fontFamily: "RajdahaniBold",
    color: "#fff",
    lineHeight:60,

  },
  modelContainer: {
    backgroundColor: "#000000aa",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",

    alignSelf: "center",
  },

  modalImageContainer: {
    position: "relative",
    maxWidth: 374,
    width: width - 20,
    backgroundColor: "#ffffff",
    borderRadius: 5,
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: "#000",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    marginTop: "3%",
    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
  },
});
export default GradeItemPopup;
