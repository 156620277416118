import React from "react";
import AllBagPresentation from "../prensetation/AllBagPresentation";
import { useGetBagItemsQuery } from "../api/bagApi";

const AllBagContainer = () => {
  const {
    data: bagsItems,
    isLoading,
    isError,
    isSuccess,
  } = useGetBagItemsQuery();
  return <>{isSuccess && <AllBagPresentation items={bagsItems} />}</>;
};

export default AllBagContainer;
