import { Image } from "expo-image";
import React from "react";
import { View, TouchableOpacity, StyleSheet, Text } from "react-native";
import { images } from "../../../constants";
import PrizeMenuTextPresentation from "./PrizeMenuTextPresentation";
import BagMenuTextPresentation from "./BagMenuTextPresentation";
type PrizeHeaderPresentationProps = {
  gem: number;
  onBag?: () => void;
  shop?: boolean;
  onShop?: () => void;
  freeTicket: number;
};
const PrizeHeaderPresentation: React.FC<PrizeHeaderPresentationProps> = ({
  gem,
  onBag,
  freeTicket,
  shop,
  onShop,
}) => {
  return (
    <>
      <View style={styles.profileXpCoinsContainer}>
        {shop ? (
          <></>
        ) : (
          <View style={styles.headerxpcontainer}>
            <View style={styles.headerBag}>
              <TouchableOpacity onPress={onBag} style={{flex:1, flexDirection:"row", justifyContent:"space-around"}}>
                <Image
                  style={styles.headergemImage}
                  contentFit="contain"
                  source={images.orangeBag}
                />
                <Image
                  style={styles.headergemImage}
                  contentFit="contain"
                  source={images.bagArrow}
                />
              </TouchableOpacity>
            </View>
          </View>
        )}
     {!shop &&
        <View style={styles.headercoinscontainer}>
          <View style={[styles.headerItems, styles.headerGem]}>
             <Text style={styles.coinsNumber}>{gem}</Text>
            <Image
              style={styles.headergemImage}
              contentFit="contain"
              source={images.gemss}
            />
           
          </View>
          {/* <View style={[styles.headerItems, styles.headerTicket]}>
            <Image
              style={styles.headerTicketImage}
              resizeMode="contain"
              source={images.Ticket}
            />
            <Text style={styles.coinsNumber}>
    
              {freeTicket}
            </Text>
          </View> */}
        </View>
      }
      </View>

      {shop && (

         <BagMenuTextPresentation/>
      )}
      {!shop && (
         <PrizeMenuTextPresentation/>

      )}
    </>
  );
};

const styles = StyleSheet.create({
  profileXpCoinsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-end",
    alignItems: "flex-end",
  },
  headerxpcontainer: {
    flex: 1,
    paddingBottom: 10,
    paddingRight: 5,
  },
  headercoinscontainer: {
    flex: 1,
    paddingBottom: 10,
    alignItems: "flex-end",
  },
  headerBag: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 5,
    paddingLeft: 5,
    height: 44,
    width: 124,
    backgroundColor: "#000",
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    top:-20,
    left:25,
    borderColor:"#F8C586",
    borderWidth:2,
    borderStyle:"solid"
  },
  headerItems: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "75%",
    height: 44,
    backgroundColor: "rgba(0,0,0,0.8)",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    paddingRight: 10,
  },
  headerGem: {
    width: "20%",
    backgroundColor: "#000",
    minWidth:125,
    right:25,
    borderColor:"#fff",
    borderWidth:2,
    top:-20,
    borderStyle:"solid",
    borderRadius:50,
    flexDirection:"row-reverse",
    justifyContent:"space-around",
    alignItems:"center"
  },
  headerTicket: {
    width: "55%",
    backgroundColor: "rgba(164,170,177,0.8)",
    marginTop: 5,
  },
  headergemImage: {
    height: 30,
    width: 30,
  },
  headerTicketImage: {
    height: 30,
    width: 30,
  },
  shopHeaderTitleIcon: {
    height: 40,
    width: 40,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
  },
  xp: {
    color: "white",
    fontSize: 26,
  },
  coinsNumber: {
    color: "white",
    lineHeight:28,
    fontSize: 23,
    fontFamily:"HelveticaBold",
  },
  shopHeaderCoin: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "32%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#1069C1",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    top: -5,
    left: 0,
  },
  shopHeaderContainer: {
    position: "relative",
    alignItems: "center",
    width: "100%",
    marginTop:-40
  },
  shopHeaderCoinText: {
    fontSize: 20,
    color: "#fff",
  },
  shopHeaderCoinIcon: {
    height: 20,
    width: 33,
    marginTop: 5,
    marginBottom: 5,
  },
  shopHeaderTitleContent: {
    alignItems: "center",
    marginTop: -90,
  },
  shopHeaderTitleText: {
    fontSize: 29,

    color: "#3E3F5E",
    marginBottom: 12,
  },
});
export default PrizeHeaderPresentation;
