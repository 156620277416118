import React from "react";
import NotehstarMainContainer from "../features/notehstar/container/NotehstarMainContainer";

const NotehstarScreen = () => {
  return (
    <>
      <NotehstarMainContainer />
    </>
  );
};

export default NotehstarScreen;