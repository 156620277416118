import React, { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import FindFriendsPresentation from "../presentation/FindFriendsPresentation/FindFriendsPresentation";
import FriendsListPresentation from "../presentation/FriendsListPresentation/FriendsListPresentation";
import FriendsPresentation from "../presentation/FriendsPresentation/FriendsPresentation";
import RequestFriendsPresentation from "../presentation/RequestFriendsPresentation/RequestFriendsPresentation";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import { Text, Platform } from "react-native";
import FreindsTitlePresentation from "../presentation/FrinedsTitlePresentation/FreindsTitlePresentation";
import FindFirendsContainer from "./FindFirendsContainer";
import RequestFriendsContainer from "./RequestFriendsContainer";
import FriendsListContainer from "./FriendsListContainer";
import { SearchBar } from "@rneui/themed";
import { images } from "../../../constants";
import FriendsTextContainer from "./FriendsTextContainer";

const FriendsContainer = () => {
  const account = useAppSelector(selectDefaultAccount);

  const [selectedAccount, setSelectedAccount] = useState<
    Partial<StudentSystemType>
  >(account!);

  const [status, setStatus] = useState<number>(2);
  const [term, setTerm] = useState<string | null>(null);

  const onChangeStatus = (statusSelected: number) => {
    setStatus(statusSelected);
  };

  const onSearchInput = (text: string | null) => {
    setTerm(text);
  };

  const renderTabContent = () => {
    if (status === 2) {
      return <FriendsListContainer account={account!} />;
    } else if (status === 1) {
      return <RequestFriendsContainer account={account!} />;
    } else if (status === 0) {
      return (
        <FindFirendsContainer
          account={account!}
          term={term}
          onSearchInput={onSearchInput}
        />
      );
    }
  };

  return (
    <>
      <FriendsTextContainer />

      <FriendsPresentation onChangeStatus={onChangeStatus}>
        {renderTabContent()}
      </FriendsPresentation>
    </>
  );
};

export default FriendsContainer;
