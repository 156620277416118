import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import FeedContainer from "../features/feeds/container/FeedContainer";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { useAppSelector } from "../redux/hooks";
import { selectAdmin } from "../features/auth/slice/authSlice";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../navigation/types";
const FeedScreen = () => {
  const user = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  return (
    <>
      <HeaderBar profileImage={user?.image!} student={user!} />
      <FeedContainer />
    </>
  );
};

export default FeedScreen;
