import React from "react";
import { View, Text } from "react-native";
import ExperienceMainContainer from "../features/experience/container/ExperienceMainContainer";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { useAppSelector } from "../redux/hooks";
import { selectAdmin } from "../features/auth/slice/authSlice";
import { SafeAreaView } from "react-native-safe-area-context";
import { useRoute } from "@react-navigation/native";
import { ExperienceCardScreenDetailsProp } from "../navigation/types";
const ExperienceMapScreen = () => {
  const user = useAppSelector(selectAdmin);

  return (
    <>
      <HeaderBar
        profileImage={user?.image!}
        backColor="transparent"
        student={user!}
        headerPosition="absolute"
      />
      <ExperienceMainContainer />
    </>
  );
};

export default ExperienceMapScreen;
