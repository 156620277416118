import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { IResponseList } from "../../../redux/types/types";
import { ExperienceCard } from "../types/types";

const experienceCardAdaptor = createEntityAdapter<ExperienceCard>({
  selectId: (experienceCard) => experienceCard._id,
});

interface ExperienceCardInitialState extends EntityState<ExperienceCard> {}

const initialState: ExperienceCardInitialState =
  experienceCardAdaptor.getInitialState();

export const extendedExperienceCardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllActiveExperience: builder.query<
      EntityState<ExperienceCard>,
      {
        currentSystem: string;
        page?: number;
        limit?: number;
        sortParams?: string;
      }
    >({
      query: (expData) =>
        `/experience-card?currentSystemString=${expData.currentSystem}&sort=-startDate`,
      transformResponse: (response: IResponseList<ExperienceCard[]>) => {
        const experienceData = response.data;

        const experienceMetaData = response.metadata;
        return experienceCardAdaptor.setAll(initialState, experienceData);
      },
      providesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "ExperienceCard" as const, id })),
      ],
    }),
    getAllPassDueExperience: builder.query<
      EntityState<ExperienceCard>,
      {
        currentSystem: string;
        page?: number;
        limit?: number;
        sortParams?: string;
      }
    >({
      query: (expData) =>
        `/experience-card/passdue?currentSystemString=${expData.currentSystem}&sort=-startDate`,
      transformResponse: (response: IResponseList<ExperienceCard[]>) => {
        const experienceData = response.data;

        const experienceMetaData = response.metadata;
        return experienceCardAdaptor.setAll(initialState, experienceData);
      },
      providesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "ExperienceCard" as const, id })),
      ],
    }),

    getAllPassDueHotSpotExperienceSecondChance: builder.query<
      EntityState<ExperienceCard>,
      {
        groupId: string;
        currentSystem: string;
        page?: number;
        limit?: number;
        sortParams?: string;
      }
    >({
      query: (expData) =>
        `/experience-card/passduehotgrouplist?currentSystemString=${
          expData.currentSystem
        }&hotspotgroup=${expData.groupId}&page=${1}&limit=${4}&sort=-createdAt`,
      transformResponse: (response: IResponseList<ExperienceCard[]>) => {
        const experienceData = response.data;

        const experienceMetaData = response.metadata;
        return experienceCardAdaptor.setAll(initialState, experienceData);
      },
      providesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "ExperienceCard" as const, id })),
      ],
    }),

    getAllPassDueHotSpotExperience: builder.query<
      EntityState<ExperienceCard>,
      {
        currentSystem: string;
        page?: number;
        limit?: number;
        sortParams?: string;
      }
    >({
      query: (expData) =>
        `/experience-card/passduehot?currentSystemString=${expData.currentSystem}`,
      transformResponse: (response: IResponseList<ExperienceCard[]>) => {
        const experienceData = response.data;

        const experienceMetaData = response.metadata;
        return experienceCardAdaptor.setAll(initialState, experienceData);
      },
      providesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "ExperienceCard" as const, id })),
      ],
    }),

    getAllExperienceCompleteList: builder.query<
      EntityState<ExperienceCard>,
      {
        currentSystem: string;
        page?: number;
        limit?: number;
        sortParams?: string;
      }
    >({
      query: (expData) =>
        `/experience-card/complete?currentSystemString=${expData.currentSystem}`,
      transformResponse: (response: IResponseList<ExperienceCard[]>) => {
        const experienceData = response.data;

        const experienceMetaData = response.metadata;
        return experienceCardAdaptor.setAll(initialState, experienceData);
      },
      providesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "ExperienceCard" as const, id })),
      ],
    }),

    returnBacktoExpereinceList: builder.mutation<
      EntityState<ExperienceCard>,
      {
        id: EntityId;
        experience: Partial<ExperienceCard>;
        currentSystem: string;
      }
    >({
      query: (data) => ({
        url: `/experience-card/back-hotspot-to-list?currentSystemString=${data.currentSystem}&id=${data.id}`,
        method: "PUT",
        body: data.experience,
      }),
      transformResponse: (response: ExperienceCard) => {
        return experienceCardAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "ExperienceCard" as const, id })),
      ],
    }),

    updateMissionRevive: builder.mutation<
      EntityState<ExperienceCard>,
      {
        data: Partial<ExperienceCard>;
        cacheData?: {
          currentSystem: string;
          page?: number;
          limit?: number;
          sortParams?: string;
        };
      }
    >({
      query: (exp) => ({
        url: `/experience-card?id=${exp.data._id}`,
        method: "PUT",
        body: exp.data,
      }),
      transformResponse: (response: ExperienceCard) => {
        return experienceCardAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      async onQueryStarted(
        { cacheData, ...patch },
        { dispatch, queryFulfilled }
      ) {
        dispatch(
          extendedExperienceCardApiSlice.util.updateQueryData(
            "getAllActiveExperience",
            { currentSystem: cacheData?.currentSystem! },
            (draft) => {
              experienceCardAdaptor.addOne(
                draft,
                patch?.data! as ExperienceCard
              );
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetAllActiveExperienceQuery,
  useLazyGetAllActiveExperienceQuery,
  useGetAllPassDueExperienceQuery,
  useLazyGetAllPassDueExperienceQuery,
  useGetAllPassDueHotSpotExperienceQuery,
  useLazyGetAllPassDueHotSpotExperienceQuery,
  useLazyGetAllExperienceCompleteListQuery,
  useGetAllExperienceCompleteListQuery,
  useLazyGetAllPassDueHotSpotExperienceSecondChanceQuery,
  useReturnBacktoExpereinceListMutation,
  useUpdateMissionReviveMutation,
} = extendedExperienceCardApiSlice;
