import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthUser, AuthErrorResponse } from "../types/types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { emptyAdmin } from "../slice/authSlice";
import { store } from "../../../../src/redux/store";
// get admin status, it is exist or not, that shows us it is logged in or not
export const getAdminStatus = createAsyncThunk<
  AuthUser | AuthErrorResponse,
  void,
  { rejectValue: AuthErrorResponse }
>("auth/getAdminStatus", async (_, { rejectWithValue }) => {
  try {
    const admin = await axios.get<AuthUser>(
      // "http://192.168.43.184:6800/api/authadmin/status",
      //`http://192.168.1.101:6800/api/authadmin/status`,
      `https://api.gradify.app/api/authadmin/status`,
      {
        withCredentials: true,
      }
    );

    return admin.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

//logout
export const loguot = createAsyncThunk<
  { logout: boolean },
  void,
  { rejectValue: AuthErrorResponse }
>("auth/logut", async (_, { rejectWithValue }) => {
  try {
    await AsyncStorage.removeItem("@account");

    const logout = await axios.post(
      // "http://192.168.43.184:6800/api/authadmin/logout",
      // `http://192.168.1.101:6800/api/authadmin/logout`,
      `https://api.gradify.app/api/authadmin/logout`,
      _,
      {
        withCredentials: true,
      }
    );

    return logout.data;
  } catch (error: any) {
    // console.log(error);
    return rejectWithValue(error);
  }
});

export const updateUser = createAsyncThunk<
  AuthUser,
  AuthUser,
  { rejectValue: AuthErrorResponse }
>("auth/updateUser", async (user, { rejectWithValue }) => {
  try {
    const updatedUser = await axios.put(
      //"http://192.168.43.184:6800/api/student",
      // `http://192.168.1.101:6800/api/student`,
      `https://api.gradify.app/api/student`,
      user,
      {
        withCredentials: true,
      }
    );

    return updatedUser.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
