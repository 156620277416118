import React, { FC, useState } from "react";
import {
  ScrollView,
  View,
  ImageBackground,
  Image,
  Text,
  Dimensions,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { ProgressBar } from "react-native-paper";
import RadioButton from "../components/RadioButton/RadioButton";
import StyleSheet from "react-native-media-query";
import { useAppDispatch } from "../redux/hooks";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../navigation/types";
import { images } from "../constants";
import { editDetails } from "../features/onBoarding/slice/onBoardingSlice";
const { height } = Dimensions.get("window");

const WorkingScreen = () => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const dispatch = useAppDispatch();
  const [working, setWorking] = useState<string | null>(null);

  const data = [
    { value: "No" },
    { value: "Not Yet" },
    { value: "Less than 10 hrs" },
    { value: "10 to 20 hrs" },
    { value: "More than  20 hrs" },
  ];
  const onSelect = (value: string) => {
    setWorking(value);
  };
  return (
    <ScrollView style={styles.container}>
      <ImageBackground
        source={images.onBoardingbackgroundDark}
        resizeMode="cover"
        style={styles.backgroundImage}
      >
        <View style={styles.LogoContainer}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image source={images.AuthBack} />
          </TouchableOpacity>
          <Image source={images.AuthLog} />
        </View>
        <View style={{ justifyContent: "space-between", flex: 1 }}>
          <View style={styles.content}>
            <Text style={styles.title}>Are you working?</Text>

            <View style={styles.boxes}>
              <Text style={styles.boxesText}>Select one</Text>
              <RadioButton
                data={data}
                onSelect={onSelect}
                itemStyle={undefined}
                secoundType={undefined}
                containerStyle={undefined}
                textStyle={undefined}
              />
            </View>
          </View>

          <View>
            <Pressable
              onPress={() => {
                dispatch(editDetails({ workingText: working }));

                if (working === "No" || working === "Not Yet") {
                  navigation.navigate("TeamSelect");
                } else {
                  navigation.navigate("WorkingWhere");
                }

                // if (working.working === "No" || working.working === "Not Yet") {
                //   navigation.navigate("boardingStep8");
                // } else {
                //   navigation.navigate("boardingStep5");
                // }
              }}
              style={
                true
                  ? [styles.buttonNext, styles.buttonAction]
                  : styles.buttonNext
              }
              disabled={true ? false : true}
            >
              <Text style={styles.nextButtonText}>Next</Text>
            </Pressable>
            <View style={styles.progressContainer}>
              <ProgressBar
                progress={0.8}
                color={"#ffba00"}
                style={styles.progressBar}
              />
              <Text style={styles.progressValue}>80%</Text>
            </View>
          </View>
        </View>
      </ImageBackground>
    </ScrollView>
  );
};
const { styles } = StyleSheet.create({
  boxes: {
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    marginTop: "3%",
    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
    borderRadius: 12,
    padding: 10,
    paddingTop: 24,
    paddingBottom: 45,
    width: "99%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    "@media (max-width: 360px)": {
      marginTop: 10,
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  boxesText: {
    fontSize: 18,
    paddingBottom: 20,
    "@media (max-width: 360px)": {
      paddingBottom: 10,
    },
  },
  buttonNext: {
    width: 240,
    height: 45,
    borderRadius: 8,
    backgroundColor: "#B1ADAD",
    marginTop: "10%",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "9%",
    "@media (max-width: 360px)": {
      marginBottom: "4%",
      width: 200,
      height: 30,
      marginTop: "4%",
    },
  },
  buttonAction: {
    backgroundColor: "#5CFF88",
  },
  nextButtonText: {
    color: "white",
    fontSize: 32,

    "@media (max-width: 360px)": {
      fontSize: 20,
    },
  },
  tabContainer: {
    flexDirection: "row",
    marginTop: 25,
    height: 50,
    position: "relative",
    overflow: "hidden",
    borderRadius: 19,
    width: 263,
    marginBottom: 20,
    alignSelf: "center",
  },
  textInput: {
    marginBottom: 15,
  },
  contentContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },

  container: {
    minHeight: height,
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2%",
    textAlign: "center",
    maxWidth: "90%",
    alignSelf: "center",
    "@media (max-width: 360px)": {
      marginTop: 5,
    },
  },

  title: {
    color: "#fff",
    fontSize: 45,
    textAlign: "center",
    marginBottom: "15%",
    "@media (max-width: 360px)": {
      marginBottom: "5%",
      fontSize: 25,
    },
  },
  desc: {
    color: "#fff",
    fontSize: 48,
    textAlign: "center",
    marginBottom: "15%",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    minHeight: height,
    width: "100%",
    alignItems: "center",
  },

  image: {
    width: 39,
    height: 35,
    marginTop: 30,
    alignItems: "flex-end",
    marginRight: 20,
  },
  LogoContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 20,
    paddingTop: 40,
    "@media (max-width: 360px)": {
      paddingTop: 30,
      paddingBottom: 10,
    },
  },
  buttonContainer: {
    backgroundColor: "#23d2e2",
    borderRadius: 12,
  },
  buttonText: {
    fontSize: 14,
    paddingLeft: 20,
    paddingRight: 20,
  },
  progressContainer: {
    flexDirection: "row",
    marginBottom: "10%",
    justifyContent: "space-around",
  },
  progressBar: {
    height: 31,
    width: "100%",
    backgroundColor: "#b1adad",
    borderRadius: 20,
    minWidth: 230,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
    "@media (max-width: 360px)": {
      height: 25,
      minWidth: 200,
    },
  },
  progressValue: {
    color: "#fff",
    fontSize: 25,
    marginLeft: "4%",
    "@media (max-width: 360px)": {
      fontSize: 20,
    },
  },
  errorText: {
    paddingTop: "5%",
    color: "red",
  },
});

export default WorkingScreen;
