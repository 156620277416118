import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import { getAdminStatus, loguot, updateUser } from "../authThunks/authThunks";
import { AuthUser, IAuthinitialState, StudentSystemType } from "../types/types";
const initialState: IAuthinitialState = {
  admin: null,
  status: "idle",
  error: null,
  selectedAccount: null,
  xps: 0,
  gem: 0,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    chooseSelectAccount: (
      state,
      action: PayloadAction<Partial<StudentSystemType>>
    ) => {
      state.selectedAccount = action.payload;
    },
    emptyAdmin: (state) => {
      state.admin = null;
       //state.status = "idle";
    },
    editAdmin: (state, action) => {
      state.admin = action.payload;
    },
    changeXpsAndGems: (state, action) => {
      const projectReport = state.admin!.projectReport.find(
        (item) => item.project === state.selectedAccount?.project
      );

      if (projectReport) {
        state.xps = action.payload.xps + projectReport.xps;
        state.gem = action.payload.gem + projectReport.gems;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAdminStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.admin = action.payload as AuthUser;
      })
      .addCase(getAdminStatus.rejected, (state, action) => {
        state.status = "failed";

        state.error = action.payload!;
      })
      .addCase(loguot.fulfilled, (state, action) => {
        state.admin = null;
      })
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.admin = action.payload! as AuthUser;
      })
      .addCase(updateUser.rejected, (state, action) => {
        (state.status = "failed"), (state.error = action.payload!);
      });
  },
});
export const { chooseSelectAccount, emptyAdmin, editAdmin, changeXpsAndGems } =
  authSlice.actions;
export default authSlice.reducer;

//selectors
export const selectAdmin = (state: RootState) => state.auth.admin;

export const selectStatus = (state: RootState) => state.auth.status;

export const selectDefaultAccount = (state: RootState) =>
  state.auth.selectedAccount;

export const selectXp = (state: RootState) => state.auth.xps;

export const selectGem = (state: RootState) => state.auth.gem;
