import React from "react";
import { View, Text, ScrollView, Dimensions, Platform } from "react-native";
import StyleSheet from "react-native-media-query";
import { FontAwesome5 } from "@expo/vector-icons";
import { StudentSystemType } from "../../auth/types/types";
import { EntityState } from "@reduxjs/toolkit";
import { TeamAndScore } from "../types/type";
import ShowTeamsDataItem from "./ShowTeamsDataItem";
const { width, height } = Dimensions.get("window");
type TeamTabItemPresentationProps = {
  account: Partial<StudentSystemType>;
  projectTitle: string | null;
  categoryTitle: string | null;
  data: TeamAndScore;
  showCat: boolean;
};
const TeamTabItemPresentation: React.FC<TeamTabItemPresentationProps> = ({
  projectTitle,
  categoryTitle,
  account,
  data,
  showCat,
}) => {
  return (
    <ScrollView  style={(Platform.OS === "web") ? styles.teamsTabContentContainerWeb : styles.teamsTabContentContainer}>
      {/* {true ? (
        <View style={{ marginTop: 50 }}>
          {/* <LoadingScreen iconSize={60} height={undefined} /> */}
      {/* </View> */}
      {/* ) : (  */}
      <>
        <View style={styles.teamsTabContainer}>
          <View style={styles.totalXpEarned}>
            <View style={styles.totalXpEarnedIcon}>
              <FontAwesome5 name="chart-pie" size={24} color="#3E3F5E" />
            </View>
            <View style={styles.totalXpEarnedNumberContent}>
              <Text style={styles.totalXpEarnedNumber}>
                {data?.totalProjectXps}
              </Text>
              <Text style={styles.totalXpEarnedText} numberOfLines={2}>
                <Text>{projectTitle}</Text>
              </Text>
              {/* {showCat && account.categories!.length > 0 ? (
                <Text style={styles.totalXpEarnedText}> */}
              {/* {account.categories!.map((item) => item.title + ", ")} */}
              {/* </Text>
              ) : ( */}
              {showCat && (
                <Text style={styles.totalXpEarnedText}>{categoryTitle}</Text>
              )}
            </View>
          </View>

          {data?.team?.map((item, index) => (
            <ShowTeamsDataItem
              key={item._id}
              item={item}
              idx={index}
              outOfBound={data.outofBound!}
            />
          ))}
        </View>
      </>
      {/* )} */}
    </ScrollView>
  );
};

const { styles } = StyleSheet.create({
  teamsTabContainer: {
    maxWidth: "100%",
    // minHeight: "80%",
  },
  totalXpEarnedNumberContent: {
    alignItems: "center",
  },
  totalXpEarned: {
    flexDirection: "row",
    alignSelf: "flex-end",
    paddingRight: 15,
    justifyContent: "flex-end",

    width: "100%",
  },
  totalXpEarnedCenter: {
    alignSelf: "center",
    justifyContent: "center",
  },
  totalXpEarnedText: {
    fontSize: 17,
    lineHeight: 20,
    color: "#3E3F5E",
    fontFamily: "RajdahaniBold",
    top: -10,
    maxWidth: 220,
    textAlign: "center",
    "@media (max-width: 360px)": {
      fontSize: 18,
      lineHeight: 22,
      top: -10,
    },
  },
  totalXpEarnedIcon: {
    paddingTop: 5,
    paddingRight: 3,
  },
  totalXpEarnedNumber: {
    fontSize: 59,
    lineHeight: 58,
    fontFamily: "RajdahaniBold",
    color: "#3E3F5E",
    "@media (max-width: 360px)": {
      fontSize: 30,
      lineHeight: 35,
    },
  },
  teamTabContentContainer: {
    "@media (max-width: 360px)": {
      // height: height - 150,
    },
  },
  teamLogoContainer: {
    // backgroundColor: "#556EE6",
  },
  searchInputContainerStyle: {
    backgroundColor: "#EBEBEB",
    borderRadius: 20,
    borderColor: "#707070",
    borderWidth: 1,
    borderStyle: "solid",
    borderBottomWidth: 1,
    // height: 35,
    maxWidth: 240,
    width: "100%",

    alignSelf: "center",
    marginTop: 10,
  },
  searchInput: {
    backgroundColor: "#fff",
    borderColor: "#fff",
    height: 78,
  },
  teamContainer: {
    flex: 1,
  },
  searchInputStyle: {
    fontSize: 12,
    lineHeight: 15,

    color: "#847D7D",
    letterSpacing: 0.2,
    alignItems: "center",
  },
  cardList: {
    width: width - 30,

    maxWidth: 390,
    paddingTop: 15,
    paddingBottom: 15,
    shadowColor: "#000",
    overflow: "visible",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 3.84,
    backgroundColor: "#fff",
    elevation: 10,
    borderRadius: 8,
  },
  cardHeader: {
    width: "100%",

    height: 100,
    backgroundColor: "#1766C1",
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
  },
  profileXpCoinsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  headerxpcontainer: {
    flex: 1,
    paddingBottom: 10,
    paddingRight: 5,
  },
  xpNumbrt: {
    fontSize: 28,
    color: "#fff",
    alignSelf: "center",
    fontWeight: "bold",
  },
  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#000",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#000",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
  },
  xp: {
    color: "white",
    fontSize: 26,
  },
  teamsTabContentContainer: {
    maxHeight: height - 175,
 
  },
  teamsTabContentContainerWeb:{
    height:555,
    flexShrink:0
  }
});

export default TeamTabItemPresentation;
