import React from "react";
import { Dimensions, FlatList, Platform, Text } from "react-native";
import BadgeSystemItemPresentation from "./BadgeSystemItemPresentation";
import { EntityState } from "@reduxjs/toolkit";
import { BadgeSystem } from "../type/type";
import StyleSheet from "react-native-media-query";

export type BadgeSystemListPresentationProp = {
  badgesList: EntityState<BadgeSystem>;
  onItemDetail: (item: BadgeSystem | null) => void;
};
const { width, height } = Dimensions.get("window");

const BadgeSystemListPresentation: React.FC<
  BadgeSystemListPresentationProp
> = ({ badgesList, onItemDetail }) => {
  return (
    <>
      {badgesList && (
        <FlatList
          data={Object.values(badgesList?.entities!) as BadgeSystem[]}
          keyboardDismissMode="on-drag"
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{}}

          style={(Platform.OS === "web") ? styles.badgeSystemContainerWeb : styles.badgeSystemContainer}
          keyExtractor={(item) => `${item._id}`}
          renderItem={({ item }) => (
            <>
              <BadgeSystemItemPresentation
                item={item}
                onItemDetail={onItemDetail}
              />
            </>
          )}
        />
      )}
    </>
  );
};
const { styles } = StyleSheet.create({
  badgeSystemContainer:{
    maxHeight: "100%",
     marginBottom: 30 
  },
  badgeSystemContainerWeb:{
    maxHeight: height - 200,
  }
})
export default BadgeSystemListPresentation;
