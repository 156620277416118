import React from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAdmin } from "../../auth/slice/authSlice";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";

import { Dimensions, ImageBackground, ScrollView } from "react-native";

import { images } from "../../../constants";
import NorthstarBackButton from "../presentation/NorthstarBackButton";

import YouthPresentation from "../presentation/YouthPresentation";

const { width, height } = Dimensions.get("window");

const YouthMainContainer = () => {
  const user = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const goBackHandler = () => {
    navigation.goBack();
  };

  return (
    <>
      <ScrollView>
        <ImageBackground
          source={images.youthBack}
          resizeMode="cover"
          style={{
            minHeight: height,
          }}
        >
          <NorthstarBackButton goBackHandler={goBackHandler!} />

          <YouthPresentation />
        </ImageBackground>
      </ScrollView>
    </>
  );
};

export default YouthMainContainer;
