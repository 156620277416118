import React, { FC, useEffect, useState } from "react";
import {
  ScrollView,
  View,
  ImageBackground,
  Image,
  Text,
  TouchableOpacity,
  Animated,
  Dimensions,
  Pressable,
} from "react-native";
import PolygonProfile from "../components/PolygonProfile";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import { ProgressBar } from "react-native-paper";

import StyleSheet from "react-native-media-query";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { HomeScreenNavigationProps } from "../navigation/types";
import { images } from "../constants";
import { editDetails } from "../features/onBoarding/slice/onBoardingSlice";
import { selectAdmin } from "../features/auth/slice/authSlice";
const { height } = Dimensions.get("window");

const TeamSelectScreen = () => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [team, setTeam] = useState<string | null>("blue");
  const bLeftValue = useState(new Animated.Value(0))[0];
  const yLeftValue = useState(new Animated.Value(0))[0];
  const rLeftValue = useState(new Animated.Value(0))[0];
  const [saveState, setSaveState] = useState(false);
  const [naviState, setNaviState] = useState(false);

  useEffect(() => {
    if (naviState) {
      // navigation.navigate("boardingStep9");
    }
  }, [naviState]);

  const handleSlide = (type: string) => {
    setTeam(type);
    if (type === "blue") {
      Animated.timing(bLeftValue, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
      Animated.timing(rLeftValue, {
        toValue: 70,
        duration: 500,
        useNativeDriver: true,
      }).start();
      Animated.timing(yLeftValue, {
        toValue: 70,
        duration: 500,
        useNativeDriver: true,
      }).start();
    } else if (type === "yellow") {
      Animated.timing(yLeftValue, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
      Animated.timing(bLeftValue, {
        toValue: 70,
        duration: 500,
        useNativeDriver: true,
      }).start();
      Animated.timing(rLeftValue, {
        toValue: 70,
        duration: 500,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(rLeftValue, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
      Animated.timing(bLeftValue, {
        toValue: 70,
        duration: 500,
        useNativeDriver: true,
      }).start();
      Animated.timing(yLeftValue, {
        toValue: 70,
        duration: 500,
        useNativeDriver: true,
      }).start();
    }
  };

  return (
    <ScrollView style={styles.container}>
      <ImageBackground
        source={images.onBoardingbackgroundDark}
        style={styles.backgroundImage}
      >
        <View style={styles.LogoContainer}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image source={images.AuthBack} />
          </TouchableOpacity>
          <Image source={images.AuthLog} />
        </View>

        <View style={styles.content}>
          <Text style={styles.gradify}>Pick Your Team</Text>
          <Text style={styles.desc}>
            Humans are tribal so we do our greatest when working with others
          </Text>
        </View>
        <TouchableOpacity
          style={{ width: "100%" }}
          onPress={() => {
            handleSlide("blue");
          }}
        >
          <Animated.View
            style={[
              {
                transform: [{ translateX: bLeftValue }],
              },
            ]}
          >
            <View style={styles.teamContainer}>
              <View style={styles.profilePic}>
                <PolygonProfile
                  studentPic={images.ProfileAvatar}
                  strokeColor="#2E5366"
                  xp={0}
                  showXp={false}
                />
              </View>
              <LinearGradient
                colors={["#2E5366", "#2E5366"]}
                style={styles.teamGradient}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
              >
                <Text style={styles.teamGradientText}>Blue Team</Text>
              </LinearGradient>
            </View>
          </Animated.View>
        </TouchableOpacity>

        <TouchableOpacity
          style={{ width: "100%" }}
          onPress={() => {
            handleSlide("yellow");
          }}
        >
          <Animated.View
            style={[
              {
                transform: [{ translateX: yLeftValue }],
              },
            ]}
          >
            <View style={styles.teamContainer}>
              <View style={styles.profilePic}>
                <PolygonProfile
                  studentPic={images.ProfileAvatar}
                  strokeColor="#FFC700"
                  xp={0}
                  showXp={false}
                />
              </View>
              <LinearGradient
                colors={["#FFC700", "#FFC700"]}
                style={styles.teamGradient}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
              >
                <Text style={styles.teamGradientText}>Yellow Team</Text>
              </LinearGradient>
            </View>
          </Animated.View>
        </TouchableOpacity>

        <TouchableOpacity
          style={{ width: "100%" }}
          onPress={() => {
            if (admin?.system[0].eventProject) {
              handleSlide("green");
            } else {
              handleSlide("red");
            }
          }}
        >
          <Animated.View
            style={[
              {
                transform: [{ translateX: rLeftValue }],
              },
            ]}
          >
            <View style={styles.teamContainer}>
              <View style={styles.profilePic}>
                <PolygonProfile
                  studentPic={images.ProfileAvatar}
                  strokeColor={
                    admin?.system[0].eventProject ? "#07B8C4" : "#C74545"
                  }
                  xp={0}
                  showXp={false}
                />
              </View>
              <LinearGradient
                colors={
                  admin?.system[0].eventProject
                    ? ["#07B8C4", "#07B8C4"]
                    : ["#C74545", "#C74545"]
                }
                style={styles.teamGradient}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
              >
                <Text style={styles.teamGradientText}>
                  {admin?.system[0].eventProject ? "Green Team" : "Red Team"}{" "}
                </Text>
              </LinearGradient>
            </View>
          </Animated.View>
        </TouchableOpacity>
        <Pressable
          onPress={() => {
            switch (team) {
              case "blue":
                dispatch(editDetails({ team: "6454b5a8ff1f4e3577b0faf6" }));
                break;
              case "red":
                dispatch(editDetails({ team: "6454b5bdff1f4e3577b0faf9" }));
                break;
              case "yellow":
                dispatch(editDetails({ team: "6454b5d3ff1f4e3577b0fafc" }));
                break;
              case "green":
                dispatch(editDetails({ team: "654c637a9ac91b74d794aff2" }));
                break;
              default:
                dispatch(editDetails({ team: "6454b5a8ff1f4e3577b0faf6" }));
            }
            admin?.systemProject[0].project_type === "Event"
              ? navigation.navigate("EventLoading")
              : navigation.navigate("Confirm");
          }}
          style={
            true ? [styles.buttonNext, styles.buttonAction] : styles.buttonNext
          }
          disabled={true ? false : true}
        >
          <Text style={styles.nextButtonText}>Next</Text>
        </Pressable>
        <View style={styles.progressContainer}>
          <ProgressBar
            progress={0.94}
            color={"#ffba00"}
            style={styles.progressBar}
          />
          <Text style={styles.progressValue}>94%</Text>
        </View>
      </ImageBackground>
    </ScrollView>
  );
};

const { styles } = StyleSheet.create({
  nextButtonText: {
    color: "white",
    fontSize: 32,

    "@media (max-width: 360px)": {
      paddingBottom: 10,
      fontSize: 20,
    },
  },
  buttonNext: {
    width: 240,
    height: 45,
    borderRadius: 8,
    backgroundColor: "#B1ADAD",
    marginTop: "5%",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    "@media (max-width: 360px)": {
      marginBottom: "4%",
      width: 200,
      height: 30,
      marginTop: "4%",
    },
  },
  buttonAction: {
    backgroundColor: "#5CFF88",
  },
  teamContainer: {
    height: 100,
    position: "relative",
    width: "100%",
    marginBottom: "7%",
    "@media (max-width: 360px)": {
      height: 88,
      marginBottom: 5,
    },
  },
  profilePic: {
    top: -60,
    transform: [{ scale: 0.55 }],
    left: 0,
    zIndex: 3,
    position: "absolute",
    "@media (max-width: 360px)": {
      transform: [{ scale: 0.4 }],
      top: -75,
    },
  },
  textInput: {
    marginBottom: 15,
  },
  contentContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  teamGradient: {
    width: "80%",
    height: "18%",
    minHeight: 100,
    borderRadius: 30,
    right: "-15%",
    marginBottom: 6,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
    "@media (max-width: 360px)": {
      height: "10%",
      minHeight: 70,
    },
  },
  teamGradientText: {
    fontSize: 35,
    color: "white",

    zIndex: 2,
    "@media (max-width: 360px)": {
      fontSize: 25,
    },
  },

  container: {
    flex: 1,
    minHeight: height,
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2%",
    alignSelf: "center",
  },

  title: {
    color: "#fff",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    minHeight: height,
    width: "100%",
    alignItems: "center",
  },

  image: {
    width: 39,
    height: 35,
    marginTop: 30,
    alignItems: "flex-end",
    marginRight: 20,
  },
  LogoContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 20,
    paddingTop: 40,
    "@media (max-width: 360px)": {
      paddingTop: 30,
      paddingBottom: 10,
    },
  },
  gradify: {
    color: "#fff",
    fontSize: 45,
    textAlign: "center",
    maxWidth: "80%",
    "@media (max-width: 360px)": {
      fontSize: 25,
    },
  },
  desc: {
    color: "#fff",
    fontSize: 18,
    marginTop: 19,
    maxWidth: 350,
    textAlign: "center",
    marginBottom: "5%",
    "@media (max-width: 360px)": {
      fontSize: 15,
      marginTop: 5,
    },
  },

  progressContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "10%",
  },
  progressBar: {
    height: 31,
    width: "100%",
    backgroundColor: "#b1adad",
    borderRadius: 20,
    minWidth: 230,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    "@media (max-width: 360px)": {
      height: 25,
      minWidth: 200,
    },
  },
  progressValue: {
    color: "#fff",
    fontSize: 25,
    marginLeft: "4%",
    "@media (max-width: 360px)": {
      fontSize: 20,
    },
  },
});

export default TeamSelectScreen;
