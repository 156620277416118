import React from "react";
import { useGetItemsBagQuery } from "../api/bagApi";
import AllBagPresentation from "../prensetation/AllBagPresentation";

const AllItemsBagContainer = () => {
  const {
    data: bagsItems,
    isLoading,
    isError,
    isSuccess,
  } = useGetItemsBagQuery();
  return <>{isSuccess && <AllBagPresentation items={bagsItems} />}</>;
};

export default AllItemsBagContainer;
