import React from "react";
import { View, Dimensions, TouchableOpacity, Text } from "react-native";
import { Image } from "expo-image";
import StyleSheet from "react-native-media-query";
import { images } from "../../../constants";
import { FontAwesome5 } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin } from "../../auth/slice/authSlice";

const { width, height } = Dimensions.get("window");

type FeedDetaisHeaderProp = {
  photo: Partial<ExperiencePhoto>;
  onOpenDeletePopup?: () => void;
  onPhotoClick?: (item: Partial<ExperiencePhoto>) => void;
};
const FeedDetailHeader: React.FC<FeedDetaisHeaderProp> = ({
  photo,
  onOpenDeletePopup,
  onPhotoClick,
}) => {
  const admin = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  return (
    <View
      style={{
        width: width,
        height: 100,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        position: "absolute",
        top: 0,
        zIndex: 100,
      }}
    >
      <TouchableOpacity
        style={{
          height: 42,
          width: 42,
          backgroundColor: "rgba(255,255,255,0.75)",
          padding: 0,
          borderRadius: 50,
          paddingLeft: 2,
          paddingTop: 1,
          marginTop: 32,
          marginLeft: 10,
        }}
        onPress={() => {
          navigation.goBack();
        }}
      >
        <FontAwesome5 name="arrow-circle-left" size={37} color="black" />
      </TouchableOpacity>

      <Text
        style={{
          color: "#fff",
          fontFamily: "HelveticaBold",
          fontSize: 18,
          top: 17,
          textShadowColor: "#000",
          textShadowOffset: { width: 1, height: 2 },
          textShadowRadius: 6,
        
         
        }}
      >
        {photo?.experience?.title}
      </Text>

      {/* Photo Delete my profile */}

      {photo.student?._id === admin?._id && (
        <TouchableOpacity
          onPress={() => {
            onOpenDeletePopup!();
            onPhotoClick!(photo);
          }}
        >
          <Image
            style={{
              zIndex: 1,
              backgroundColor: "#000",
              top: 20,
              left: -15,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
            contentFit="contain"
            source={images.deleteMenuIcon}
          />
        </TouchableOpacity>
      )}
      {photo.student?._id !== admin?._id && (
        <TouchableOpacity onPress={() => {}}>
          <Image
            style={{
              width: 45,
              height: 45,
              borderRadius: 17,
              overflow: "hidden",
              zIndex: 1,
              marginBottom: 13,
              backgroundColor: "#fff",
              top: 20,
              left: -15,
            }}
            source={{ uri: photo.student?.image }}
            contentFit="cover"
            transition={1000}
          />

          <Image
            source={images.commentBack}
            style={{
              width: 57,
              height: 62,
              overflow: "hidden",
              position: "absolute",
              top: 8,
              left: -20,
              zIndex: 1,
            }}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

// const { styles } = StyleSheet.create({
//   feedDetailContainer: {
//     backgroundColor: "#000",
//     flex: 1,
//     display: "flex",
//     justifyContent: "center",
//   },
//   feedDetailImage: {
//     width: width,
//   },
// });

export default FeedDetailHeader;
