import React from "react";
import { View, Image, Dimensions, Text, TouchableOpacity } from "react-native";
import StyleSheet from "react-native-media-query";
import { FontAwesome } from "@expo/vector-icons";
import { images } from "../../../constants";
import PostLike from "../../../components/PostLike/PostLike";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { useGetAllPhotosQuery } from "../../cameraFeatures/api/experiencePhotoApiSlice";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
const { width, height } = Dimensions.get("window");
type FeedDetailFooterProp = {
  photo: Partial<ExperiencePhoto>;
  onOpen: () => void;
  onSelectPhoto: (experiencePhoto: Partial<ExperiencePhoto> | null) => void;

  applyLike: (item: Partial<ExperiencePhoto>) => void;
};
const FeedDetailFooter: React.FC<FeedDetailFooterProp> = ({
  photo,
  onOpen,
  onSelectPhoto,
  applyLike,
}) => {
  return (
    <>
      <View style={styles.FeedDetailFooterContainer}>
        <PostLike
          applyLike={() => {
            applyLike(photo);
          }}
          item={photo}
        />

        <View style={styles.FeedDetailFooterContent}>
          <TouchableOpacity
            onPress={() => {
              onOpen();
              onSelectPhoto(photo);
            }}
            style={styles.userComment}
          >
            <FontAwesome name="comment" size={24} color="white" />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={async () => {
              const downloadResumable = FileSystem.createDownloadResumable(
                photo?.photo!,
                FileSystem.documentDirectory + `${photo._id}.jpg`,
                {}
              );

              try {
                const uri = await downloadResumable.downloadAsync();
                console.log("Finished downloading to ", uri?.uri);

                Sharing.isAvailableAsync()
                  .then((isAvailable) => {
                    if (!isAvailable) {
                      alert("Sharing is not available on this platform");
                      return;
                    }

                    Sharing.shareAsync(uri?.uri!);
                  })
                  .catch((error) => console.log(error));
              } catch (e) {
                console.error(e);
              }
            }}
            style={styles.userComment}
          >
            <FontAwesome name="share" size={24} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const { styles } = StyleSheet.create({
  FeedDetailFooterContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  FeedDetailFooterContainer: {
    position: "absolute",

    height: 60,
    bottom: 20,
    width: width,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  userComment: {
    marginTop: 0,
    marginRight: 6,
    display: "flex",

    paddingRight: 30,
    alignItems: "flex-end",
  },

  feedDetailContainer: {
    backgroundColor: "#000",
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  feedDetailImage: {
    width: width,
  },
});

export default FeedDetailFooter;
