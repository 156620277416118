import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { ExperiencePhoto } from "../types/types";
import { extendedExperienceCardApiSlice } from "../../experience/api/experienceApiSlice";
import { StudentSystemType } from "../../auth/types/types";
import { IResponseList } from "../../../redux/types/types";

export const experiencePhotoAdaptor = createEntityAdapter<ExperiencePhoto>({
  selectId: (experiencePhoto) => experiencePhoto._id,
});

interface ExperiencePhotoInitialState extends EntityState<ExperiencePhoto> {}

export const initialState: ExperiencePhotoInitialState =
  experiencePhotoAdaptor.getInitialState();

export const extendedExperiencePhotoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPhotos: builder.query<
      EntityState<ExperiencePhoto>,
      {
        currentSystem: string;
        page?: string;
        limit?: string;
        sort?: string;
      }
    >({
      query: (data) =>
        `/experience-photo/getbycurrentsystem?currentSystem=${data.currentSystem}&sort=${data.sort}`,
      transformResponse: (response: IResponseList<ExperiencePhoto[]>) => {
        return experiencePhotoAdaptor.setAll(initialState, response.data);
      },
      providesTags: (result, error, arg) => [
        {
          type: "ExperiencePhoto",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({
          type: "ExperiencePhoto" as const,
          id,
        })),
        "ExperienceCard",
      ],
    }),
    addNewPhoto: builder.mutation<
      EntityState<ExperiencePhoto>,
      {
        expPhoto: Partial<ExperiencePhoto>;
        currentSystem: string;
      }
    >({
      query: (expereincePhoto) => ({
        url: "/experience-photo",
        method: "POST",
        body: expereincePhoto.expPhoto,
      }),
      transformResponse: (response: ExperiencePhoto) => {
        return experiencePhotoAdaptor.setOne(initialState, response);
      },
    }),

    removePhoto: builder.mutation<ExperiencePhoto, EntityId>({
      query: (id) => ({
        url: `/experience-photo/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: [
        "ExperienceCard",
        "ExperiencePhoto",
        "Grade",
        "GradeAvgScore",
        "PhotoCount",
        "Student",
        "TeamAndScore",
      ],
    }),

    reTakePhoto: builder.mutation<
      EntityState<ExperiencePhoto>,
      {
        id: string;
        photo: string;
      }
    >({
      query: (data) => ({
        url: `/experience-photo/${data.id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: ExperiencePhoto) => {
        return experiencePhotoAdaptor.updateOne(initialState, {
          changes: response,
          id: response._id,
        });
      },
      invalidatesTags: ["ExperienceCard", "ExperiencePhoto"],
    }),
  }),
});

export const {
  useAddNewPhotoMutation,
  useGetAllPhotosQuery,
  useLazyGetAllPhotosQuery,
  useRemovePhotoMutation,
  useReTakePhotoMutation,
} = extendedExperiencePhotoApiSlice;
