import React, { useState, FC, useEffect } from "react";
import {
  ScrollView,
  View,
  ImageBackground,
  Image,
  Text,
  TouchableOpacity,
  Animated,
  Dimensions,
  Pressable,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { HomeScreenNavigationProps } from "../navigation/types";
import { useNavigation } from "@react-navigation/native";
import { images } from "../constants";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { studentDetails } from "../features/onBoarding/slice/onBoardingSlice";
import { editAdmin, selectAdmin } from "../features/auth/slice/authSlice";
import { updateUser } from "../features/auth/authThunks/authThunks";

const { height } = Dimensions.get("window");

const ConfirmScreen = () => {
  const details = useAppSelector(studentDetails);
  const admin = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<HomeScreenNavigationProps>();

  //   useEffect(() => {
  //     if (NaviState) {
  //       navigation.navigate("mainTab");
  //     }
  //   }, [NaviState]);

  return (
    <ScrollView style={styles.container}>
      <ImageBackground
        source={images.onBoardingbackgroundDark}
        style={styles.backgroundImage}
      >
        <View style={styles.LogoContainer}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image source={images.AuthBack} />
          </TouchableOpacity>
          <Image source={images.AuthLog} />
        </View>

        <View style={styles.content}>
          <Text style={styles.gradify}></Text>

          <Text style={styles.desc}></Text>
        </View>

        <View style={{ width: 300, height: 300 }}></View>

        <Animated.View
          style={[
            {
              opacity: 1,
              position: "absolute",
              marginTop: "18%",

              height: 700,
            },
          ]}
        >
          <View style={styles.content}>
            <Text style={styles.gradify}>How do you change the world?</Text>
            <Text style={styles.desc}>"Be the change you want to see…”</Text>
          </View>
          <View style={{ alignItems: "center" }}>
            <Pressable
              onPress={() => {
                dispatch(
                  updateUser({
                    ...admin!,
                    ...details,
                    team: details?.team as any,
                    confirm: true,
                  })
                );

                navigation.navigate("Auth");
              }}
              style={[styles.buttonNext, styles.buttonAction]}
            >
              <Text style={styles.nextButtonText}>Next</Text>
            </Pressable>
          </View>
        </Animated.View>
      </ImageBackground>
    </ScrollView>
  );
};

const { styles } = StyleSheet.create({
  loadingText: {
    color: "white",
    fontSize: 18,

    marginBottom: "20%",
  },
  video: {
    alignSelf: "center",
    width: 320,
    height: 200,
  },
  nextButtonText: {
    color: "white",
    fontSize: 32,

    "@media (max-width: 360px)": {
      paddingBottom: 5,
      fontSize: 22,
    },
  },
  buttonNext: {
    width: 240,
    height: 45,
    borderRadius: 8,
    backgroundColor: "#B1ADAD",
    marginTop: "10%",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    "@media (max-width: 360px)": {
      marginBottom: "4%",
      width: 200,
      height: 30,
      marginTop: "4%",
    },
  },
  buttonAction: {
    backgroundColor: "#5CFF88",
  },
  backgroundVideo: {
    height: 250,
    width: "100%",
  },
  mediaControls: {
    height: "100%",
    flex: 1,
    alignSelf: "center",
  },

  textInput: {
    marginBottom: 15,
  },

  container: {
    flex: 1,
    minHeight: height,
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: "8%",
    alignSelf: "center",
  },

  title: {
    color: "#fff",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    minHeight: height,
    width: "100%",
    alignItems: "center",
  },

  image: {
    width: 39,
    height: 35,
    marginTop: 30,
    alignItems: "flex-end",
    marginRight: 20,
  },
  LogoContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 20,
    paddingTop: 40,
    "@media (max-width: 360px)": {
      paddingTop: 30,
      paddingBottom: 10,
    },
  },
  gradify: {
    color: "#fff",
    fontSize: 45,
    textAlign: "center",
    maxWidth: "98%",
    marginBottom: "15%",

    "@media (max-width: 360px)": {
      marginBottom: "1%",
      fontSize: 25,
    },
  },
  desc: {
    color: "#fff",
    fontSize: 48,
    textAlign: "center",
    marginBottom: "15%",

    "@media (max-width: 360px)": {
      marginBottom: "15%",
      fontSize: 40,
    },
  },
});

export default ConfirmScreen;
