import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAdmin } from "../../auth/slice/authSlice";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  HomeScreenNavigationProps,
  MyWhyScreenDetailsProp,
} from "../../../navigation/types";

import {
  Dimensions,
  ImageBackground,
  Keyboard,
  ScrollView,
  TouchableOpacity,
} from "react-native";

import { images } from "../../../constants";
import NorthstarMyWhyTextContainer from "./NorthstarMyWhyTextContainer";
import NorthstarBackButton from "../presentation/NorthstarBackButton";
import NorthstarMyWhyPresentation from "../presentation/NorthstarMyWhyPresentation";
import {
  useAddMyWhyMutation,
  useDeleteMywhyMutation,
  useUpdateMyWhyMutation,
} from "../api/myWhy";
import { EntityId } from "@reduxjs/toolkit";

const { width, height } = Dimensions.get("window");

const NorthstarMyWhyMainContainer = () => {
  const user = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const route = useRoute<MyWhyScreenDetailsProp>();
  const { northStar, titleref, descriptionref, myWhyIdref, editref } =
    route.params;

  const [addMyWhy, { isLoading, isError, isSuccess }] = useAddMyWhyMutation();
  const [
    updateMyWhy,
    { isLoading: myLoading, isError: myError, isSuccess: mySuccess },
  ] = useUpdateMyWhyMutation();

  const [
    deleteMyWhy,
    {
      isLoading: removeLoading,
      isError: removeError,
      isSuccess: removeSuccess,
    },
  ] = useDeleteMywhyMutation();

  const [title, setTitle] = useState<string | null>(titleref ? titleref : null);
  const [description, setDescription] = useState<string | null>(
    descriptionref ? descriptionref : null
  );
  const [myWhyId, setMyWhyId] = useState<EntityId | null>(
    myWhyIdref ? myWhyIdref : null
  );
  const [northStarState, setNorthstar] = useState<EntityId | null>(null);
  const goBackHandler = () => {
    navigation.goBack();
  };

  useEffect(() => {
    if (northStar) {
      setNorthstar(northStar);
    }
  }, [northStar]);

  const onTitle = (text: string) => {
    setTitle(text);
  };

  const onDescription = (text: string) => {
    setDescription(text);
  };

  const onSubmit = async () => {
    try {
      if (!editref) {
        if (title && description) {
          await addMyWhy({
            title: title!,
            description: description!,
            northStar: northStarState!,
          }).unwrap();
          navigation.navigate("Notehstar");
        } else {
          alert("Please fill inputs");
        }
      } else {
        await updateMyWhy({
          id: String(myWhyIdref!),
          title: title!,
          description: description!,
        }).unwrap();
        navigation.navigate("Notehstar");
      }
    } catch (error) {}
  };

  const onDelete = async () => {
    try {
      if (editref) {
        await deleteMyWhy({
          id: String(myWhyIdref!),
        }).unwrap();
        navigation.navigate("Notehstar");
      }
    } catch (error) {}
  };

  return (
    <TouchableOpacity onPress={Keyboard.dismiss}>
      <ImageBackground
        source={images.northmywhy}
        resizeMode="cover"
        style={{
          minHeight: height,
        }}
      >
        <NorthstarBackButton goBackHandler={goBackHandler!} />

        <NorthstarMyWhyTextContainer />

        <NorthstarMyWhyPresentation
          title={title!}
          description={description!}
          onTitle={onTitle}
          onDescription={onDescription}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
      </ImageBackground>
    </TouchableOpacity>
  );
};

export default NorthstarMyWhyMainContainer;
