import React from "react";
import { View } from "react-native";
import {
  selectAdmin,
  selectDefaultAccount,
} from "../features/auth/slice/authSlice";
import { useAppSelector } from "../redux/hooks";
import { HomeScreenNavigationProps } from "../navigation/types";
import { useNavigation } from "@react-navigation/native";
import GradeHeaderPersentation from "../features/grade/presentation/GradeHeaderPersentation";
import GradeContainer from "../features/grade/container/GradeContainer";
import { useGetALearnerSettingQuery } from "../features/learnerSetting/api/learnerSetting";
import { EntityId } from "@reduxjs/toolkit";
const GradeScreen = () => {
  const accountSelected = useAppSelector(selectDefaultAccount);
  const user = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const {
    data: learnerSettingData,
    isLoading: leanerLoading,
    isSuccess: leanerSuccess,
    isError: leanerError,
  } = useGetALearnerSettingQuery(accountSelected?.project! as EntityId);
  const onGoAccountSetting = () => {
    navigation.navigate("Setting");
  };

  const onGoBack = () => {
    navigation.goBack();
  };
  return (
    <View style={{ backgroundColor: "#fff" }}>
      <GradeHeaderPersentation
        backgroundProfile={user?.backgroundBanner!}
        goAccountSetting={onGoAccountSetting}
        goBackHandler={onGoBack}
        showSetting={true}
      />
      <GradeContainer
        learnerSetting={
          learnerSettingData?.entities[learnerSettingData.ids[0]]!
        }
      />
    </View>
  );
};

export default GradeScreen;
