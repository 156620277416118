import React, { useState, FC } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Image,
  TouchableOpacity,
} from "react-native";
import PolygonShape from "../PolygonShape/PolygonShape";
import { Bag } from "../../features/bag/types/type";
import { images } from "../../constants";
// import bagBorderIcon from "../../assets/image/bagItemBorder.png";
// import raffleIcon from "../../assets/image/bagRaffle.png";
// import sadIcon from "../../assets/image/sadIcon.png";
// import happyIcon from "../../assets/image/happyIcon.png";
// import BagPopup from "./BagPopup";
// import PolygonShape from "../general/polygonShape";
// import CountDown from "../general/countDownCounter";

type BagsItemsProps = {
  item: Bag;
};

const { width } = Dimensions.get("window");

const BagsItem: FC<BagsItemsProps> = ({ item }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const getTimeStamp = (date: any) => {
    return (new Date(date).getTime() - new Date().getTime()) / 1000;
  };
  return (
    <>
      <TouchableOpacity
        style={styles.container}
        onPress={() => setOpenPopup(true)}
      >
        <View style={styles.leftSide}>
          <PolygonShape
            profileImage={item.shop?.image! || item.raffelTicket?.image!}
            teamColor="#2ED2E2"
          />
        </View>
        <View style={styles.textWrapper}>
          <Text style={styles.title}>
            {item?.shop?.title || item?.raffelTicket?.title}
          </Text>
        </View>
        <View style={styles.rightSideContainer}>
          {item?.raffelTicket &&
            new Date(item?.raffelTicket!.drawDate!) <= new Date() && (
              <>
                {item?.isWinner === true ? (
                  <Image source={images.HappyIcon} style={styles.status} />
                ) : (
                  <Image source={images.SadIcon} style={styles.status} />
                )}
              </>
            )}

          {item?.raffelTicket ? (
            <Text style={styles.timer}>
              {/* <CountDown
                size={30}
                until={getTimeStamp(item?.raffelTicket.end_date)}
                onFinish={() => {}}
                doubleDigitContStyle={{ height: 12 }}
                digitStyle={{ fontSize: 11, lineHeight: 12 }}
                countContainerStyle={{
                  maxWidth: 20,
                  flexWrap: "wrap",
                  marginTop: 12,
                }}
                digitTxtStyle={{
                  fontSize: 11,
                  lineHeight: 12,
                  color: "#3E3F5E",
                }}
                timeLabelStyle={{
                  fontSize: 11,
                  lineHeight: 12,
                  color: "#3E3F5E",
                }}
                timeToShow={["D", "H", "M"]}
                style={{}}
              /> */}
            </Text>
          ) : null}

          {item?.raffelTicket ? (
            <View style={styles.rightSide}>
              <Text style={styles.entries}>Entries</Text>
              <Image source={images.BagRaffleIcon} />
              <Text style={styles.numberOfChance}>{item.count}</Text>
            </View>
          ) : (
            <Text style={[styles.numberOfChance, styles.numberOfChanceText]}>
              {item.count}
            </Text>
          )}
        </View>
      </TouchableOpacity>
      {/* <BagPopup openPopup={openPopup} setOpenPopup={setOpenPopup} item={item} /> */}
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "94%",
    marginLeft:"3%",
    height: 61,
    borderRadius: 8,
    padding: 5,
    marginBottom: 10,
    shadowColor:"rgba(0,0,0,0.5)",
    elevation: 2,
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.6,
    shadowRadius: 3,
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 10,
    paddingLeft: 5,
  },
  imageIcon: {
    marginLeft: 11,
    marginRight: 14,
  },
  textWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",

    fontSize: 14,
  },
  title: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",

    fontSize: 14,
    fontWeight: "bold",
  },
  desc: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",

    fontSize: 10,
  },
  rightSideContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    position: "absolute",
    marginRight: 10,
    right: 0,
    height: "100%",
    width: width * 0.4,
  },
  rightSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  entries: {
    display: "flex",
    marginRight: 13,
    textAlign: "right",
    fontWeight: "bold",
  },
  numberOfChance: {
    position: "absolute",
    fontFamily: "HelveticaBold",
    fontSize: 18,
    top: 21,
  },
  numberOfChanceText: {
    fontSize: 17,
    top: 20,
    paddingRight: 10,
  },
  status: {
    display: "flex",
    marginRight: 8,
  },
  timer: {
    fontSize: 11,
    fontWeight: "bold",
    width: 30,
    marginRight: 8,
  },
});

export default BagsItem;
