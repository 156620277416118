import React, { useRef, useEffect } from "react";
import {
  TouchableOpacity,
  ImageBackground,
  View,
  StyleSheet,
  Text,
  Platform,
  Animated,
} from "react-native";
import { Shop } from "../types/types";
import { images } from "../../../constants";
import { Image } from "expo-image";
import LottieView, { AnimatedLottieViewProps } from "lottie-react-native";
import changeSVGColor from "@killerwink/lottie-react-native-color";
const isAndroid = Platform.OS === "android" ? true : false;
type ShopItemPresnetationProps = {
  shop: Shop;
  studentLevel: number;
  onPressShopItem: (item: Partial<Shop>) => void;
};
const ShopItemPresentation: React.FC<ShopItemPresnetationProps> = ({
  shop,
  studentLevel,
  onPressShopItem,
}) => {
  const progress = useRef(new Animated.Value(0)).current;
  const animation = React.createRef<LottieView>();
  useEffect(() => {
    if (animation && animation?.current) animation.current.play();
  }, []);

  return (
    <TouchableOpacity
      style={styles.imageContainer}
      onPress={() => {
        onPressShopItem(shop);
      }}
    >
      {shop._id === 5 ? (
        <ImageBackground
          source={images.revieBy}
          resizeMode="contain"
          style={styles.image}
        >
          {/* <View style={styles.LockWrapper}> */}
          {/* {studentLevel < shop.unlock_level && (
                  <View style={styles.LockContainer}>
                    <Image source={images.LockIcon} style={styles.lockImage} />
                    <Text style={styles.lockNumber}>{shop.unlock_level}</Text>
                  </View>
                )} */}
          {/* {studentLevel >= shop.unlock_level && !shop.unlockshop && ( */}
          {/* <View style={styles.LockContainer}>
                    <LottieView
                      style={{ width: 40, height: 40 }}
                      ref={animation}
                      loop
                      source={changeSVGColor(images.LockAnimation, "#fff")}
                      progress={progress}
                    />
                  </View> */}
          {/* )} */}
          {/* </View> */}

          <View style={styles.ShopContent}>
            <Text numberOfLines={1} style={styles.ShopItemName}>
              {shop.title}
            </Text>

            <View style={{ flexDirection: "row" }}>
              <View style={styles.lock}>
                {false && (
                  <>
                    <Text numberOfLines={1} style={styles.lockNumbers}>
                      {shop.current_stock || 0}
                    </Text>
                    <Text numberOfLines={1} style={styles.lockStyle}>
                      Left
                    </Text>
                  </>
                )}
                {true && (
                  <>
                    <Image source={images.infinit} style={styles.infinitIcon} />
                  </>
                )}
              </View>

              <View style={styles.ShopItemGemContainer}>
                <Image source={images.gemss} style={styles.shopItemCoinImg} />
                <Text style={styles.ShopItemCoin}>{shop.gem}</Text>
              </View>
            </View>
          </View>
        </ImageBackground>
      ) : (
        <ImageBackground
          source={{ uri: shop.image }}
          resizeMode="cover"
          style={styles.image}
        >
          <View style={styles.LockWrapper}>
            {studentLevel < shop.unlock_level && (
              <View style={styles.LockContainer}>
                <Image source={images.LockIcon} style={styles.lockImage} />
                <Text style={styles.lockNumber}>{shop.unlock_level}</Text>
              </View>
            )}
            {studentLevel >= shop.unlock_level && !shop.unlockshop && (
              <View style={styles.LockContainer}>
                <LottieView
                  style={{ width: 40, height: 40 }}
                  ref={animation}
                  loop
                  source={changeSVGColor(images.LockAnimation, "#fff")}
                  progress={progress}
                />
              </View>
            )}
          </View>

          <View style={styles.ShopContent}>
            <Text numberOfLines={1} style={styles.ShopItemName}>
              {shop.title}
            </Text>

            <View style={{ flexDirection: "row" }}>
              <View style={styles.lock}>
                {true && (
                  <>
                    <Text numberOfLines={1} style={styles.lockNumbers}>
                      {shop.current_stock || 0}
                    </Text>
                    <Text numberOfLines={1} style={styles.lockStyle}>
                      Left
                    </Text>
                  </>
                )}
                {false && (
                  <>
                    <Image source={images.infinit} style={styles.infinitIcon} />
                  </>
                )}
              </View>

              <View style={styles.ShopItemGemContainer}>
                <Image source={images.gemss} style={styles.shopItemCoinImg} />
                <Text style={styles.ShopItemCoin}>{shop.gem}</Text>
              </View>
            </View>
          </View>
        </ImageBackground>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  infinitIcon: {
    width: 16,
    height: 8,
    marginLeft: 5,
  },
  ShopItemCoin: {
    color: "#23AAE1",
    fontSize: 17,
    lineHeight: 17,
    fontFamily: "HelveticaBold",
  },
  ShopItemGemContainer: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#23AAE1",
    flexDirection: "row-reverse",
    width: 53,
    height: 28,
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: 5,
    marginBottom: 4,
  },
  shopItemCoinImg: {
    width: 19,
    height: 18,
    alignSelf: "center",
  },

  ShopItemName: {
    fontSize: 12,
    fontFamily: "HelveticaBold",
    color: "#000",
    paddingBottom: 3,
    paddingTop: 3,
    textAlign: "center",
  },
  lockNumber: {
    position: "absolute",
    top: "35%",
    left: "20%",
    color: "#34C38F",
    fontSize: 15,
    width:22,
    textAlign:"center",
    fontFamily: "RajdahaniBold",
  },
  LockWrapper: {
    width: 52,
    height: 52,
    borderRadius: 6,
    position: "relative",
  },
  LockContainer: {
    width: 37,
    height: 37,
    borderBottomRightRadius: 10,
    backgroundColor: "#34C38F",
    position: "relative",
  },
  lockImage: {
    width: 37,
    height: 37,
    borderBottomRightRadius: 10,
    backgroundColor: "#34C38F",
    position: "relative",
  },
  lockNumbers: {
    fontSize: 9,
    color: "#000",
    fontFamily: "HelveticaBold",
    lineHeight: 10,
  },
  lockStyle: {
    fontSize: 9,
    color: "#000",
    marginLeft: 3,
    lineHeight: 10,
    fontFamily: "HelveticaBold",
  },
  image: {
    width: "100%",
    height: 167,
    position: "relative",
    overflow: "hidden",
    borderRadius: 8,
    borderWidth: 3,

    borderColor: "#000",
    backgroundColor: "#000",
    
  },
  ShopContent: {
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    position: "absolute",
    bottom: -3,
    right: -3,
    backgroundColor: "#fff",
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 4,
    borderTopLeftRadius: 15,
    width: "95%",
  },
  imageContainer: {
    width: "31%",
    marginTop: 10,
    marginBottom: 5,
    borderRadius: 8,
    overflow: "hidden",
    marginLeft: 7,
    shadowColor: "#000",
    zIndex: 2,
    backgroundColor: "#fff",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.14,
    shadowRadius: 3,
    elevation: 1,
    padding: 5,
  },
  userContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    height: 40,
    marginBottom: 6,
  },
  lock: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    width: 30,
    paddingBottom: 2,
    paddingLeft: 2,
    flexWrap: "wrap",
    borderColor: "#179300",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 4,
    marginRight: 4,
    marginBottom: 4,
  },
});

export default ShopItemPresentation;
