import React from "react";
import { View, Text, FlatList } from "react-native";
import { ExperienceCard } from "../types/types";
import { Image } from "expo-image";
import { EntityState } from "@reduxjs/toolkit";
import ExperienceItemPresentation from "./ExperienceItemPresentation";
import { GradeReward } from "../../gradeReward/types/GradeReward";

type ExperienceListPresentationProp = {
  cards: EntityState<ExperienceCard>;
  onItemClick: (card: Partial<ExperienceCard>) => void;
  openDetailsHandler: () => void;
  passDue?: boolean;
  padding?: string | any;
  complete?: boolean;
  onOpenReviePopupMission?: (item: Partial<ExperienceCard>) => void;
  gradeCourseTotal?: GradeReward[] | null;
  disableLoading?: boolean;
  onDisableLoading?: () => void;
  onDisableLoadingOff?: () => void;
};
const ExperienceListPresentation: React.FC<ExperienceListPresentationProp> = ({
  cards,
  onItemClick,
  openDetailsHandler,
  passDue,
  complete,
  padding,
  onOpenReviePopupMission,
  gradeCourseTotal,
  disableLoading,
  onDisableLoading,
  onDisableLoadingOff,
}) => {
  const renderExperienceItem = (item: ExperienceCard) => {
    if (item.experienceType.title === "Events") {
      return (
        <ExperienceItemPresentation
          item={item}
          onItemClick={onItemClick}
          openDetailsHandler={openDetailsHandler}
          passDue={passDue}
          complete={complete}
          disableLoading={disableLoading!}
          onDisableLoading={onDisableLoading!}
          onDisableLoadingOff={onDisableLoadingOff!}
        />
      );
    }
    if (
      (item.experienceType.title === "Gradegy" ||
        item.experienceType.title === "Mission" ||
        item.experienceType.title === "HotSpot") &&
      new Date().getTime() > new Date(item.startDate).getTime()
    ) {
      return (
        <ExperienceItemPresentation
          item={item}
          onItemClick={onItemClick}
          openDetailsHandler={openDetailsHandler}
          passDue={passDue}
          complete={complete}
          onOpenReviePopupMission={onOpenReviePopupMission}
          disableLoading={disableLoading!}
          onDisableLoading={onDisableLoading!}
          onDisableLoadingOff={onDisableLoadingOff!}
        />
      );
    }

    return <></>;
  };
  return (
    <View style={{ flex: 1, paddingBottom: padding, maxWidth:550 }}>
      <FlatList
        data={Object.values(cards!.entities!) as ExperienceCard[]}
        keyExtractor={(item) => `${item._id!}`}
        ListHeaderComponent={
          <FlatList
            data={gradeCourseTotal}
            keyExtractor={(item) => `${item.id}`}
            renderItem={({ item }) => (
              <ExperienceItemPresentation
                item={null}
                itemGrade={item!}
                onItemClick={onItemClick}
                openDetailsHandler={openDetailsHandler}
                passDue={passDue}
                complete={complete}
                onOpenReviePopupMission={onOpenReviePopupMission}
                disableLoading={disableLoading!}
                onDisableLoading={onDisableLoading!}
                onDisableLoadingOff={onDisableLoadingOff!}
              />
            )}
          />
        }
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{}}
        style={{ marginBottom: 30, flex: 1 }}
        renderItem={({ item }) => <>{renderExperienceItem(item)}</>}
      />
    </View>
  );
};

export default ExperienceListPresentation;
