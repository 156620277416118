import React, { useState } from "react";
import FeedDetailContainer from "../features/feedDetail/container/FeedDetailContainer";
import { useRoute } from "@react-navigation/native";
import { FeedScreenDetailsProp } from "../navigation/types";
import { useGetAllPhotosQuery } from "../features/cameraFeatures/api/experiencePhotoApiSlice";
import { selectDefaultAccount } from "../features/auth/slice/authSlice";
import { useAppSelector } from "../redux/hooks";
import { ExperiencePhoto } from "../features/cameraFeatures/types/types";
import { SafeAreaView, StatusBar } from "react-native";
import { useGetStudentPostQuery } from "../features/badge/api/badgesApi";

const FeedDetailScreen = () => {
  const route = useRoute<FeedScreenDetailsProp>();
  const account = useAppSelector(selectDefaultAccount);
  const { photo, photos, student, open } = route.params;

  const {
    data: photosList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllPhotosQuery({
    currentSystem: JSON.stringify(account!),
    sort: "-updatedAt",
  });
  const {
    data: photosStudent,
    isLoading: studentPostLoading,
    isError: studentPostError,
    isSuccess: studentSuccess,
  } = useGetStudentPostQuery({
    student: String(student?._id!),
    currentsystemstring: JSON.stringify(account!),
  });

  return (
    <>
      {isSuccess && photosList && (
        <>
          <StatusBar hidden />

          <FeedDetailContainer
            photo={photo}
            photoList={Object.values(photosList.entities) as ExperiencePhoto[]}
            photos={photosStudent}
            student={student}
            openComment={open}
          />
        </>
      )}
    </>
  );
};

export default FeedDetailScreen;
