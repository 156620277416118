import React from "react";
import { useAppSelector } from "../redux/hooks";
import {
  selectAdmin,
  selectDefaultAccount,
} from "../features/auth/slice/authSlice";
import ProfileSettingHeaderPresentation from "../features/profileSetting/presentation/ProfileSettingHeaderPresentation";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  BadgesScreenRouteProp,
  HomeScreenNavigationProps,
} from "../navigation/types";
import BadgesContainer from "../features/badge/container/BadgesContainer";
import { useGetStudentPostQuery } from "../features/badge/api/badgesApi";

const BadgesScreen = () => {
  const user = useAppSelector(selectAdmin);
  const systemSelected = useAppSelector(selectDefaultAccount);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const route = useRoute<BadgesScreenRouteProp>();
  const { student } = route.params;

  const {
    data: studentPost,
    isLoading,
    isError,
    isSuccess,
  } = useGetStudentPostQuery({
    student: String(student!._id!),
    currentsystemstring: JSON.stringify(systemSelected),
  });

  const onGoAccountSetting = () => {
    navigation.navigate("Setting");
  };

  const onGoBack = () => {
    navigation.goBack();
  };
  return (
    <>
      <ProfileSettingHeaderPresentation
        backgroundProfile={student?.backgroundBanner!}
        goAccountSetting={onGoAccountSetting}
        goBackHandler={onGoBack}
        showSetting={true}
      />
      <BadgesContainer student={student} photolist={studentPost} />
    </>
  );
};

export default BadgesScreen;
