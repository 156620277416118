import { StatusBar } from "expo-status-bar";
import { ImageBackground, Platform, Text, View } from "react-native";
import StyleSheet from "react-native-media-query";
import { Provider } from "react-redux";
import { store } from "./src/redux/store";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NavigationContainer } from "@react-navigation/native";
import Auth from "./src/screens/Auth";
import { getAdminStatus } from "./src/features/auth/authThunks/authThunks";
import { AuthNavgigationParamList } from "./src/navigation/types";
import LoginScreen from "./src/screens/LoginScreen";
import HomeScreen from "./src/screens/HomeScreen";
import { useFonts } from "expo-font";
import Tabs from "./src/navigation/tabs";
import ProfileSettingScreen from "./src/screens/ProfileSettingScreen";
import AcountSettingScreen from "./src/screens/AcountSettingScreen";
import SettingScreen from "./src/screens/SettingScreen";
import SwitchAccontScreen from "./src/screens/SwitchAccontScreen";
import CameraScreeen from "./src/screens/CameraScreeen";
import PreviewPictrueScreen from "./src/screens/PreviewPictrueScreen";
import ProfileAccountSettingScreen from "./src/screens/ProfileAccountSettingScreen";
import GradeScreen from "./src/screens/GradeScreen";
import PrizesScreen from "./src/screens/PrizesScreen";
import React, { useEffect, useState, useRef } from "react";
import HeaderBack from "./src/components/HeaderBack/HeaderBack";
import HeaderSetting from "./src/components/HeaderSetting/HeaderSetting";
import FriendsScreen from "./src/screens/FriendsScreen";
import BadgesScreen from "./src/screens/BadgesScreen";
import BadgeSystemScreen from "./src/screens/BadgeSystemScreen";
import TeamsAndScoreScreen from "./src/screens/TeamsAndScoreScreen";
import NorthstarScreen from "./src/screens/NorthstarScreen";
import NorthstarMyWhyScreen from "./src/screens/NorthstarMyWhyScreen";
import YouthScreen from "./src/screens/YouthScreen";
import BagScreen from "./src/screens/BagScreen";
import PermissionNotificationScrren from "./src/screens/PermissionNotificationScreen";
import PrizeWheelScreen from "./src/screens/PrizeWheelScreen";
import TermAndConditionScreen from "./src/screens/TermAndConditionScreen";
import BoardingSocialScreen from "./src/screens/BoardingSocialScreen";
import ContactScreen from "./src/screens/ContactScreen";
import GenderScreen from "./src/screens/GenderScreen";
import PronounScreen from "./src/screens/PronoumScreen";
import EthnicityScreen from "./src/screens/EthnicityScreen";
import FirstGenScreen from "./src/screens/FirstGenScreen";
import WorkingScreen from "./src/screens/WorkingScreen";
import WorkingWhereScreen from "./src/screens/WorkingWhereScreen";
import TeamSelectScreen from "./src/screens/TeamSelectScreen";
import ConfirmScreen from "./src/screens/ConfirmScreen";
import LoadingScreen from "./src/components/LoadingScreen/LoadingScreen";
import FeedDetailScreen from "./src/screens/FeedDetailScreen";
import NotificationCenterScreen from "./src/screens/NotificationCenter";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import PrizeWheelFortuneScreen from "./src/screens/PrizeWheelFortuneScreen";
import * as TaskManager from "expo-task-manager";
import EventLoadingScreen from "./src/screens/EventLoadingScreen";
import { images } from "./src/constants";

const Stack = createNativeStackNavigator<AuthNavgigationParamList>();

export default function App() {
  const [fontloaded] = useFonts({
    "Roboto-Black": require("./assets/fonts/Roboto-Black.ttf"),
    "Roboto-BlackItalic": require("./assets/fonts/Roboto-BlackItalic.ttf"),
    "Roboto-Bold": require("./assets/fonts/Roboto-Bold.ttf"),
    "Roboto-BoldItalic": require("./assets/fonts/Roboto-BoldItalic.ttf"),
    "Roboto-Italic": require("./assets/fonts/Roboto-Italic.ttf"),
    "Roboto-Light": require("./assets/fonts/Roboto-Light.ttf"),
    "Roboto-LightItalic": require("./assets/fonts/Roboto-LightItalic.ttf"),
    "Roboto-Medium": require("./assets/fonts/Roboto-Medium.ttf"),
    "Roboto-MediumItalic": require("./assets/fonts/Roboto-MediumItalic.ttf"),
    "Roboto-Regular": require("./assets/fonts/Roboto-Regular.ttf"),
    "Roboto-Thin": require("./assets/fonts/Roboto-Thin.ttf"),
    "Roboto-ThinItalic": require("./assets/fonts/Roboto-ThinItalic.ttf"),
    "RobotoCondensed-Bold": require("./assets/fonts/RobotoCondensed-Bold.ttf"),
    "RobotoCondensed-BoldItalic": require("./assets/fonts/RobotoCondensed-BoldItalic.ttf"),
    "RobotoCondensed-Italic": require("./assets/fonts/RobotoCondensed-Italic.ttf"),
    "RobotoCondensed-Light": require("./assets/fonts/RobotoCondensed-Light.ttf"),
    "RobotoCondensed-LightItalic": require("./assets/fonts/RobotoCondensed-LightItalic.ttf"),
    "RobotoCondensed-Regular": require("./assets/fonts/RobotoCondensed-Regular.ttf"),
    HelveticaBold: require("./assets/fonts/Helvetica-Bold-Font.ttf"),
    RajdahaniMedium: require("./assets/fonts/Rajdhani-Medium.ttf"),
    RajdahaniBold: require("./assets/fonts/Rajdhani-Bold.ttf"),
  });

  useEffect(() => {
    store.dispatch(getAdminStatus());
  }, []);

  if (!fontloaded) {
    return (
      <View>
        <LoadingScreen />
      </View>
    );
  }

  return (
    <View style={{flex: 1}}>
    <ImageBackground
      source={images.webBack}
      style={(Platform.OS === "web") ? styles.backProfileImageWeb : styles.backProfileImage}
      resizeMode="stretch"
    >

    <GestureHandlerRootView style={{ flex:1, maxWidth:580, width:"100%" }}>
      <Provider store={store}>
        <NavigationContainer>
          <Stack.Navigator
            screenOptions={{
              headerShown: true,
            }}
            initialRouteName="Auth"
          >
            <Stack.Screen
              name="Auth"
              component={Auth}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="Login"
              component={LoginScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Home"
              component={Tabs}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="ProfileSetting"
              component={ProfileSettingScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="AccountSetting"
              component={AcountSettingScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Setting"
              component={SettingScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="SwitchAccount"
              component={SwitchAccontScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Camera"
              component={CameraScreeen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="PreviewPicture"
              component={PreviewPictrueScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="ProfileAccountSetting"
              component={ProfileAccountSettingScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="GradeScreen"
              component={GradeScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Prizes"
              component={PrizesScreen}
              options={{
                headerTransparent: true,
                title: "",
                headerLeft: () => <HeaderBack />,
                headerRight: () => <HeaderSetting />,
              }}
            />
            <Stack.Screen
              name="Friend"
              component={FriendsScreen}
              options={{
                headerShown: false,
              }}
            />

            <Stack.Screen
              name="FeedDetail"
              component={FeedDetailScreen}
              options={{
                headerShown: false,
              }}
            />

            <Stack.Screen
              name="Badges"
              component={BadgesScreen}
              options={{
                headerShown: false,
              }}
            />

            <Stack.Screen
              name="BadgeSystem"
              component={BadgeSystemScreen}
              options={{
                headerShown: false,
              }}
            />

            <Stack.Screen
              name="Notehstar"
              component={NorthstarScreen}
              options={{
                headerShown: false,
              }}
            />

            <Stack.Screen
              name="NotehstarMyWhy"
              component={NorthstarMyWhyScreen}
              options={{
                headerShown: false,
              }}
            />

            <Stack.Screen
              name="youthScreen"
              component={YouthScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="TeamsScore"
              component={TeamsAndScoreScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Bag"
              component={BagScreen}
              options={{
                headerTransparent: true,
                title: "",
                headerLeft: () => <HeaderBack />,
                headerRight: () => <HeaderSetting />,
              }}
            />
            <Stack.Screen
              name="PermissionNotification"
              component={PermissionNotificationScrren}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="PrizeWheel"
              component={PrizeWheelScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="TermAndCondition"
              component={TermAndConditionScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Social"
              component={BoardingSocialScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Contact"
              component={ContactScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Gender"
              component={GenderScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Pronoun"
              component={PronounScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Ethnicity"
              component={EthnicityScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="FirstGen"
              component={FirstGenScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Working"
              component={WorkingScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="WorkingWhere"
              component={WorkingWhereScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="TeamSelect"
              component={TeamSelectScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="Confirm"
              component={ConfirmScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="NotificationCenter"
              component={NotificationCenterScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="PrizeWheelFortune"
              component={PrizeWheelFortuneScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="EventLoading"
              component={EventLoadingScreen}
              options={{
                headerShown: false,
              }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </Provider>
    </GestureHandlerRootView>
    </ImageBackground>
    </View>
  );
}

const { styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  backProfileImageWeb:{
    flex:1,
   padding:0,
    justifyContent: "center",
    alignItems:"center",
  
  },
  backProfileImage:{
    flex:1,

    justifyContent: "center",
    alignItems:"center",
  
  }
});
