import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { useGetAllPhotosQuery } from "../../cameraFeatures/api/experiencePhotoApiSlice";
import { EntityId } from "@reduxjs/toolkit";
import FeedPresentation from "../presentation/FeedPresentation";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import PostFeedPopup from "../presentation/PostFeedPopup";
import { useAddLikeMutation } from "../../review/api/reviewApi";
import { useGetAllActiveExperienceQuery } from "../../experience/api/experienceApiSlice";
import ExperienceDetailsPresentation from "../../experience/presentation/ExperienceDetailsPresentation";
import { ExperienceCard } from "../../experience/types/types";
import { useEditOrAddExperienceCompletionForStudentMutation } from "../../experienceComplete/api/experienceCompleteApiSlice";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  FeedScreenDetailsProp,
  HomeScreenNavigationProps,
} from "../../../navigation/types";
import { Linking } from "react-native";
import { useFriendsListQuery } from "../../friend/api/friendsApiSlice";
import { AuthUser } from "../../auth/types/types";
import FriendItemPopup from "../presentation/FriendItemPopup";
import * as webBrowser from "expo-web-browser";

const FeedContainer = () => {
  const navigation = useNavigation<HomeScreenNavigationProps>();

  const account = useAppSelector(selectDefaultAccount);
  const {
    data: friendsList,
    isLoading: friendLoading,
    isError: friendError,
    isSuccess: friendSuccess,
  } = useFriendsListQuery({
    currentSystem: JSON.stringify(account!),
  });

  const [
    editOrAddExperienceCompletionForStudent,
    { isLoading: loadingExp, isError: erroExp, isSuccess: successExp },
  ] = useEditOrAddExperienceCompletionForStudentMutation();
  const {
    data: photosList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllPhotosQuery({
    currentSystem: JSON.stringify(account!),
    sort: "-updatedAt",
  });

  const {
    data: experienceActiveList,
    isLoading: experineceLoading,
    isError: experienceError,
    isSuccess: experienceSuccess,
  } = useGetAllActiveExperienceQuery({
    currentSystem: JSON.stringify(account!),
  });

  const [
    addLike,
    { isLoading: addLoading, isError: addError, isSuccess: addSuccess },
  ] = useAddLikeMutation();

  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [experienceItem, setExperienceItem] =
    useState<Partial<ExperienceCard> | null>(null);
  const [postItem, setPostItem] = useState<Partial<ExperiencePhoto> | null>(
    null
  );
  const [friendItem, setFriendItem] = useState<Partial<AuthUser> | null>(null);

  const [openFriendPopup, setOpenFrienPopup] = useState<boolean>(false);
  const [friendXp, setFriendXp] = useState<number>(0);
  const [completeLoading, setCompleteLoading] = useState<boolean>(false);
  const [disableComplete, setDisableComplete] = useState<boolean>(false);

  const onPhotoClick = (item: Partial<ExperiencePhoto> | null) => {
    setOpenPopup(true);
    setPostItem(item);
  };

  useEffect(() => {}, [friendsList]);

  const onClose = () => {
    setOpenPopup(false);
  };

  const applyLike = async (item: Partial<ExperiencePhoto>) => {
    try {
      const result = await addLike({
        photo: item!,
      }).unwrap();
      let itemss: any = [];
      const likeStatus = result.entities[result.ids[0]]?.like;
      if (likeStatus) {
        let newReview = item.reviews![0].likeCount + 1;
        itemss = [
          {
            likeCount: newReview,
          },
        ];
      } else {
        let newReview = item.reviews![0].likeCount - 1;
        itemss = [
          {
            likeCount: newReview,
          },
        ];
      }
      setPostItem({
        ...postItem,
        like: result.entities[result.ids[0]],
        reviews: itemss,
      });
    } catch (error) {}
  };

  const onOpenDetails = (item: Partial<ExperienceCard>) => {
    setExperienceItem(item);
    setOpenDetails(true);
  };

  const onExperienceClose = () => {
    setOpenDetails(false);
  };

  const completeHandler = async () => {
    setCompleteLoading(true);
    setDisableComplete(true);

    if (experienceItem!.completionType!.title === "Student") {
      try {
        const list = await editOrAddExperienceCompletionForStudent({
          experience: experienceItem!,
          complete: true,
          currentSystem: account!,
        }).unwrap();
      } catch (error) {}
    } else if (experienceItem!.completionType!.title === "Photo") {
      onExperienceClose();
      navigation.navigate("Camera", {
        item: experienceItem!,
        currentSystem: account!,
      });
    } else if (experienceItem!.completionType?.title === "Link") {
      try {
        const list = await editOrAddExperienceCompletionForStudent({
          experience: experienceItem!,
          complete: true,
          currentSystem: account!,
        }).unwrap();

        await webBrowser.openBrowserAsync(experienceItem!.button_link_url!);
      } catch (error) {}
    } else if (experienceItem!.completionType!.title === "Admin") {
      try {
        const list = await editOrAddExperienceCompletionForStudent({
          experience: experienceItem!,
          complete: true,
          currentSystem: account!,
        }).unwrap();
      } catch (error) {}
    }
    setCompleteLoading(false);
    setDisableComplete(false);
    onExperienceClose();
  };

  const onOpenFriendPopup = (friend: Partial<AuthUser>) => {
    setFriendItem(friend);
    const projectrep = friend.projectReport?.find(
      (item) => item.project === account?.project
    );
    if (projectrep) {
      setFriendXp(projectrep.xps);
    }
    setOpenFrienPopup(true);
  };

  const onCloseFriendPopup = () => {
    setOpenFrienPopup(false);
  };

  const onFriendItemClick = (student: Partial<AuthUser>) => {
    navigation.navigate("Badges", {
      student: student!,
    });
  };

  const onPhotoCommentClick = (experiencePhoto: Partial<ExperiencePhoto>) => {
    navigation.navigate("FeedDetail", {
      photo: experiencePhoto!,
    });
  };
  const onItemClickHanlder = async (card: Partial<ExperienceCard>) => {
    // setItem(card);
    // setRevive(false);

    try {
      const response = await editOrAddExperienceCompletionForStudent({
        experience: card!,
        complete: false,
        currentSystem: account!,
      }).unwrap();
    } catch (error) {}
  };

  return (
    <>
      {photosList && experienceActiveList && friendsList && (
        <>
          <FeedPresentation
            photosList={photosList}
            onPhotoClick={onPhotoClick}
            applyLike={applyLike}
            experienceCards={experienceActiveList!}
            onOpenDetails={onOpenDetails}
            friendsList={friendsList!}
            onOpenFriendPopup={onOpenFriendPopup}
            onFriendItemClick={onFriendItemClick}
            onPhotoCommentClick={onPhotoCommentClick}
            onItemClickHanlder={onItemClickHanlder}
          />

          <PostFeedPopup
            openPopup={openPopup}
            onClose={onClose}
            item={postItem}
            applyLike={applyLike}
          />
          {openDetails && (
            <ExperienceDetailsPresentation
              open={openDetails}
              item={experienceItem}
              onClose={onExperienceClose!}
              completeHandler={completeHandler}
              disableComplete={disableComplete}
              completeLoading={completeLoading}
            />
          )}

          {openFriendPopup && (
            <FriendItemPopup
              friend={friendItem!}
              openPopup={openFriendPopup}
              onClose={onCloseFriendPopup}
              friendXp={friendXp}
            />
          )}
        </>
      )}
    </>
  );
};

export default FeedContainer;
