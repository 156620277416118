import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { FlatList, View, Text } from "react-native";
import { Bag } from "../types/type";
import BagsItem from "../../../components/bagsitem/BagsItem";
type AllBagPrsentationProps = {
  items: EntityState<Bag>;
};
const AllBagPresentation: React.FC<AllBagPrsentationProps> = ({ items }) => {
  return (
    <FlatList
      data={Object.values(items!.entities!) as Bag[]}
      numColumns={1}
      keyExtractor={(item) => `${item.id}`}
      keyboardDismissMode="on-drag"
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{}}
      renderItem={({ item }) => <BagsItem item={item} />}
      ListFooterComponent={
        <View
          style={{
            marginBottom: 150,
          }}
        ></View>
      }
    />
  );
};

export default AllBagPresentation;
