import React, { FC } from 'react';
import { View, Animated, Easing } from 'react-native';

import StyleSheet from "react-native-media-query";
import images from '../../constants/images';
type LoadingScreenProps = {
  height?: string;
  iconSize?: string | number;
};

const LoadingScreen: FC<LoadingScreenProps> = ({ height, iconSize }) => {
  const spinValue = new Animated.Value(1);
  Animated.loop(
    Animated.timing(
      spinValue,
      {
        toValue: 0,
        duration: 3000,
        easing: Easing.linear,
        useNativeDriver: true
      }
    )
  ).start();


  // Next, interpolate beginning and end values (in this case 0 and 1)
  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  })
  return (
    <View style={[{ height: height, alignContent: "center", justifyContent: "center", alignSelf: "center" }, styles.container]}>
      <Animated.Image
        style={{
          width: iconSize ? iconSize : 150,
          height: iconSize ? iconSize : 150,
          transform: [{ rotate: spin }],
        }}
        source={images.GradifyLoading}
      />
    </View>
  )
}
const { styles } = StyleSheet.create({
  container: {
    "@media (max-width: 350px)": {
      transform: [{ scale: 0.65 }],
    },
  },
});

export default LoadingScreen;


