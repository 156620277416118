import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { MyWhy } from "./type";
import { InitialState } from "../../notehstar/api/northStar.api";

export const myWhyAdaptor = createEntityAdapter<MyWhy>({
  selectId: (myWhy) => myWhy._id,
});

export interface IMyWhy extends EntityState<MyWhy> {}

export const initialState: IMyWhy = myWhyAdaptor.getInitialState();

export const myWhyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addMyWhy: builder.mutation<
      EntityState<MyWhy>,
      {
        title: string;
        description: string;
        northStar: EntityId;
      }
    >({
      query: (data) => ({
        url: "/northstar-mywhy",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: MyWhy) => {
        return myWhyAdaptor.addOne(initialState, response);
      },
      invalidatesTags: ["MyWhy", "NorhtStar"],
    }),

    getMyWhy: builder.query<
      EntityState<MyWhy>,
      {
        northStar: string;
      }
    >({
      query: (northStar) => `/northstar-mywhy?northStar=${northStar.northStar}`,
      transformResponse: (response: MyWhy[]) => {
        return myWhyAdaptor.setAll(initialState, response);
      },
      providesTags: ["MyWhy", "NorhtStar"],
    }),

    updateMyWhy: builder.mutation<
      EntityState<MyWhy>,
      {
        id: string;
        title: string;
        description: string;
      }
    >({
      query: (data) => ({
        url: `/northstar-mywhy?id=${data.id}&title=${data.title}&description=${data.description}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: MyWhy) => {
        return myWhyAdaptor.updateOne(initialState, {
          changes: response,
          id: response._id,
        });
      },
      invalidatesTags: ["MyWhy", "NorhtStar"],
    }),

    deleteMywhy: builder.mutation<
      EntityState<MyWhy>,
      {
        id: string;
      }
    >({
      query: (data) => ({
        url: `/northstar-mywhy?id=${data.id}`,
        method: "Delete",
        body: data,
      }),
      transformResponse: (response: MyWhy) => {
        return myWhyAdaptor.removeOne(initialState, response!._id);
      },
      invalidatesTags: ["NorhtStar", "MyWhy"],
    }),
  }),
});

export const {
  useAddMyWhyMutation,
  useGetMyWhyQuery,
  useLazyGetMyWhyQuery,
  useUpdateMyWhyMutation,
  useDeleteMywhyMutation,
} = myWhyApi;
