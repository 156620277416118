import React, { FC, useEffect, useState } from "react";
import {
  ScrollView,
  View,
  ImageBackground,
  Image,
  Text,
  TouchableOpacity,
  Dimensions,
  Pressable,
} from "react-native";

import { useNavigation } from "@react-navigation/native";

import StyleSheet from "react-native-media-query";

import { HomeScreenNavigationProps } from "../navigation/types";
import { images } from "../constants";

import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { updateUser } from "../features/auth/authThunks/authThunks";
import { selectAdmin } from "../features/auth/slice/authSlice";
import { studentDetails } from "../features/onBoarding/slice/onBoardingSlice";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
const { height } = Dimensions.get("window");

const EventLoadingScreen = () => {
  const details = useAppSelector(studentDetails);
  const admin = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <ScrollView style={styles.container}>
      <ImageBackground
      resizeMode="cover"
        source={images.eventBack}
        style={styles.backgroundImage}
      >
        <View style={styles.LogoContainer}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image source={images.AuthBack} />
          </TouchableOpacity>
          <Image source={images.AuthLog} />
        </View>

        <View style={styles.content}>
          <Text style={styles.gradify}>Thrive with Youth Breakfast</Text>
          {loading && <LoadingScreen  iconSize={100}/>}
          <Text style={styles.gradify}>Are you ready to help build the future for our youth?</Text>
        </View>

        <Pressable
          onPress={() => {
            dispatch(
              updateUser({
                ...admin!,
                ...details,
                team: details?.team as any,
                confirm: true,
              })
            );

            navigation.navigate("Auth");
          }}
          style={
            true ? [styles.buttonNext, styles.buttonAction] : styles.buttonNext
          }
          disabled={true ? false : true}
        >
          <Text style={styles.nextButtonText}>Yes!</Text>
        </Pressable>
      </ImageBackground>
    </ScrollView>
  );
};

const { styles } = StyleSheet.create({
  nextButtonText: {
    color: "white",
    fontSize: 32,

    "@media (max-width: 360px)": {
      paddingBottom: 10,
      fontSize: 20,
    },
  },
  buttonNext: {
    width: 240,
    height: 45,
    borderRadius: 8,
    backgroundColor: "#B1ADAD",
    marginTop: -10,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    "@media (max-width: 360px)": {
      marginBottom: "4%",
      width: 200,
      height: 30,
      marginTop: "4%",
    },
  },
  buttonAction: {
    backgroundColor: "#5CFF88",
  },
  teamContainer: {
    height: 130,
    position: "relative",
    width: "100%",
    marginBottom: "5%",
    "@media (max-width: 360px)": {
      height: 88,
      marginBottom: 5,
    },
  },
  profilePic: {
    top: -48,
    transform: [{ scale: 0.64 }],
    left: 0,
    zIndex: 3,
    position: "absolute",
    "@media (max-width: 360px)": {
      transform: [{ scale: 0.4 }],
      top: -75,
    },
  },
  textInput: {
    marginBottom: 15,
  },
  contentContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  teamGradient: {
    width: "80%",
    height: "18%",
    minHeight: 130,
    borderRadius: 30,
    right: "-15%",
    marginBottom: 6,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
    "@media (max-width: 360px)": {
      height: "10%",
      minHeight: 70,
    },
  },
  teamGradientText: {
    fontSize: 40,
    color: "white",

    zIndex: 2,
    "@media (max-width: 360px)": {
      fontSize: 25,
    },
  },

  container: {
    flex: 1,
    minHeight: height,
  },
  content: {
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: -10,
    alignSelf: "center",
    width:"96%",
    borderRadius:8,
    height: height - 250,

  },

  title: {
    color: "#fff",
  },
  backgroundImage: {
    flex: 1,
    minHeight: height,
    width: "100%",
    alignItems: "center",
  },

  image: {
    width: 39,
    height: 35,
    marginTop: 30,
    alignItems: "flex-end",
    marginRight: 20,
  },
  LogoContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 20,
    paddingTop: 40,
    "@media (max-width: 360px)": {
      paddingTop: 30,
      paddingBottom: 10,
    },
  },
  gradify: {
    color: "#fff",
    backgroundColor:"rgba(0,0,0,0.6)",
    fontFamily: "HelveticaBold",
    borderRadius:8,
    shadowColor: "#000",
    shadowOffset: {
      width: 10,
      height: 5,
    },
    shadowOpacity: 0.3,
    shadowRadius: 20.84,
    width:"96%",
    overflow:"hidden",
    marginLeft:"2%",
    letterSpacing:0,
    fontSize:33,
    textAlign:"center",
    fontWeight:"600",
    padding:6,
    paddingBottom:15,
    paddingTop:15
  },
  desc: {
    color: "#fff",
    fontSize: 18,
    marginTop: 19,
    maxWidth: 350,
    textAlign: "center",
    marginBottom: "5%",
    "@media (max-width: 360px)": {
      fontSize: 15,
      marginTop: 5,
    },
  },

  progressContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "10%",
  },
  progressBar: {
    height: 31,
    width: "100%",
    backgroundColor: "#b1adad",
    borderRadius: 20,
    minWidth: 230,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    "@media (max-width: 360px)": {
      height: 25,
      minWidth: 200,
    },
  },
  progressValue: {
    color: "#fff",
    fontSize: 25,
    marginLeft: "4%",
    "@media (max-width: 360px)": {
      fontSize: 20,
    },
  },
});

export default EventLoadingScreen;
