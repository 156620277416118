import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { AuthUser } from "../../auth/types/types";
import { FlatList, Text, View } from "react-native";
import FriendsItemsFeeds from "./FriendItemPresentation";
type FriendLIstFeedPresentationProps = {
  friendList: EntityState<AuthUser>;
  onOpenFriendPopup: (friend: Partial<AuthUser>) => void;
  onFriendItemClick: (student: Partial<AuthUser>) => void;
};
const FriendListFeedPresentation: React.FC<FriendLIstFeedPresentationProps> = ({
  friendList,
  onOpenFriendPopup,
  onFriendItemClick,
}) => {
  return (
    <View
      style={{
        paddingLeft: 10,
      }}
    >
      <Text
        style={{
          fontSize: 15,
          fontFamily: "HelveticaBold",
          marginTop: 6,
          marginBottom: 6,
          paddingLeft: 10,
        }}
      >
        Friends
      </Text>
      <FlatList
        data={Object.values(friendList!.entities!) as AuthUser[]}
        keyExtractor={(item) => `${item._id}`}
        keyboardDismissMode="on-drag"
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{}}
        renderItem={({ item }) => (
          <>
            <FriendsItemsFeeds
              item={item}
              onOpenFriendPopup={onOpenFriendPopup}
              onFriendItemClick={onFriendItemClick}
            />
          </>
        )}
      />
    </View>
  );
};

export default FriendListFeedPresentation;
