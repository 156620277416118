import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { NorthStar } from "./types";
import { Project } from "../../auth/types/types";
import { initialState } from "../../onBoarding/slice/onBoardingSlice";

export const northStarAdaptor = createEntityAdapter<NorthStar>({
  selectId: (northStar) => northStar._id,
});

export interface INorthStar extends EntityState<NorthStar> {}

export const InitialState: INorthStar = northStarAdaptor.getInitialState();

export const northStarApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNorthStar: builder.mutation<
      EntityState<NorthStar>,
      {
        amount: number;
        unit: string;
        title: string;
        project: Project;
      }
    >({
      query: (data) => ({
        url: `/northstar`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response: NorthStar) => {
        return northStarAdaptor.addOne(InitialState, response);
      },
      invalidatesTags: ["NorhtStar"],
    }),

    getNorthStar: builder.query<EntityState<NorthStar>, void>({
      query: () => `/northstar`,
      transformResponse: (response: NorthStar) => {
        return northStarAdaptor.setOne(InitialState, response);
      },
      providesTags: ["NorhtStar"],
    }),
  }),
});

export const { useAddNorthStarMutation, useGetNorthStarQuery } = northStarApi;
