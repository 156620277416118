import { Dictionary, EntityState, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "../../../redux/api/apiSlice";
import { UnlockShop } from "../types/type";
import { Shop } from "../../shop/types/types";
import { extendedShopApi, shopAdaptor } from "../../shop/api/shopApi";

const unLockShopAdaptor = createEntityAdapter<UnlockShop>({
  selectId: (unlockshop) => unlockshop._id,
});

interface UnlockShopInitialState extends EntityState<UnlockShop> {}

const initialState: UnlockShopInitialState =
  unLockShopAdaptor.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUnlockShop: builder.query<EntityState<UnlockShop>, void>({
      query: () => `/unlock-shop`,
      transformResponse: (response: UnlockShop[]) => {
        return unLockShopAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        { type: "Unlock", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "Unlock" as const, id })),
      ],
    }),
    createUnlockShop: builder.mutation<
      EntityState<UnlockShop>,
      Partial<UnlockShop>
    >({
      query: (unlockShop) => ({
        url: `/unlock-shop`,
        method: "POST",
        body: unlockShop,
      }),
      transformResponse: (response: UnlockShop) => {
        return unLockShopAdaptor.setOne(initialState, response);
      },

      invalidatesTags: (result, error, arg) => [{ type: "Unlock", id: "LIST" }],
      async onQueryStarted(unlockShop, { dispatch, queryFulfilled }) {
        dispatch(
          extendedShopApi.util.updateQueryData(
            "getAllShop",
            { currentSystem: unlockShop.currentSystem! },
            (shops) => {
              shopAdaptor.updateOne(shops, {
                id: unlockShop.shop!._id!,
                changes: {
                  ...shops.entities[unlockShop.shop!._id!],
                  unlockshop: unlockShop,
                },
              });
            }
          )
        );
      },
    }),
  }),
});

export const { useGetAllUnlockShopQuery, useCreateUnlockShopMutation } =
  extendedApiSlice;
