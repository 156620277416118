const onBoardingbackground = require("../../assets/images/onboardingback.png");
const profileBorder = require("../../assets/images/profileborder.png");
const gemImage = require("../../assets/images/gem.png");
const xpLevelBackground = require("../../assets/images/xplevel.png");
const dotExp = require("../../assets/images/experince-dot.png");
const iconClose = require("../../assets/images/close-icon.png");
const titleLink = require("../../assets/images/title-link.png");
const expereinceBack = require("../../assets/images/experience.png");
const cameraIcon = require("../../assets/images/camera11.png");
const deleteMenuIcon = require("../../assets/images/delete-menu-icon.png");
const passDueIcon = require("../../assets/images/pastDue.png");
const completeIcon = require("../../assets/images/completed.png");
const Camera2 = require("../../assets/images/camera2.png");
const ShopBack = require("../../assets/images/shop-back.jpg");
const Ticket = require("../../assets/images/ticket.png");
const Bag = require("../../assets/images/bag.png");
const StoreShop = require("../../assets/images/store-icon.png");
const LockIcon = require("../../assets/images/lock-icon.png");
const LockAnimation = require("../../assets/images/lottie/unlock.json");
const BackAnimattion = require("../../assets/images/lottie/92235-confetti.json");
const UnLockAnimation = require("../../assets/images/lottie/8193-unlocked.json");
const DotAnimation = require("../../assets/images/lottie/dot_animation.json");
const TimerAnimation = require("../../assets/images/lottie/timer-animation.json");
const claimButton = require("../../assets/images/lottie/Claim_button_animation");
const Spinner = require("../../assets/images/spinners.gif");
const ButtonLoading = require("../../assets/images/buttonloading.gif");
const LeaderBoardIcon = require("../../assets/images/leader-board-icon.png");
const CheckIcon = require("../../assets/images/Icon-check.png");
const GradeBack = require("../../assets/images/GradeBack.png");
const CheckAnimation = require("../../assets/images/lottie/checkboxs.json");
const notificationAnimation = require("../../assets/images/lottie/91944-notification-dot.json");
const ReviveGemAnimation = require("../../assets/images/lottie/ReviveGemAnimation.json");
const Wheel7Days = require("../../assets/images/wheel-in-7-day.png");
const ProfileAvatar = require("../../assets/images/ProfileAvatar.png");
const ClanderTime = require("../../assets/images/calendar-day.png");
const BagBackground = require("../../assets/images/bag-back.png");
const BagHeaderIcon = require("../../assets/images/bagScreenIcon.png");
const ShopIcon = require("../../assets/images/shopIcon.png");
const HappyIcon = require("../../assets/images/happyIcon.png");
const SadIcon = require("../../assets/images/sadIcon.png");
const BagRaffleIcon = require("../../assets/images/bagRaffle.png");
const CheckBox = require("../../assets/images/lottie/checkbox.json");
const SevenDaysAgo = require("../../assets/images/seven-day/seven-day-logo.png");
const SevenDaysItemBackground = require("../../assets/images/seven-day/seven-day-item-back.png");
const SevenDaysLastItemBackgournd = require("../../assets/images/seven-day/seven-day-last-item-back.png");
const SevenDaysGemImage = require("../../assets/images/seven-day/seven-day-gem.png");
const SevenDaysXpImage = require("../../assets/images/seven-day/seven-day-xp.png");
const SevenDaysReviveImage = require("../../assets/images/seven-day/seven-day-plus.png");
const SevenDaysFreeTicketImage = require("../../assets/images/seven-day/seven-day-ticket.png");
const SevenDaysFusesImage = require("../../assets/images/seven-day/seven-day-fu.png");
const SevenDaysGem = require("../../assets/images/seven-day/seven-day-dymon.png");
const ProfileNotification = require("../../assets/images/push-notifications-4.png");
const PrizeWheelBackground = require("../../assets/images/wheel-background.png");
const backtimer = require("../../assets/images/timerBack.png");
const timerCloud = require("../../assets/images/timer/timercloude.png");
const timerbook = require("../../assets/images/timer/timerbook.png");
const timergroup = require("../../assets/images/timer/timergroup.png");
const timerlight = require("../../assets/images/timer/timerlight.png");
const timermask = require("../../assets/images/timer/timermask.png");
const timermath = require("../../assets/images/timer/timermath.png");
const timermoon = require("../../assets/images/timer/timermoon.png");
const timerpen = require("../../assets/images/timer/timerpen.png");
const timerradical = require("../../assets/images/timer/timerradical.png");
const timershape = require("../../assets/images/timer/timershape.png");
const timerworld = require("../../assets/images/timer/timerworld.png");
const timeryoutube = require("../../assets/images/timer/timeryoutube.png");
const timerapple = require("../../assets/images/timer/timerapple.png");
const pointImage = require("../../assets/images/timer/pointImage.png");
const coinImage = require("../../assets/images/coin.png");
const focusPowerImage = require("../../assets/images/timer/focusPower.png");
const onBoardingbackgroundDark = require("../../assets/images/onboardingBackDark.png");
const AuthLog = require("../../assets/images/authlogo.png");
const GradegyWaterMark = require("../../assets/images/gradegytexticon.png");
const AuthBack = require("../../assets/images/auth-back.png");
const Gradegy = require("../../assets/images/GRADy_100.png");
const pushNotification = require("../../assets/images/push-notifications-animate.png");
const GradifyLoading = require("../../assets/images/ScreenLoading.png");
const HotspotBack = require("../../assets/images/hotspot-back.png");
const hotspotRev = require("../../assets/images/hotspot-rev.png");
const hotspotPopupIcon = require("../../assets/images/hotspoot-popup-icon.png");
const hotspotPopupHeaderIcon = require("../../assets/images/hotspot-pop-icon.png");
const hotspotPopupItemBack = require("../../assets/images/hotspotItemBack.png");
const reviveGem = require("../../assets/images/Revive-gem.png");
const experienceTop = require("../../assets/images/experienceTop.png");
const gemss = require("../../assets/images/gemss.png");
const orangeBag = require("../../assets/images/orange-bag.png");
const bagArrow = require("../../assets/images/bag-arrow.png");
const yellowBorderTicket = require("../../assets/images/yellow-border-ticket.png");
const yellowTicket = require("../../assets/images/yellow-ticket.png");
const badge = require("../../assets/images/badge.png");
const badge1 = require("../../assets/images/badge1.png");
const levelChart = require("../../assets/images/levelChart.png");
const badgeTopIcon = require("../../assets/images/badge-top-icon.png");
const BadgedetailBackground = require("../../assets/images/BadgedetailBackground.png");
const badgeDetail = require("../../assets/images/badgeDetail.png");
const samplePic = require("../../assets/images/sample-pic.png");
const feedBack = require("../../assets/images/feed-back.png");
const commentBack = require("../../assets/images/comment-back.png");
const eventBack = require("../../assets/images/event-back.png");
const notehstarIcon = require("../../assets/images/notehstar-icon.png");
const northstar = require("../../assets/images/northstar.png");
const northstarback = require("../../assets/images/northstarback.png");
const northstaritems = require("../../assets/images/northstaritems.png");
const northmywhy = require("../../assets/images/northmywhy.png");
const youthBack = require("../../assets/images/youthBack.png");
const avatars = require("../../assets/images/avatars.png");
const popups = require("../../assets/images/popups.png");
const timerBack = require("../../assets/images/timer-back.png");
const arrow = require("../../assets/images/arrow.png");
const fuze = require("../../assets/images/fuze.png");
const item = require("../../assets/images/item.png");
const timerPopup = require("../../assets/images/timerpopup.png");
const timerelec = require("../../assets/images/timerelec.png");
const timerfuze = require("../../assets/images/timerfuze.png");
const timerloc = require("../../assets/images/timerloc.png");
const timert = require("../../assets/images/timert.png");
const infinit = require("../../assets/images/infinit.png");
const webBack = require("../../assets/images/webback.png");
const revieBy = require("../../assets/images/revive-gem-buy.png");
const leaderBoardTop = require("../../assets/images/leaderboard.png");
const groupImage = require("../../assets/images/group-image.png");

export default {
  timergroup,
  groupImage,
  arrow,
  webBack,
  infinit,
  timerelec,
  leaderBoardTop,
  timerfuze,
  timerloc,
  timert,
  fuze,
  timerPopup,
  item,
  avatars,
  popups,
  timerBack,
  youthBack,
  notehstarIcon,
  northmywhy,
  northstarback,
  northstar,
  northstaritems,
  commentBack,
  yellowBorderTicket,
  yellowTicket,
  samplePic,
  badgeDetail,
  BadgedetailBackground,
  timerlight,
  orangeBag,
  timerCloud,
  eventBack,
  bagArrow,
  claimButton,
  timerbook,
  backtimer,
  timermoon,
  timermask,
  timermath,
  timerpen,
  timerradical,
  timerworld,
  timershape,
  timeryoutube,
  badge1,
  badge,
  pointImage,
  TimerAnimation,
  timerapple,
  coinImage,
  deleteMenuIcon,
  GradeBack,
  focusPowerImage,
  onBoardingbackground,
  profileBorder,
  gemImage,
  xpLevelBackground,
  dotExp,
  iconClose,
  titleLink,
  DotAnimation,
  expereinceBack,
  cameraIcon,
  passDueIcon,
  completeIcon,
  Camera2,
  ShopBack,
  Ticket,
  Bag,
  StoreShop,
  LockIcon,
  LockAnimation,
  BackAnimattion,
  UnLockAnimation,
  Spinner,
  ButtonLoading,
  LeaderBoardIcon,
  gemss,
  CheckAnimation,
  Wheel7Days,
  ProfileAvatar,
  badgeTopIcon,
  ClanderTime,
  BagBackground,
  BagHeaderIcon,
  ShopIcon,
  HappyIcon,
  SadIcon,
  BagRaffleIcon,
  CheckBox,
  SevenDaysAgo,
  SevenDaysItemBackground,
  SevenDaysLastItemBackgournd,
  SevenDaysGemImage,
  SevenDaysXpImage,
  SevenDaysReviveImage,
  SevenDaysFreeTicketImage,
  SevenDaysFusesImage,
  SevenDaysGem,
  ProfileNotification,
  notificationAnimation,
  CheckIcon,
  PrizeWheelBackground,
  onBoardingbackgroundDark,
  AuthLog,
  GradegyWaterMark,
  AuthBack,
  Gradegy,
  pushNotification,
  GradifyLoading,
  HotspotBack,
  hotspotRev,
  hotspotPopupIcon,
  hotspotPopupHeaderIcon,
  hotspotPopupItemBack,
  ReviveGemAnimation,
  reviveGem,
  experienceTop,
  levelChart,
  feedBack,
  revieBy,
};
