import React from "react";
import ExperienceTabPresentation from "../presentation/ExperienceTabPresentation";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  ExperienceCardScreenDetailsProp,
  HomeScreenNavigationProps,
} from "../../../navigation/types";

const ExperienceMainContainer = () => {
  const route = useRoute<ExperienceCardScreenDetailsProp>();
  const { item, openPopup, isBack } = route.params;

  return (
    <>
      <ExperienceTabPresentation
        item={item}
        openPopup={openPopup}
        isBack={isBack}
      />
    </>
  );
};

export default ExperienceMainContainer;
