import React from "react";
import { View, Text, SafeAreaView } from "react-native";
import { Header } from "react-native/Libraries/NewAppScreen";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { useAppSelector } from "../redux/hooks";
import { selectAdmin } from "../features/auth/slice/authSlice";
import FriendsContainer from "../features/friend/container/FriendsContainer";
import ProfileSettingHeaderPresentation from "../features/profileSetting/presentation/ProfileSettingHeaderPresentation";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../navigation/types";
const FriendsScreen = () => {
  const user = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();

  const onGoAccountSetting = () => {
    navigation.navigate("Setting");
  };

  const onGoBack = () => {
    navigation.goBack();
  };
  return (
    <>
      <ProfileSettingHeaderPresentation
        backgroundProfile={user?.backgroundBanner!}
        goAccountSetting={onGoAccountSetting}
        goBackHandler={onGoBack}
        showSetting={true}
      />
      <FriendsContainer />
    </>
  );
};

export default FriendsScreen;
