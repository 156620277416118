import { Image } from "expo-image";
import React from "react";
import {
  TouchableOpacity,
  ImageBackground,
  View,
  Platform,
  Text,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { RaffelTicket } from "../types/types";
import { images } from "../../../constants";
import CountDownTimer from "../../../components/CountDownTimer/CountDownTimer";
const isAndroid = Platform.OS === "android" ? true : false;
type RaffelTicketItemPresentationProps = {
  raffelTicket: RaffelTicket;
  onPressShopItem: (item: Partial<RaffelTicket>) => void;
};
const RaffelTicketItemPresentation: React.FC<
  RaffelTicketItemPresentationProps
> = ({ raffelTicket, onPressShopItem }) => {
  return (
    <>
      <TouchableOpacity
        style={styles.imageContainer}
        onPress={() => {
          onPressShopItem(raffelTicket);
        }}
      >
        <ImageBackground
          source={{
            uri: raffelTicket.image,
          }}
          resizeMode="cover"
          style={styles.image}
        >
          <View style={styles.RaffleTextContainer}>
            <Text style={styles.RaffleText}>{raffelTicket.title} </Text>
            <View style={styles.RaffleTextIcon}>
              <Image
                source={images.yellowTicket}
                style={styles.RaffleTextImage}
              />
            </View>
          </View>

          <View style={styles.ShopContent}>
            <View style={styles.LockContainer}>
              <Image source={images.Ticket} style={styles.lockImage} />
            </View>
            <View
            // style={styles.dueDate}
            ></View>
          </View>
        </ImageBackground>

        <View style={styles.ShopItemCoinContainer}>
          <CountDownTimer drawDate={new Date(raffelTicket.drawDate)} />

          <View style={styles.ShopItemGemContainer}>
            <Image source={images.gemss} style={styles.shopItemCoinImg} />
            <Text style={styles.ShopItemCoin}>{raffelTicket.gem}</Text>
          </View>

          <View style={styles.ShopItemTicketContainer}>
            <Image
              source={images.yellowBorderTicket}
              style={styles.shopItemCoinTicket}
            />
            <Text style={styles.ShopItemTicket}>1</Text>
          </View>
        </View>
      </TouchableOpacity>
      {/* <TicketBuyRaffle
    user={user}
    openPopup={openPopup}
    setOpenPopup={setOpenPopup}
    raffleImage={raffleImage}
    title={title}
    gem={gem}
    description={description}
    endDate={getTimeStamp(endDate)}
    isShop={false}
    id={id}
  /> */}
    </>
  );
};

const { styles } = StyleSheet.create({
  dueDateText: {
    color: "#fff",
    fontSize: 26,
    paddingLeft: 5,
    paddingBottom: 10,
    textAlign: "center",
    "@media (max-width: 350px)": {
      fontSize: 19,
    },
  },
  ShopItemGemContainer: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#23AAE1",
    flexDirection: "row-reverse",
    width: 53,
    height: 31,
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: 5,
  },
  ShopItemTicketContainer: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#FFEA00",
    flexDirection: "row-reverse",
    width: 53,
    height: 31,
    justifyContent: "space-around",
    alignItems: "center",
    marginLeft: 3,
    marginRight: 5,
    borderRadius: 5,
  },
  RaffleTextImage: {
    width: 18,
    height: 12,
  },

  RaffleTextIcon: {
    position: "absolute",
    right: -20,
    width: 37,
    height: 37,
    top: -8,
    borderRadius: 50,
    borderWidth: 4,
    borderStyle: "solid",
    borderColor: "#000",
    backgroundColor: "#F7941D",
    justifyContent: "center",
    alignItems: "center",
  },
  RaffleTextContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: "#023C56",
    borderWidth: 3,
    borderColor: "#000",
    borderStyle: "solid",
    height: 29,
    maxWidth: "74%",
    marginLeft: "13%",
    marginTop: 10,
    borderRadius: 5,
  },
  ShopItemCoin: {
    color: "#23AAE1",
    fontSize: 18,
  },
  ShopItemTicket: {
    color: "#FFEA00",
    fontSize: 18,
  },
  shopItemCoinImg: {
    width: 19,
    height: 15,
    alignSelf: "center",
  },
  shopItemCoinTicket: {
    width: 24,
    height: 17,
    alignSelf: "center",
  },
  lockImage: {
    width: 50,
    height: 34,
  },
  ShopItemCoinContainer: {
    height: 42,
    width: "90%",
    backgroundColor: "#fff",
    borderTopLeftRadius: 10,

    alignItems: "center",
    textAlign: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    borderWidth: 3,
    borderColor: "#000",
    paddingRight: 5,
    position: "absolute",
    bottom: -2,
    right: -2,
    "@media (max-width: 350px)": {
      height: 32,
    },
  },

  RaffleText: {
    fontSize: 14,
    fontFamily: "HelveticaBold",
    textAlign: "center",
    lineHeight: 22,
    color: "#fff",
    letterSpacing: 1.2,
  },

  LockContainer: {
    width: 52,
    height: 52,
    borderRadius: 6,
    position: "absolute",
    right: 10,
    top: -10,
    "@media (max-width: 350px)": {
      width: 40,
      height: 40,
    },
  },

  image: {
    width: "100%",
    height: isAndroid ? 157 : 147,
    position: "relative",
    overflow: "hidden",
    borderRadius: 4,

    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 350px)": {
      height: isAndroid ? 127 : 117,
    },
  },

  ShopContent: {
    flexDirection: "row",
  },
  imageContainer: {
    width: isAndroid ? 356 : 346,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    margin: 10,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderColor: "#000",
    borderWidth: 2,
    borderStyle: "solid",

    "@media (max-width: 350px)": {
      width: isAndroid ? 280 : 260,
    },
  },
});

export default RaffelTicketItemPresentation;
