import React from "react";
import { View, Text } from "react-native";
import PreviewPictureContainer from "../features/cameraFeatures/container/PreviewPictureContainer";
import { useRoute } from "@react-navigation/native";
import { PreviewPictureScreenRouteProps } from "../navigation/types";

const PreviewPictrueScreen = () => {
  const route = useRoute<PreviewPictureScreenRouteProps>();
  const { item, currentSystem, photo, from, prevItem, reviveItem } =
    route.params;
  return (
    <PreviewPictureContainer
      item={item}
      currentSystem={currentSystem}
      photo={photo}
      from={from}
      prevItem={prevItem}
      reviveItem={reviveItem}
    />
  );
};

export default PreviewPictrueScreen;
