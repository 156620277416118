import React, { useState, useEffect, useRef, CSSProperties } from "react";
import Svg, { Circle } from "react-native-svg";
import { View, Text, Animated, ImageBackground } from "react-native";
import StyleSheet from "react-native-media-query";
import { images } from "../../constants";
// import { createIconSetFromFontello } from "react-native-vector-icons";
type CircleLevelChartProps = {
  left?: number;
  top?: number;
  level?: number;
  rank?: number;
  percent?: number;
  chartPercentCal?: number;
};
const CircleLevelChart: React.FC<CircleLevelChartProps> = ({
  left,
  top,
  rank,
  chartPercentCal,
  level,
}) => {
  const scaleAnim = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(scaleAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  }, [scaleAnim]);
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        left: left,
        top: top,
        margin: 2,
        width: 144,
      }}
    >
      <Svg
        width="144"
        height="144"
        style={{ transform: [{ rotate: "-90deg" }], position: "relative" }}
      >
        <Circle
          r="59"
          cx="70"
          cy="70"
          stroke="#e5e6e9"
          strokeWidth="18"
          fill="none"
          strokeLinecap={"butt"}
          strokeDasharray={""}
        ></Circle>

        <>
          <Circle
            r="59"
            cx="70"
            cy="70"
            fill="none"
            strokeWidth="18"
            stroke="#34C38F"
            strokeLinecap="round"
            strokeDasharray="380"
            strokeDashoffset={chartPercentCal! >= 0 ? chartPercentCal : 380}
          ></Circle>
        </>
      </Svg>
      <ImageBackground
        source={images.levelChart}
        resizeMode="contain"
        style={styles.levelNumberImage}
      >
        <View style={styles.rankContainer}>
          <Text style={styles.rankNumber}>{rank ? rank : 0}</Text>
          <Text style={styles.rankText}>Rank</Text>
        </View>
      </ImageBackground>
      <Text style={styles.levelNumber}>
        <Text style={styles.levelText}>lv</Text>
        {level ? level : 0}
      </Text>
    </View>
  );
};

const { styles } = StyleSheet.create({
  rankContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    top: 4,
  },
  rankNumber: {
    fontFamily: "RajdahaniBold",
    fontSize: 11,
    color: "#fff",
    lineHeight: 14,
  },
  rankText: {
    fontFamily: "RajdahaniBold",
    fontSize: 9,
    color: "#fff",
    lineHeight: 9,
    top: -2,
  },
  levelNumberImage: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 54,
    position: "absolute",
    height: 44,
    right: -5,
    top: -10,
    zIndex: 9999,
  },

  numbericCircleStyle: {
    position: "absolute",
    padding: 7,
    borderRadius: 50,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    top: -5,
    right: -9,
    backgroundColor: "#E50966",
    width: 40,
    height: 40,
  },
  numbericCircleText: {
    color: "#fff",
    fontSize: 13,
  },
  levelNumber: {
    position: "absolute",
    top: 40,
    left: 20,
    width: 100,
    textAlign: "center",
    fontSize: 55,
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 30,
    },
  },
  levelText: {
    fontFamily: "RajdahaniBold",
    fontSize: 22,
  },
  titleText: {
    top: 0,
    left: 0,
    maxWidth: 150,
    fontSize: 24,
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 21,
    },
  },
  descriptionText: {
    top: 0,
    maxWidth: 150,
    fontSize: 24,
    color: "#B7B7BC",
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 21,
    },
  },
});

export default CircleLevelChart;
