import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Platform, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../navigation/types";
const HeaderSetting = () => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  return (
    <View
      style={{
        backgroundColor: "rgba(255,255,255,0.75)",
        padding: 1,
        borderRadius: 50,
        marginTop:Platform.OS === "android" ? 20 : 0,
      }}
    >
      <Ionicons
        name="md-settings"
        size={32}
        color="black"
        onPress={() => navigation.navigate("Setting")}
      />
    </View>
  );
};

export default HeaderSetting;
