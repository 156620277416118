import React from "react";
import { FontAwesome5 } from "@expo/vector-icons";
import { Platform, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../navigation/types";
const HeaderBack = () => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  return (
    <View
      style={{
        backgroundColor: "rgba(255,255,255,0.75)",
        padding: 3,
        borderRadius: 50,
        marginTop:Platform.OS === "android" ? 20 : 0,

      }}
    >
      <FontAwesome5
        name="arrow-circle-left"
        size={32}
        color="black"
        onPress={() => navigation.goBack()}
      />
    </View>
  );
};

export default HeaderBack;
