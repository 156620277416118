import React, { useEffect, useState } from "react";
import { ImageBackground, View, Text, TouchableOpacity, TouchableHighlight } from "react-native";
import StyleSheet from "react-native-media-query";
import PolygonProfile from "../PolygonProfile";
import { AuthUser, StudentSystemType } from "../../features/auth/types/types";
import { images } from "../../constants";
import PolygonShape from "../PolygonShape/PolygonShape";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../navigation/types";
import { selectAdmin } from "../../features/auth/slice/authSlice";
import { useAppSelector } from "../../redux/hooks";
type ProfileInfoImageProps = {
  student: AuthUser;
  currentSystem: Partial<StudentSystemType>;
};
const ProfileInfoImage: React.FC<ProfileInfoImageProps> = ({
  student,
  currentSystem,
}) => {
  const [xps, setXps] = useState<number>(0);
  const [gems, setGems] = useState<number>(0);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const admin = useAppSelector(selectAdmin);
  
  useEffect(() => {
    if (student) {
      const projectReport = student.projectReport.find(
        (item) => item.project === currentSystem!.project
      );
      if (projectReport) {
        setXps(projectReport?.xps);
        setGems(projectReport?.gems);
      }
    }
  }, [student, currentSystem]);
  return (
    <View style={styles.profileInfoImage}>
      <View style={styles.profileInfoShapeContainer}>
      <TouchableHighlight
          activeOpacity={1}
          underlayColor="transparent"
          onPress={() => {
            navigation.navigate("Badges", {
              student: admin!,
            });
          }}
        >
        <PolygonProfile

          xp={xps}
          showXp={false}
          studentPic={student?.image}
        />
        </TouchableHighlight>

        <ImageBackground
          source={images.xpLevelBackground}
          resizeMode="contain"
          style={styles.image}
        >
          <Text style={styles.xpNumbrt}>{Math.floor(xps! / 3500)}</Text>
        </ImageBackground>
        <View style={styles.profileTeamPic}>
          <PolygonShape
            profileImage={student?.image}
            sourceImage={images.Gradegy}
            teamColor={student?.team!.color!}
          />
        </View>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  profilename: {
    width: "100%",
    color: "black",
    flexDirection: "row",
    justifyContent: "center",
  },
  profileLastName: {
    marginLeft: 3,
  },
  profileText: {
    fontSize: 27,
    fontFamily: "RajdahaniMedium",
  },
  profileInfoImage: {
    position: "relative",
    top: -140,
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  profileInfoShapeContainer: {
    width: 240,
  },
  image: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 60,
    position: "absolute",
    height: 60,
    left: 182,
    top: 108,
    zIndex: 9999,
  },
  xpNumbrt: {
    fontSize: 28,
    lineHeight: 33,
    color: "#fff",
    alignSelf: "center",
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 22,
    },
  },
  profileTeamPic: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 60,
    position: "absolute",
    height: 60,
    left: 240,
    top: 110,
    zIndex: 9999,
  },

  profileTeamPicContainer: {
    marginBottom: 13,
    position: "absolute",
    top: 160,
    left: -52,
    justifyContent: "flex-start",
    flexDirection: "row",
    marginLeft: "9%",
    zIndex: 9999,
  },
  profileRd: {
    width: 35,
    height: 35,
    borderRadius: 50,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#0BC4B8",
    flexDirection: "row",
    top: 8,
  },
  profileRdNumber: {
    color: "#fff",
    fontSize: 18,

    "@media (max-width: 350px)": {
      fontSize: 15,
    },
  },
  profileRdText: {
    color: "#fff",
  },
});
export default ProfileInfoImage;
