import React from "react";
import BadgeSystemTabPresentation from "../presentation/BadgeSystemTabPresentation";
import BadgeSystemTextPresentation from "../presentation/BadgeSystemTextPresentation";
type BadgeSystemMainContainerProps = {
  active: number | undefined;
  xTowButton: number | undefined;
  itemBadge: any;
  popUpOpen: boolean | undefined;
};
const BadgeSystemMainContainer: React.FC<BadgeSystemMainContainerProps> = ({
  active,
  xTowButton,
  itemBadge,
  popUpOpen,
}) => {
  return (
    <>
      <BadgeSystemTextPresentation />
      <BadgeSystemTabPresentation
        activeProp={active}
        xTwoButton={xTowButton}
        itemBadge={itemBadge}
        popUpOpen={popUpOpen}
      />
    </>
  );
};

export default BadgeSystemMainContainer;
