import React, { useState } from "react";
import CameraPresentation from "../presentation/CameraPresentation";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { CameraCapturedPicture } from "expo-camera";
import { ExperienceCard } from "../../experience/types/types";
import { StudentSystemType } from "../../auth/types/types";
import { ExperiencePhoto } from "../types/types";
type CameraContainerProps = {
  item: Partial<ExperienceCard>;
  currentSystem: Partial<StudentSystemType>;
  prevItem?: Partial<ExperiencePhoto>;
};
const CameraContainer: React.FC<CameraContainerProps> = ({
  item,
  currentSystem,
  prevItem,
}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [snapPhoto, setSanpPhoto] = useState<CameraCapturedPicture | null>();
  const navigtation = useNavigation<HomeScreenNavigationProps>();
  const goback = () => {
    // navigtation.goBack();
    navigation.navigate("ExperienceMap", {
      item: null,
      openPopup: false,
      isBack: true,
    });
  };

  const takePicutre = (photo: CameraCapturedPicture) => {
    setSanpPhoto(photo);

    navigation.navigate("PreviewPicture", {
      item,
      currentSystem,
      photo,
      from: "cam",
      prevItem: prevItem,
    });
  };

  const pickPicutre = (photo: CameraCapturedPicture) => {
    setSanpPhoto(photo);
  };

  return (
    <CameraPresentation
      goback={goback}
      takePicture={takePicutre!}
      pickPicture={pickPicutre}
    />
  );
};

export default CameraContainer;
