import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { NotificationCenterType } from "../types/types";

const notificationCenterAdaptor = createEntityAdapter<NotificationCenterType>({
  selectId: (notification) => notification._id,
});

interface INotificationCenter extends EntityState<NotificationCenterType> {}

const initialState: INotificationCenter =
  notificationCenterAdaptor.getInitialState();

export const apiExtendNotificationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotification: builder.mutation<
      EntityState<NotificationCenterType>,
      {
        currentSystem: string;
      }
    >({
      query: (data) => ({
        url: `/notification-center?currentSystemString=${data.currentSystem}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response: NotificationCenterType[]) => {
        return notificationCenterAdaptor.setAll(initialState, response);
      },
      invalidatesTags: ["NotificationCenter"],
    }),
    completeNotification: builder.mutation<
      boolean,
      {
        notificationCenter: Partial<NotificationCenterType>;
      }
    >({
      query: (data) => ({
        url: `/notification-center-completion`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["NotificationCenter"],
    }),
  }),
});

export const { useGetNotificationMutation, useCompleteNotificationMutation } =
  apiExtendNotificationApi;
