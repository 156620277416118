import { Image } from "expo-image";
import React, { useState } from "react";
import { View, FlatList, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { images } from "../../../constants";
import LeaderBoardTabPresentation from "./LeaderBoardTabPresentation";
type LeaderBoardListPresentationProps = {
  children: React.ReactNode;
  setThisMonth: React.Dispatch<React.SetStateAction<string>>;
};
const LeaderBoardListPresentation: React.FC<
  LeaderBoardListPresentationProps
> = ({ children, setThisMonth }) => {
  const [thisMonth, setThisMont] = useState<string>("true");

  const onTimeChange = (status: number) => {
    if (status === 1) {
      setThisMont("true");
      setThisMonth("true");
    } else {
      setThisMont("false");
      setThisMonth("false");
    }
  };

  return (
    <View style={styles.leaderBoardContainer}>
      <View style={styles.leaderBoardHeader}>
        <View style={styles.leaderBoardImage}>
          <Image
            source={images.leaderBoardTop}
            style={styles.leaderBoardImageItem}
            contentFit="contain"
          />
        </View>
   
        <Text style={styles.leaderBoardHeaderText}>Leader Board</Text>
      </View>

      <View style={styles.leaderBoardContent}>
        <LeaderBoardTabPresentation onChangeStatus={onTimeChange}>
          {children}
        </LeaderBoardTabPresentation>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  leaderBoardImage: {
    width: 74,
    height: 72,
    backgroundColor: "#fff",
    borderBottomEndRadius: 7,
    justifyContent:"center",
    alignItems:"center"
  },
  leaderBoardImageItem:{
      width: 47,
    height: 47,
  },
  academicChartText: {
    color: "#3E3F5E",

    fontSize: 36,
    textAlign: "center",
    marginTop: 15,
    "@media (max-width: 350px)": {
      fontSize: 28,
    },
  },
  profileProgressCharts: {
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    top: -140,
    justifyContent: "space-around",
    padding: 10,

    "@media (max-width: 350px)": {
      transform: [{ scale: 0.8 }],
      top: -170,
      justifyContent: "space-between",
    },
  },

  profileInfoShapeStyle: {
    "@media (max-width: 350px)": {
      transform: [{ scale: 0.75 }],
    },
  },
  profileContainer: {
    backgroundColor: "#fff",
    "@media (max-width: 350px)": {
      zIndex: 2,
    },
  },
  profileHeader: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  profileXpCoinsContainer: {
    marginTop: 15,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerxpcontainer: {
    flex: 1,

    paddingBottom: 10,
    paddingRight: 5,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },

  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#1069C1",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#1069C1",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,

    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
    "@media (max-width: 350px)": {
      fontSize: 13,
    },
  },
  xp: {
    color: "white",
    fontSize: 26,

    "@media (max-width: 350px)": {
      fontSize: 24,
    },
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  profileInfoContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    flexWrap: "wrap",
  },
  profileInfo: {
    width: "95%",
    height: 550,
    borderRadius: 12,
    alignSelf: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.84,
    backgroundColor: "#fff",
    elevation: 3,
    "@media (max-width: 350px)": {
      height: 450,
    },
  },
  profileInfoHeight: {
    height: 600,
    "@media (max-width: 350px)": {
      height: 500,
    },
  },
  leaderBoardContainer: {
    width: "94%",
    height: 600,
    marginBottom: 10,
    marginTop: 30,
    alignItems: "center",
    backgroundColor: "#033C56",
    marginLeft: "3%",
    borderWidth: 10,
    borderRadius: 15,
    borderColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    "@media (max-width: 350px)": {
      height: 350,
    },
  },
  leaderBoardHeader: {
    width: "100%",
    overflow: "hidden",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leaderBoardHeaderText: {
    fontSize: 35,

    color: "#fff",
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "white",
    width: "90%",
    textAlign: "left",
    paddingLeft: 10,
    marginLeft: "3%",
    "@media (max-width: 350px)": {
      fontSize: 33,
      paddingTop: 8,
    },
  },
  leaderColumn: {
    width: "80%",
  },
  leaderBoardContent: {
    width: "100%",
    height: 500,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",

    paddingTop: 10,
    flex: 1,
    "@media (max-width: 350px)": {
      height: "78%",
    },
  },
  scrollView: {
    height: "20%",
    width: "80%",
    margin: 20,
    alignSelf: "center",
    padding: 20,
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "black",
    backgroundColor: "lightblue",
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgrey",
    paddingBottom: 50,
  },

  profilePicContainer: {
    alignItems: "center",
    width: "100%",
  },
});
export default LeaderBoardListPresentation;
