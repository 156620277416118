import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { View, FlatList } from "react-native";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { Image } from "expo-image";
import PostFeedsItems from "./PostFeedItemPresentaion";
import { ExperienceCard } from "../../experience/types/types";
import ExperienceCardsListPresentation from "./ExperienceCardsListPresentation";
import { Friend } from "../../friend/types/type";
import { AuthUser } from "../../auth/types/types";
import FriendListFeedPresentation from "./FriendListFeedPresentation";
type FeedPresentationProps = {
  photosList: EntityState<ExperiencePhoto>;
  onPhotoClick: (item: Partial<ExperiencePhoto> | null) => void;
  applyLike: (item: Partial<ExperiencePhoto>) => void;
  experienceCards: EntityState<ExperienceCard>;
  onOpenDetails: (item: Partial<ExperienceCard>) => void;
  friendsList: EntityState<AuthUser>;
  onOpenFriendPopup: (friend: Partial<AuthUser>) => void;
  onFriendItemClick: (student: Partial<AuthUser>) => void;
  onPhotoCommentClick: (experiencePhoto: Partial<ExperiencePhoto>) => void;
  onItemClickHanlder: (card: Partial<ExperienceCard>) => void;
};
const FeedPresentation: React.FC<FeedPresentationProps> = ({
  photosList,
  onPhotoClick,
  applyLike,
  experienceCards,
  onOpenDetails,
  friendsList,
  onOpenFriendPopup,
  onFriendItemClick,
  onPhotoCommentClick,
  onItemClickHanlder,
}) => {
  return (
    <FlatList
      data={Object.values(photosList!.entities!) as ExperiencePhoto[]}
      numColumns={2}
      keyExtractor={(item) => `${item._id}`}
      keyboardDismissMode="on-drag"
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: "#fff" }}
      ListHeaderComponent={
        <>
          <FriendListFeedPresentation
            friendList={friendsList}
            onOpenFriendPopup={onOpenFriendPopup}
            onFriendItemClick={onFriendItemClick}
          />
          <ExperienceCardsListPresentation
            experienceCards={experienceCards}
            onOpenDetails={onOpenDetails}
            onItemClickHanlder={onItemClickHanlder}
          />
        </>
      }
      contentContainerStyle={{}}
      renderItem={({ item }) => (
        <PostFeedsItems
          item={item}
          onPhotoClick={onPhotoClick}
          applyLike={applyLike}
          onPhotoCommentClick={onPhotoCommentClick}
        />
      )}
      ListFooterComponent={
        <View
          style={{
            marginBottom: 150,
          }}
        ></View>
      }
    />
  );
};

export default FeedPresentation;
