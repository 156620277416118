import React, { FC } from "react";
import { View, Text, Dimensions } from "react-native";
import StyleSheet from "react-native-media-query";
import { LeaderBoard } from "../../leaderborad/types/type";
const screenWidth = Dimensions.get("window").width;
type LeaderItemPresentationProps = {
  rank: number;
  item: LeaderBoard;
};
const LeaderItemPresentation: React.FC<LeaderItemPresentationProps> = ({
  rank,
  item,
}) => {
  return (
    <>
      <View style={styles.leaderContent}>
        <Text style={styles.number}>{rank}</Text>
        <View style={styles.leaderContainer}>
          <View style={styles.nameContainer}>
            <View style={styles.nameWrap}>
              <Text style={styles.name} numberOfLines={1}>
                {item.fullName.split(" ").map((item: any, index: any) => {
                  if (index === 1) {
                    return <Text key={index}>{item.charAt(0)}.</Text>;
                  } else {
                    return <Text key={index}>{item} </Text>;
                  }
                })}
              </Text>
            </View>
          </View>
          <View style={styles.xpContainer}>
            <Text style={styles.xp} numberOfLines={1}>
              {item.xp}
            </Text>
            <Text style={styles.XpText}>XP</Text>
          </View>
        </View>
      </View>
    </>
  );
};
const { styles } = StyleSheet.create({
  leaderContent: {
    borderRadius: 15,
    width: "98%",

    paddingLeft: 10,
    paddingRight: 10,
    height: 80,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "@media (max-width: 350px)": {
      fontSize: 20,
      height: 60,
    },
  },
  leaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: "#bababa",
    borderStyle: "solid",
    borderBottomWidth: 1,
    paddingBottom: 25,
    paddingTop: 20,
    width: screenWidth - 150,
    maxWidth:370,
    justifyContent: "space-between",
    textAlign: "center",
    textAlignVertical: "center",
    "@media (max-width: 350px)": {
      paddingBottom: 15,
      paddingTop: 10,
    },
  },
  number: {
    backgroundColor: "#34C38F",
    width: 39,
    height: 39,
    borderRadius: 20,
    overflow: "hidden",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: 22,

    lineHeight: 39,
    "@media (max-width: 350px)": {
      width: 32,
      height: 32,
      fontSize: 15,
      lineHeight: 30,
    },
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  name: {
    paddingLeft: 15,
    fontSize: 18,

    width: "100%",
    textTransform: "capitalize",
    "@media (max-width: 350px)": {
      fontSize: 15,
      paddingLeft: 10,
    },
  },
  lastName: {
    paddingLeft: 3,
    width: 22,
    fontSize: 21,

    textTransform: "capitalize",
    "@media (max-width: 350px)": {
      fontSize: 18,
    },
  },
  nameWrap: {
    maxWidth: screenWidth - 280,
    flexDirection: "row",
    alignItems: "flex-start",
    flex: 1,
  },
  xpContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    textAlign: "right",
    justifyContent: "flex-end",

    alignSelf: "flex-start",
  },
  xp: {
    color: "#FF4C00",
    fontSize: 18,

    paddingRight: 10,
    minWidth: 10,
    textAlign: "center",

    marginTop: 2,
    maxWidth: 100,
    "@media (max-width: 350px)": {
      fontSize: 14,
      marginTop: 2,
      lineHeight: 22,
    },
  },
  XpText: {
    fontSize: 20,
    color: "#000",

    "@media (max-width: 350px)": {
      fontSize: 16,
    },
  },
});
export default LeaderItemPresentation;
