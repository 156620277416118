import React, { useState } from "react";
import GradeTabPresentation from "../presentation/GradeTabPresentation";
import GradeItemContainer from "./GradeItemContainer";
import { useGetAllGradeForStudentQuery } from "../api/grade.api";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { EntityId } from "@reduxjs/toolkit";
import { LearnerSetting } from "../../learnerSetting/type/LearnerSetting";
type GradeContainerProps = {
  learnerSetting: LearnerSetting;
};
const GradeContainer: React.FC<GradeContainerProps> = ({ learnerSetting }) => {
  const accountSelected = useAppSelector(selectDefaultAccount);
  const [thisTerm, setThisTerm] = useState<string>("Yes");
  const [terms, setTerms] = useState<number>(0);
  const {
    data: gradesList,
    isLoading,
    isSuccess,
    isError,
  } = useGetAllGradeForStudentQuery({
    currentSystem: JSON.stringify(accountSelected),
    term: thisTerm!,
    termStartDate: String(learnerSetting.termStartDate!),
    termEndDate: String(learnerSetting.termEndDate),
    termArray: JSON.stringify(learnerSetting.selectedTerms),
  });

  const onTimeChange = (status: number) => {
    if (status === 1) {
      setThisTerm("Yes");
    } else {
      setThisTerm("No");
    }
  };

  const renderItem = () => {
    if (terms === 0) {
      return (
        <>
          {isSuccess && (
            <GradeItemContainer term={thisTerm} grades={gradesList!} />
          )}
        </>
      );
    } else {
      return (
        <>
          {isSuccess && (
            <GradeItemContainer term={thisTerm} grades={gradesList!} />
          )}{" "}
        </>
      );
    }
  };

  return (
    <GradeTabPresentation onChangeStatus={onTimeChange}>
      {renderItem()}
    </GradeTabPresentation>
  );
};

export default GradeContainer;
