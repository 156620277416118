import React, { useEffect } from "react";
import {
  TouchableOpacity,
  Dimensions,
  Pressable,
  Text,
  View,
  FlatList,
  TextInput,
  Platform,
  ScrollView,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import Modal from "react-native-modal";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { useRemovePhotoMutation } from "../../cameraFeatures/api/experiencePhotoApiSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";

const { width, height } = Dimensions.get("window");

type CommentPopupProps = {
  openPopup?: boolean;
  onClose?: () => void;
  postItem?: Partial<ExperiencePhoto>;
  onCloseDelete: () => void;
};
const DeletePhotoPopup: React.FC<CommentPopupProps> = ({
  openPopup,
  onClose,
  postItem,
  onCloseDelete,
}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const account = useAppSelector(selectDefaultAccount);
  const [removePhoto, { isLoading, isError, isSuccess }] =
    useRemovePhotoMutation();
  return (
    <Modal
      statusBarTranslucent
      style={styles.container}
      swipeDirection="down"
      onSwipeComplete={() => {
        onCloseDelete();
      }}
      isVisible={true}
      animationIn="slideInUp"
      propagateSwipe
    >
      <View
        style={{
          backgroundColor: "#272626",
          width: width,
          height: 220,
          borderTopLeftRadius: 25,
          borderTopEndRadius: 25,
          overflow: "hidden",
          margin: 0,
          paddingTop: 20,
        }}
      >
        <View
          style={{
            backgroundColor: "#585858",
            width: "36%",
            height: 6,
            borderRadius: 40,
            marginLeft: "32%",
            marginBottom: 20,
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        ></View>

        <View style={{ paddingTop: 18 }}>
          <TouchableOpacity
            onPress={async () => {
              onCloseDelete();
              navigation.navigate("Camera", {
                item: postItem?.experience!,
                currentSystem: account!,
                prevItem: postItem,
              });
            }}
            style={styles.item}
          >
            <MaterialCommunityIcons
              style={{ marginLeft: -10 }}
              name="camera-retake"
              size={32}
              color="white"
            />
            <Text style={styles.text}>Re-Take Photo</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={async () => {
              try {
                await removePhoto(postItem!._id!).unwrap();
                navigation.navigate("Home");
              } catch (error) {}
            }}
            style={styles.item}
          >
            <Ionicons
              style={{ marginLeft: -10 }}
              name="trash-sharp"
              size={29}
              color="#FF0000"
            />
            <Text style={styles.text}>Delete and Unsubmit </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const { styles } = StyleSheet.create({
  container: {
    height: height - 100,
    backgroundColor: "rgba(0,0,0,0.1)",
    margin: 0,
    padding: 0,
    width: width,
    flex: 1,
    justifyContent: "flex-end",
  },
  text: {
    fontFamily: "HelveticaBold",
    fontSize: 13,
    color: "#fff",
    paddingLeft: 23,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 44,
    marginLeft: 44,

    paddingTop: 12,
    paddingBottom: 12,
    borderBottomColor: "#9D9A9A",
    borderBottomWidth: 0.5,
    borderStyle: "solid",
  },
});

export default DeletePhotoPopup;
