import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import {
  chooseSelectAccount,
  selectAdmin,
  selectStatus,
} from "../features/auth/slice/authSlice";
import { AuthScreenNavigationProps } from "../navigation/types";
import { useAppSelector } from "../redux/hooks";
import { RootState, store } from "../redux/store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StudentSystemType, Category } from "../features/auth/types/types";
import { useLoginMutation } from "../features/auth/authApiSlice/authApiSlice";
import { useAddToLoginMutation } from "../features/login/api/loginApi";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
let count = 0;
const Auth = () => {
  const navigation = useNavigation<AuthScreenNavigationProps>();
  const admin = useAppSelector(selectAdmin);

  const status = useAppSelector(selectStatus);
  const [addtoLogin, { isLoading, isError, isSuccess }] =
    useAddToLoginMutation();

  useEffect(() => {
    (async () => {
      if (status === "succeeded") {
        if (admin) {
          try {
            if (admin?.adminLevel === "USER") {
              if (!admin.confirm) {
                navigation.navigate("TermAndCondition");
                return;
              }
              const accountString = await AsyncStorage.getItem("@account");
              if (accountString) {
                const account = JSON.parse(accountString);

                store.dispatch(chooseSelectAccount(account));
                try {
                  const login = await addtoLogin({
                    student: admin,
                    project: account.project,
                  }).unwrap();
                } catch (error) {}
              } else {
                const systemCategories: StudentSystemType[] = [];
                const systemCategory: StudentSystemType[] = [];
                admin.system.forEach((item) => {
                  const findCategory = admin?.systemCategories?.find(
                    (cate) =>
                      cate._id === item.category && cate.displayProject === true
                  );
                  if (findCategory) {
                    systemCategories.push({ ...item, category: findCategory });
                  } else {
                    systemCategory.push({ ...item, categories: [] });
                  }
                });
                const categories: Category[] = systemCategories.map(
                  (item) => item.category
                ) as Category[];
                let system = [];
                if (!!systemCategories[0]?.project) {
                  system = [
                    {
                      ...systemCategories[0],
                      categories: categories!,
                    },
                    ...systemCategory,
                  ];
                } else {
                  system = [...systemCategory];
                }
                store.dispatch(chooseSelectAccount(system[0]));
                await AsyncStorage.setItem(
                  "@account",
                  JSON.stringify(system[0])
                );
                try {
                  const login = await addtoLogin({
                    student: admin,
                    project: system[0].project,
                  }).unwrap();
                } catch (error) {}
              }
              navigation.replace("Home");
            } else {
              navigation.replace("Login");
            }
          } catch (error) {}
        } else {
          // console.log('login')
          navigation.replace("Login");
        }
      } else if (status === "failed") {
        navigation.replace("Login");
      }
    })();
  }, [admin, status]);

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingScreen />
    </View>
  );
};

export default Auth;
