import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { BadgeSystem } from "../type/type";
import { initialState } from "../../onBoarding/slice/onBoardingSlice";

const badgeSystemAdaptor = createEntityAdapter<BadgeSystem>({
  selectId: (badgeSystem) => badgeSystem._id,
});

interface BadgeSystemInitialState extends EntityState<BadgeSystem> {}

const intialState: BadgeSystemInitialState =
  badgeSystemAdaptor.getInitialState();

export const badgeSystemApii = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBadges: builder.query<
      EntityState<BadgeSystem>,
      {
        currentSystem: string;
      }
    >({
      query: (data) => `/badges?currentSystemString=${data.currentSystem}`,
      transformResponse: (response: BadgeSystem[]) => {
        return badgeSystemAdaptor.setAll(intialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "BadgeSystem",
          id: "LIST",
          ...result!.ids.map((id) => {
            type: "BadgeSystem" as const, id;
          }),
        },
      ],
    }),
    getAllLockedBadges: builder.query<
      EntityState<BadgeSystem>,
      {
        currentSystem: string;
      }
    >({
      query: (data) =>
        `/badges/locked?currentSystemString=${data.currentSystem}`,
      transformResponse: (response: BadgeSystem[]) => {
        return badgeSystemAdaptor.setAll(intialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "BadgeSystem",
          id: "LIST",
          ...result!.ids.map((id) => {
            type: "BadgeSystem" as const, id;
          }),
        },
      ],
    }),
    getAllAchiveBadges: builder.query<
      EntityState<BadgeSystem>,
      {
        currentSystem: string;
      }
    >({
      query: (data) =>
        `/badges/achive?currentSystemString=${data.currentSystem}`,
      transformResponse: (response: BadgeSystem[]) => {
        return badgeSystemAdaptor.setAll(intialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "BadgeSystem",
          id: "LIST",
          ...result!.ids.map((id) => {
            type: "BadgeSystem" as const, id;
          }),
        },
      ],
    }),

    getAllLevelBadge: builder.query<
      EntityState<BadgeSystem>,
      {
        badgeId: EntityId;
        currentSystem: string;
      }
    >({
      query: (data) =>
        `/badges/${data.badgeId}?currentSystemString=${data.currentSystem}`,
      transformResponse: (response: BadgeSystem[]) => {
        return badgeSystemAdaptor.setAll(intialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "BadgeSystem",
          id: "LIST",
          ...result!.ids.map((id) => {
            type: "BadgeSystem" as const, id;
          }),
        },
      ],
    }),
  }),
});

export const {
  useGetAllBadgesQuery,
  useLazyGetAllBadgesQuery,
  useGetAllLevelBadgeQuery,
  useLazyGetAllLevelBadgeQuery,
  useGetAllAchiveBadgesQuery,
  useLazyGetAllAchiveBadgesQuery,
  useGetAllLockedBadgesQuery,
  useLazyGetAllLockedBadgesQuery,
} = badgeSystemApii;
