import { Image } from "expo-image";
import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { AuthUser } from "../../auth/types/types";
import { FONTS, images } from "../../../constants";
import { FontAwesome } from "@expo/vector-icons";
import LottieView from "lottie-react-native";
import LinearChart from "../../../components/LinearChart/LinearChart";
import ProgressBarWithLabel from "../../../components/ProgressBarWithLabel/ProgressBarWithLabel";
type ProfileAccountSettingPresentationProps = {
  user: AuthUser;
  pickImage: () => void;
  pickBanner: () => void;
  notificationAnimations: any;
  onOpenNotificationSetting: () => void;
  value: number;
  showProfile: boolean;
  showBgBanner: boolean;
  showNotification: boolean;
};
const { height } = Dimensions.get("window");
const ProfileAccountSettingPresentation: React.FC<
  ProfileAccountSettingPresentationProps
> = ({
  user,
  pickImage,
  pickBanner,
  notificationAnimations,
  onOpenNotificationSetting,
  value,
  showBgBanner,
  showNotification,
  showProfile,
}) => {
  return (
    <View style={styles.profileSettingContent}>
      <ProgressBarWithLabel
        value={value}
        lastValue={0}
        maxValue={100}
        minValue={0}
      />
      <TouchableOpacity
        onPress={pickImage}
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        <View style={styles.profileSettingBox}>
          <View style={styles.profileContent}>
            <Image
              style={styles.profileSettingImage}
              source={{ uri: user?.image }}
            />
            <Text style={[styles.profileSettingTitle]}>Profile Images</Text>
          </View>
          {showProfile && (
            <Image source={images.CheckIcon} style={styles.CheckIconStyle} />
          )}
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={pickBanner}
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        <View style={styles.profileSettingBox}>
          <View style={styles.profileContent}>
            <Image
              source={{
                uri: user?.backgroundBanner,
              }}
              style={styles.profileSettingImage}
            />

            <Text style={[styles.profileSettingTitle]}>Banner Image</Text>
          </View>
          {/* <View style={styles.LockBox}>
            <Image style={styles.LockImage} source={LockImage} />
            <Text style={styles.LockNumber}>2</Text>
          </View> */}
          {showBgBanner && (
            <Image source={images.CheckIcon} style={styles.CheckIconStyle} />
          )}
        </View>
      </TouchableOpacity>

      {/* <View style={styles.profileSettingBox}>
        {showNotification ? (
          <View style={styles.profileContent}>
            <LottieView
              loop
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: -7,
                left: -8,
                zIndex: 2,
              }}
              ref={notificationAnimations}
              source={images.notificationAnimation}
            />
            <Image
              source={images.ProfileNotification}
              style={styles.profileSettingImage}
            />
            <TouchableOpacity
              onPress={() => {
                onOpenNotificationSetting();
              }}
            >
              <Text style={[styles.profileSettingTitle]}>
                Turn on Notifications
              </Text>
            </TouchableOpacity> 
            <View style={styles.xpContianer}>
              <View style={styles.xpContent}>
                <Text style={styles.xpNumber}>500</Text>
                <Text style={styles.xpText}>XP</Text>
              </View>

              <View style={styles.coinNumberContainer}>
                <Text style={styles.coinNumber}>5</Text>
                <Image
                  source={images.gemImage}
                  style={styles.coinNumberImage}
                />
              </View>
            </View>
          </View>
        ) : (
          <View style={styles.profileContent}>
            <Image
              source={images.ProfileNotification}
              style={styles.profileSettingImage}
            />
            <TouchableOpacity
              onPress={() => {
                onOpenNotificationSetting();
              }}
            >
              <Text style={[styles.profileSettingTitle]}>
                Notifications are on
              </Text>
            </TouchableOpacity>

            <Image source={images.CheckIcon} style={styles.CheckIconStyle} />
          </View>
        )}
      </View> */}
    </View>
  );
};

const { styles } = StyleSheet.create({
  profileSettingContainer: {
    width: "100%",
    marginLeft: 0,
  },
  profilePicShape: {
    "@media (max-width: 350px)": {
      top: -30,
      transform: [{ scale: 0.8 }],
    },
  },
  coinNumberImage: {
    marginLeft: 2,
    height: 28,
    width: 32,
    left: 10,
  },
  xpContent: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 5,
  },
  xpContianer: {
    flexDirection: "column",
    marginTop: 5,
    alignItems: "center",
  },
  xpNumber: {
    color: "black",
    fontSize: 20,
    paddingLeft: 20,
    fontWeight: "bold",
    "@media (max-width: 350px)": {
      fontSize: 17,
    },
  },
  xpText: {
    color: "#FF0000",
    fontSize: 20,
    paddingLeft: 3,
    fontWeight: "bold",
    "@media (max-width: 350px)": {
      fontSize: 17,
    },
  },
  coinNumberContainer: {
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    backgroundColor: "#000",
    borderRadius: 18,
    paddingLeft: 10,
    height: 20,
    marginLeft: "3%",
  },
  coinNumber: {
    ...FONTS.h4,
    fontSize: 16,
    color: "#fff",
    "@media (max-width: 350px)": {
      fontSize: 14,
    },
  },
  profileNameText: {
    "@media (max-width: 350px)": {
      top: -40,
    },
  },
  LockBox: {
    padding: 3,
    backgroundColor: "#34C38F",
    borderRadius: 5,
    position: "relative",
  },
  LockImage: {
    width: 37,
    height: 34,
  },
  disableStyle: {
    color: "#B1ADAD",
  },
  LockNumber: {
    position: "absolute",
    ...FONTS.body2,
    fontSize: 14,
    color: "#34C38F",

    top: 15,
    left: 17,
  },
  profileSettingBox: {
    backgroundColor: "white",
    borderBottomColor: "#9D9A9A",
    borderBottomWidth: 1,
    borderStyle: "solid",
    color: "#fff",
    width: "89%",
    flexDirection: "row",
    margin: "2%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    marginLeft: 20,
    marginRight: 20,
  },
  GreenBox: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#34C38F",
    borderRadius: 4,
    color: "#fff",
  },
  profileContent: {
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
  },
  profileSettingTitle: {
    paddingLeft: 6,
    fontWeight: "bold",
    fontSize: 22,
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 20,
      paddingLeft: 10,
    },
  },
  CheckIconStyle: {
    height: 28,
    width: 36,
    marginLeft: "15%",
  },
  profileSettingImage: {
    borderRadius: 50,
    height: 42,
    width: 42,
    backgroundColor: "#e3e3e3",
    "@media (max-width: 350px)": {
      height: 50,
      width: 50,
    },
  },
  EditText: {
    color: "#fff",
    ...FONTS.body3,
    fontSize: 16,
    padding: 4,
  },
  EditShape: {
    backgroundColor: "#fff",
    borderRadius: 50,
    height: 18,
    width: 18,
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    marginRight: 4,
  },
  EditImage: {
    height: 12,
    width: 12,
    marginTop: 3,
  },
  profileInfo: {},
  backProfilePic: {
    width: "100%",
    height: 80,
  },
  profilePic: {
    alignItems: "center",
  },

  backProfileImage: {
    width: "100%",
    backgroundColor: "#e3e3e3",
    height: 170,
  },
  profileSettingContent: {
    width: "100%",
    shadowColor: "#000",
    alignSelf: "center",
    shadowOffset: {
      width: 0,
      height: -12,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6.84,
    backgroundColor: "white",
    elevation: 7,
    borderRadius: 20,
    minHeight: height - 200,
    marginTop: 60,
    height: "100%",
    top: -70,
    "@media (max-width: 350px)": {
      top: -30,
      paddingTop: 10,
    },
  },
});

export default ProfileAccountSettingPresentation;
