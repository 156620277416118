import React from "react";
import { Dimensions, Platform } from "react-native";
import { View } from "react-native";
import StyleSheet from "react-native-media-query";
import BackPictrueProfile from "../../../components/BackPictureProfile/BackPictrueProfile";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import ProfileSettingImageContainer from "../../profileSetting/container/ProfileSettingImageContainer";
import ProfileInfoImage from "../../../components/ProfileInfoImage/ProfileInfoImage";
import ProfileChartContainer from "../../ProfileCharts/profileChartContainer/ProfileChartContainer";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
const isAndroid = Platform.OS === "android" ? true : false;
const { width } = Dimensions.get("window");
type HomeHeaderPresentationProps = {
  student: AuthUser;
  currentSystem: Partial<StudentSystemType>;
};
const HomeHeaderPresentation: React.FC<HomeHeaderPresentationProps> = ({
  student,
  currentSystem,
}) => {
  return (
    <View style={{ width: "95%", alignSelf: "center" }}>
      <BackPictrueProfile backBanner={student?.backgroundBanner} />
      <View style={styles.profileInfo}>
        <ProfileInfoImage currentSystem={currentSystem} student={student} />

        <ProfileChartContainer />
      </View>
    </View>
  );
};
const { styles } = StyleSheet.create({
  leaderBoardImage: {
    width: 45,
    height: 45,
  },
  academicChartText: {
    color: "#3E3F5E",

    fontSize: 36,
    textAlign: "center",
    marginTop: 15,
    "@media (max-width: 350px)": {
      fontSize: 28,
    },
  },
  profileProgressCharts: {
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    top: -140,
    justifyContent: "space-around",
    padding: 10,

    "@media (max-width: 350px)": {
      width: width,
      transform: [{ scale: 0.8 }],
      top: -170,
      justifyContent: "space-between",
    },
  },

  profileInfoShapeStyle: {
    "@media (max-width: 350px)": {
      transform: [{ scale: 0.75 }],
    },
  },
  profileContainer: {
    backgroundColor: "#fff",
    "@media (max-width: 350px)": {
      zIndex: 2,
    },
    // marginTop: isAndroid ? 25 : 0,
  },
  profileHeader: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  profileXpCoinsContainer: {
    marginTop: 15,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerxpcontainer: {
    flex: 1,

    paddingBottom: 10,
    paddingRight: 5,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },

  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#1069C1",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#1069C1",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,

    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
    "@media (max-width: 350px)": {
      fontSize: 13,
    },
  },
  xp: {
    color: "white",
    fontSize: 26,

    "@media (max-width: 350px)": {
      fontSize: 24,
    },
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  profileInfoContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    flexWrap: "wrap",
  },
  profileInfo: {
    width: "100%",
    height: 380,
    marginTop: -50,
    borderRadius: 15,

    alignSelf: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 10,
    backgroundColor: "#fff",
    elevation: 3,
    "@media (max-width: 350px)": {
      height: 380,
    },
  },
  profileInfoHeight: {
    height: 600,
    "@media (max-width: 350px)": {
      height: 500,
    },
  },
  leaderBoardContainer: {
    width: "95%",
    height: 600,
    marginBottom: 10,
    marginTop: 30,
    alignItems: "center",
    backgroundColor: "#033C56",

    borderWidth: 10,
    borderRadius: 15,
    borderColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    "@media (max-width: 350px)": {
      height: 350,
    },
  },
  leaderBoardHeader: {
    width: "92%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 12,
    "@media (max-width: 350px)": {
      marginTop: 18,
    },
  },
  leaderBoardHeaderText: {
    fontSize: 35,

    color: "#fff",
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "white",
    width: "80%",
    textAlign: "center",
    "@media (max-width: 350px)": {
      fontSize: 33,
      paddingTop: 8,
    },
  },
  leaderColumn: {
    width: "80%",
  },
  leaderBoardContent: {
    width: "100%",
    height: 500,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 10,
    "@media (max-width: 350px)": {
      height: "78%",
    },
  },
  scrollView: {
    height: "20%",
    width: "80%",
    margin: 20,
    alignSelf: "center",
    padding: 20,
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "black",
    backgroundColor: "lightblue",
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgrey",
    paddingBottom: 50,
  },

  profilePicContainer: {
    alignItems: "center",
    width: "100%",
  },
});

export default HomeHeaderPresentation;
