import React from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../../components/TextStroke/TextStroke";

const FriendsTextPresentation = () => {

  return (

        <View style={styles.ProfileSettingMenuTextContainer}>

          <TextStroke stroke={ 2 } color={ '#FFF' } >
              <Text style={ {
                fontSize: 52,
                color: '#000',
                letterSpacing:3
              } }> Friends </Text>

            </TextStroke>

        </View>

  );

};


const { styles } = StyleSheet.create({
    ProfileSettingMenuTextContainer:{
      top:50,
      position:"absolute",
      width:"50%",
      marginLeft:"21%",
      marginRight:"22%"
   },


});

export default FriendsTextPresentation;
