import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { Comment, CommentLike } from "../types";

const commentAdaptor = createEntityAdapter<Comment>({
  selectId: (comment) => comment._id,
});

interface ICommentInitialState extends EntityState<Comment> {}

const initialState: ICommentInitialState = commentAdaptor.getInitialState();

export const extendeCommentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllComment: builder.query<EntityState<Comment>, string>({
      query: (id) => `/comment/${id}`,
      transformResponse: (response: Comment[]) => {
        return commentAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Comment",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Comment" as const, id })),
      ],
    }),
    addComment: builder.mutation<EntityState<Comment>, Partial<Comment>>({
      query: (data) => ({
        url: `/comment`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response: Comment) => {
        return commentAdaptor.addOne(initialState, response);
      },
      invalidatesTags: ["Comment"],
    }),
    addLikeComment: builder.mutation<
      Partial<CommentLike>,
      {
        comment: string;
        like: boolean;
      }
    >({
      query: (data) => ({
        url: `/comment-like`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: CommentLike) => {
        return response;
      },
      invalidatesTags: ["Comment", "CommentLike"],
    }),
    deleteComment: builder.mutation<EntityState<Comment>, EntityId>({
      query: (data) => ({
        url: `/comment/${data}`,
        method: "DELETE",
        body: data,
      }),
      transformResponse: (response: Comment) => {
        return commentAdaptor.removeOne(initialState, response!._id);
      },
      invalidatesTags: ["Comment"],
    }),
  }),
});

export const {
  useAddCommentMutation,
  useGetAllCommentQuery,
  useLazyGetAllCommentQuery,
  useAddLikeCommentMutation,
  useDeleteCommentMutation,
} = extendeCommentApi;
