import React from "react";
import { SafeAreaView, Dimensions, View, FlatList, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import BadgesProfileInfo from "./BadgesProfileInfo";
import BadgesList from "./BadgesList";
import SocialConnectionPresentation from "./BadgesSocialConnectionPresentation";
import BadgesSocialConnectionPresentation from "./BadgesSocialConnectionPresentation";
import BadgesExperienceListPresentation from "./BadgesExperienceListPresentation";
import { useGetStudentPostQuery } from "../api/badgesApi";
import { ProfilePost } from "../api/types/types";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";

import BadgesExperienceItemPresentation from "./BadgesExperienceItemPresentation";
import { AuthUser } from "../../auth/types/types";
import { EntityId } from "@reduxjs/toolkit";
import { BadgeSystem } from "../../badgeSystem/type/type";

const { height } = Dimensions.get("window");
type BadgesContentPresentationProps = {
  student: Partial<AuthUser>;
  posts: ProfilePost;
  friendsCount: number;
  likeCount: number;
  loginCount: number;
  rank: number;
  onPhotoClick: (item: Partial<ExperiencePhoto> | null) => void;
  onSocialPoint: () => {
    socialPoint: number;
    lv: number;
  };
  applyLike: (item: Partial<ExperiencePhoto>) => void;
  studentLevel: number;
  lastBadges: {
    student: EntityId;
    badge: BadgeSystem;
    complete: boolean;
  }[];
  onPhotoCommentClick: (experiencePhoto: Partial<ExperiencePhoto>) => void;
};
const BadgesContentPresentation: React.FC<BadgesContentPresentationProps> = ({
  student,
  posts,
  friendsCount,
  likeCount,
  loginCount,
  onPhotoClick,
  onSocialPoint,
  applyLike,
  studentLevel,
  rank,
  lastBadges,
  onPhotoCommentClick,
}) => {
  return (
    <View
      style={{
        position: "absolute",
        top: 95,
        right: 0,
        overflow: "hidden",
        width: "100%",
        maxHeight: height,
      }}
    >
      <FlatList
        data={posts?.data}
        ListHeaderComponent={
          <View style={styles.badgetContent}>
            <BadgesProfileInfo student={student} studentLevel={studentLevel} />
            <BadgesList lastBadges={lastBadges} />
            <BadgesSocialConnectionPresentation
              posts={posts}
              friendsCount={friendsCount}
              likeCount={likeCount}
              loginCount={loginCount}
              onSocialPoint={onSocialPoint!}
              rank={rank}
            />
            <View style={styles.ExperienceList}>
              <Text style={styles.ExperienceListTitle} numberOfLines={1}>
                Experiences
              </Text>
              <SafeAreaView style={styles.ExperienceListContet}>
                <FlatList
                  data={posts?.data}
                  numColumns={3}
                  renderItem={({ item }) => (
                    <BadgesExperienceItemPresentation
                      item={item}
                      onPhotoClick={onPhotoClick!}
                      applyLike={applyLike}
                      onPhotoCommentClick={onPhotoCommentClick}
                    />
                  )}
                />
              </SafeAreaView>
            </View>
          </View>
        }
        keyExtractor={(item) => `${item._id}`}
        renderItem={({ item }) => <></>}
      />
    </View>
  );
};

const { styles } = StyleSheet.create({
  ExperienceListTitle: {
    width: "100%",
    paddingLeft: 15,
    fontSize: 18,
    color: "#3E3F5E",
    fontFamily: "RajdahaniBold",
    paddingTop: 3,
    paddingBottom: 4,
  },
  ExperienceList: {
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 8,
    shadowColor: "#000",
    alignSelf: "center",
    width: "100%",
    marginBottom: 50,
    top: -180,
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.12,
    shadowRadius: 6.84,
    elevation: 7,
    padding: 10,
  },
  ExperienceListContet: {
    width: "95%",

    flex: 1,
  },
  badgetContentContainer: {
    minHeight: height,
    width: "100%",
  },
  badgetContent: {
    width: "100%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -12,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6.84,
    backgroundColor: "white",
    elevation: 7,
    borderRadius: 20,
    paddingTop: 30,
    marginTop: 57,
    paddingLeft: 15,
    paddingRight: 15,
    zIndex: 100,
    flex: 1,
    minHeight: height,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    alignSelf: "flex-start",
    justifyContent: "flex-start",

    "@media (max-width: 350px)": {
      minHeight: height - 100,
    },
  },
});
export default BadgesContentPresentation;
