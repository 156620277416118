import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  ImageBackground,
  Image,
} from "react-native";
import StyleSheet from "react-native-media-query";

import { FONTS, SIZES, images } from "../../../constants";
import { ExperienceCard } from "../types/types";
import ExperienceDetailsPresentation from "./ExperienceDetailsPresentation";
import { ReviveCard } from "../../reviveCard/types/type";
import { calculateRemaining } from "../../../helper/diffTime";
type ExperienceItemRevivePresentationProps = {
  item: Partial<ReviveCard>;
  onItemClick: (card: Partial<ExperienceCard>, isRevive?: boolean) => void;
  openDetailsHandler?: () => void;
  passDue?: boolean;
  complete?: boolean;
  completeHandler?: () => void;
  onItemClickHandlerRevive?: (rItem: Partial<ReviveCard> | null) => void;
};
const ExperienceItemRevivePresentation: React.FC<
  ExperienceItemRevivePresentationProps
> = ({
  item,
  onItemClick,
  openDetailsHandler,
  passDue,
  onItemClickHandlerRevive,
  complete,
}) => {
  const [month, setMonth] = useState("");
  const [day, setDay] = useState(0);
  const [year, setYear] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [itemState, setItemState] = useState(null);
  const [loadImage, setLoadImage] = useState(false);
  const [retakeState, setRetakeState] = useState(false);
  const [hasPhotoState, setHasPhotoState] = useState(null);

  const { days, hours } = calculateRemaining(
    new Date(item!.endDate!),
    new Date()
  );

  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const getMonthFromDate = new Date(item!.endDate!).getMonth();
    const getDayFromDate = new Date(item!.endDate!).getDate();
    const getYearfromDate = new Date(item!.endDate!).getFullYear();

    setMonth(months[getMonthFromDate]);
    setDay(getDayFromDate);
    setYear(getYearfromDate);
  }, []);
  return (
    <TouchableOpacity
      style={styles.expItemContainer}
      onPress={() => {
        onItemClickHandlerRevive!(item);
        onItemClick!(item.experienceCard!, true);
        openDetailsHandler!();
      }}
    >
      <ImageBackground
        source={images.hotspotPopupItemBack}
        resizeMode="contain"
        style={styles.hotspotBack}
      >
        <View style={styles.content}>
          <View style={styles.box}>
            <Text style={styles.title}>{item!.experienceCard!.title}</Text>
            <Text style={styles.subTitle}>
              {item!.experienceCard!.subTitle}
            </Text>
            <Text style={styles.Date}>
              {" "}
              {month} {day}, {year}{" "}
            </Text>
          </View>

          <View style={styles.Timer}>
            <Text style={styles.TimerText}> {days} days </Text>
            <Text style={styles.TimerText}> {hours} hrs </Text>
          </View>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

const { styles } = StyleSheet.create({
  hotspotBack: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    resizeMode: "cover",
    height: 82,
  },
  hotspotRev: {
    alignSelf: "center",
    width: 85,
    height: 30,
  },
  box: {
    justifyContent: "center",
  },
  expItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "98%",
    minWidth:"98%",
    marginLeft: "1%",
  },

  content: {
    width: "82%",
    height: 58,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    paddingRight: 20,
  },
  xp: {
    width: 85,
    height: 30,
    backgroundColor: "green",
  },

  title: {
    fontFamily: "Roboto-Bold",
    fontSize: 13,
    color: "#000",
    fontWeight: "bold",
  },
  subTitle: {
    ...FONTS.body3,
    fontSize: 10,
    lineHeight: 15,
  },
  Date: {
    ...FONTS.body5,
    fontSize: 10,
    lineHeight: 10,
    paddingTop: 3,
  },
  image: {
    position: "absolute",
    left: 33,
    top: 0,
    flex: 1,
    width: 3,
    height: 98,
  },
  completedIcon: {
    flex: 1,
    minWidth: 50,
    paddingRight: 20,
    justifyContent: "center",
    "@media (max-width: 350px)": {
      minWidth: 30,
      flex: 0.5,
    },
  },
  completeImage: {
    alignSelf: "center",
    width: 15,
    height: 15,
  },
  TimerText: {
    fontSize: 12,
    fontFamily: "HelveticaBold",
  },
  Timer: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});
export default ExperienceItemRevivePresentation;
