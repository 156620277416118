import React from "react";
import SwitchAccountPresentation from "../presentation/SwitchAccountPresentation";
import ProfileSettingHeaderContainer from "./ProfileSettingHeaderContainer";
import ProfileSettingImageContainer from "./ProfileSettingImageContainer";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin } from "../../auth/slice/authSlice";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { AuthUser, StudentSystemType } from "../../auth/types/types";

type SwitchAccountContainerProps = {
  user: AuthUser;
};
const SwitchAccountContainer: React.FC<SwitchAccountContainerProps> = ({
  user,
}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();

  const backToHome = () => {
    navigation.navigate("Home");
  };
  return <SwitchAccountPresentation user={user} backToHome={backToHome} />;
};

export default SwitchAccountContainer;
