import React from "react";
import { Dimensions, Platform, View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { ProfileChart } from "../types/profileCharts.type";

import CircleChart from "../../../components/CircleChart/CircleChart";
import { calulateCircleChartPercent } from "../../../helper/chart.helper";
import LinearChart from "../../../components/LinearChart/LinearChart";
import {
  useGetAllAcademicScoreQuery,
  useGetAllGradeAvgScoreQuery,
} from "../../academicScore/academicScoreApi";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { LearnerSetting } from "../../learnerSetting/type/LearnerSetting";
import { EntityState } from "@reduxjs/toolkit";
const isAndroid = Platform.OS === "android" ? true : false;
const { width } = Dimensions.get("window");
type ProfileChartPresentationProps = {
  chartData: ProfileChart | null;
  chartView: string;
  leanerSettingData?: EntityState<LearnerSetting>;
};
const PofileChartPresentation: React.FC<ProfileChartPresentationProps> = ({
  chartData,
  chartView,
  leanerSettingData,
}) => {
  const systemCurrent = useAppSelector(selectDefaultAccount);

  const {
    data: academic,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllAcademicScoreQuery({
    currentSystem: JSON.stringify(systemCurrent),
  });

  const {
    data: gradeScore,
    isLoading: gradeAvgLoading,
    isError: gradeAvgError,
    isSuccess: gradeAvgSuccess,
  } = useGetAllGradeAvgScoreQuery({
    currentSystem: JSON.stringify(systemCurrent),
  });

  return (
    <View style={styles.profileProgressCharts}>
      <CircleChart
        textWeight={"100"}
        chartPercentCal={calulateCircleChartPercent(
          380,
          chartData!.progress.current
        )}
        title={"Progress"}
        description={"Score"}
        percent={Math.round(chartData!.progress.current)}
        dotCircle
      />
      {chartView === "Academic" ? (
        <>
          {leanerSettingData?.entities[leanerSettingData.ids[0]]
            ?.typeOfAcademic === "Default Gradegy" ? (
            <CircleChart
              percent={
                academic?.current_grade
                  ? Math.floor(academic?.current_grade!)
                  : 0
              }
              textWeight={"100"}
              chartPercentCal={calulateCircleChartPercent(
                380,
                academic?.current_grade
                  ? Math.floor(academic?.current_grade!)
                  : 0
              )}
              title={"Grade"}
              description={"Score"}
              // numbericCircle={chartData!.momentum.current - chartData!.momentum.last}
              lastChartPercentCal={calulateCircleChartPercent(
                380,
                chartData!.momentum.last
              )}
            />
          ) : (
            <CircleChart
              percent={
                gradeScore?.gradeAvg ? Math.floor(gradeScore?.gradeAvg!) : 0
              }
              textWeight={"100"}
              chartPercentCal={calulateCircleChartPercent(
                380,
                gradeScore?.gradeAvg ? Math.floor(gradeScore?.gradeAvg!) : 0
              )}
              title={"Grade"}
              description={"Score"}
              // numbericCircle={chartData!.momentum.current - chartData!.momentum.last}
              lastChartPercentCal={calulateCircleChartPercent(
                380,
                chartData!.momentum.last
              )}
              showAvg={true}
            />
          )}
        </>
      ) : (
        <CircleChart
          percent={Math.round(chartData!.momentum.current)}
          textWeight={"100"}
          chartPercentCal={calulateCircleChartPercent(
            380,
            chartData!.momentum.current
          )}
          title={"Momentum"}
          description={"Score"}
          // numbericCircle={chartData!.momentum.current - chartData!.momentum.last}
          lastChartPercentCal={calulateCircleChartPercent(
            380,
            chartData!.momentum.last
          )}
        />
      )}

      {chartView === "Academic" && (
        <View>
          <Text style={styles.academicChartText}>Academic Progress</Text>

          {isSuccess && (
            <>
              {leanerSettingData?.entities[leanerSettingData.ids[0]]
                ?.typeOfAcademic === "Default Gradegy" ? (
                <LinearChart
                  value={
                    academic?.course_completion
                      ? Math.floor(academic?.course_completion)
                      : 0
                  }
                  lastValue={Math.floor(academic?.where_you_should_be)}
                  maxValue={100}
                  minValue={0}
                />
              ) : (
                <LinearChart
                  value={
                    gradeScore?.courseCompletedAvg
                      ? Math.floor(gradeScore?.courseCompletedAvg)
                      : 0
                  }
                  lastValue={74}
                  maxValue={100}
                  minValue={0}
                  showAvg={true}
                />
              )}
            </>
          )}
        </View>
      )}
    </View>
  );
};

const { styles } = StyleSheet.create({
  leaderBoardImage: {
    width: 45,
    height: 45,
  },
  academicChartText: {
    color: "#3E3F5E",
    fontFamily: "RajdahaniBold",
    fontSize: 36,
    textAlign: "center",
    marginTop: 5,
    "@media (max-width: 350px)": {
      fontSize: 28,
    },
  },
  profileProgressCharts: {
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    top: -180,
    justifyContent: "space-around",
    padding: 10,

    "@media (max-width: 350px)": {
      width: width,
      transform: [{ scale: 0.8 }],
      top: -170,
      justifyContent: "space-between",
    },
  },

  profileInfoShapeStyle: {
    "@media (max-width: 350px)": {
      transform: [{ scale: 0.75 }],
    },
  },
  profileContainer: {
    backgroundColor: "#fff",
    "@media (max-width: 350px)": {
      zIndex: 2,
    },
    // marginTop: isAndroid ? 25 : 0,
  },
  profileHeader: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  profileXpCoinsContainer: {
    marginTop: 15,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerxpcontainer: {
    flex: 1,

    paddingBottom: 10,
    paddingRight: 5,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },

  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#1069C1",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#1069C1",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,

    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
    "@media (max-width: 350px)": {
      fontSize: 13,
    },
  },
  xp: {
    color: "white",
    fontSize: 26,

    "@media (max-width: 350px)": {
      fontSize: 24,
    },
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  profileInfoContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    flexWrap: "wrap",
  },
  profileInfo: {
    width: "95%",
    height: 550,
    borderRadius: 12,
    alignSelf: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.84,
    backgroundColor: "#fff",
    elevation: 3,
    "@media (max-width: 350px)": {
      height: 450,
    },
  },
  profileInfoHeight: {
    height: 600,
    "@media (max-width: 350px)": {
      height: 500,
    },
  },
  leaderBoardContainer: {
    width: "95%",
    height: 600,
    marginBottom: 10,
    marginTop: 30,
    alignItems: "center",
    backgroundColor: "#033C56",

    borderWidth: 10,
    borderRadius: 15,
    borderColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    "@media (max-width: 350px)": {
      height: 350,
    },
  },
  leaderBoardHeader: {
    width: "92%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 12,
    "@media (max-width: 350px)": {
      marginTop: 18,
    },
  },
  leaderBoardHeaderText: {
    fontSize: 35,

    color: "#fff",
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "white",
    width: "80%",
    textAlign: "center",
    "@media (max-width: 350px)": {
      fontSize: 33,
      paddingTop: 8,
    },
  },
  leaderColumn: {
    width: "80%",
  },
  leaderBoardContent: {
    width: "100%",
    height: 500,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 10,
    "@media (max-width: 350px)": {
      height: "78%",
    },
  },
  scrollView: {
    height: "20%",
    width: "80%",
    margin: 20,
    alignSelf: "center",
    padding: 20,
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "black",
    backgroundColor: "lightblue",
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgrey",
    paddingBottom: 50,
  },

  profilePicContainer: {
    alignItems: "center",
    width: "100%",
  },
});

export default PofileChartPresentation;
