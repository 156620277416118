import React, { useState, useEffect, FC } from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";

export interface IProgressBarWithLabel {
  value: number;
  lastValue: number;
  minValue: number;
  maxValue: number;
}

const ProgressBarWithLabel: FC<IProgressBarWithLabel> = ({
  value,
  lastValue,
  minValue,
  maxValue,
}) => {
  const [percent, setPercent] = useState(0);
  const [lastPercent, setLastPercent] = useState(0);
  const [minVal, setMinVal] = useState(0);
  const [percentText, setPercentText] = useState(0);
  const [lastPercentText, setLastPercentText] = useState(0);
  const [maxVal, setMaxVal] = useState(3500);
  useEffect(() => {
    setMaxVal(maxValue);
    setMinVal(minValue);
    const percentProgress = (value * 100) / maxVal;
    setPercent(percentProgress);
    const lastPercentProgress = (lastValue * 100) / maxVal;
    setLastPercent(lastPercentProgress);
    const textPercentProgress = (value * 100) / maxVal - 5;
    setPercentText(textPercentProgress);
    const lastTextPercentProgress = (lastValue * 100) / maxVal - 5;
    setLastPercentText(lastTextPercentProgress);
  });

  return (
    <View style={styles.containerLinearChart}>
      <View style={styles.mainLinearProgress}>
        <View
          style={[styles.progressLastLinear, { width: `${lastPercent}%` }]}
        ></View>

        <View style={[styles.progressLinear, { width: `${percent}%` }]}></View>

        <Text
          style={[
            styles.progressLastLinearVal,
            { width: `${lastPercentText}%` },
          ]}
        >
          {!isNaN(lastValue) ? lastValue : 0}%
        </Text>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  progressLinearVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 10,
    zIndex: 2,
    textAlign: "right",
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  progressLastLinearVal: {
    fontSize: 14,
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 10,
    zIndex: 0,
    textAlign: "right",
    fontWeight:"bold",
    "@media (max-width: 350px)": {
      top: 7,
    },
  },

  progressLinearMinVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 10,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  progressLinearMaxVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },

  containerLinearChart: {
    flexDirection: "row",
    borderRadius: 10,
    width: "100%",
    height: 45 ,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 20,
  },
  mainLinearProgress: {
    width: "100%",
    backgroundColor: "#AFAEAE",
    height: 45,
    borderRadius: 50,
    overflow: "hidden",
    position: "relative",
    top:-22,
    borderColor:"#fff",
    borderWidth:3,
    borderStyle:"solid",
    "@media (max-width: 350px)": {
      height: 25,
    },
  },
  progressLinear: {
    overflow: "hidden",
    height: 39,
    backgroundColor: "#4ED486",
    borderRadius: 50,
    zIndex: 1,
    position: "absolute",
    left: 0,
    "@media (max-width: 350px)": {
      height: 25,
    },
  },
  progressLastLinear: {
    overflow: "hidden",
    height: 39,
    backgroundColor: "#F7941C",
    borderRadius: 50,
    "@media (max-width: 350px)": {
      height: 25,
    },
  },
});
export default ProgressBarWithLabel;
