import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { RaffelTicket } from "../types/types";
import { IResponseList } from "../../../redux/types/types";

export const raffelTicketAdaptor = createEntityAdapter<RaffelTicket>({
  selectId: (raffelTicket) => raffelTicket._id,
});

export interface RaffelTicketInitialState extends EntityState<RaffelTicket> {}

export const raffleInitialState: RaffelTicketInitialState =
  raffelTicketAdaptor.getInitialState();

export const extendedRaffelTicketApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRaffelTicket: builder.query<
      EntityState<RaffelTicket>,
      {
        currentSystem: string;
        page?: string;
        limit?: string;
        sort?: string;
      }
    >({
      query: (data) =>
        `/raffel-ticket/raffelTicketByCurrentSystem?currentSystemString=${data.currentSystem}&page=${data.page}&limit=${data.limit}&sort=${data.sort}`,
      transformResponse: (response: IResponseList<RaffelTicket[]>) => {
        const raffelTicketData = response.data;
        const raffelTicketMetaData = response.metadata;
        return raffelTicketAdaptor.setAll(raffleInitialState, raffelTicketData);
      },
      providesTags: (result, error, arg) => [
        {
          type: "RaffelTicket",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "RaffelTicket" as const, id })),
      ],
    }),
  }),
});

export const { useGetAllRaffelTicketQuery, useLazyGetAllRaffelTicketQuery } =
  extendedRaffelTicketApi;
