import React, { useState, useEffect, FC } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import StyleSheet from "react-native-media-query";
// @ts-ignore
import shiled from "../../../assets/levelback.png";

export interface ILinearChart {
  value: number;
  lastValue: number;
  minValue: number;
  maxValue: number;
  showAvg?: boolean;
}

const LinearChart: FC<ILinearChart> = ({
  value,
  lastValue,
  minValue,
  maxValue,
  showAvg,
}) => {
  const [percent, setPercent] = useState(0);
  const [lastPercent, setLastPercent] = useState(0);
  const [minVal, setMinVal] = useState(0);
  const [percentText, setPercentText] = useState(0);
  const [lastPercentText, setLastPercentText] = useState(0);
  const [maxVal, setMaxVal] = useState(3500);
  useEffect(() => {
    setMaxVal(maxValue);
    setMinVal(minValue);
    const percentProgress = (value * 100) / maxVal;
    setPercent(percentProgress);
    const lastPercentProgress = (lastValue * 100) / maxVal;
    setLastPercent(lastPercentProgress);
    const textPercentProgress = (value * 100) / maxVal - 5;
    setPercentText(textPercentProgress);
    const lastTextPercentProgress = (lastValue * 100) / maxVal - 5;
    setLastPercentText(lastTextPercentProgress);
  });

  return (
    <View style={styles.containerLinearChart}>
      <View style={styles.mainLinearProgress}>
        {percentText > 10 && lastPercentText > 10 && (
          <Text style={styles.progressLinearMinVal}>
            {!isNaN(minVal) ? minVal : 0}
          </Text>
        )}
        <View
          style={[styles.progressLastLinear, { width: `${lastPercent}%` }]}
        ></View>

        <Text style={[styles.progressLinearVal, { width: `${percentText}%` }]}>
          {!isNaN(value) ? value : 0}
        </Text>

        {/* avg tag element */}
        {showAvg && (
          <View style={[styles.avgTagContainer, { width: `${percentText}%` }]}>
            <TouchableOpacity style={styles.avgTag}>
              <Text style={styles.avgTagText}>AVG</Text>
            </TouchableOpacity>
          </View>
        )}

        <View style={[styles.progressLinear, { width: `${percent}%` }]}></View>

        <Text
          style={[
            styles.progressLastLinearVal,
            { width: `${lastPercentText}%` },
          ]}
        >
          {!isNaN(lastValue) ? lastValue : 0}
        </Text>

        <Text style={styles.progressLinearMaxVal}>
          {!isNaN(maxVal) ? maxVal : 0}
        </Text>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  progressLinearVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 10,
    zIndex: 2,
    textAlign: "right",
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  avgTagContainer: {
    position: "absolute",
    left: 10,
    top: 8,
    zIndex: 2,
    textAlign: "right",

    alignItems: "flex-end",
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  avgTag: {
    width: 26,
    borderColor: "#000",
    borderWidth: 2,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderRadius: 4,
    alignItems: "center",
    fontSize: 9,
    color: "#fff",
    marginRight: 24,
  },
  avgTagText: {
    fontFamily: "RajdahaniBold",
    fontSize: 9,
    lineHeight: 12,
  },
  progressLastLinearVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 10,
    zIndex: 0,
    textAlign: "right",
    "@media (max-width: 350px)": {
      top: 7,
    },
  },

  progressLinearMinVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 10,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  progressLinearMaxVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },

  containerLinearChart: {
    flexDirection: "row",
    borderRadius: 10,
    width: "100%",
    height: 125,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 20,
  },
  mainLinearProgress: {
    width: "100%",
    backgroundColor: "#E3E3E3",
    height: 30,
    borderRadius: 50,
    overflow: "hidden",
    position: "relative",
    "@media (max-width: 350px)": {
      height: 25,
    },
  },
  progressLinear: {
    overflow: "hidden",
    height: 30,
    backgroundColor: "#4ED486",
    borderRadius: 50,
    zIndex: 1,
    position: "absolute",
    left: 0,
    "@media (max-width: 350px)": {
      height: 25,
    },
  },
  progressLastLinear: {
    overflow: "hidden",
    height: 30,
    backgroundColor: "#FF86B9",
    borderRadius: 50,
    "@media (max-width: 350px)": {
      height: 25,
    },
  },
});
export default LinearChart;
