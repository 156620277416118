import { EntityState } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import LockPopup from "../../../components/LockPopup/LockPopup";
import PrizeBuy from "../../../components/PrizeBuy/PrizeBuy";

import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import { useAddToBagMutation } from "../../bag/api/bagApi";
import { useLazyGetAllRaffelTicketQuery } from "../../raffelTicket/api/raffelTicketApi";
import { RaffelTicket } from "../../raffelTicket/types/types";
import { useLazyGetAllShopQuery } from "../../shop/api/shopApi";
import { Shop } from "../../shop/types/types";
import { useCreateUnlockShopMutation } from "../../unlockshop/api/unlockShopApi";
import { UnlockShop } from "../../unlockshop/types/type";
import PrizesPresentation from "../presentation/PrizesPresentation";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import { useGetCountFreeTicketQuery } from "../../freeTicket/api/freeTicketApi";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { View } from "react-native";
import { useAddReviveGemByShopMutation } from "../../revieGem/api/reviveGemApi";

type PrizesContainerProps = {
  user: AuthUser;
};
const PrizesContainer: React.FC<PrizesContainerProps> = ({ user }) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const accountSelected = useAppSelector(selectDefaultAccount);

  const [
    addToBag,
    {
      isLoading: addBagLoading,
      isError: addBagError,
      isSuccess: addBagSuccess,
    },
  ] = useAddToBagMutation();
  const [getAllShop, { isLoading, isError, isSuccess }] =
    useLazyGetAllShopQuery();

  const [
    getAllRaffelTicket,
    {
      isLoading: raffelLoading,
      isError: raffelError,
      isSuccess: raffelSuccess,
    },
  ] = useLazyGetAllRaffelTicketQuery();
  // console.log(getAllShop);

  const [
    creteUnlockShop,
    {
      isLoading: creatUnlockShopLoading,
      isError: unlockShopError,
      isSuccess: unlockShopSuccess,
    },
  ] = useCreateUnlockShopMutation();

  const {
    data: freeTicket,
    isLoading: freeTicketLoading,
    isError: freeTicketError,
    isSuccess: freeSuccess,
  } = useGetCountFreeTicketQuery({
    currentSystem: JSON.stringify(accountSelected!),
  });

  const [
    addReviveGemByShop,
    {
      isLoading: addRevieLoading,
      isError: addReviveError,
      isSuccess: addReviveSuccess,
    },
  ] = useAddReviveGemByShopMutation();
  const goBackHandler = () => {};
  const [selectedItem, setSelectedItem] = useState<Partial<StudentSystemType>>(
    accountSelected!
  );
  // const { data: shopLists, isSuccess } = useGetAllShopQuery({
  //   currentSystem: JSON.stringify(selectedItem!),
  // });
  const [unlockSelected, setUnLockSelected] =
    useState<EntityState<UnlockShop> | null>(null);
  const [shopList, setShopList] = useState<EntityState<Shop> | null>();
  const [raffelList, setRaffelList] =
    useState<EntityState<RaffelTicket> | null>(null);
  const [studentLevel, setStudentLevel] = useState<number>(0);
  const [openUnlock, setUnlock] = useState<boolean>(false);
  const [itemShop, setItemShop] = useState<
    Partial<Shop> | Partial<RaffelTicket> | null
  >(null);
  const [openPrizePopup, setOpenPrizePopup] = useState<boolean>(false);
  const [gems, setGems] = useState<number>(0);
  const [completeLoading, setCompleteLoading] = useState<boolean>(false);
  const [disableLoading, setDisableLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      const projectReport = user.projectReport.find(
        (item) => item.project === accountSelected!.project
      );

      if (projectReport) {
        setGems(projectReport.gems);
      }
    }
  }, [accountSelected, user]);
  useEffect(() => {
    if (user) {
      if (accountSelected) {
        setSelectedItem(accountSelected!);
        const projectReport = user.projectReport.find(
          (item) => item.project === accountSelected!.project
        );

        if (projectReport) {
          setStudentLevel(Math.floor(projectReport.xps! / 3500));
        }
      }
    }
  }, [accountSelected, user]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getAllShop({
          currentSystem: JSON.stringify(selectedItem),
          sort: "unlock_level",
        }).unwrap();
        setShopList(res);

        const raffelRes = await getAllRaffelTicket({
          currentSystem: JSON.stringify(selectedItem),
        }).unwrap();

        setRaffelList(raffelRes);
      } catch (error) {}
    })();
  }, [selectedItem, raffelList, unlockSelected, openPrizePopup]);

  const onOpenPrize = () => {
    setOpenPrizePopup(true);
  };

  const onClosePrize = () => {
    setOpenPrizePopup(false);
  };

  const onSelecteTab = (system: Partial<StudentSystemType>) => {
    setSelectedItem(system);
  };

  const openOnLock = () => {
    setUnlock(true);
  };

  const onCloseLock = () => {
    setUnlock(false);
  };

  const onPressShopItem = async (
    item: Partial<Shop> | Partial<RaffelTicket>
  ) => {
    setItemShop(item);
    if (item._id === 5) {
      if (accountSelected!.gems! > 1) {
        alert("Get 3 Revive Gem");
        try {
          await addReviveGemByShop({
            currentSystem: JSON.stringify(accountSelected),
          }).unwrap();
          // console.log("done");
        } catch (error) {
          // console.log(error);
        }
      } else {
        alert("You have no enough gem");
      }
      return;
    }

    if ((item as Shop)?.title) {
      if (
        Math.floor(accountSelected!.xps! / 3500) >=
        (item as Shop)?.unlock_level!
      ) {
        if (!(item as Shop)?.unlockshop) {
          openOnLock();
        } else {
          onOpenPrize();
        }
      } else {
        onOpenPrize();
      }
    }

    if ((item as RaffelTicket)?.drawDate) {
      onOpenPrize();
    }
  };

  const onPressUnLock = async () => {
    try {
      const response = await creteUnlockShop({
        shop: itemShop!,
        currentSystem: JSON.stringify(accountSelected),
      }).unwrap();
      setUnLockSelected(response);
      onCloseLock();
    } catch (error) {}
  };

  const onAddBag = async () => {
    setCompleteLoading(true);
    setDisableLoading(true);
    try {
      if ((itemShop as Shop)?.inital_stock) {
        await addToBag({
          currentSystem: selectedItem!,
          amount: itemShop!.gem!,
          bagDetails: {
            shop: itemShop! as Shop,
          },
        }).unwrap();
        setOpenPrizePopup(false);
      } else if ((itemShop as RaffelTicket)?.drawDate) {
        const res = await addToBag({
          currentSystem: selectedItem!,
          amount: itemShop!.gem!,
          bagDetails: {
            raffelTicket: itemShop! as RaffelTicket,
          },
        }).unwrap();
        setOpenPrizePopup(false);
      }

      onClosePrize();
    } catch (error) {}
    setCompleteLoading(false);
    setDisableLoading(false);
  };

  const onBag = async () => {
    navigation.navigate("Bag");
  };

  let content;
  if (isLoading || raffelLoading) {
    content = (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <LoadingScreen iconSize={50} />
      </View>
    );
  } else if (isSuccess && raffelSuccess && shopList && raffelList) {
    content = (
      <>
        {isSuccess && raffelSuccess && shopList && raffelList && (
          <>
            <PrizesPresentation
              user={user}
              goBackHandler={goBackHandler}
              currentSystem={accountSelected!}
              onSelecteTab={onSelecteTab}
              shopList={shopList!}
              raffelList={raffelList!}
              studentLevel={studentLevel!}
              onPressShopItem={onPressShopItem}
              gem={gems}
              onBag={onBag}
              freeTicket={freeTicket?.freeTicketNumber!}
            />
            <LockPopup
              open={openUnlock}
              onClose={onCloseLock}
              item={itemShop!}
              onPressUnlock={onPressUnLock}
            />
            <PrizeBuy
              open={openPrizePopup}
              onClose={onClosePrize}
              item={itemShop!}
              studentLevel={studentLevel!}
              onAddBag={onAddBag}
              gem={gems}
              completeLoading={completeLoading}
              disableLoading={disableLoading}
            />
          </>
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default PrizesContainer;
