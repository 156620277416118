import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { ReviveCard } from "../types/type";

const reviveCardAdaptor = createEntityAdapter<ReviveCard>({
  selectId: (reviveCard) => reviveCard._id,
});

interface IReviveCard extends EntityState<ReviveCard> {}

const initialState: IReviveCard = reviveCardAdaptor.getInitialState();

export const extendedReviveCard = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createReviveCard: builder.mutation<
      EntityState<ReviveCard>,
      {
        revive: Partial<ReviveCard>;
        currentSystem: string;
      }
    >({
      query: (data) => ({
        url: `/revive-card?currentSystemString=${data.currentSystem}`,
        method: "POST",
        body: data.revive,
      }),
      transformResponse: (response: ReviveCard) => {
        return reviveCardAdaptor.setOne(initialState, response);
      },
      invalidatesTags: ["ReviveCard", "RevieGem"],
    }),

    getAllRevive: builder.query<
      EntityState<ReviveCard>,
      {
        currentSystem: string;
      }
    >({
      query: (data) => `/revive-card?currentSystemString=${data.currentSystem}`,
      transformResponse: (response: ReviveCard[]) => {
        return reviveCardAdaptor.setAll(initialState, response);
      },
      providesTags: [{ type: "ReviveCard", id: "LISt" }],
    }),

    updateRevive: builder.mutation<
      EntityState<ReviveCard>,
      Partial<ReviveCard>
    >({
      query: (data) => ({
        url: `/revive-card/${data._id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: ReviveCard) => {
        return reviveCardAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: [{ type: "ReviveCard", id: "LISt" }],
    }),
  }),
});

export const {
  useCreateReviveCardMutation,
  useLazyGetAllReviveQuery,
  useGetAllReviveQuery,
  useUpdateReviveMutation,
} = extendedReviveCard;
