import React, { useEffect, useState } from "react";
import BadgesContentPresentation from "../presentation/BadgesContentPresentation";
import { ProfilePost } from "../api/types/types";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { AuthUser } from "../../auth/types/types";
import { useGetLevelRankMutation } from "../api/badgesApi";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { EntityId } from "@reduxjs/toolkit";
import { BadgeSystem } from "../../badgeSystem/type/type";

type BadgesContainerProrps = {
  student: Partial<AuthUser>;
  posts: ProfilePost;
  friendsCount: number;
  likeCount: number;
  loginCount: number;
  onPhotoClick: (item: Partial<ExperiencePhoto> | null) => void;
  applyLike: (item: Partial<ExperiencePhoto>) => void;
  studentLevel: number;
  commentLikeCount: number;
  lastBadges: {
    student: EntityId;
    badge: BadgeSystem;
    complete: boolean;
  }[];
  onPhotoCommentClick: (experiencePhoto: Partial<ExperiencePhoto>) => void;
};
const BadgesContainer: React.FC<BadgesContainerProrps> = ({
  student,
  posts,
  friendsCount,
  likeCount,
  loginCount,
  onPhotoClick,
  applyLike,
  studentLevel,
  commentLikeCount,
  lastBadges,
  onPhotoCommentClick,
}) => {
  const systemSelected = useAppSelector(selectDefaultAccount);
  const [getRank, { isLoading, isError, isSuccess }] =
    useGetLevelRankMutation();
  const [rank, setRank] = useState<number>(0);

  const socialCalculatePoints = (): {
    socialPoint: number;
    lv: number;
  } => {
    const socialPostPoint = 20 * (posts ? posts?.count?.postStudnetCount : 0);
    const socialFriendsPoint = 10 * friendsCount;
    const socialLoginPoint = loginCount;
    const socialLikePoint = likeCount;
    return {
      socialPoint:
        socialPostPoint +
        socialFriendsPoint +
        socialLoginPoint +
        socialLikePoint,
      lv: Math.round(
        (socialPostPoint +
          socialFriendsPoint +
          socialLoginPoint +
          socialLikePoint) /
          50
      ),
    };
  };

  useEffect(() => {
    (async () => {
      try {
        const rankk = await getRank({
          student: student,
          project: systemSelected!.project as string,
          level: socialCalculatePoints().lv,
        }).unwrap();

        setRank(rankk);
      } catch (error) {}
    })();
  }, [socialCalculatePoints().lv]);
  return (
    <>
      <BadgesContentPresentation
        student={student}
        posts={posts}
        friendsCount={friendsCount!}
        likeCount={likeCount! + commentLikeCount!}
        loginCount={loginCount}
        onPhotoClick={onPhotoClick}
        onSocialPoint={socialCalculatePoints}
        applyLike={applyLike}
        studentLevel={studentLevel}
        rank={rank}
        lastBadges={lastBadges}
        onPhotoCommentClick={onPhotoCommentClick}
      />
    </>
  );
};

export default BadgesContainer;
