import React from "react";
import CameraContainer from "../features/cameraFeatures/container/CameraContainer";
import { useRoute } from "@react-navigation/native";
import {
  CameraScreenRouteProp,
  HomeScreenNavigationProps,
} from "../navigation/types";

const CameraScreeen = () => {
  const route = useRoute<CameraScreenRouteProp>();
  const { item, currentSystem, prevItem } = route.params;

  return (
    <CameraContainer
      item={item}
      currentSystem={currentSystem}
      prevItem={prevItem}
    />
  );
};

export default CameraScreeen;
