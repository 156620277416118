import React from "react";
import { View, TouchableOpacity } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";


type NorthstarBackButtonProps = {
  goBackHandler: () => void;

};
const NorthstarBackButton: React.FC<
NorthstarBackButtonProps
> = ({goBackHandler }) => {
  return (




        <View
        style={{
          height: 40,
          backgroundColor: "rgba(255,255,255,0.75)",
          padding: 4,
          borderRadius: 50,
          marginTop: 46,
          width:40,
          marginLeft:24,
          marginBottom:10
        }}
        >
            <TouchableOpacity onPress={goBackHandler}>
              <FontAwesome5 name="arrow-circle-left" size={32} color="black"  style={{width:32 ,top:-1, height:32 , borderRadius:50}}  />
            </TouchableOpacity>
        </View>
  );
};

export default NorthstarBackButton;
