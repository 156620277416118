import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { useLazyGetProfileChartQuery } from "../api/profileChartsApi";
import { ProfileChart } from "../types/profileCharts.type";
import PofileChartPresentation from "../profileChartPresentation/PofileChartPresentation";
import { useGetALearnerSettingQuery } from "../../learnerSetting/api/learnerSetting";
import { EntityId } from "@reduxjs/toolkit";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";

const ProfileChartContainer = () => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const systemSelected = useAppSelector(selectDefaultAccount);

  const student = useAppSelector(selectAdmin);

  const {
    data: learnerSettingData,
    isLoading: leanerLoading,
    isSuccess: leanerSuccess,
    isError: leanerError,
  } = useGetALearnerSettingQuery(systemSelected?.project! as EntityId);

  const [getProfileChart, { isLoading, isError, isSuccess }] =
    useLazyGetProfileChartQuery();

  const [chartData, setChartData] = useState<ProfileChart | null>(null);
  const [chartView, setChartView] = useState<string>("Standard");

  useEffect(() => {
    (async () => {
      try {
        const chartDataResponse = await getProfileChart(
          JSON.stringify(systemSelected)
        ).unwrap();

        setChartData(chartDataResponse);
      } catch (error) {}
    })();
  }, [systemSelected]);

  useEffect(() => {
    if (student) {
      const findProject = student?.systemProject?.find(
        (item) => item._id.toString() === systemSelected?.project!.toString()
      );

      setChartView(findProject?.chartView!);
    }
  }, [student, systemSelected]);

  return (
    <>
      {isSuccess && chartData && leanerSuccess && learnerSettingData && (
        <TouchableOpacity
          onPress={() => {
            if (
              learnerSettingData.entities[learnerSettingData.ids[0]]
                ?.typeOfAcademic === "ASU Acceleration"
            ) {
              navigation.navigate("GradeScreen");
            }
          }}
        >
          <PofileChartPresentation
            chartData={chartData!}
            chartView={chartView}
            leanerSettingData={learnerSettingData}
          />
        </TouchableOpacity>
      )}
    </>
  );
};

export default ProfileChartContainer;
