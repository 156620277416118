import { Ionicons } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";
import {
  Camera,
  CameraCapturedPicture,
  CameraType,
  ImageType,
} from "expo-camera";
import { Image } from "expo-image";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import { useEffect, useRef, useState } from "react";
import { Button, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { images } from "../../../constants";
type CameraPresentationProp = {
  goback: () => void;
  takePicture: (photo: CameraCapturedPicture) => void;
  pickPicture: (photo: CameraCapturedPicture) => void;
};
const CameraPresentation: React.FC<CameraPresentationProp> = ({
  goback,
  takePicture,
  pickPicture,
}) => {
  const isFoucesd = useIsFocused();
  const [camera, setCamera] = useState<boolean>(true);
  const [type, setType] = useState(CameraType.back);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const cameraRef = useRef<Camera | null>(null);

  useEffect(() => {
    if (isFoucesd) {
      setCamera(true);
    }
  }, [isFoucesd]);
  useEffect(() => {
    return () => {
      if (camera) {
        cameraRef.current?.pausePreview();
        cameraRef.current = null;
        setCamera(false);
      }
    };
  }, [camera]);
  if (!permission) {
    // Camera permissions are still loading
    return <View />;
  }

  if (!permission.granted) {
    // Camera permissions are not granted yet
    return (
      <View style={styles.container}>
        <Text style={{ textAlign: "center" }}>
          We need your permission to show the camera
        </Text>
        <Button onPress={requestPermission} title="grant permission" />
      </View>
    );
  }

  function toggleCameraType() {
    setType((current) =>
      current === CameraType.back ? CameraType.front : CameraType.back
    );
  }

  const snapPicutre = async () => {
    const photo = await cameraRef.current?.takePictureAsync({
      quality: 1,
      imageType: ImageType.jpg,
      skipProcessing: true,
    });

    const resizedPhoto = await ImageManipulator.manipulateAsync(
      photo!.uri,
      [
        {
          resize: {
            width: 720, // Or whatever size you want
          },
        },
      ],
      { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
    );

    if (camera) {
      cameraRef.current?.pausePreview();
      cameraRef.current = null;
      setCamera(false);
    }
    takePicture(resizedPhoto!);
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,

      quality: 1,
    });

    if (!result.canceled) {
      const resizedPhoto = await ImageManipulator.manipulateAsync(
        result.assets[0].uri,
        [
          {
            resize: {
              width: 720, // Or whatever size you want
            },
          },
        ],
        { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
      );

      if (camera) {
        cameraRef.current?.pausePreview();
        cameraRef.current = null;
        setCamera(false);
      }
      takePicture(resizedPhoto!);
    }
  };

  return (
    <View style={styles.container}>
      {camera ? (
        <Camera style={styles.camera} type={type} ref={cameraRef}>
          <View style={styles.headerContainer}>
            <TouchableOpacity onPress={goback}>
              <Ionicons name="arrow-back-circle" size={24} color="white" />
            </TouchableOpacity>
            <TouchableOpacity onPress={toggleCameraType} style={{ top: -10 }}>
              <Ionicons name="md-camera-reverse" size={34} color="white" />
            </TouchableOpacity>
          </View>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={styles.cameraIconContainer}
              onPress={pickImage}
            >
              <Image
                source={images.Camera2}
                style={styles.cameraIcon}
                contentFit="contain"
              />
            </TouchableOpacity>

            <TouchableOpacity style={styles.takePhoto} onPress={snapPicutre}>
              <View style={styles.innerCircle}></View>
            </TouchableOpacity>
          </View>
        </Camera>
      ) : (
        <Text>Nullllllll</Text>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  camera: {
    flex: 1,
    width: "120%",
  },
  cameraIcon: {
    width: 40,
    height: 40,
  },
  cameraIconContainer: {
    position: "absolute",
    left: "7%",
    bottom: "5%",
  },
  cameraBox: {
    width: 300,
    height: 90,
    backgroundColor: "#fff",
    borderRadius: 10,
    shadowColor: "#000",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
  },
  cameraBoxText: {
    fontSize: 33,

    paddingLeft: 10,
  },
  takePhoto: {
    width: 100,
    height: 100,

    borderRadius: 100,
    padding: 50,
    borderWidth: 5,
    borderTopColor: "white",
    borderLeftColor: "white",
    borderBottomColor: "white",
    borderRightColor: "white",
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 50,
    width: "90%",
  },
  innerCircle: {
    width: 80,
    height: 80,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
  },
  headerContainer: {
    flex: 1,
    width: "84%",
    paddingTop: 50,
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default CameraPresentation;
