import React, { FC, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  Modal,
  Dimensions,
  Pressable,
  FlatList,
  TouchableOpacity,
  Animated,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import StyleSheet from "react-native-media-query";
import { LinearGradient } from "expo-linear-gradient";
import LottieView from "lottie-react-native";
import { EntityState } from "@reduxjs/toolkit";
import { scale, verticalScale, moderateScale } from "react-native-size-matters";
import { images } from "../../constants";
import { useAppSelector } from "../../redux/hooks";
import { selectAdmin } from "../auth/slice/authSlice";
import { useCreateNewMutation } from "../timerfocuse/api/timerfocuseApi";
const { width, height } = Dimensions.get("window");
export interface IFHotspotExperienceDetails {
  onClose?: () => void;
  isMakeGem: boolean;
  min: number;
  onDoMakeGem: () => void;
  onDoMakeOtherPoints: () => void;
  onOpenEndTimer: (status: boolean) => void;
}

const TimerPopup: FC<IFHotspotExperienceDetails> = ({
  onClose,
  isMakeGem,
  min,
  onDoMakeGem,
  onDoMakeOtherPoints,
  onOpenEndTimer,
}) => {
  const [createNew, { isLoading, isSuccess, isError }] = useCreateNewMutation();
  const user = useAppSelector(selectAdmin);
  const lockAnimation = React.createRef();
  const backAnimations = React.createRef<LottieView>();
  const lockProgress = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    // @ts-ignore
    if (lockAnimation && lockAnimation?.current) lockAnimation.current.play();
    if (backAnimations && backAnimations?.current)
      // @ts-ignore
      backAnimations.current.play();
  });
  return (
    <Modal
      transparent={true}
      animationType="fade"
      onRequestClose={() => {
        onOpenEndTimer(false);
      }}
    >
      <Pressable
        style={styles.modelContainer}
        onPress={(event) => {
          if (event.target == event.currentTarget) {
            onOpenEndTimer(false);
          }
        }}
      >
        <LinearGradient
          colors={["#fff", "#fff"]}
          style={styles.modalImageContainer}
        >
          <View style={styles.detailsContentContainer}>
            <View style={styles.hotspotPopupHeader}>
              <View style={styles.hotspotPopupIconContainer}>
                <Image
                  source={images.timerPopup}
                  style={styles.hotspotPopupIcon}
                />
              </View>

              <Text style={styles.hotspotPopupHeadeTitle}>
                Resources Earned
              </Text>
            </View>
            <View style={styles.timerPopupBox}>
              <View style={styles.timerPopupBoxContent}>
                <Image source={images.timert} style={styles.hotspotPopupIcon} />
                <Text style={styles.timerPopupBoxTitle}>Time:</Text>
              </View>
              <Text style={styles.timerPopupBoxDesc}>{min} mins</Text>
            </View>
            <View style={styles.timerPopupBox}>
              <View style={styles.timerPopupBoxContent}>
                <Image
                  source={images.timerloc}
                  style={styles.hotspotPopupIcon}
                />
                <View>
                  <Text style={styles.timerPopupBoxTitle}>Location:</Text>
                  <Text style={styles.timerPopupBoxTitle}>Bonus:</Text>
                </View>
              </View>
              <View>
                <Text style={styles.timerPopupBoxDesc}>-</Text>
                <Text style={styles.timerPopupBoxDesc}>* 0</Text>
              </View>
            </View>
            <View style={styles.timerPopupBox}>
              <View style={styles.timerPopupBoxContent}>
                <Image
                  source={images.timerfuze}
                  style={styles.hotspotPopupIcon}
                />
                <View>
                  <Text style={styles.timerPopupBoxTitle}>Fuses used:</Text>
                  <Text style={styles.timerPopupBoxTitle}>Shards used:</Text>
                </View>
              </View>
              <View>
                <Text style={styles.timerPopupBoxDesc}>
                  {isMakeGem ? "1" : "0"}
                </Text>
                <Text style={styles.timerPopupBoxDesc}>
                  {isMakeGem ? "6000" : "0"}
                </Text>
              </View>
            </View>
            <View style={[styles.timerPopupBox, styles.timerPopupBoxEarned]}>
              <View style={[styles.timerPopupBoxContent, { width: "100%" }]}>
                <Image
                  source={images.timerelec}
                  style={styles.hotspotPopupIcon}
                />
                <Text style={styles.timerPopupBoxTitle}>Resources Earned </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                {!isMakeGem && (
                  <>
                    <View style={styles.resourceItem}>
                      <Text style={styles.itemText}>{min * 10}</Text>
                      <Image style={styles.item} source={images.item} />
                    </View>
                    <View style={styles.resourceXp}>
                      <Text style={styles.xpText}>{min * 2}</Text>
                      <Text style={styles.xpText}>XP</Text>
                    </View>
                  </>
                )}

                {isMakeGem && (
                  <View style={styles.resourceGem}>
                    <Text style={styles.gemText}>1</Text>
                    <Image style={styles.gem} source={images.gemImage} />
                  </View>
                )}
              </View>
            </View>

            <TouchableOpacity
              onPress={async () => {
                const today = new Date().toLocaleDateString("US-en");
                // console.log(today);
                // const userId = user?._id;
                // const timePerDay = 1;
                //[{date:---,,userId:---,timePerDays:1}]
                const daysSystem = await AsyncStorage.getItem("@daySystem");
                const daySystem = daysSystem ? JSON.parse(daysSystem) : null;
                // console.log(daySystem);
                //when it should item to array storage
                let obj = {
                  date: new Date().toLocaleDateString("US-en"),
                  day: 1,
                  userId: user?._id,
                  timePerDay: 1,
                };
                if (!daySystem) {
                  obj = {
                    date: new Date().toLocaleDateString("US-en"),
                    day: 1,
                    userId: user?._id,
                    timePerDay: 1,
                  };
                  await AsyncStorage.setItem("@daySystem", JSON.stringify(obj));
                } else if (daySystem.date === today) {
                  obj = {
                    date: new Date().toLocaleDateString("US-en"),
                    day: daySystem.day,
                    userId: user?._id,
                    timePerDay: daySystem.timePerDay + 1,
                  };
                  await AsyncStorage.setItem("@daySystem", JSON.stringify(obj));
                } else if (daySystem.date !== today) {
                  obj = {
                    date: new Date().toLocaleDateString("US-en"),
                    day: daySystem.day + 1,
                    userId: user?._id,
                    timePerDay: 1,
                  };
                  await AsyncStorage.setItem("@daySystem", JSON.stringify(obj));
                }

                if (isMakeGem) {
                  onDoMakeGem();
                  try {
                    await createNew({
                      duration: min,
                      gemMade: 1,
                      fusesEarned: 0,
                      fusesUsed: 1,
                      setTime: min,
                      shardesEarned: 0,
                      shardesUsed: 6000,
                      timerSuccessFully: true,
                      student: user!,
                      startTimer: new Date(),
                      endTimer: new Date(),
                    });
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  onDoMakeOtherPoints();
                  try {
                    await createNew({
                      duration: min,
                      gemMade: 0,
                      fusesEarned: 0,
                      fusesUsed: 0,
                      setTime: min,
                      shardesEarned: min * 10,
                      shardesUsed: 0,
                      timerSuccessFully: true,
                      student: user!,
                      startTimer: new Date(),
                      endTimer: new Date(),
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }
                onOpenEndTimer(false);
              }}
              style={{
                position: "relative",
                marginLeft: "10%",
                width: "80%",
                height: moderateScale(90),
              }}
            >
              <LottieView
                style={{
                  width: "100%",
                }}
                //@ts-ignore
                ref={lockAnimation}
                loop={true}
                source={images.claimButton}
                progress={lockProgress}
              />
              <Text style={styles.modalClaimButtonText}>Claim Points</Text>
            </TouchableOpacity>
          </View>
        </LinearGradient>
      </Pressable>
    </Modal>
  );
};
const { styles } = StyleSheet.create({
  modalClaimButtonText: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    alignSelf: "center",
    fontSize: moderateScale(25),
    paddingTop: moderateScale(35),
    fontFamily: "RajdahaniBold",
    color: "#fff",
  },
  item: {
    width: 20,
    height: 30,
    resizeMode: "contain",
  },
  gem: {
    width: 36,
    height: 30,
    resizeMode: "contain",
  },
  switerfuze: {
    minWidth: 85,
    height: 39,
    marginLeft: 10,
    borderColor: "#fff",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 50,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: "center",
    justifyContent: "space-around",
  },
  itemText: {
    fontFamily: "HelveticaBold",
    fontSize: 20,
    color: "#46E6FF",
    paddingLeft: 4,
  },
  gemText: {
    fontFamily: "HelveticaBold",
    fontSize: 20,
    color: "#02ADE6",
    paddingLeft: 4,
  },
  xpText: {
    fontFamily: "HelveticaBold",
    fontSize: 20,
    color: "#FF2E00",
    paddingLeft: 4,
  },

  resourceItem: {
    minWidth: 85,
    height: 39,
    margin: 5,
    borderColor: "#46E6FF",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  resourceGem: {
    minWidth: 85,
    margin: 5,
    height: 39,
    borderColor: "#02ADE6",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  resourceXp: {
    minWidth: 85,
    margin: 5,
    height: 39,
    borderColor: "#FF4901",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  TimerDonePopupEnd: {
    width: "70%",
    height: 60,
    borderRadius: 50,
    backgroundColor: "#5CFF88",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowColor: "#000",
    shadowOpacity: 0.9,
    shadowRadius: 3.84,
    elevation: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginBottom: 30,
    marginTop: "5%",
    borderColor: "#fff",
    borderWidth: 5,
    borderStyle: "solid",
  },
  TimerDonePopupEndText: {
    fontFamily: "Roboto-Bold",
    fontSize: 30,
    color: "#fff",
  },
  timerPopupBoxDesc: {
    fontSize: 23,
  },
  timerPopupBoxEarned: {
    flexDirection: "column",
  },
  timerPopupBoxTitle: {
    fontSize: 23,
    fontWeight: "bold",
  },
  timerPopupBoxContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  timerPopupBox: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderColor: "#000000",
    borderWidth: 1,
    borderStyle: "solid",
    margin: 10,
    paddingLeft: 0,
    borderRadius: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },
 

    shadowOpacity: 1,
    shadowRadius: 2,
    padding: 8,
    elevation: 4,
    backgroundColor: "#fff",
  },
  list: {},
  itemContainer: {
    flexGrow: 1,
    backgroundColor: "white",
    alignItems: "center",
  },
  hotspotPopupContent: {},
  hotspotPopupContentTop: {
    padding: 22,
    paddingBottom: 8,
    paddingTop: 8,
  },
  hotspotPopupHeaderIcon: {
    width: 18,
    height: 22,
  },
  hotspotPopupContentHotspotNumber: {
    flexDirection: "row",
    width: 86,
    height: 30,
    borderWidth: 3,
    borderColor: "#C74545",
    borderRadius: 15,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 8,
    paddingRight: 12,
  },
  hotspotPopupContentList: {},
  modelContainer: {
    flex: 1,
    backgroundColor: "#000000aa",

    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    maxWidth: "100%",
    width: width,
  },
  hotspotPopupIconContainer: {
    width: 70,
    height: 65,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
    borderBottomRightRadius: 4,
  },
  hotspotPopupHeader: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailsContentContainer: {},
  hotspotPopupHeadeTitle: {
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: "#000",
    backgroundColor: "#34C38F",
    color: "#000",
    fontSize: 27,
    textAlign: "center",
    justifyContent: "center",
    height: 58,
    width: "76%",
    borderRadius: 7,
    lineHeight: 50,
    overflow: "hidden",
    right: -5,
  },
  modalImageContainer: {
    position: "relative",
    maxWidth: 374,
    maxHeight: 650,
    width: "95%",
    backgroundColor: "#ffffff",
    borderRadius: 15,
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: "#000",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },

    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
  },
  hotspotPopupIcon: {
    width: 56,
    height: 56,
  },
});
export default TimerPopup;
