import React, { FC, useState } from "react";
import { View, Text, Pressable } from "react-native";
import StyleSheet from "react-native-media-query";

export interface IFRadioButton {
  data: any;
  onSelect: any;
  itemStyle: any;
  secoundType: any;
  containerStyle: any;
  textStyle: any;
}

const RadioButton: FC<IFRadioButton> = ({
  data,
  onSelect,
  itemStyle,
  secoundType,
  containerStyle,
  textStyle,
}) => {
  const [userOption, setUserOption] = useState(null);
  const selectHandler = (value: any) => {
    onSelect(value);
    setUserOption(value);
  };

  return (
    <View
      style={[
        styles.radioButtonContaier,
        secoundType ? styles.secoundTypes : null,
        containerStyle,
      ]}
    >
      {data.map((item: any, index: number) => {
        return (
          <Pressable
            key={index}
            style={[
              item.value === userOption ? styles.selected : styles.unselected,
              index === 1 && secoundType
                ? styles.secoundItem
                : styles.firstItem,
              itemStyle,
            ]}
            onPress={() => selectHandler(item.value)}
          >
            <Text
              numberOfLines={2}
              style={[
                item.value === userOption
                  ? styles.optionSelected
                  : styles.optionUnselected,
                textStyle,
              ]}
            >
              {item.value}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
};
const { styles } = StyleSheet.create({
  radioButtonContaier: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignContent: "center",
    textAlign: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  secoundTypes: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "yellow",
  },
  secoundItem: {
    position: "absolute",
    bottom: -5,
    right: "-30%",
    width: 70,
    borderRadius: 30,
    "@media (max-width: 360px)": {
      minWidth: "auto",
      height: "auto",
    },
  },
  firstItem: {
    minWidth: 80,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
    "@media (max-width: 360px)": {
      minWidth: "auto",
      height: "auto",
    },
  },
  optionSelected: {
    fontSize: 14,

    color: "#fff",
    textAlign: "center",
    "@media (max-width: 360px)": {
      fontSize: 12,
    },
  },
  secoundStyle: {
    backgroundColor: "red",
  },
  optionUnselected: {
    fontSize: 14,

    textAlign: "center",
    color: "#000",
    "@media (max-width: 360px)": {
      fontSize: 12,
    },
  },
  unselected: {
    color: "#000",
    borderColor: "#000",
    borderWidth: 1,
    marginLeft: 3,
    marginRight: 3,
    padding: 13,
    paddingLeft: 17,
    paddingRight: 17,
    borderRadius: 20,
    marginBottom: 11,
    "@media (max-width: 360px)": {
      padding: 7,
      paddingLeft: 7,
      paddingRight: 7,
      marginLeft: 3,
      marginRight: 3,
    },
  },
  selected: {
    backgroundColor: "#5CFF88",
    color: "#fff",
    marginLeft: 3,
    marginRight: 3,
    padding: 13,
    paddingLeft: 17,
    paddingRight: 17,
    borderRadius: 20,
    marginBottom: 11,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.62,
    elevation: 1,
    "@media (max-width: 360px)": {
      padding: 7,
      paddingLeft: 7,
      paddingRight: 7,
      marginLeft: 3,
      marginRight: 3,
    },
  },
});
export default RadioButton;
