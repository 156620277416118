import React from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";
import { AuthUser } from "../../auth/types/types";
type BadgesTextPresentationProps = {
  student: Partial<AuthUser>;
};
const BadgesTextPresentation: React.FC<BadgesTextPresentationProps> = ({
  student,
}) => {
  return (
    <View style={styles.ProfileSettingMenuTextContainer}>
      <TextStroke stroke={1} color={"#FFF"}>
        <Text numberOfLines={1} style={styles.badgesText}>
          {student.fullName}
        </Text>
      </TextStroke>
    </View>
  );
};

const { styles } = StyleSheet.create({
  ProfileSettingMenuTextContainer: {
    top: 60,
    position: "absolute",
    width: "68%",
    marginLeft: "16%",
    marginRight: "16%",
  },
  badgesText: {
    fontSize: 22,
    color: "#000",
    letterSpacing: 1,
    fontFamily: "RajdahaniBold",
  },
});

export default BadgesTextPresentation;
