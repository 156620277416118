import React from "react";
import { View, Text, SafeAreaView } from "react-native";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { selectAdmin } from "../features/auth/slice/authSlice";
import { useAppSelector } from "../redux/hooks";
import TeamAndScoreContainer from "../features/teamsandscore/container/TeamAndScoreContainer";
import { HomeScreenNavigationProps } from "../navigation/types";
import { useNavigation } from "@react-navigation/native";

import MySchoolTabHeaderPersentation from "../features/teamsandscore/presentation/MySchoolTabHeaderPersentation";
const TeamsAndScoreScreen = () => {
  const user = useAppSelector(selectAdmin);
  const navigation = useNavigation<HomeScreenNavigationProps>();

  const onGoAccountSetting = () => {
    navigation.navigate("Setting");
  };

  const onGoBack = () => {
    navigation.goBack();
  };
  return (
    <View style={{ backgroundColor: "#fff" }}>
      <MySchoolTabHeaderPersentation
        backgroundProfile={user?.backgroundBanner!}
        goAccountSetting={onGoAccountSetting}
        goBackHandler={onGoBack}
        showSetting={true}
      />
      <TeamAndScoreContainer user={user!} />
    </View>
  );
};

export default TeamsAndScoreScreen;
