import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import {
  CompletionResponse,
  ExperienceStudentCompletion,
} from "../types/types";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import { ExperienceCard } from "../../experience/types/types";
import { store } from "../../../redux/store";
import { chooseSelectAccount } from "../../auth/slice/authSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAdminStatus } from "../../auth/authThunks/authThunks";

const experienceStudentCompletionAdaptor =
  createEntityAdapter<StudentSystemType>({
    selectId: (system) => system._id!,
  });

interface ExperienceStudentCompletionInitialState
  extends EntityState<StudentSystemType> {}

const initialState: ExperienceStudentCompletionInitialState =
  experienceStudentCompletionAdaptor.getInitialState();

export const extendedExperienceStudentCompletionInitialState =
  apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      editOrAddExperienceCompletionForStudent: builder.mutation<
        EntityState<StudentSystemType>,
        {
          experience: Partial<ExperienceCard>;
          complete: boolean;
          currentSystem: Partial<StudentSystemType>;
        }
      >({
        query: (expstudentCompletion) => ({
          url: `/experience-student-completion?xpViewAmount=${expstudentCompletion.experience.xpView}&gem=${expstudentCompletion.experience.gem}&xpCompletion=${expstudentCompletion.experience.xpCompletion}`,
          method: "POST",
          body: expstudentCompletion,
        }),
        transformResponse: async (response: StudentSystemType) => {
          store.dispatch(getAdminStatus());
          store.dispatch(chooseSelectAccount(response));

          await AsyncStorage.setItem("@account", JSON.stringify(response));
          return experienceStudentCompletionAdaptor.setOne(
            initialState,
            response
          );
        },
        invalidatesTags: [
          { type: "CompletionStudent", id: "LIST" },
          "ExperienceCard",
        ],
      }),

      updateReviveShow: builder.mutation<
        EntityState<ExperienceStudentCompletion>,
        Partial<ExperienceStudentCompletion>
      >({
        query: (data) => ({
          url: "experience-student-completion/updaterev",
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["CompletionStudent", "ExperienceCard"],
      }),
    }),
  });

export const {
  useEditOrAddExperienceCompletionForStudentMutation,
  useUpdateReviveShowMutation,
} = extendedExperienceStudentCompletionInitialState;
