import React, { useState } from "react";
import {
  useGetSuggetionFriendsQuery,
  useSendRequestMutation,
} from "../api/friendsApiSlice";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import FindFriendsPresentation from "../presentation/FindFriendsPresentation/FindFriendsPresentation";
type FindFriendsContainerProps = {
  account: Partial<StudentSystemType>;
  term?: string | null;
  setTerm?: any;
  onSearchInput: (text: string | null) => void;
};
const FindFirendsContainer: React.FC<FindFriendsContainerProps> = ({
  account,
  term,
  setTerm,
  onSearchInput,
}) => {
  const {
    data: suggestionList,
    isSuccess,
    isError,
    isLoading,
  } = useGetSuggetionFriendsQuery({
    currentSystem: JSON.stringify(account!),
    term: term ? term : "null",
  });

  const [
    sendRequest,
    { isLoading: sendLoading, isError: sendError, isSuccess: sendSuccess },
  ] = useSendRequestMutation();

  const [disabeldButton, setDisabeldButton] = useState<boolean>(false);

  const onSendRequest = async (student: AuthUser) => {
    try {
      setDisabeldButton(true);
      await sendRequest({
        friend: student,
        project: account.project,
      });
    } catch (error) {}
  };
  return (
    <FindFriendsPresentation
      account={account!}
      suggestionList={suggestionList!}
      isLoading={isLoading}
      isSuccess={isSuccess}
      onSendRequest={onSendRequest!}
      disabeld={disabeldButton}
      term={term}
      onSearchInput={onSearchInput}
    />
  );
};

export default FindFirendsContainer;
