import React, { useState } from "react";
import {
  View,
  Text,
  ScrollView,
  ImageBackground,
  SafeAreaView,
  Platform,
} from "react-native";
import TimerContainer from "../features/timer/timerContainer";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { useAppSelector } from "../redux/hooks";
import {
  selectAdmin,
  selectDefaultAccount,
} from "../features/auth/slice/authSlice";
import { images } from "../constants";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import TimerSwiper from "../features/timer/timerSwiper";
import {
  useDoMakeGemMutation,
  useDoMakeOtherPointMutation,
  useGetInitialTimerDataQuery,
} from "../features/timer/api/timerApi";

const TimerScreen = () => {
  const user = useAppSelector(selectAdmin);
  const account = useAppSelector(selectDefaultAccount);
  const {
    data: timerData,
    isLoading,
    isError,
    isSuccess,
  } = useGetInitialTimerDataQuery({
    currentSystem: JSON.stringify(account),
  });
  // console.log("timerData",timerData)
  const [
    doMakeGem,
    {
      isLoading: doMakeGemLoading,
      isError: doMakeGemError,
      isSuccess: doMakeGemSuccess,
    },
  ] = useDoMakeGemMutation();

  const [
    doMakeOtherPoint,
    {
      isLoading: doMakeOtherLoading,
      isError: doMakeOtherError,
      isSuccess: doMakeOtherSuccess,
    },
  ] = useDoMakeOtherPointMutation();

  const [isMakeGem, setIsMakeGem] = useState<boolean>(false);
  const [isSliderMakeGemOpen, setIsSliderMakeGemOpen] =
    useState<boolean>(false);
  const [min, setMin] = useState<number>(0);

  const onMin = (minutes: number) => {
    // console.log("mmmmmm", minutes);
    setMin(minutes);
  };
  const onSetIsSliderMakeGemOpen = (status: boolean) => {
    setIsSliderMakeGemOpen(status);
  };

  const onMakeGem = (status: boolean) => {
    // console.log(status);
    setIsMakeGem(status);
  };

  const onDoMakeGem = async () => {
    try {
      await doMakeGem({
        currentSystem: JSON.stringify(account),
      }).unwrap();
    } catch (error) {}
  };
  const onDoMakeOtherPoints = async () => {
    try {
      await doMakeOtherPoint({
        currentSystem: JSON.stringify(account),
        min: min,
      }).unwrap();
    } catch (error) {}
  };

  return (
    <ImageBackground
      source={images.timerBack}
      resizeMode="cover"
      style={(Platform.OS === "web") ? { } : { width: wp("100%"), height: hp("100%") }}
 
    >
      <HeaderBar
        profileImage={user?.image!}
        student={user!}
        backColor="transparent"
      />
      {isSuccess && (
        <>
          <TimerSwiper
            focuseCrystal={timerData.entities[1]?.focuseCrystal!}
            fuses={timerData.entities[1]?.fuses!}
            isMakeGem={isMakeGem}
            onMakeGem={onMakeGem}
            isSliderMakeGemOpen={isSliderMakeGemOpen}
            onSetIsSliderMakeGemOpen={onSetIsSliderMakeGemOpen}
          />

          <TimerContainer
            isMakgeGem={isMakeGem}
            onDoMakeGem={onDoMakeGem}
            isSliderMakeGemOpen={isSliderMakeGemOpen}
            onSetIsSliderMakeGemOpen={onSetIsSliderMakeGemOpen}
            min={min}
            onMin={onMin}
            onDoMakeOtherPoints={onDoMakeOtherPoints}
            focuseCrystal={timerData.entities[1]?.focuseCrystal!}
            fuses={timerData.entities[1]?.fuses!}
          />
        </>
      )}
    </ImageBackground>
  );
};

export default TimerScreen;
