import React from "react";
import { View, Text } from "react-native";
const AcountSettingScreen = () => {
  return (
    <View>
      <Text>AcountSettingScreen</Text>
    </View>
  );
};

export default AcountSettingScreen;
