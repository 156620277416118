import React, { FC, useEffect, useState } from "react";
import {
  View,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  Pressable,
  ImageBackground,
  Platform,
} from "react-native";
import { Image } from "expo-image";
import { images } from "../../../constants";
import { SharedValue } from "react-native-reanimated";
import { BadgeSystem } from "../type/type";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { useGetAllLevelBadgeQuery } from "../api/apiBadgeSystem";
import { useAchiveBadgeMutation } from "../../badgeAchivement/api/badgeAchivmentApi";
import { EntityId } from "@reduxjs/toolkit";
import { SvgUri } from "react-native-svg";

const { width } = Dimensions.get("window");

export type PostFeedPopupProps = {
  rotate: SharedValue<number>;
  openPopup?: boolean;
  onClose?: () => void;
  item?: BadgeSystem | null;
};

const BadgeSystemDetailsPresentation: FC<PostFeedPopupProps> = ({
  rotate,
  openPopup,
  onClose,
  item,
}) => {
  const [achiveBadge, { isLoading, isError, isSuccess }] =
    useAchiveBadgeMutation();

  const achiveBadgeHandler = async () => {
    try {
      const result = await achiveBadge({
        badgeId: item?._id as EntityId,
      }).unwrap();
    } catch (error) {}
  };
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={styles.modalImageContainer}
      onPress={() => {
        if (!item?.locked) {
          achiveBadgeHandler();
        }
        rotate.value = rotate.value ? 0 : 1;
      }}
    >
      <ImageBackground
        source={images.BadgedetailBackground}
        style={styles.image}
        resizeMode="cover"
      />

      <View style={styles.BadgedetailHeader}>
        <View style={styles.BadgedetailHeaderContent}>
          <Text style={styles.BadgedetailHeaderText}>{item?.title}</Text>
        </View>
        <Image
          style={styles.BadgedetailHeaderImage}
          source={images.badgeDetail}
        />
      </View>

      <View style={styles.BadgedetailImage}>
  

{(Platform.OS === "web") ?   <Image style={[styles.BadgedetailItemImage , {width:450 ,height:450 }]}  source={ {
              uri: item?.image!} }/>  :  
              <SvgUri
              style={styles.BadgedetailItemImage}
              height="450px"
              width="450px"
              uri={item?.image!}
            /> }


        
      </View>
      <View style={styles.BadgedetailContent}>
        <Text style={styles.BadgedetailContentText}>
          {item?.locked ? item?.how : item?.description}
        </Text>
        <View style={styles.BadgedetailContentAchive}>
          <Pressable
            onPress={() => {
              rotate.value = rotate.value ? 0 : 1;
            }}
          >
            <View
              style={[
                styles.BadgedetailContentCta,
                {
                  borderColor: item?.locked ? "red" : "#4ED386",
                },
              ]}
            >
              <Text
                style={[
                  styles.BadgedetailContentCtaText,
                  {
                    color: item?.locked ? "red" : "#4ED386",
                  },
                ]}
              >
                {item?.locked ? "Locked" : "Achieved"}
              </Text>
            </View>
          </Pressable>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  BadgedetailContentCtaText: {
    fontFamily: "RajdahaniBold",
    fontSize: 17,
    color: "#4ED386",
  },
  BadgedetailContentCta: {
    padding: 4,
    paddingLeft: 30,
    paddingRight: 30,
    borderWidth: 5,
    borderColor: "#4ED386",
    borderRadius: 4,
  },
  BadgedetailContentAchive: {
    position: "absolute",
    bottom: 0,
    right: 0,
    borderTopStartRadius: 10,
    borderBottomEndRadius: 10,
    padding: 5,
    borderWidth: 5,
    borderColor: "#000",
    zIndex: 999999999,
  },
  BadgedetailContent: {
    backgroundColor: "rgba(255,255,255,0.86)",
    borderWidth: 5,
    borderColor: "#000",
    height: "30%",
    width: "90%",
    position: "absolute",
    padding: 15,
    bottom: 0,
    right: 0,
    borderTopStartRadius: 30,
    borderBottomEndRadius: 15,
  },
  BadgedetailContentText: {
    fontFamily: "RajdahaniMedium",
    fontSize: 16,
  },

  disable: {
    opacity: 0.6,
    pointerEvents: "none",
  },
  BadgedetailItem: {
    height: 58,
    backgroundColor: "#fff",
    borderRadius: 8,
    width: "75%",
    marginLeft: "18%",
    flexDirection: "row",
    paddingLeft: "15%",
  },
  BadgedetailItemImage: {
    width: width - 80,
    height: 450,
    maxWidth: 272,
    maxHeight: 299,
    objectFit: "contain",
  },
  BadgedetailItemContent: {
    textAlign: "center",
    justifyContent: "center",
  },
  BadgedetailItemTitle: {
    fontFamily: "HelveticaBold",
    fontSize: 14,
    paddingBottom: 4,
  },
  BadgedetailItemSubTitle: {
    fontFamily: "HelveticaBold",
    fontSize: 10,
    maxWidth: "88%",
    lineHeight: 11,
  },
  BadgedetailItemAccept: {
    maxWidth: 36,
    objectFit: "contain",
  },
  BadgedetailHeader: {
    height: 56,
    backgroundColor: "#033C56",
    borderRadius: 6,
    borderWidth: 8,
    borderColor: "#000",
    width: "80%",
    marginLeft: "9%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  BadgedetailHeaderContent: {},
  BadgedetailHeaderText: {
    fontSize: 21,
    color: "#fff",
  },
  BadgedetailHeaderImage: {
    height: 66,
    width: 66,
    borderColor: "#000",
    borderWidth: 8,
    borderRadius: 50,
    position: "absolute",
    right: -30,
  },
  BadgedetailImage: {
    justifyContent: "center",
    flex: 1,
    marginTop: "10%",
    marginBottom: "55%",
    alignContent: "center",
    alignItems: "center",
  },
  modalImageContainer: {
    position: "relative",
    maxWidth: 425,
    maxHeight: 585,
    height: "80%",
    width: width - 30,
    backgroundColor: "#fff",
    borderRadius: 15,
    borderWidth: 5,
    borderColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },

    shadowOpacity: 0.5,
    shadowRadius: 2.62,
    elevation: 8,
  },

  modelContainer: {
    backgroundColor: "#000000aa",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    maxWidth: "100%",
    width: width,
    alignSelf: "center",
  },

  image: {
    height: "100%",
    width: "100%",
    position: "absolute",
    borderRadius: 15,
    borderWidth: 6,
    borderColor: "#000",
    overflow: "hidden",
    objectFit: "cover",
  },
});

export default BadgeSystemDetailsPresentation;
