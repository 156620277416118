import React, { useState } from "react";
import { View, Animated, Dimensions } from "react-native";
import TimerSoloTab from "./timerSoloTab";
import TimerGroupTab from "./timerGroupTab";
import TimerPopup from "./timerPopup";
import StyleSheet from "react-native-media-query";
const { width } = Dimensions.get("window");
type TimerContainerProps = {
  isMakgeGem: boolean;
  onDoMakeGem: () => void;
  isSliderMakeGemOpen: boolean;
  onSetIsSliderMakeGemOpen: (status: boolean) => void;
  min: number;
  onMin: (minutes: number) => void;
  onDoMakeOtherPoints: () => void;
  focuseCrystal: number;
  fuses: number;
};
const TimerContainer: React.FC<TimerContainerProps> = ({
  isMakgeGem,
  onDoMakeGem,
  isSliderMakeGemOpen,
  onSetIsSliderMakeGemOpen,
  min,
  onMin,
  onDoMakeOtherPoints,
  focuseCrystal,
  fuses,
}) => {
  const [translateXTabOne, setTranslateXTabOne] = useState(
    new Animated.Value(0)
  );
  const [translateXTabTwo, setTranslateXTabTwo] = useState(
    new Animated.Value(width)
  );
  const [translateY, setTranslateY] = useState(-1000);

  const [openEndTimer, setOpenEndTimer] = useState<boolean>(false);

  const onOpenEndTimer = (status: boolean) => {
    setOpenEndTimer(status);
  };
  return (
    <>
      <View style={styles.cardContainer}>
        <View
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            height: 600,
          }}
        >
          <View>
            <Animated.View
              style={{
                justifyContent: "center",
                alignItems: "center",
                opacity: translateXTabOne,
              }}
              onLayout={(event) =>
                setTranslateY(event.nativeEvent.layout.height)
              }
            >
              <TimerGroupTab />
            </Animated.View>

            <Animated.View
              style={{
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",

                opacity: translateXTabTwo,
                transform: [
                  {
                    translateY: 0,
                  },
                ],
              }}
            >
              <TimerSoloTab
                isMakeGem={isMakgeGem}
                onDoMakeGem={onDoMakeGem}
                onSetIsSliderMakeGemOpen={onSetIsSliderMakeGemOpen}
                isSliderMakeGemOpen={isSliderMakeGemOpen}
                min={min}
                onMin={onMin}
                onDoMakeOtherPoints={onDoMakeOtherPoints}
                onOpenEndTimer={onOpenEndTimer}
                focuseCrystal={focuseCrystal!}
                fuses={fuses!}
              />
            </Animated.View>
          </View>
        </View>
        {openEndTimer && (
          <TimerPopup
            isMakeGem={isMakgeGem}
            min={min}
            onDoMakeGem={onDoMakeGem}
            onDoMakeOtherPoints={onDoMakeOtherPoints}
            onOpenEndTimer={onOpenEndTimer}
          />
        )}
      </View>
    </>
  );
};

const { styles } = StyleSheet.create({
  cardContainer: {
    flex: 1,
    zIndex: 999,
    alignContent: "center",
    backgroundColor: "transparent",
    justifyContent: "flex-start",
    "@media (max-height: 550px)": {
      transform: [{ scale: 0.75 }],
      top:-40
    },
    
  },

  cardHeader: {
    width: "100%",
    height: 100,
  },
  profileXpCoinsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  headerxpcontainer: {
    flex: 1,
    paddingBottom: 10,
    paddingRight: 5,
  },
  xpNumbrt: {
    fontSize: 28,
    color: "#fff",
    alignSelf: "center",
    fontWeight: "bold",
  },
  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#000",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#000",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
  },
  xp: {
    color: "white",
    fontSize: 26,
  },
});

export default TimerContainer;
