import React, { FC } from "react";
import {
  View,
  Text,
  Image,
  Modal,
  Dimensions,
  Pressable,
  FlatList,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { LinearGradient } from "expo-linear-gradient";
import { ExperienceCard } from "../types/types";
import { images } from "../../../constants";
import ExperienceHotspotPopupItem from "./ExperienceHotspotPopupItem";

import { EntityState } from "@reduxjs/toolkit";
const { width, height } = Dimensions.get("window");
export interface IFHotspotExperienceDetails {
  onClose?: () => void;
  itemList: EntityState<ExperienceCard>;
  completeHandler?: (item: ExperienceCard) => void;
  revieGemTotal: number;
}

const ExperienceHotspotDetailsPresentation: FC<IFHotspotExperienceDetails> = ({
  onClose,
  itemList,
  completeHandler,
  revieGemTotal,
}) => {
  return (
    <Modal
      transparent={true}
      animationType="fade"
      onRequestClose={() => {
        onClose!();
      }}
    >
      <Pressable
        style={styles.modelContainer}
        onPress={(event) => {
          if (event.target == event.currentTarget) {
            onClose!();
          }
        }}
      >
        <LinearGradient
          colors={["#fff", "#fff"]}
          style={styles.modalImageContainer}
        >
          <View style={styles.detailsContentContainer}>
            <View style={styles.hotspotPopupHeader}>
              <View style={styles.hotspotPopupIconContainer}>
                <Image
                  source={images.hotspotPopupIcon}
                  style={styles.hotspotPopupIcon}
                />
              </View>

              <Text style={styles.hotspotPopupHeadeTitle}>Second Chance</Text>
            </View>
            <View style={styles.hotspotPopupContent}>
              <View style={styles.hotspotPopupContentTop}>
                <View style={styles.hotspotPopupContentHotspotNumber}>
                  <Image
                    source={images.hotspotPopupHeaderIcon}
                    style={styles.hotspotPopupHeaderIcon}
                  />
                  <Text style={{ color: "#D8373F", fontWeight: "bold" }}>
                    {revieGemTotal}
                  </Text>
                </View>
              </View>
              <View style={styles.list}>
                <FlatList
                  data={Object.values(itemList!.entities!) as ExperienceCard[]}
                  contentContainerStyle={styles.itemContainer}
                  keyExtractor={(item) => `${item._id!}`}
                  style={{ minHeight:300,maxHeight:430, paddingBottom: 10 }}
                  renderItem={({ item }) => (
                    <>
                      <ExperienceHotspotPopupItem
                        item={item}
                        onClickItem={completeHandler!}
                      />
                    
                    </>
                  )}
                />
              </View>
            </View>
          </View>
        </LinearGradient>
      </Pressable>
    </Modal>
  );
};
const { styles } = StyleSheet.create({
  list: {},
  itemContainer: {
    flexGrow: 1,
    backgroundColor: "white",
    alignItems: "center",
  },
  hotspotPopupContent: {},
  hotspotPopupContentTop: {
    padding: 22,
    paddingBottom:8,
    paddingTop:8
  },
  hotspotPopupHeaderIcon: {
    width: 18,
    height: 22,
  },
  hotspotPopupContentHotspotNumber: {
    flexDirection: "row",
    width: 86,
    height: 30,
    borderWidth: 3,
    borderColor: "#C74545",
    borderRadius: 15,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 8,
    paddingRight: 12,
  },
  hotspotPopupContentList: {},
  modelContainer: {
    flex: 1,
    backgroundColor: "#000000aa",

    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    maxWidth: "100%",
    width: width,
  },
  hotspotPopupIconContainer: {
    width: 70,
    height: 65,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
    borderBottomRightRadius: 4,
  },
  hotspotPopupHeader: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailsContentContainer: {},
  hotspotPopupHeadeTitle: {
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: "#000",
    backgroundColor: "#FDABAB",
    color: "#C74545",
    fontSize: 27,
    textAlign: "center",
    justifyContent: "center",
    height: 58,
    width: "76%",
    borderRadius: 7,
    lineHeight: 50,
    overflow: "hidden",
    right: -5,
  },
  modalImageContainer: {
    position: "relative",
    maxWidth: 374,
    maxHeight: 600,
    width: "95%",
    backgroundColor: "#ffffff",
    borderRadius: 15,
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: "#000",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },

    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
  },
  hotspotPopupIcon: {
    width: 56,
    height: 56,
  },
});
export default ExperienceHotspotDetailsPresentation;
