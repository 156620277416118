import React from "react";
import { SafeAreaView, View } from "react-native";
import { COLORS } from "../constants";
import PrizesContainer from "../features/Prizes/container/PrizesContainer";
import { selectAdmin } from "../features/auth/slice/authSlice";
import { useAppSelector } from "../redux/hooks";

const PrizesScreen = () => {
  const user = useAppSelector(selectAdmin);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: COLORS.white,
      }}
    >
      <PrizesContainer user={user!} />
    </View>
  );
};

export default PrizesScreen;
