import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Animated,
  Text,
  Platform,
} from "react-native";
import { StudentSystemType } from "../../features/auth/types/types";
type AccountTabProps = {
  currentSystem: Partial<StudentSystemType>;
  onSelecteTab: (system: Partial<StudentSystemType>) => void;
  children: React.ReactNode;
};
const AccountTab: React.FC<AccountTabProps> = ({
  currentSystem,
  onSelecteTab,
  children,
}) => {
  const [active, setActive] = useState(0);
  const [xTabOne, setXTabOne] = useState(0);
  const [xTabTwo, setXTabTwo] = useState(0);
  const [xTabThree, setXTabThree] = useState(0);
  const [xTabFour, setXTabFour] = useState(0);
  const [xTabFive, setXTabFive] = useState(0);
  const [translateX, setTranslateX] = useState(new Animated.Value(0));
  const [tabOne, setTranslateXTabOne] = useState(new Animated.Value(0));
  const [tabTwo, setTranslateXTabTwo] = useState(new Animated.Value(0));
  const [tabThree, setTranslateXTabThree] = useState(new Animated.Value(0));
  const [tabFour, setTranslateXTabFour] = useState(new Animated.Value(0));
  const [tabFive, setTranslateXTabFive] = useState(new Animated.Value(0));

  const handleSlide = (type: number) => {
    Animated.spring(translateX, {
      toValue: type,
      speed: 200,
      useNativeDriver: true,
    }).start();
    if (active === 0) {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFour, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFive, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else if (active === 1) {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFour, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFive, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else if (active === 2) {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFour, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFive, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else if (active === 3) {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFour, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFive, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFour, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabFive, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    }
  };
  useEffect(() => {
    if (active === 0) {
      handleSlide(xTabOne - 3 );
    } else if (active === 1) {
      handleSlide(xTabTwo - 3 );
    } else if (active === 2) {
      handleSlide(xTabThree - 3 );
    } else if (active === 3) {
      handleSlide(xTabFour - 3 );
    } else {
      handleSlide(xTabFive - 3);
    }
  }, [active]);

  const handleLabelFilterPress = (selectedItem: {
    current: Partial<StudentSystemType>;
    index: number;
  }) => {
    setActive(selectedItem.index);
    onSelecteTab(selectedItem.current);
    switch (selectedItem.index) {
      case 1:
        handleSlide(xTabOne);
        return;
      case 2:
        handleSlide(xTabTwo);
        return;
      case 3:
        handleSlide(xTabThree);
        return;
      case 4:
        handleSlide(xTabFour);
        return;
      case 5:
        handleSlide(xTabFive);
        return;
    }
  };

  const handleLayout = (event: any, index: number) => {
    switch (index) {
      case 0:
        setXTabOne(event.nativeEvent.layout.x);
        break;
      case 1:
        setXTabTwo(event.nativeEvent.layout.x);
        break;
      case 2:
        setXTabThree(event.nativeEvent.layout.x);
        break;
      case 3:
        setXTabFour(event.nativeEvent.layout.x);
        break;
      case 4:
        setXTabFive(event.nativeEvent.layout.x);
        break;
      // no default
    }
  };

  const handleStyle = (index: number) => {
    switch (index) {
      case 0:
        return tabOne;
      case 1:
        return tabTwo;
      case 2:
        return tabThree;
      case 3:
        return tabFour;
      case 4:
        return tabFive;
      // no default
    }
  };

  const renderLabels = () => {
    return (
      <>
        <TouchableOpacity
          key={0}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 36,
    
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onLayout={(event) => {
            handleLayout(event, 0);
          }}
          onPress={() => {
            handleLabelFilterPress({
              current: { ...currentSystem, categories: [], category: null },
              index: 0,
            });
          }}
        >
          <Text
            numberOfLines={1}
            style={{
              color: active === 0 ? "#fff" : "#fff",
              fontSize: 13,
              fontWeight: "700",
              paddingLeft: 4,
            }}
          >
            All
          </Text>
        </TouchableOpacity>

        {currentSystem?.categories!.length > 0 &&
          currentSystem?.categories!.map((item, index: number) => {
            return (
              <TouchableOpacity
                key={index + 1}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
         
                  overflow: "hidden",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                onLayout={(event) => {
                  handleLayout(event, index + 1);
                }}
                onPress={() => {
                  handleLabelFilterPress({
                    current: {
                      ...currentSystem,
                      categories: [],
                      category: item,
                    },
                    index: index + 1,
                  });
                }}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    color: active === 0 ? "#fff" : "#fff",
                    fontSize: 14,
                    fontWeight: "700",
                    paddingLeft: 4,
                  }}
                >
                  {item.title}
                </Text>
              </TouchableOpacity>
            );
          })}
      </>
    );
  };
  return (
    <>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: 40,
          position: "relative",
          backgroundColor: "#BFE2D5",
          borderRadius: 36,
          width: `90%`,
          marginBottom: -10,
          marginTop: 5,
          zIndex:5,
          borderColor:"#fff",
          borderWidth:3,
          borderStyle:"solid"
        }}
      >
        <Animated.View
          style={{
            position: "absolute",
            width: `${
              currentSystem.categories!.length > 0
                ? 100 / (currentSystem!.categories!.length + 1)
                : 100
            }%`,
            height: "100%",
            top: 0,
            left: 1,
            backgroundColor: "#34C38F",

            borderRadius: 36,
            transform: [
              {
                translateX,
              },
            ],
          }}
        />
        {renderLabels()}
      </View>
      <View
        style={{
          height: "100%",
        }}
      >
        <Animated.View
          style={[(Platform.OS === "web") ? styles.itemStyleWeb : styles.itemStyle , { opacity: handleStyle(0.5),
            zIndex: handleStyle(100),}]}
        >
          <View
            style={{
              flexGrow: 1,
              width: "95%",
              height: 400,
              flex: 1,
              marginBottom: 50,
            }}
          >
            {children}
          </View>
        </Animated.View>
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  itemStyle: {
      justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
   
  },
  itemStyleWeb:{
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    minWidth:515
  }

});


export default AccountTab;
