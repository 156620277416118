import React from "react";
import { FlatList, View } from "react-native";
import { ExperienceCard } from "../types/types";

import { EntityState } from "@reduxjs/toolkit";
import { getMicrophonePermissionsAsync } from "expo-camera";
import ExperienceItemRevivePresentation from "./ExperienceItemRevivePresentation";
import { ReviveCard } from "../../reviveCard/types/type";

type ExperienceReviveListPresentationProps = {
  cards: EntityState<ReviveCard>;
  onItemClick: (card: Partial<ExperienceCard>, isRevive?: boolean) => void;
  onItemClickHandlerRevive: (rItem: Partial<ReviveCard> | null) => void;
  openDetailsHandler: () => void;
};

const ExperienceReviveListPresentation: React.FC<
  ExperienceReviveListPresentationProps
> = ({ cards, onItemClick, openDetailsHandler, onItemClickHandlerRevive }) => {
  return (
    <View style={{ maxHeight: "30%" }}>
      <FlatList
        data={Object.values(cards!.entities!) as ReviveCard[]}
        keyExtractor={(item) => `${item._id!}`}
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{}}
        style={{ maxHeight: "100%", paddingBottom: 10 }}
        renderItem={({ item }) => (
          <>
            <ExperienceItemRevivePresentation
              item={item}
              onItemClick={onItemClick!}
              openDetailsHandler={openDetailsHandler}
              onItemClickHandlerRevive={onItemClickHandlerRevive}
            />
          </>
        )}

      />
           <View
            style={{
              overflow: "hidden",
              marginBottom: 8,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop:5
            }}
          >
            <View
              style={{
                borderStyle: "dashed",
                borderWidth: 3,
                borderColor: "#777777",
                margin: -3,
                marginTop: 0,
              }}
            />
          </View>

      
    </View>
  );
};

export default ExperienceReviveListPresentation;
