import React from "react";
import { View, Text, StyleSheet, ImageBackground } from "react-native";

const TimerGroupTab = () => {
  return (
    <View style={styles.groupContainer}>
      <Text style={styles.groupContainerText}>
        The Group is being implemented
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  groupContainer: {
    width: "90%",
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    marginTop: "10%",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: "white",
    elevation: 5,
  },
  groupContainerText: {
    fontSize: 40,

    textShadowColor: "#13C4B8",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 10,
    textAlign: "center",
    color: "#3E3F5E",
  },
});
export default TimerGroupTab;
