import { EntityState } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { Dimensions, Keyboard, View } from "react-native";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";
import { useAddLikeMutation } from "../../review/api/reviewApi";
import { useAddCommentMutation } from "../api/commentApi";
import CommentPopup from "../presentation/CommentPopup";
import FeedDetailFooter from "../presentation/FeedDetailFooter";
import FeedDetailHeader from "../presentation/FeedDetailHeader";
import FeedDetailPresentation from "../presentation/FeedDetailPresentation";
import { Comment } from "../types";
import { useLazyGetAllPhotosQuery } from "../../cameraFeatures/api/experiencePhotoApiSlice";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { useLazyGetStudentPostQuery } from "../../badge/api/badgesApi";
import { AuthUser } from "../../auth/types/types";
import { ProfilePost } from "../../badge/api/types/types";
import DeletePhotoPopup from "../presentation/DeletePhotoPopup";

export type FeedDetailContainerProp = {
  photo: Partial<ExperiencePhoto>;
  photoList: ExperiencePhoto[];
  photos: ProfilePost | undefined;
  student?: Partial<AuthUser>;
  openComment?: boolean;
};

const { width, height } = Dimensions.get("window");

const FeedDetailContainer: React.FC<FeedDetailContainerProp> = ({
  photo,
  photoList,
  photos,
  student,
  openComment,
}) => {
  const account = useAppSelector(selectDefaultAccount);
  const [
    getStudentpost,
    {
      isLoading: lazyPostLoading,
      isError: lazyPostError,
      isSuccess: lazyPostSuccess,
    },
  ] = useLazyGetStudentPostQuery();
  const [photoSelected, setPhotoSelectd] =
    useState<Partial<ExperiencePhoto> | null>(openComment ? photo : null);
  // const [getComments, { isLoading, isError, isSuccess }] =
  //   useLazyGetAllCommentQuery();

  const [getallPhotolink, {}] = useLazyGetAllPhotosQuery();
  const [
    addComment,
    {
      isLoading: addCommentLoading,
      isError: addCommentError,
      isSuccess: addCommentSuccess,
    },
  ] = useAddCommentMutation();

  const [
    addLike,
    { isLoading: addLoading, isError: addError, isSuccess: addSuccess },
  ] = useAddLikeMutation();

  const [open, setOpen] = useState<boolean>(openComment!);

  const [commentList, setCommentList] = useState<EntityState<Comment> | null>(
    null
  );
  const [reRenderList, setReRenderList] = useState<string>("default");
  const [commentLength, setCommentLength] = useState<number>(0);
  const [postItem, setPostItem] = useState<Partial<ExperiencePhoto> | null>(
    null
  );

  const [comment, setComment] = useState<string | null>(null);
  const [openDeletePopupState, setOpenPopupState] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setComment("");
    };
  }, [open]);

  useEffect(() => {
    if (student) {
      (async () => {
        try {
          await getStudentpost({
            student: String(student!._id!),
            currentsystemstring: JSON.stringify(account),
          }).unwrap();
        } catch (error: any) {}
      })();
    }
  }, [postItem, student]);
  const getIndex = (photo: Partial<ExperiencePhoto>) => {
    let index = 0;
    if (photos?.data?.length! > 0) {
      index = photos!.data.findIndex((item) => item._id === photo._id);
    } else {
      index = photoList.findIndex((item) => item._id === photo._id);
    }
    return index;
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onOpenDeletePopup = () => {
    setOpenPopupState(true);
  };

  const onCloseDelete = () => {
    setOpenPopupState(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSelectPhoto = (experiencePhoto: Partial<ExperiencePhoto> | null) => {
    setPhotoSelectd(experiencePhoto);
  };

  const onChangeComment = (value: string) => {
    if (value.length <= 140) {
      setComment(value);
      setCommentLength(value.length);
    }
  };

  const onPhotoClick = (item: Partial<ExperiencePhoto> | null) => {
    setPostItem(item);
  };

  const onAddComment = async () => {
    try {
      await addComment({
        comment: comment!,
        experiencePhoto: photoSelected!._id,
      }).unwrap();
      setReRenderList(comment!);
      setComment("");
      Keyboard.dismiss();
    } catch (error) {}
  };

  const applyLike = async (item: Partial<ExperiencePhoto>) => {
    try {
      const result = await addLike({
        photo: item!,
      }).unwrap();
      let itemss: any = [];
      const likeStatus = result.entities[result.ids[0]]?.like;
      if (likeStatus) {
        let newReview = item.reviews![0]?.likeCount
          ? item.reviews![0].likeCount + 1
          : 1;
        itemss = [
          {
            likeCount: newReview,
          },
        ];
      } else {
        let newReview = item.reviews![0]?.likeCount
          ? item.reviews![0].likeCount - 1
          : 0;
        itemss = [
          {
            likeCount: newReview,
          },
        ];
      }

      setPostItem({
        ...postItem,
        like: result.entities[result.ids[0]],
        reviews: itemss,
      });
    } catch (error) {}
  };

  return (
    <>
      <SwiperFlatList
        // removeClippedSubviews
        vertical
        index={getIndex(photo)}
        data={photos?.data.length! > 0 ? photos?.data : photoList}
        renderItem={({ item }) => (
          <View style={{ position: "relative" }}>
            <FeedDetailPresentation photo={item} />
            <FeedDetailHeader
              photo={item}
              onOpenDeletePopup={onOpenDeletePopup}
              onPhotoClick={onPhotoClick}
            />
            <FeedDetailFooter
              onOpen={onOpen}
              onSelectPhoto={onSelectPhoto}
              photo={item}
              applyLike={applyLike}
            />
          </View>
        )}
      />

      {/* Photo Delete my profile */}
      {openDeletePopupState && (
        <DeletePhotoPopup postItem={postItem!} onCloseDelete={onCloseDelete} />
      )}

      {photoSelected && open && (
        <CommentPopup
          openPopup={open}
          onClose={onClose}
          commentList={commentList}
          comment={comment!}
          onChangeComment={onChangeComment}
          onAddComment={onAddComment}
          photoSelected={photoSelected}
          commentLength={commentLength!}
        />
      )}
    </>
  );
};

export default FeedDetailContainer;
