import React, {useState} from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {TextInput, DefaultTheme} from 'react-native-paper';



const theme = {
  ...DefaultTheme,
  roundness: 15,
  fonts: {
    regular: {
      fontFamily: 'RajdahaniBold',
      letterSpacing:0.2
    }
  },
  
  colors: {
    ...DefaultTheme.colors,
    primary: '#AFB0C0',
    accent: 'green',
    placeholder: '#AFB0C0',
    text: '#3e3f5e',
    error:"#FF0000"
    
  },
};
const InputComponent = (props:any) => {

    const [passwordVisible, setPasswordVisible] = useState(true);



  return (
    <View style={styles.formControl}>
       
      <TextInput
        {...props}
        value={props.value}
        onChangeText={props.onInputChange}
        theme={theme}
        mode="outlined"
        selectionColor="#000"
        underlineColor="#FF0000"
        error= {props.error}
        fontFamily="RajdahaniBold"
        style={styles.input}
        autoCapitalize="none"
        secureTextEntry={props.textContentType && props.textContentType == 'password' ?   passwordVisible : false}

        right={props.textContentType && props.textContentType == 'password' ?  <TextInput.Icon color={"#AFB0C0"} style={{marginTop:12}} icon={passwordVisible ? "eye" : "eye-off"} onPress={() => setPasswordVisible(!passwordVisible)} /> : ''}
    
      />
      {props.error && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{props.errorText}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  formControl: {
    height:40
  },
  input: {
    height:45,
    fontFamily:"RajdahaniBold",
    backgroundColor:"#fff",
    fontSize:15,
    lineHeight:17
  
  },
  errorContainer: {
    marginVertical: 5,
  },
  errorText: {
    fontSize: 10,
    letterSpacing:0.3,
    color:"#FF0000",
    fontFamily:"RajdahaniBold",
  },
});

export default InputComponent;
