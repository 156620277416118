import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Image } from "expo-image";
import PolygonProfile from "../../../../components/PolygonProfile";
import { AuthUser } from "../../../auth/types/types";
import { Ionicons } from "@expo/vector-icons";
import PolygonShape from "../../../../components/PolygonShape/PolygonShape";
import { images } from "../../../../constants";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";

type FriendsItemPresenationProps = {
  item: AuthUser;
  onSendRequest?: (student: AuthUser) => void;
  onAcceptFriend?: (student: Partial<AuthUser>) => void;
  status?: string;
  getRankFriends: (item: AuthUser) => number;
  onFriendItemClick?: (student: Partial<AuthUser>) => void;
};
const FriendsItemPresentation: React.FC<FriendsItemPresenationProps> = ({
  item,
  onSendRequest,
  onAcceptFriend,
  status,
  getRankFriends,
  onFriendItemClick,
}) => {
  const [disabelButton, setDisabelButton] = useState<boolean>(false);
  return (
    <>
      <View >
      <TouchableOpacity
              onPress={() => {
                if (onFriendItemClick) {
                  onFriendItemClick!(item);
                } else {
                  return null;
                }
              }}
              style={styles.friendsItemContainer}
            >
        <View style={styles.friendsNameContainer}>
          <View style={styles.userImage}>
       
              <PolygonProfile
                showXp={false}
                xp={3450}
                studentPic={item.image}
                strokeColor={"black"}
                ContainerStyle={{    transform: [{ scale: 0.26 }],left:-75,top:-76}}
              />
            
          </View>

          <Text
            style={{
              fontSize: 18,
              paddingLeft: 40,
              flex: 1,
              fontFamily: "HelveticaBold",
            }}
          >
            {item.fullName}
          </Text>

          {/* <Text
              style={{
              
                fontSize: 18,
                paddingLeft: 22,
                flex: 1,
              }}
            >
              {studentItem.ownerFriend.firstName +
                " " +
                studentItem.ownerFriend.lastName}
            </Text> */}
        </View>

        {status === "friends" && (
          <>
            <View style={styles.friendsDataContainer}>
              {/* <View style={styles.friendsRd}>
                <Text style={styles.friendsRdNumber}>
                  {getRankFriends(item)}
                </Text>
                <Text style={styles.friendsRdText}>
                  {getRankFriends(item) == 1
                    ? "st"
                    : getRankFriends(item) == 2
                    ? "nd"
                    : getRankFriends(item) === 3
                    ? "rd"
                    : "th"}
                </Text>
              </View> */}
              <View style={styles.teamImage}>
                <PolygonProfile
                  showXp={false}
                  xp={3450} 
                  studentPic={images.Gradegy}
                  strokeColor={item.team.color!}
                  ContainerStyle={{    transform: [{ scale: 0.26 }],left:-70,top:-76}}
                />
              </View>
            </View>
          </>
        )}

        {/* {!req && !find && ( */}
        {/* <View style={styles.friendsDataContainer}>
            <View style={styles.friendsRd}>
              <Text style={styles.friendsRdNumber}>{userRank}</Text> */}

        {/* <Text style={styles.friendsRdText}>
                {userRank == 1
                  ? "st"
                  : userRank == 2
                  ? "nd"
                  : userRank === 3
                  ? "rd"
                  : "th"}
              </Text> */}
        {/* </View>
            <View style={styles.teamImage}>
              <PolygonShape
                studentPic={profileAvatar}
                localImage={true}
                borderColor={
                  student._id === studentItem.ownerFriend._id
                    ? studentItem.friend.team.color
                    : studentItem.ownerFriend.team.color
                }
              ></PolygonShape>
            </View>
          </View>
        )} */}
        {/* // {req && ( */}
        {/* //   <TouchableOpacity style={styles.addButton}>
        //     <Text style={styles.addButtonText} onPress={() => {}}>
        //       acceptText
        //     </Text>
        //   </TouchableOpacity> */}
        {/* // )} */}

        {status !== "friends" && (
          <TouchableOpacity
            style={styles.friendButton}
            disabled={disabelButton}
            onPress={() => {
              setDisabelButton(true);
              if (status === "send") {
                onSendRequest!(item);
              } else if (status === "accept") {
                onAcceptFriend!(item);
              }
            }}
          >
            {disabelButton ? (
              <>
                <LoadingScreen iconSize={20} />
              </>
            ) : (
              <>
                <Text style={styles.friendButtonText}>Add</Text>
              </>
            )}
          </TouchableOpacity>
        )}
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  addButton: {
    backgroundColor: "#34C38F",
    padding: 6,
    borderRadius: 4,
    marginRight: 15,
    paddingLeft: 10,
    paddingRight: 10,
  },
  friendButton: {
    backgroundColor: "#08C4B9",
    padding: 6,
    borderRadius: 5,
    marginRight: 15,
    width: 80,
    display: "flex",
    flexDirection: "row",

    justifyContent: "center",
    alignItems: "center",
  },
  friendButtonText: {
    color: "#fff",
    fontSize: 16,
  },
  addButtonText: {
    color: "#fff",
    fontSize: 16,
  },

  teamImage: {
    height:70,
    width: 47,
    left: -25,
    top: -2,
  },
  friendsNameContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  friendsDataContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 7,
  },
  friendsItemContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3.84,
    backgroundColor: "white",
    elevation: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 372,
    width: "90%",
    alignSelf: "center",
    borderRadius: 7,
    height: 70,
    
    marginBottom: 9,
    marginTop: 9,
  },
  userImage: {

    height:70,
    width: 47,
    left: -10,
  },
  friendsRd: {
    width: 47,
    height: 47,
    borderRadius: 50,
    marginRight: 8,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#0BC4B8",
    flexDirection: "row",
  },
  friendsRdNumber: {
    color: "#fff",
    fontSize: 20,
  },
  friendsRdText: {
    color: "#fff",
    fontSize: 17,
    paddingLeft: 1,
  },
});
export default FriendsItemPresentation;
