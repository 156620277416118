import React from "react";
import { View, Text, Dimensions, ScrollView, Platform } from "react-native";
import StyleSheet from "react-native-media-query";
import { FontAwesome5 } from "@expo/vector-icons";
import { CategoryLeaderboard } from "../../categoryLeaderboard/types/type";
import { EntityState } from "@reduxjs/toolkit";

import { Image } from "expo-image";
import CategoryLeaderboardItemPresentatin from "../../categoryLeaderboard/categoryLeaderboardItemPresentation/CategoryLeaderboardItemPresentatin";
import { images } from "../../../constants";
import { useAppSelector } from "../../../redux/hooks";
import { totalCategoryLeaderBoard } from "../../categoryLeaderboard/slice/categoryLeaderBoardSlice";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { selectAdmin } from "../../auth/slice/authSlice";

const { width, height } = Dimensions.get("window");

type SchoolTabItemPresentationProp = {
  categoryLeaderboard: EntityState<CategoryLeaderboard>;
  isLoading: boolean;
  isSuccess: boolean;
};
const SchoolTabItemPresentation: React.FC<SchoolTabItemPresentationProp> = ({
  categoryLeaderboard,
  isLoading,
  isSuccess,
}) => {
  const user = useAppSelector(selectAdmin);
  const getTotal = useAppSelector(totalCategoryLeaderBoard);
  let content;

  if (isLoading) {
    content = <LoadingScreen iconSize={40} />;
  } else if (isSuccess) {
    content = (
      <>
        <View style={[styles.totalXpEarned, styles.totalXpEarnedCenter]}>
          <View style={styles.totalXpEarnedIcon}>
            <FontAwesome5 name="chart-pie" size={24} color="#3E3F5E" />
          </View>
          <View style={styles.totalXpEarnedNumberContent}>
            <Text style={styles.totalXpEarnedNumber}>{(Object.values(categoryLeaderboard.entities) as CategoryLeaderboard[]).reduce((acc,item) => acc + item.totalXps! ,0 )}</Text>
            <Text style={styles.totalXpEarnedText}>Total XP Earned</Text>
          </View>
        </View>

        <View style={(Platform.OS === "web") ? [styles.cardList , styles.cardListWeb ] : styles.cardList}>
          <ScrollView
            style={{
              maxHeight: height - 270,
              minHeight: height - 400,
            }}
          >
            {categoryLeaderboard.ids.map((id, index) => (
              <CategoryLeaderboardItemPresentatin
                categoryLeaderboardItem={categoryLeaderboard.entities[id]!}
                number={++index}
                key={id}
                showAvg={
                  user?.systemProject[0].project_type === "Event" ? false : true
                }
              />
            ))}
          </ScrollView>
        </View>
      </>
    );
  }
  return <>{content}</>;
};
const { styles } = StyleSheet.create({
  teamsTabContainer: {
    maxWidth: "100%",
    minHeight: "80%",
  },
  totalXpEarnedNumberContent: {
    alignItems: "center",
  },
  totalXpEarned: {
    flexDirection: "row",
    alignSelf: "flex-end",
    paddingRight: 15,
    justifyContent: "flex-end",

    width: "100%",
  },
  totalXpEarnedCenter: {
    alignSelf: "center",
    justifyContent: "center",
  },
  totalXpEarnedText: {
    fontSize: 22,
    lineHeight: 28,
    fontFamily: "RajdahaniBold",
    color: "#3E3F5E",
    top: -15,
    maxWidth: 220,
    textAlign: "center",
    "@media (max-width: 360px)": {
      fontSize: 18,
      lineHeight: 22,
      top: -10,
    },
  },
  totalXpEarnedIcon: {
    paddingTop: 5,
    paddingRight: 3,
  },
  totalXpEarnedNumber: {
    fontSize: 50,
    lineHeight: 50,
    fontFamily: "RajdahaniBold",
    color: "#3E3F5E",
    "@media (max-width: 360px)": {
      fontSize: 30,
      lineHeight: 35,
    },
  },
  teamTabContentContainer: {
    "@media (max-width: 360px)": {
      height: height - 150,
    },
  },
  teamLogoContainer: {
    // backgroundColor: "#556EE6",
  },
  searchInputContainerStyle: {
    backgroundColor: "#EBEBEB",
    borderRadius: 20,
    borderColor: "#707070",
    borderWidth: 1,
    borderStyle: "solid",
    borderBottomWidth: 1,
    height: 35,
    maxWidth: 240,
    width: "100%",

    alignSelf: "center",
    marginTop: 10,
  },
  searchInput: {
    backgroundColor: "#fff",
    borderColor: "#fff",
    height: 78,
  },
  teamContainer: {
    flex: 1,
  },
  searchInputStyle: {
    fontSize: 12,
    lineHeight: 15,

    color: "#847D7D",
    letterSpacing: 0.2,
    alignItems: "center",
  },
  cardList: {
    width: width - 20,

    paddingTop: 15,
    paddingBottom: 15,
    shadowColor: "#000",
    overflow: "visible",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 3.84,
    backgroundColor: "#fff",
    elevation: 10,
    borderRadius: 20,
  },
  cardListWeb:{
    width:"100%"
  },
  cardHeader: {
    width: "100%",

    height: 100,
    backgroundColor: "#1766C1",
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
  },
  profileXpCoinsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  headerxpcontainer: {
    flex: 1,
    paddingBottom: 10,
    paddingRight: 5,
  },
  xpNumbrt: {
    fontSize: 28,
    color: "#fff",
    alignSelf: "center",
    fontWeight: "bold",
  },
  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#000",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#000",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
  },
  xp: {
    color: "white",
    fontSize: 26,
  },
  teamsTabContentContainer: {
    flex: 1,
  },
});
export default SchoolTabItemPresentation;
