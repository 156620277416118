import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { FoucseTimer } from "./types";
import { apiSlice } from "../../../redux/api/apiSlice";

export const FoucseTimerAdaptor = createEntityAdapter<FoucseTimer>({
  selectId: (focuseTimer) => focuseTimer._id,
});

export interface FoucseTimerInitialState extends EntityState<FoucseTimer> {}

export const foucseTimerInitialState: FoucseTimerInitialState =
  FoucseTimerAdaptor.getInitialState();

export const extenedFoucseTimerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNew: builder.mutation<EntityState<FoucseTimer>, Partial<FoucseTimer>>(
      {
        query: (data) => ({
          url: `/foucse-timer`,
          method: "POST",
          body: data,
        }),
        transformResponse: (response: FoucseTimer) => {
          return FoucseTimerAdaptor.addOne(foucseTimerInitialState, response);
        },
        invalidatesTags: ["FoucseTimer"],
      }
    ),
  }),
});

export const { useCreateNewMutation } = extenedFoucseTimerApi;
