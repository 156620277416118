import React from "react";
import { View, Text , Image } from "react-native";
import StyleSheet from "react-native-media-query";
import { TextStroke } from "../../../components/TextStroke/TextStroke";
import { images } from "../../../constants";


const BadgeSystemTextPresentation = ({

}) => {
  return (
    <View style={styles.ProfileSettingMenuTextContainer}>
      <Image
        source={images.badgeTopIcon}
        style={styles.image}
      />
      <TextStroke stroke={1} color={"#FFF"}>
        <Text numberOfLines={1} style={styles.badgesText}>
          Badges
        </Text>
      </TextStroke>
    </View>
  );
};

const { styles } = StyleSheet.create({
  ProfileSettingMenuTextContainer: {
    top: 40,
    position: "absolute",
    width: "68%",
    marginLeft: "16%",
    marginRight: "16%",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"row"
  },
  badgesText: {
    fontSize: 55,
    color: "#000",
    letterSpacing: 1,
    fontFamily: "RajdahaniBold",
  },
  image:{
    maxWidth:49,
    height:49,
    marginRight:8
  }
});

export default BadgeSystemTextPresentation;
