import React from "react";
import { View, Text, ImageBackground, Platform } from "react-native";

import StyleSheet from "react-native-media-query";
import { images } from "../../../constants";
import { CategoryLeaderboard } from "../types/type";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin } from "../../auth/slice/authSlice";
type CategoryLeaderboardItemPresntationProps = {
  number: number;
  categoryLeaderboardItem: CategoryLeaderboard;
  showAvg?: boolean;
};
const CategoryLeaderboardItemPresentatin: React.FC<
  CategoryLeaderboardItemPresntationProps
> = ({ categoryLeaderboardItem, number, showAvg }) => {
  const user = useAppSelector(selectAdmin);
  return (
    <>
      <View style={(Platform.OS === "web") ? [styles.teamItemContainer , styles.teamItemContainerWeb ] : styles.teamItemContainer}>
        <View style={styles.teamNameContainer}>
          <ImageBackground
            source={images.xpLevelBackground}
            resizeMode="contain"
            style={(Platform.OS === "web") ? styles.imageWeb : styles.image}
     
          >
            <Text style={styles.xpNumbrt}>{number}</Text>
          </ImageBackground>
        </View>

        <View style={styles.teamDataContainer}>
          <View style={[styles.teamDataContent]}>
            <Text
              style={{
                fontSize: 14,
                paddingRight: 2,
                flex: 1,
              }}
            >
              {categoryLeaderboardItem.title}
            </Text>
            <View style={styles.teamXPContent}>
              <Text style={styles.teamXPContentNumber}>
                {user?.systemProject[0].project_type === "Event"
                  ? Math.floor(Number(categoryLeaderboardItem.totalXps))
                  : Number(categoryLeaderboardItem.xpAvg).toFixed(1)}{" "}
                {showAvg ? "AVG" : ""}
              </Text>
              <Text style={styles.teamXPContentText}> XP</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const { styles } = StyleSheet.create({
  image: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 54,
    height: 55,
    top: -5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 1,
  },

  imageWeb:{
    resizeMode: "contain",
    justifyContent: "center",
    width: 54,
    height: 55,
    top: -5,
  },
  xpNumbrt: {
    fontSize: 22,
    color: "#fff",
    alignSelf: "center",
    fontFamily: "Roboto-Bold",
    "@media (max-width: 360px)": {
      fontSize: 20,
    },
  },

  teamNameContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  teamDataContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 7,
    borderBottomColor: "#BABABA",
    borderBottomWidth: 1,
    flex: 1,
    marginRight: 14,
    paddingBottom: 10,
    paddingLeft: 4,
  },
  teamDataContent: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    width:"100%"
  },
  teamItemContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  teamItemContainerWeb:{
    minWidth:450
  },
  teamXPContent: {
    flexDirection: "row",
  },
  teamXPContentNumber: {
    color: "#FF4C00",
    fontSize: 18,
    fontFamily: "Roboto-Bold",
    paddingRight: 10,
    "@media (max-width: 360px)": {
      fontSize: 16,
    },
  },
  teamXPContentText: {
    color: "#000000",
    fontSize: 20,
    fontFamily: "Roboto-Bold",
    "@media (max-width: 360px)": {
      fontSize: 18,
    },
  },
  ownHighScool: {
    borderColor: "#00EF40",
    borderWidth: 2,
    borderRadius: 8,
    paddingTop: 10,
    paddingBottom: 10,
  },
});

export default CategoryLeaderboardItemPresentatin;
