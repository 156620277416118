import React, { useEffect, useState } from "react";
import {
  Animated,
  View,
  TouchableOpacity,
  FlatList,
  Text,
  Dimensions,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { FONTS } from "../../../constants";

const { width, height } = Dimensions.get("window");
type FriendsPresentationProps = {
  children: React.ReactNode;
  onChangeStatus: (status: number) => void;
};
const TeamScoreTabPresentation: React.FC<FriendsPresentationProps> = ({
  children,
  onChangeStatus,
}) => {
  const [active, setActive] = useState(2);
  const [xTabOne, setXTabOne] = useState(0);
  const [xTabTwo, setXTabTwo] = useState(0);
  const [xTabThree, setXTabThree] = useState(0);
  const [translateX, setTranslateX] = useState(new Animated.Value(1));
  const [tabOne, setTranslateXTabOne] = useState(new Animated.Value(0));
  const [tabTwo, setTranslateXTabTwo] = useState(new Animated.Value(0));
  const [tabThree, setTranslateXTabThree] = useState(new Animated.Value(0));
  const [translateY, setTranslateY] = useState(-1000);

  const handleSlide = (type: number) => {
    Animated.spring(translateX, {
      toValue: type,
      speed: 200,
      useNativeDriver: true,
    }).start();
    if (active === 0) {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else if (active === 1) {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else if (active === 2) {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 1,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.spring(tabOne, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabTwo, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
        Animated.spring(tabThree, {
          toValue: 0,
          speed: 200,
          useNativeDriver: true,
        }),
      ]).start();
    }
  };
  useEffect(() => {
    if (active === 0) {
      handleSlide(xTabOne - 2);
    } else if (active === 1) {
      handleSlide(xTabTwo);
    } else if (active === 2) {
      handleSlide(xTabThree - 3);
    }
  }, [active]);

  const renderLabels = () => {
    return (
      <>
        <TouchableOpacity
          key={0}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 36,
            overflow: "hidden",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderColor:"transparent",
          }}
          onLayout={(event) => {
            handleLayout(event, 2);
          }}
          onPress={() => {
            setActive(2);
            onChangeStatus(0);
            // handleLabelFilterPress({ current: currentSystem, index: 0 });
          }}
        >
          <Text
            numberOfLines={1}
            style={{
              color: "#fff",
              fontSize:14,
              fontFamily: "HelveticaBold",
              paddingLeft: 4,
            }}
          >
            Teams
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          key={1}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 36,
            overflow: "hidden",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderColor:"transparent",
          }}
          onLayout={(event) => {
            handleLayout(event, 1);
          }}
          onPress={() => {
            setActive(1);
            onChangeStatus(1);
            // handleLabelFilterPress({ current: currentSystem, index: 0 });
          }}
        >
          <Text
            numberOfLines={1}
            style={{
              color: "#fff",
              fontSize:14,
              fontFamily: "HelveticaBold",
              paddingLeft: 4,
            }}
          >
            Schools
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          key={2}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 36,
            overflow: "hidden",
            borderColor:"transparent",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onLayout={(event) => {
            handleLayout(event, 0);
          }}
          onPress={() => {
            setActive(0);
            onChangeStatus(2);
            // handleLabelFilterPress({ current: currentSystem, index: 0 });
          }}
        >
          <Text
            numberOfLines={1}
            style={{
              color: "#fff",
              fontSize:14,
              fontFamily: "HelveticaBold",
              paddingLeft: 4,
            }}
          >
            MySchools
          </Text>
        </TouchableOpacity>
      </>
    );
  };

  const handleLayout = (event: any, index: number) => {
    switch (index) {
      case 0:
        setXTabOne(event.nativeEvent.layout.x);
        break;
      case 1:
        setXTabTwo(event.nativeEvent.layout.x);
        break;
      case 2:
        setXTabThree(event.nativeEvent.layout.x);
        break;
    }
  };

  const handleStyle = (index: number) => {
    switch (index) {
      case 0:
        return tabOne;
      case 1:
        return tabTwo;
      case 2:
        return tabThree;

      // no default
    }
  };

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: 42,
          position: "relative",
          backgroundColor: "#F8C586",
          borderRadius: 36,
          width: `90%`,
          marginBottom: 15,
          marginTop: 5,
          borderColor:"#fff",
          borderWidth:3,
          borderStyle:"solid"
        }}
      >
        <Animated.View
          style={{
            position: "absolute",
            width: `33%`,
            height: "100%",
            top: 0,
            left: 1,
            backgroundColor: "#F7941C",
            borderRadius: 36,
            transform: [
              {
                translateX,
              },
            ],
          }}
        />
        {renderLabels()}
      </View>
      <View
        style={{
          height: "100%",
        }}
      >
        <Animated.View
          style={{
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: handleStyle(0.5),
            zIndex: handleStyle(100),
          }}
        >
          <View
            style={{
              flexGrow: 1,
              height: 400,
              flex: 1,
              marginBottom: 50,
            }}
          >
            {children}
          </View>
        </Animated.View>
      </View>
    </>
  );
};

export default TeamScoreTabPresentation;
