import React from "react";
import { Dimensions, Text, View, FlatList } from "react-native";
import { useGetSuggetionFriendsQuery } from "../../api/friendsApiSlice";
import { AuthUser, StudentSystemType } from "../../../auth/types/types";
import StyleSheet from "react-native-media-query";
import { SearchBar } from "react-native-elements";
import FriendsItemPresentation from "../FriendItemPresentation/FriendsItemPresentation";
import { Image } from "expo-image";
import { images } from "../../../../constants";
import { EntityState } from "@reduxjs/toolkit";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
const { width, height } = Dimensions.get("window");
type FindFriendsPresentationProps = {
  account: Partial<StudentSystemType>;
  suggestionList: EntityState<AuthUser>;
  isLoading: boolean;
  isSuccess: boolean;
  onSendRequest: (student: AuthUser) => void;
  disabeld?: boolean;
  term?: string | null;
  setTerm?: any;
  onSearchInput: (text: string | null) => void;
};
const FindFriendsPresentation: React.FC<FindFriendsPresentationProps> = ({
  account,
  suggestionList,
  isLoading,
  isSuccess,
  onSendRequest,
  disabeld,
  term,
  setTerm,
  onSearchInput,
}) => {
  if (isLoading) {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: 300,
        }}
      >
        <LoadingScreen iconSize={40} />
      </View>
    );
  }
  // if (isSuccess) {
  return (
    <View style={styles.cardList}>
      <SearchBar
        placeholder="Search"
        containerStyle={styles.searchInput}
        lightTheme={true}
        inputContainerStyle={styles.searchInputContainerStyle}
        inputStyle={styles.searchInputStyle}
        //@ts-ignore
        onChangeText={(text: string) => {
          if (text === "") {
            onSearchInput(null);
          } else {
            onSearchInput(text);
          }
        }}
        onClear={() => {
          onSearchInput(null);
        }}
        value={term!}
      />

      <FlatList
        data={Object.values(suggestionList!.entities!) as AuthUser[]}
        numColumns={1}
        keyExtractor={(item) => `${item._id}`}
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingTop: 10,
          zIndex: 2,
          backgroundColor: "#fff",
        }}
        renderItem={({ item }) => (
          <FriendsItemPresentation
            item={item}
            status="send"
            onSendRequest={onSendRequest}
            getRankFriends={function (item: AuthUser): number {
              throw new Error("Function not implemented.");
            }}
          />
        )}
        ListFooterComponent={
          <View
            style={{
              marginBottom: 150,
            }}
          ></View>
        }
      />
    </View>
  );
  // } else {
  //   return <></>;
  // }
};

const { styles } = StyleSheet.create({
  searchInputContainerStyle: {
    backgroundColor: "#EBEBEB",
    borderRadius: 20,
    borderColor: "#707070",
    borderWidth: 1,
    borderStyle: "solid",
    borderBottomWidth: 1,
    height: 35,
    maxWidth: 240,
    width: "100%",

    alignSelf: "center",
  },
  searchInput: {
    backgroundColor: "#fff",
    borderWidth: 0,
    marginTop: -35,
    marginBottom: -5,
    paddingTop: 40,

    minWidth: "100%",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    shadowColor: "red",
  },
  friendsContainer: {
    flex: 1,
  },
  searchInputStyle: {
    fontSize: 12,
    lineHeight: 15,

    color: "#847D7D",
    letterSpacing: 0.2,
    alignItems: "center",
  },
  cardList: {
    width: "100%",
    // flex: 1,
    // flexGrow: 1,

    minWidth: "100%",
    paddingTop: 15,
    paddingBottom: 15,

    backgroundColor: "#fff",

    borderRadius: 8,

    height: height - 175,
  },

  cardHeader: {
    width: "100%",

    height: 100,
    backgroundColor: "#1766C1",
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
  },
  profileXpCoinsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  headerxpcontainer: {
    flex: 1,
    paddingBottom: 10,
    paddingRight: 5,
  },
  xpNumbrt: {
    fontSize: 28,
    color: "#fff",
    alignSelf: "center",
    fontWeight: "bold",
  },
  headerxp: {
    paddingTop: 2,
    paddingBottom: 2,
    width: "80%",
    paddingRight: 15,
    paddingLeft: 5,
    backgroundColor: "#000",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headercoins: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    width: "85%",
    backgroundColor: "#000",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
  },
  headercoinsImage: {
    height: 27,
    width: 25,
  },
  headercoinscontainer: {
    flex: 1,

    paddingBottom: 10,
    alignItems: "flex-end",
  },
  coinsNumber: {
    color: "white",

    fontSize: 15,
  },
  xpnumber: {
    color: "white",

    fontSize: 15,
  },
  xp: {
    color: "white",
    fontSize: 26,
  },
  friendsTabFriendText: {
    "@media (max-width: 350px)": {
      fontSize: 33,
    },
  },
  friendTabText: {
    "@media (max-width: 350px)": {
      fontSize: 15,
    },
  },
});
export default FindFriendsPresentation;
