import React, { useEffect, useState } from "react";
import { ImageBackground, View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { images } from "../../../constants";
import PolygonProfile from "../../../components/PolygonProfile";
import PolygonShape from "../../../components/PolygonShape/PolygonShape";
import { AuthUser } from "../../auth/types/types";

type BadgesProfileInfoProps = {
  student: Partial<AuthUser>;
  studentLevel: number;
};

const BadgesProfileInfo: React.FC<BadgesProfileInfoProps> = ({
  student,
  studentLevel,
}) => {
  return (
    <View style={styles.profileInfoImage}>
      <View style={styles.profileInfoShapeContainer}>
        <PolygonProfile
          ContainerStyle={{ transform: [{ scale: 0.6 }] }}
          xp={10}
          showXp={false}
          studentPic={student?.image}
        />

        <ImageBackground
          source={images.xpLevelBackground}
          resizeMode="contain"
          style={styles.image}
        >
          <Text style={styles.xpNumbrt}>{studentLevel}</Text>
        </ImageBackground>
        <View style={styles.profileTeamPic}>
          <PolygonShape
            profileImage={images.Gradegy}
            sourceImage={images.Gradegy}
            teamColor={student?.team?.color!}
          />
        </View>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  profilename: {
    width: "100%",
    color: "black",
    flexDirection: "row",
    justifyContent: "center",
  },
  profileLastName: {
    marginLeft: 3,
  },
  profileText: {
    fontSize: 27,
    fontFamily: "RajdahaniMedium",
  },
  profileInfoImage: {
    position: "relative",
    top: -135,
    left: -120,
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    zIndex: 100,
  },
  profileInfoShapeContainer: {
    width: 240,
  },
  image: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 60,
    position: "absolute",
    height: 60,
    left: 140,
    top: 83,
    zIndex: 9999,
  },
  xpNumbrt: {
    fontSize: 28,
    lineHeight: 33,
    color: "#fff",
    alignSelf: "center",
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 22,
    },
  },
  profileTeamPic: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 60,
    position: "absolute",
    height: 60,
    left: 197,
    top: 86,
    zIndex: 9999,
  },

  profileTeamPicContainer: {
    marginBottom: 13,
    position: "absolute",
    top: 160,
    left: -52,
    justifyContent: "flex-start",
    flexDirection: "row",
    marginLeft: "9%",
    zIndex: 9999,
  },
  profileRd: {
    width: 35,
    height: 35,
    borderRadius: 50,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#0BC4B8",
    flexDirection: "row",
    top: 8,
  },
  profileRdNumber: {
    color: "#fff",
    fontSize: 18,

    "@media (max-width: 350px)": {
      fontSize: 15,
    },
  },
  profileRdText: {
    color: "#fff",
  },
});
export default BadgesProfileInfo;
