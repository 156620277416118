import React, { useState } from "react";
import TimeTeamTabPresentation from "../presentation/TimeTeamTabPresentation";
import TeamScoreTabPresentation from "../presentation/TeamScoreTabPresentation";
import TeamTabItemPresentation from "../presentation/TeamTabItemPresentation";
import SchoolTabItemPresentation from "../presentation/SchoolTabItemPresentation";
import MySchoolTabPresentation from "../presentation/MySchoolTabPresentation";
import TeamTabItemContainer from "./TeamTabItemContainer";
import SchoolTabItemContainer from "./SchoolTabItemContainer";
import MySchoolTabItemContainer from "./MySchoolTabItemContainer";
import { AuthUser } from "../../auth/types/types";
type TeamAndScoreContainerProps = {
  user: AuthUser;
};
const TeamAndScoreContainer: React.FC<TeamAndScoreContainerProps> = ({
  user,
}) => {
  const [status, setStatus] = useState<number>(0);
  const [thisMonth, setThisMont] = useState<string>("true");
  const onThisMonth = () => {};
  const renderItem = () => {
    if (status === 0) {
      return (
        <TeamTabItemContainer user={user!} month={thisMonth} showCat={false} />
      );
    } else if (status === 1) {
      return <SchoolTabItemContainer month={thisMonth} />;
    } else if (status === 2) {
      return (
        <TeamTabItemContainer user={user!} month={thisMonth} showCat={true} />
      );
    }
  };
  const onTimeChange = (status: number) => {
    if (status === 1) {
      setThisMont("true");
    } else {
      setThisMont("false");
    }
  };

  const onStatusChange = (statusChange: number) => {
    setStatus(statusChange);
  };
  return (
    <TimeTeamTabPresentation onChangeStatus={onTimeChange}>
      <TeamScoreTabPresentation onChangeStatus={onStatusChange}>
        {renderItem()}
      </TeamScoreTabPresentation>
    </TimeTeamTabPresentation>
  );
};

export default TeamAndScoreContainer;
