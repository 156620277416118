import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { Image } from "expo-image";
import { FONTS, SIZES, images } from "../../constants";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../navigation/types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import LottieView from "lottie-react-native";
import { Ionicons } from "@expo/vector-icons";
import {
  changeXpsAndGems,
  selectDefaultAccount,
  selectGem,
  selectXp,
} from "../../features/auth/slice/authSlice";
import { AuthUser } from "../../features/auth/types/types";
import CenterContainer from "../../features/notification_center/container/NotificationCenterContainer";
import NotificationCenterContainer from "../../features/notification_center/container/NotificationCenterContainer";
import { useGetNotificationMutation } from "../../features/notification_center/api/notificationCenterApi";
import { EntityState } from "@reduxjs/toolkit";
import { NotificationCenterType } from "../../features/notification_center/types/types";
type HeaderBarProps = {
  profileImage: string;
  backColor?: string;
  student: AuthUser;
  headerPosition?: "absolute" | "relative" | undefined;
};

const HeaderBar: React.FC<HeaderBarProps> = ({
  profileImage,
  backColor,
  student,
  headerPosition,
}) => {
  const systemSelected = useAppSelector(selectDefaultAccount);
  const navigation = useNavigation<HomeScreenNavigationProps>();

  // this code goes here

  const [getNotificationCenter, { isLoading, isError, isSuccess }] =
    useGetNotificationMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [showRedDot, setRedDot] = useState<boolean>(false);

  const [notificationList, setNotficationList] =
    useState<EntityState<NotificationCenterType> | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const notiList = await getNotificationCenter({
          currentSystem: JSON.stringify(systemSelected),
        }).unwrap();

        //
        setNotficationList(notiList);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (openNotification) {
      (async () => {
        try {
          const notiList = await getNotificationCenter({
            currentSystem: JSON.stringify(systemSelected),
          }).unwrap();

          setNotficationList(notiList);
        } catch (error) {}
      })();
    }
  }, [openNotification]);

  const [xps, setXps] = useState<number>(0);
  const [gems, setGems] = useState<number>(0);

  const animation = React.createRef<LottieView>();
  useEffect(() => {
    if (animation && animation?.current) animation.current.play();
  }, []);

  useEffect(() => {
    if (student) {
      const projectReport = student.projectReport.find(
        (item) => item?.project === systemSelected!.project
      );

      if (projectReport) {
        setXps(projectReport?.xps);
        setGems(projectReport?.gems);
      }
    }
  }, [student, systemSelected]);

  const onClose = () => {
    setOpenNotification(false);
  };

  return (
    <>
      <View
        style={{
          height: Platform.OS === "android" ? 90 : 105,
          paddingTop:Platform.OS === "android" ? 15 : 15,
          borderBottomWidth: backColor ? 0 : 1,
          borderColor: "#CCC",
          shadowColor: "#000",
          zIndex: 2,
          
          width: "100%",
          shadowOffset: {
            width: 0,
            height: 0,
          },
          shadowOpacity: 0.14,
          shadowRadius: 3,
          flexDirection: "row",
          justifyContent: "space-between",

          elevation: 1,
          backgroundColor: backColor ? backColor : "white",
          position: headerPosition ? headerPosition : "relative",
        }}
      >
        <TouchableOpacity
          style={{
            width: 80,
          }}
          onPress={() => {
            navigation.navigate("ProfileSetting");
          }}
        >
          <Image
            style={{
              width: 42,
              height: 42,
              borderRadius: 17,
              overflow: "hidden",
              zIndex: 1,
              marginBottom: 13,
              backgroundColor: "#fff",
              top: Platform.OS === "android" ? 15 : 34,
              left: 20,
            }}
            source={{
              uri: profileImage!,
            }}
            contentFit="cover"
            transition={1000}
          />
          <Image
            source={images.profileBorder}
            style={{
              width: 53,
              height: 53,
              overflow: "hidden",
              position: "absolute",
              top: Platform.OS === "android" ? 11 : 30,
              left: 15,
              zIndex: 1,
            }}
          />
          <View
            style={{
              flexDirection: "column",
              position: "absolute",
              justifyContent: "flex-start",
              marginLeft: 20,
              top: Platform.OS === "android" ? 17 : 35,
              left: 5,
              zIndex: 0,
              width: 80,
            }}
          >
            <View
              style={{
                marginTop: 3,
                paddingRight: 15,
                paddingLeft: 22,
                height: 19,
                backgroundColor: "#000",
                borderTopRightRadius: 50,
                borderBottomRightRadius: 50,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: 70,
              }}
            >
              <View
                style={{
                  width: 80,
                  paddingRight: 15,
                  paddingLeft: 25,
                  height: 19,
                  backgroundColor: "#000",
                  borderTopRightRadius: 50,
                  borderBottomRightRadius: 50,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "white",
                    top: -2,
                    ...FONTS.body5,
                    fontSize: 10,
                  }}
                >
                  XP
                </Text>
                <Text
                  style={{
                    color: "white",
                    top: -2,
                    ...FONTS.body5,
                    fontSize: 10,
                  }}
                >
                  {xps}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                marginTop: 3,
                paddingRight: 15,
                paddingLeft: 22,
                height: 17,
                backgroundColor: "#000",
                borderTopRightRadius: 50,
                borderBottomRightRadius: 50,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: 70,
                left: 50,
                top:Platform.OS === "android" ? -17 : 2,
              }}
            >
              <Image
                style={{ height: 13, width: 13, marginRight: 5 }}
                contentFit="contain"
                source={images.gemImage}
              />
              <Text
                style={{
                  color: "white",
                  ...FONTS.body5,
                  fontSize: 10,
                  top: -2,
                }}
              >
                {gems}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ top: Platform.OS === "android" ? 19 : 42, right: 20, position: "relative" }}
          onPress={() => {
            setOpenNotification(true);
          }}
        >
          <View
            style={{
              backgroundColor: "rgba(255,255,255,0.75)",
              height: 40,
              width: 40,
              borderRadius: 50,
              alignItems: "center",
              justifyContent: "center",
              bottom:5
            }}
          >
            <Ionicons name="notifications" size={24} color="black" />
            {notificationList &&
              (
                Object.values(
                  notificationList!.entities!
                ) as NotificationCenterType[]
              ).filter((item) => !item.complete).length > 0 && (
                <LottieView
                  loop
                  style={{
                    width: 30,
                    height: 30,
                    position: "absolute",
                    top: -1,
                    right: -1,
                    zIndex: 2,
                  }}
                  ref={animation}
                  source={images.DotAnimation}
                />
              )}
          </View>
        </TouchableOpacity>
      </View>
      {notificationList && (
        <NotificationCenterContainer
          openNotification={openNotification}
          notificationList={notificationList!}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default HeaderBar;
