import React, { useEffect, useState } from "react";
import { View, Text, FlatList, ScrollView, Dimensions } from "react-native";
import { AuthUser, Category, StudentSystemType } from "../../auth/types/types";
import StyleSheet from "react-native-media-query";
import SwitchAccountItemPresentation from "./SwitchAccountItemPresentation";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  chooseSelectAccount,
  selectDefaultAccount,
} from "../../auth/slice/authSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
type SwitchAccountPresentationProps = {
  user: AuthUser;
  backToHome: () => void;
};
const { height } = Dimensions.get("window");
const SwitchAccountPresentation: React.FC<SwitchAccountPresentationProps> = ({
  user,
  backToHome,
}) => {
  const defaultAccount = useAppSelector(selectDefaultAccount);

  const dispatch = useAppDispatch();

  const [account, setAccount] = useState<Partial<StudentSystemType>>();
  const [systems, setSystems] = useState<StudentSystemType[]>([]);

  useEffect(() => {
    if (user) {
      const systemCategories: StudentSystemType[] = [];
      const systemCategory: StudentSystemType[] = [];

      user?.system.forEach((item) => {
        const findCategory = user!.systemCategories!.find(
          (cate) => cate._id === item.category && cate.displayProject === true
        );
        if (findCategory) {
          systemCategories.push({ ...item, category: findCategory });
        } else {
          systemCategory.push({ ...item, categories: [] });
        }
      });

      const categories: Category[] = systemCategories.map(
        (item) => item.category
      ) as Category[];

      let system = [];
      if (!!systemCategories[0]?.project) {
        system = [
          {
            ...systemCategories[0],
            categories: categories!,
          },
          ...systemCategory,
        ];
      } else {
        system = [...systemCategory];
      }

      setSystems(system);
      setAccount(system[0]);
    }
  }, [user]);

  const selectAccountHandler = async (
    selectedAccount: Partial<StudentSystemType>
  ) => {
    setAccount(selectedAccount);
    dispatch(chooseSelectAccount(selectedAccount));
    await AsyncStorage.setItem("@account", JSON.stringify(selectedAccount));
  };

  return (
    <View style={styles.organizationContentContainer}>
      <FlatList
        data={systems}
        keyExtractor={(item) => `${item._id!}`}
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, height: "100%" }}
        renderItem={({ item }) => {
          return (
            <SwitchAccountItemPresentation
              item={item}
              user={user}
              selectAccountHandler={selectAccountHandler}
              defaultAccount={defaultAccount!}
              backToHome={backToHome}
            />
          );
        }}
        ListFooterComponent={
          <View
            style={{
              marginBottom: 100,
            }}
          ></View>
        }
      />
    </View>
  );
};
const { styles } = StyleSheet.create({
  profileSettingContainer: {
    width: "100%",
    marginLeft: 0,
  },
  profilePicShape: {
    "@media (max-width: 350px)": {
      top: -30,
      transform: [{ scale: 0.8 }],
    },
  },
  oraginationTextContent: {
    borderBottomColor: "#9D9A9A",
    borderBottomWidth: 1,
    flex: 1,
    paddingBottom: 4,
    paddingLeft: 10,
  },
  organizationBox: {
    color: "#fff",
    width: "90%",
    paddingTop: 5,
    paddingBottom: 10,
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },

  organizationContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  organizationTitle: {
    paddingLeft: 6,
    fontSize: 18,
  },
  organizationProject: {
    paddingLeft: 6,
    fontSize: 16,
  },
  organizationImage: {
    borderRadius: 50,
    height: 45,
    width: 45,
    backgroundColor: "#e3e3e3",
    borderColor: "black",
    borderWidth: 2,
  },
  organizationActive: {
    borderColor: "#34C38F",
    borderWidth: 6,
  },

  profileInfo: {},
  backProfilePic: {
    width: "100%",
    height: 80,
  },
  profilePic: {
    alignItems: "center",
  },

  backProfileImage: {
    width: "100%",
    backgroundColor: "#e3e3e3",
    height: 170,
  },
  organizationContentContainer: {
    width: "100%",
    shadowColor: "#000",
    alignSelf:"center",
    shadowOffset: {
      width: 0,
      height: -12,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6.84,
    backgroundColor: "white",
    elevation: 7,
    borderRadius:20,
    paddingTop:30,
    marginTop:-20,
    paddingLeft:20,
    paddingRight:20,
    flex:1,
    minHeight:height - 175,
    borderBottomLeftRadius:0,
    borderBottomRightRadius:0,
    '@media (max-width: 350px)': {
        minHeight:height - 100,

       },
  },
});
export default SwitchAccountPresentation;
