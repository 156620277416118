import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { Login } from "../types/type";

const loginAdaptor = createEntityAdapter<Login>({
  selectId: (login) => login._id,
});

interface LoginInitialState extends EntityState<Login> {}

const initialState: LoginInitialState = loginAdaptor.getInitialState();

export const extendedLoginApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addToLogin: builder.mutation<EntityState<Login>, Partial<Login>>({
      query: (login) => ({
        url: "/login",
        method: "POST",
        body: login,
      }),
      transformResponse: (response: Login) => {
        return loginAdaptor.setOne(initialState, response);
      },
      invalidatesTags: ["Login"],
    }),
  }),
});

export const { useAddToLoginMutation } = extendedLoginApi;
