import React from "react";
import { useGetRaffelBagQuery } from "../api/bagApi";
import AllBagPresentation from "../prensetation/AllBagPresentation";

const AllRaffelBagContainer = () => {
  const {
    data: bagsItems,
    isLoading,
    isError,
    isSuccess,
  } = useGetRaffelBagQuery();
  return <>{isSuccess && <AllBagPresentation items={bagsItems} />}</>;
};

export default AllRaffelBagContainer;
