import React, { FC } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  Pressable,
  ImageBackground,
  Image,
} from "react-native";
import Modal from "react-native-modal";
import { MaterialIcons } from "@expo/vector-icons";
import { images } from "../../../constants";
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import BadgeSystemDetailsItemPresentation from "./BadgeSystemDetailsItemPresentation";
import BadgeSystemDetailsPresentation from "./BadgeSystemDetailsPresentation";
import { BadgeSystem } from "../type/type";

const { width } = Dimensions.get("window");

export type PostFeedPopupProps = {
  openPopup?: boolean;
  onClose?: () => void;
  item?: BadgeSystem | null;
};

const BadgetSystemDetailContainerPresentation: FC<PostFeedPopupProps> = ({
  openPopup,
  onClose,
  item,
}) => {
  const rotate = useSharedValue(0);

  const frontAnimatedStyles = useAnimatedStyle(() => {


    const rotateValue = interpolate(rotate.value, [0, 1], [0, 180]);
    return {
      transform: [
        {
          rotateY: withTiming(`${rotateValue}deg`, { duration: 1000 }),
        },
      ],
    };
  });
  const backAnimatedStyles = useAnimatedStyle(() => {
    const rotateValue = interpolate(rotate.value, [0, 1], [180, 360]);
    return {
      transform: [
        {
          rotateY: withTiming(`${rotateValue}deg`, { duration: 1000 }),
        },
      ],
    };
  });

  return (
    <>
      <Modal
        style={{ backgroundColor: "transparent" }}
        swipeDirection="down"
        onSwipeComplete={onClose}
        isVisible={openPopup}
        animationIn="fadeIn"
      >
        <Pressable
          style={styles.modelContainer}
          onPress={() => {
            onClose!();
            // rotate.value = rotate.value ? 0 : 0;
          }}
        >


          <Animated.View style={[styles.frontcard, frontAnimatedStyles]}>
            <BadgeSystemDetailsPresentation rotate={rotate} item={item!} />
          </Animated.View>

          <Animated.View style={[styles.backCard, backAnimatedStyles]}>
            <BadgeSystemDetailsItemPresentation rotate={rotate} item={item!} />
          </Animated.View>
        </Pressable>
      </Modal>
    </>

  );
};

const styles = StyleSheet.create({
  frontcard: {
    position: "absolute",
    backfaceVisibility: "hidden",
    flex: 1,
    height: "100%",
    top: "10%",
    zIndex:2

  },
  backCard: {
    position: "absolute",
    top: "10%",
    backfaceVisibility: "hidden",
    flex: 1,
    height: "100%",
  },
  disable: {
    opacity: 0.6,
    pointerEvents: "none",
  },
  BadgedetailItem: {
    height: 58,
    backgroundColor: "#fff",
    borderRadius: 8,
    width: "75%",
    marginLeft: "18%",
    flexDirection: "row",
    paddingLeft: "15%",
  },
  BadgedetailItemImage: {
    left: "-20%",
    top: -18,
    position: "absolute",
    zIndex: 2,
    width: 85,
    height: 95,
  },
  BadgedetailItemContent: {
    textAlign: "center",
    justifyContent: "center",
  },
  BadgedetailItemTitle: {
    fontFamily: "HelveticaBold",
    fontSize: 14,
    paddingBottom: 4,
  },
  BadgedetailItemSubTitle: {
    fontFamily: "HelveticaBold",
    fontSize: 10,
    maxWidth: "88%",
    lineHeight: 11,
  },
  BadgedetailItemAccept: {
    maxWidth: 36,
    objectFit: "contain",
  },
  BadgedetailHeader: {
    height: 56,
    backgroundColor: "#033C56",
    borderRadius: 6,
    borderWidth: 8,
    borderColor: "#000",
    width: "80%",
    marginLeft: "9%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  BadgedetailHeaderContent: {},
  BadgedetailHeaderText: {
    fontSize: 21,
    color: "#fff",
  },
  BadgedetailHeaderImage: {
    height: 66,
    width: 66,
    borderColor: "#000",
    borderWidth: 8,
    borderRadius: 50,
    position: "absolute",
    right: -30,
  },
  BadgedetailContent: {
    justifyContent: "space-around",
    flex: 1,
    marginTop: "10%",
    marginBottom: "5%",
  },
  modalImageContainer: {
    position: "relative",
    maxWidth: 425,
    maxHeight: 585,
    height: "80%",
    width: "92%",
    backgroundColor: "#fff",
    borderRadius: 15,
    borderWidth: 5,
    borderColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },

    shadowOpacity: 0.5,
    shadowRadius: 2.62,
    elevation: 8,
  },

  modelContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    maxWidth: "100%",
    width: width,
    alignSelf: "center",
  },

  image: {
    height: "100%",
    width: "100%",
    position: "absolute",
    borderRadius: 15,
    borderWidth: 6,
    borderColor: "#000",
    overflow: "hidden",
    objectFit: "cover",
  },

  BadgedetailContentCtaText: {
    fontFamily: "RajdahaniBold",
    fontSize: 17,
    color: "#4ED386",
  },
  BadgedetailContentCta: {
    padding: 4,
    paddingLeft: 30,
    paddingRight: 30,
    borderWidth: 5,
    borderColor: "#4ED386",
    borderRadius: 4,
  },
  BadgedetailContentAchive: {
    position: "absolute",
    bottom: 0,
    right: 0,
    borderTopStartRadius: 10,
    borderBottomEndRadius: 10,
    padding: 5,
    borderWidth: 5,
    borderColor: "#000",
  },
  BadgedetailContentText: {
    fontFamily: "RajdahaniMedium",
    fontSize: 16,
  },

  BadgedetailImage: {
    justifyContent: "center",
    flex: 1,
    marginTop: "10%",
    marginBottom: "55%",
    alignContent: "center",
    alignItems: "center",
  },
});

export default BadgetSystemDetailContainerPresentation;
