import React, { useState, useEffect } from "react";
import { Text } from "react-native";
import PrizeWheelFortune from "../components/PrizeWheel/PrizeWheel";
import PrizeWheelPopupWin from "../components/PrizeWheelPopup/PrizeWheelPopupWin";
const prizeWheelData = [
  {
    value: "1",
    image: null,
    type: "XP",
    popupValue: "1",
  },

  {
    value: "1",
    image: null,
    type: "Gem",
    popupValue: "1",
  },
  {
    value: "50",
    image: null,
    type: "XP",
    popupValue: "50",
  },
  {
    value: "1",
    image: null,
    type: "FreeTicket",
    popupValue: null,
  },
  {
    value: "25",
    image: null,
    type: "XP",
    popupValue: "25",
  },
  {
    value: "50",
    image: null,
    type: "XP",
    popupValue: "50",
  },
  {
    value: "10",
    image: null,
    type: "Gem",
    popupValue: "10",
  },
  {
    value: "1",
    image: null,
    type: "FreeTicket",
    popupValue: null,
  },
];
const PrizeWheelFortuneScreen = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [PopupType, setPopupType] = useState<any>(null);

  const onShowPopup = (show: boolean) => {
    setShowPopup(show);
  };
  return (
    <>
      <PrizeWheelFortune
        items={prizeWheelData}
        setPopupType={setPopupType}
        setShowPopup={onShowPopup}
      />
      {showPopup && PopupType && (
        <PrizeWheelPopupWin setShowPopup={setShowPopup} PopupType={PopupType} />
      )}
    </>
  );
};

export default PrizeWheelFortuneScreen;
