import React from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Text,
  Image,
  Platform,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { ExperienceCard } from "../../experience/types/types";
import { EntityState } from "@reduxjs/toolkit";
import ExperienceCardItemPresentation from "./ExperienceCardItemPresentation";
const isAndroid = Platform.OS === "android" ? true : false;
type ExperienceCardsListPresentationProps = {
  experienceCards: EntityState<ExperienceCard>;
  onOpenDetails: (item: Partial<ExperienceCard>) => void;
  onItemClickHanlder: (card: Partial<ExperienceCard>) => void;
};
const ExperienceCardsListPresentation: React.FC<
  ExperienceCardsListPresentationProps
> = ({ experienceCards, onOpenDetails, onItemClickHanlder }) => {
  return (
    <View>
      <Text style={styles.feedTitle}>Mission</Text>
      <FlatList
        data={Object.values(experienceCards!.entities!) as ExperienceCard[]}
        keyExtractor={(item) => `${item._id}`}
        keyboardDismissMode="on-drag"
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{}}
        renderItem={({ item }) => (
          <ExperienceCardItemPresentation
            experience={item}
            onOpenDetails={onOpenDetails}
            onItemClickHanlder={onItemClickHanlder}
          />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  missionsContainer: {
    width: 120,
    backgroundColor: "#13C4B8",
    borderRadius: 4,
    alignItems: "center",
    marginRight: 5,
    marginLeft: 5,
    height: isAndroid ? 170 : 165,
  },
  missionsTitle: {
    marginTop: 7,
    fontSize: 15,
    lineHeight: 20,
    color: "#fff",
  },
  missionsDay: {
    flexDirection: "row",
    width: "100%",
    marginLeft: 6,
    marginTop: 6,
    alignItems: "center",
  },
  missionsDate: {
    fontSize: 16,
    paddingLeft: 5,
    color: "#fff",
  },
  xp: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    marginRight: 5,
  },
  xpNumber: {
    fontSize: 22,

    textShadowColor: "#fff",
    textShadowOffset: { width: -1, height: 2 },
    textShadowRadius: 0,
  },
  xpStyle: {
    fontSize: 22,
    textShadowColor: "#fff",
    textShadowOffset: { width: -1, height: 2 },
    textShadowRadius: 0,
    color: "#FF0000",
  },
  missionsImage: {
    width: "100%",
    height: 85,
  },
  feedTitle: {
    fontSize: 15,
    fontFamily: "HelveticaBold",
    marginTop: 6,
    marginBottom: 6,
    paddingLeft: 10,
  },
});
export default ExperienceCardsListPresentation;
