import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { Shop } from "../types/types";
import { IResponseList } from "../../../redux/types/types";

export const shopAdaptor = createEntityAdapter<Shop>({
  selectId: (shop) => shop._id,
});

interface ShopInitialState extends EntityState<Shop> {}

export const shopInitialState: ShopInitialState = shopAdaptor.getInitialState();

export const extendedShopApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllShop: builder.query<
      EntityState<Shop>,
      {
        currentSystem: string;
        page?: string;
        limit?: string;
        sort?: string;
      }
    >({
      query: (data) =>
        `/shop/getAllByCurrentSystem?currentSystemString=${data.currentSystem}&page=${data.page}&limit=${data.limit}&sort=${data.sort}`,
      transformResponse: (response: IResponseList<Shop[]>) => {
        const shopData = response.data;
        const shopMetaData = response.metadata;

        return shopAdaptor.setAll(shopInitialState, response.data);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Shop",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Shop" as const, id })),
      ],
    }),
  }),
});

export const { useGetAllShopQuery, useLazyGetAllShopQuery } = extendedShopApi;
