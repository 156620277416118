import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { FreeTicket } from "../types/types";
import { StudentSystemType } from "../../auth/types/types";

export const freeTicketApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCountFreeTicket: builder.query<
      FreeTicket,
      {
        currentSystem: string;
      }
    >({
      query: (data) => `free-ticket?currentSystemString=${data.currentSystem}`,
      providesTags: ["FreeTicket"],
    }),
  }),
});

export const { useGetCountFreeTicketQuery } = freeTicketApi;
