import React from "react";

import NorthstarMyWhyTextPresentation from "../presentation/NorthstarMyWhyTextPresentation";



const NorthstarMyWhyTextContainer= () => {

  return (
    <NorthstarMyWhyTextPresentation/>
  );
};

export default NorthstarMyWhyTextContainer;
