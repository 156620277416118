import React from "react";
import BadgesTextPresentation from "../presentation/BadgesTextPresentation";
import { AuthUser } from "../../auth/types/types";
type BadgesTextContainerProp = {
  student: Partial<AuthUser>;
};
const BadgesTextContainer: React.FC<BadgesTextContainerProp> = ({
  student,
}) => {
  return <BadgesTextPresentation student={student} />;
};

export default BadgesTextContainer;
