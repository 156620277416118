import React from "react";
import NotehstarTextPresentation from "../presentation/NotehstarTextPresentation";



const NotehstarTextContainer= () => {

  return (
    <NotehstarTextPresentation/>
  );
};

export default NotehstarTextContainer;
