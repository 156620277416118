import {
  EntityAdapter,
  EntityId,
  EntityState,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { GradeType } from "../types/grade";

const gradeAdaptor = createEntityAdapter<GradeType>({
  selectId: (grade) => grade._id,
});

interface IGradeInititalState extends EntityState<GradeType> {}

const initialState: IGradeInititalState = gradeAdaptor.getInitialState();

export const extendedGradeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllGradeForStudent: builder.query<
      EntityState<GradeType>,
      {
        currentSystem: string;
        term: string;
        termStartDate: string;
        termEndDate: string;
        termArray: string;
      }
    >({
      query: (data) =>
        `/course-enrollment-grade?currentSystemString=${data.currentSystem}&term=${data.term}&termStartDate=${data.termStartDate}&termEndDate=${data.termEndDate}&termArray=${data.termArray}`,
      transformResponse: (response: GradeType[]) => {
        return gradeAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Grade",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Grade" as const, id })),
      ],
    }),
  }),
});

export const { useGetAllGradeForStudentQuery } = extendedGradeApi;
