import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";

type ICategoryLeaderBoard = {
  total: number;
};

const initialState: ICategoryLeaderBoard = {
  total: 0,
};

export const categoryLeaderBoardSlice = createSlice({
  name: "categoryLeaderBoard",
  initialState,
  reducers: {
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
  },
});

export const { setTotal } = categoryLeaderBoardSlice.actions;
export default categoryLeaderBoardSlice.reducer;

export const totalCategoryLeaderBoard = (state: RootState) =>
  state.categoryLeaderBoard.total;
