import React from "react";
import GradeItemPresentation from "../presentation/GradeItemPresentation";
import GradeItemPopup from "./GradeItemPopup";
import { EntityState } from "@reduxjs/toolkit";
import { GradeType } from "../types/grade";

type GradeItemContainerProps = {
  term: string;
  grades: EntityState<GradeType>;
};
const GradeItemContainer: React.FC<GradeItemContainerProps> = ({
  term,
  grades,
}) => {
  return (
    <>
      <GradeItemPresentation grades={grades} />
      {/* <GradeItemPopup /> */}
    </>
  );
};

export default GradeItemContainer;
