import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { AuthUser, Category, StudentSystemType } from "../../auth/types/types";
import { Image } from "expo-image";
import StyleSheet from "react-native-media-query";
import { FONTS } from "../../../constants";

type SwitchAccountItemPresenationProps = {
  item: Partial<StudentSystemType>;
  user: AuthUser;
  selectAccountHandler: (selectedAccount: Partial<StudentSystemType>) => void;
  defaultAccount: Partial<StudentSystemType> | null;
  backToHome: () => void;
};
const SwitchAccountItemPresentation: React.FC<
  SwitchAccountItemPresenationProps
> = ({ item, user, selectAccountHandler, defaultAccount, backToHome }) => {
  const organization = user?.systemOrganization?.find(
    (it) => it._id === item.organization
  );

  const project = user?.systemProject?.find((it) => it._id === item.project);

  const category = user?.systemCategories?.find((it) => {
    return it._id === item.category;
  });

  const subcategory = user?.systemSubcategories?.find(
    (it) => it._id === item.subcategory
  );

  return (
    <View>
      <TouchableOpacity
        key={item._id}
        onPress={() => {
          selectAccountHandler(item);
          backToHome();
        }}
        style={styles.organizationBox}
      >
        <View style={styles.organizationContent}>
          {defaultAccount?._id === item._id ? (
            <Image
              style={{
                borderRadius: 23,
                height: 45,
                width: 45,
                backgroundColor: "#e3e3e3",
                borderColor: "green",
                borderWidth: 2,
                top: -20,
              }}
              source={{
                uri: organization?.image,
              }}
              cachePolicy={"memory"}
            />
          ) : (
            <Image
              style={{
                borderRadius: 50,
                height: 50,
                width: 50,
                backgroundColor: "#e3e3e3",
                borderColor: "black",
                borderWidth: 2,
                top: -20,
              }}
              source={{
                uri: organization!.image,
              }}
              contentFit="contain"
            />
          )}

          <View style={styles.oraginationTextContent}>
            <Text style={[styles.organizationTitle]}>
              {organization?.title}
            </Text>
            <Text style={[styles.organizationProject]}>{project?.title}</Text>

            {item.categories!.length > 0 ? (
              item.categories?.map((it) => (
                <Text key={it._id} style={[styles.organizationProject]}>
                  {it.title}
                </Text>
              ))
            ) : (
              <Text style={[styles.organizationProject]}>
                {category?.title}
              </Text>
            )}
            <Text style={[styles.organizationProject]}>
              {subcategory?.title}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const { styles } = StyleSheet.create({
  profileSettingContainer: {
    width: "100%",
    marginLeft: 0,
  },
  profilePicShape: {
    "@media (max-width: 350px)": {
      top: -30,
      transform: [{ scale: 0.8 }],
    },
  },
  oraginationTextContent: {
    borderBottomColor: "#9D9A9A",
    borderBottomWidth: 1,
    flex: 1,
    paddingBottom: 4,
    paddingLeft: 10,
  },
  organizationBox: {
    color: "#fff",
    width: "90%",
    paddingTop: 5,
    paddingBottom: 10,
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },

  organizationContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  organizationTitle: {
    paddingLeft: 6,
    fontFamily: "RajdahaniBold",
    fontSize: 18,
  },
  organizationProject: {
    paddingLeft: 6,
    fontFamily: "RajdahaniMedium",
    fontSize: 16,
  },
  organizationImage: {
    borderRadius: 50,
    height: 45,
    width: 45,
    backgroundColor: "#e3e3e3",
    borderColor: "black",
    borderWidth: 2,
  },
  organizationActive: {
    borderColor: "#34C38F",
    borderWidth: 6,
  },

  profileInfo: {},
  backProfilePic: {
    width: "100%",
    height: 80,
  },
  profilePic: {
    alignItems: "center",
  },

  backProfileImage: {
    width: "100%",
    backgroundColor: "#e3e3e3",
    height: 170,
  },
  organizationContentContainer: {
    width: "100%",
    shadowColor: "#000",
    alignSelf: "center",
    shadowOffset: {
      width: 0,
      height: -12,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6.84,
    backgroundColor: "white",
    elevation: 7,
    borderRadius: 20,
    paddingTop: 40,
    marginTop: 20,
    height: "100%",
    "@media (max-width: 350px)": {
      top: -50,
      paddingTop: 20,
    },
    top: -50,
  },
});
export default SwitchAccountItemPresentation;
