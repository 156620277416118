import React, { useState, useEffect } from "react";
import HomePresentation from "../presentation/HomePresentation";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { StudentSystemType } from "../../auth/types/types";
import { EntityState } from "@reduxjs/toolkit";
import { LeaderBoard } from "../../leaderborad/types/type";
import { useLazyGetLeaderBoardQuery } from "../../leaderborad/api/leaderboardApi";

const HomeContainer = () => {
  const systemSelected = useAppSelector(selectDefaultAccount);
  const admin = useAppSelector(selectAdmin);
  const [
    getLeaderBoard,
    {
      isLoading: isLoadingLeader,
      isError: isErrorLeader,
      isSuccess: isSuccessLoading,
    },
  ] = useLazyGetLeaderBoardQuery();
  const [system, setSystem] = useState<Partial<StudentSystemType> | null>({
    ...systemSelected,
    categories: [],
    category: null,
  });
  const [leaderBoard, setLeaderBoard] =
    useState<EntityState<LeaderBoard> | null>(null);
  const [xps, setXps] = useState<number>(0);
  const [studentLevel, setStudentLevel] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [thisMonth, setThisMonth] = useState<string>("true");

  useEffect(() => {
    setSystem({
      ...systemSelected,
      categories: [],
      category: null,
    });
    if (admin) {
      const projectReport = admin.projectReport.find(
        (item) => item.project === systemSelected!.project
      );

      if (projectReport) {
        setXps(projectReport.xps);
        setStudentLevel(Math.floor(projectReport.xps / 3500));
      }
    }
  }, [admin, systemSelected]);

  useEffect(() => {
    (async () => {
      try {
        console.log(thisMonth);
        setSuccess(false);
        setLoading(true);
        const response = await getLeaderBoard({
          currentSystem: JSON.stringify(system),
          thisMonth: thisMonth,
        }).unwrap();
        setLeaderBoard(response);
        setLoading(false);
        setSuccess(true);
      } catch (error) {}
    })();
  }, [system, thisMonth]);

  const onSelecteTab = (systemSelect: Partial<StudentSystemType>) => {
    setSystem(systemSelect);
  };

  return (
    <>
      {leaderBoard && (
        <HomePresentation
          leaderBoardList={leaderBoard!}
          student={admin!}
          currentSystem={systemSelected!}
          studentLevel={studentLevel}
          studentXp={xps}
          onSelecteTab={onSelecteTab}
          isLoading={isLoading}
          isSuccess={isSuccess}
          setThisMonth={setThisMonth}
        />
      )}
    </>
  );
};

export default HomeContainer;
