import { apiSlice } from "../../../redux/api/apiSlice";
import { store } from "../../../redux/store";
import { editAdmin } from "../../auth/slice/authSlice";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import { SevenDays } from "../types/types";

export const extendedSevenDaysApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSevenDaysLogin: builder.query<SevenDays, void>({
      query: () => `/login`,
      providesTags: ["SevenDays"],
    }),

    addReward: builder.mutation<
      any,
      {
        currentSystem: Partial<StudentSystemType>;
        day: number;
        value?: number;
        gem?: string;
      }
    >({
      query: (data) => ({
        url: `/seven-days?gem=${data.gem}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response: AuthUser) => {
        if (response?.confirm) {
          store.dispatch(editAdmin(response));
        }
      },
      invalidatesTags: ["Student"],
    }),
  }),
});

export const { useGetAllSevenDaysLoginQuery, useAddRewardMutation } =
  extendedSevenDaysApi;
