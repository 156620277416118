import { Image } from "expo-image";
import React from "react";
import { Modal, View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { images } from "../../constants";
import { Shop } from "../../features/shop/types/types";
import { StudentSystemType } from "../../features/auth/types/types";
import { RaffelTicket } from "../../features/raffelTicket/types/types";
import CountDownTimer from "../CountDownTimer/CountDownTimer";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
type PrizeBuyProps = {
  open: boolean;
  onClose: () => void;
  item: Partial<Shop> | Partial<RaffelTicket>;
  studentLevel: number;
  onAddBag: () => void;
  gem: number;
  completeLoading?: boolean;
  disableLoading?: boolean;
};
const PrizeBuy: React.FC<PrizeBuyProps> = ({
  open,
  onClose,
  item,
  studentLevel,
  onAddBag,
  gem,
  completeLoading,
  disableLoading,
}) => {
  return (
    <Modal transparent={true} visible={open}>
      <View style={styles.modelContent}>
        <View style={styles.shopPopup}>
          <TouchableOpacity onPress={onClose} style={styles.popupClose}>
            <Image
              source={images.iconClose}
              style={{
                width: 25,
                height: 25,
              }}
            />
          </TouchableOpacity>
          <Image
            source={{
              uri: item?.image!,
            }}
            style={styles.popupImage}
          />
          <View style={styles.content}>
            <View style={styles.titleBox}>
              <Text style={styles.popupTitle} numberOfLines={1}>
                {item?.title}
              </Text>
              <Text style={styles.dueDate}>
                {/* {(item as Shop)?.inital_stock} Left */}
                {(item as RaffelTicket)?.drawDate && (
                  <CountDownTimer
                    drawDate={new Date((item as RaffelTicket)?.drawDate)}
                  />
                )}
              </Text>
            </View>
            <View style={styles.popupShopItemCoinContainer}>
              <Text style={styles.popupShopItemCoin}>{item?.gem}</Text>
              <Image
                source={images.gemImage}
                style={styles.popupShopItemCoinImg}
              />
            </View>
          </View>
          <Text style={styles.description}>{item?.description}</Text>

          {/* have free Ticket */}
          {/* {item?.canUseFreeTicket && (
            <View>
              <TouchableOpacity style={styles.useFreeTicket} onPress={() => {}}>
                <Text style={styles.useFreeTicketText}>Use a FREE Ticket</Text>
                <Image
                  source={require("../../assets/image/tickets.png")}
                  style={styles.useFreeTicketImage}
                />
              </TouchableOpacity>
              <Text style={styles.useFreeTicketSeprator}> ——— or ——— </Text>
            </View>
          )} */}

          {/* can buy */}
          {/* {item?.status === "noLock" && ( */}

          {studentLevel >= (item as Shop)?.unlock_level &&
            gem >= item?.gem! && (
              <View style={styles.popupButtons}>
                <TouchableOpacity
                  style={styles.popupCloseButton}
                  onPress={() => {
                    onClose();
                  }}
                >
                  <Text style={styles.ButtonText}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.popupBuyButton}
                  // disabled={pageStatus === "BUY_LOADING" ? true : false}
                  onPress={onAddBag}
                  disabled={disableLoading}
                >
                  <Text style={[styles.ButtonText, styles.BuyButtonText]}>
                    {completeLoading ? <LoadingScreen iconSize={24} /> : "BUY"}
                    {/* {pageStatus === "BUY_LOADING" ? "Loading..." : "BUY"} */}
                  </Text>
                </TouchableOpacity>
              </View>
            )}

          {/* )} */}

          {/* can't buy */}
          {studentLevel <= (item as Shop)?.unlock_level && gem < item?.gem! && (
            <View style={styles.popupButtons}>
              <TouchableOpacity
                style={[styles.popupCloseButton, styles.popupCloseButtonOnly]}
                onPress={onClose}
              >
                <Text style={styles.ButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          )}
          {studentLevel < (item as Shop)?.unlock_level && gem > item?.gem! && (
            <View style={styles.popupButtons}>
              <TouchableOpacity
                style={[styles.popupCloseButton, styles.popupCloseButtonOnly]}
                onPress={onClose}
              >
                <Text style={styles.ButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          )}

          {(item as RaffelTicket)?.drawDate! && gem >= item?.gem! && (
            <View style={styles.popupButtons}>
              <TouchableOpacity
                style={styles.popupCloseButton}
                onPress={() => {
                  onClose();
                }}
              >
                <Text style={styles.ButtonText}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.popupBuyButton}
                // disabled={pageStatus === "BUY_LOADING" ? true : false}
                onPress={onAddBag}
                disabled={disableLoading}
              >
                <Text style={[styles.ButtonText, styles.BuyButtonText]}>
                  {completeLoading ? <LoadingScreen iconSize={24} /> : "BUY"}
                  {/* {pageStatus === "BUY_LOADING" ? "Loading..." : "BUY"} */}
                </Text>
              </TouchableOpacity>
            </View>
          )}

          {(item as RaffelTicket)?.drawDate && gem < item?.gem! && (
            <View style={styles.popupButtons}>
              <TouchableOpacity
                style={[styles.popupCloseButton, styles.popupCloseButtonOnly]}
                onPress={onClose}
              >
                <Text style={styles.ButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modelContent: {
    backgroundColor: "#000000aa",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  ButtonText: {
    color: "#fff",
    fontSize: 16,
  },
  BuyButtonText: {
    paddingRight: 35,
  },
  popupShopItemCoinImg: {
    width: 28,
    height: 28,
    position: "absolute",
    right: -7,
  },
  titleBox: {
    minWidth: 150,
    borderRadius: 8,
    overflow: "hidden",
    textAlign: "center",
    alignItems: "center",
    borderWidth: 3,
    borderColor: "#fff",
    maxWidth: 200,
  },
  popupTitle: {
    backgroundColor: "#3E3F5E",
    color: "#fff",
    fontSize: 15,

    width: "100%",
    textAlign: "left",
    padding: 7,
  },
  dueDate: {
    width: "100%",
    fontSize: 15,
    textAlign: "left",
    padding: 3,
    paddingLeft: 8,
    backgroundColor: "#0BC4B8",
    color: "#fff",
    fontWeight: "bold",
  },
  popupImage: {
    backgroundColor: "#e2e2e2",
    height: 111,
  },
  shopPopup: {
    backgroundColor: "#fff",
    margin: 50,
    padding: 0,
    borderRadius: 10,
    overflow: "hidden",
    maxHeight: "70%",
    width: "80%",
    maxWidth: 347,
    position: "relative",
  },
  popupClose: {
    position: "absolute",
    top: 14,
    left: 17,
    zIndex: 10,
  },
  content: {
    flexDirection: "row",
    borderRadius: 8,
    overflow: "hidden",
    position: "absolute",
    top: 80,

    width: "100%",

    paddingLeft: 15,
    paddingRight: 15,

    justifyContent: "space-between",
  },
  popupCloseButton: {
    backgroundColor: "#F4284F",
    height: 41,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    borderRadius: 8,
    width: "30%",
    position: "absolute",
    zIndex: 2,
    right: 0,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: -2,
      height: 5,
    },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 1,
  },
  popupBuyButton: {
    backgroundColor: "#49C47D",
    height: 41,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    borderRadius: 8,
    width: "100%",
  },
  popupButtons: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    marginBottom: 21,
    position: "relative",
    width: "85%",
    justifyContent: "center",
    alignContent: "center",
    shadowColor: "rgba(0,0,0,0.5)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 1,
  },
  description: {
    fontSize: 26,
    maxWidth: "116%",
    alignItems: "center",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 30,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
  },
  popupShopItemCoin: {
    color: "#fff",
    fontSize: 18,
    top: -2,
    paddingLeft: 15,
    paddingRight: 20,
  },

  popupShopItemCoinContainer: {
    backgroundColor: "#000",
    borderRadius: 40,
    flexDirection: "row",
    minWidth: 60,
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    borderWidth: 2,
    borderColor: "#fff",
    height: 25,
    position: "relative",
    top: 20,
  },
  popupCloseButtonOnly: {
    position: "relative",
    width: 135,
  },
  useFreeTicket: {
    backgroundColor: "#000",
    borderRadius: 40,
    height: 65,
    width: "94%",
    marginLeft: "3%",
    marginRight: "3%",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    alignItems: "center",
    paddingLeft: 15,
  },
  useFreeTicketText: {
    fontSize: 20,
    color: "#fff",
    fontWeight: "bold",
  },
  useFreeTicketImage: {
    top: -2,
  },
  useFreeTicketSeprator: {
    fontSize: 26,
    alignItems: "center",
    textAlign: "center",
    paddingTop: 27,
    paddingBottom: 27,
  },
});
export default PrizeBuy;
