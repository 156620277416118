import React from "react";
import { View } from "react-native";
import XpLinearChart from "../../../components/XpLinearChart/XpLinearChart";
type HomeXpChartPresentationProp = {
  studentXp: number;
  studentLevel: number;
};
const HomeXpChartPresentation: React.FC<HomeXpChartPresentationProp> = ({
  studentLevel,
  studentXp,
}) => {
  return (
    <View style={{ width: "95%", left: 10 }}>
      <XpLinearChart xp={studentXp} />
    </View>
  );
};

export default HomeXpChartPresentation;
