import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { Bag } from "../types/type";
import { StudentSystemType } from "../../auth/types/types";
import { store } from "../../../redux/store";
import { editAdmin } from "../../auth/slice/authSlice";
import {
  extendedShopApi,
  shopAdaptor,
  shopInitialState,
} from "../../shop/api/shopApi";
import { Shop } from "../../shop/types/types";
import {
  extendedRaffelTicketApi,
  raffelTicketAdaptor,
  raffleInitialState,
} from "../../raffelTicket/api/raffelTicketApi";
import { RaffelTicket } from "../../raffelTicket/types/types";

const bagAdaptor = createEntityAdapter<Bag>({
  selectId: (bag) => bag.id,
});

interface IBagInitialState extends EntityState<Bag> {}

const initialState: IBagInitialState = bagAdaptor.getInitialState();

export const extendedBagApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBagItems: builder.query<EntityState<Bag>, void>({
      query: () => `/bag`,
      transformResponse: (response: Bag[]) => {
        return bagAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Bag",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Bag" as const, id })),
      ],
    }),
    getItemsBag: builder.query<EntityState<Bag>, void>({
      query: () => `/bag/items`,
      transformResponse: (response: Bag[]) => {
        return bagAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Bag",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Bag" as const, id })),
      ],
    }),
    getRaffelBag: builder.query<EntityState<Bag>, void>({
      query: () => `/bag/raffel`,
      transformResponse: (response: Bag[]) => {
        return bagAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Bag",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Bag" as const, id })),
      ],
    }),

    addToBag: builder.mutation<
      EntityState<Bag>,
      {
        currentSystem: Partial<StudentSystemType>;
        amount: number;
        bagDetails: Partial<Bag>;
      }
    >({
      query: (bag) => ({
        url: "/bag",
        method: "POST",
        body: bag,
      }),
      transformResponse: (response: Bag) => {
        store.dispatch(editAdmin({ ...response.student }));
        return bagAdaptor.setOne(initialState, {
          ...response,
          id: response._id!,
        });
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Bag", id: "LIST" },
        "Student",
        // { type: "Shop", id: "LIST" },
        // { type: "RaffelTicket", id: "LIST" },
      ],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        dispatch(
          extendedShopApi.util.updateQueryData(
            "getAllShop",
            { currentSystem: JSON.stringify(data.currentSystem) },
            (shops) => {
              shopAdaptor.setAll(
                shopInitialState,
                Object.values(shops.entities!) as Shop[]
              );
            }
          )
        );

        dispatch(
          extendedRaffelTicketApi.util.updateQueryData(
            "getAllRaffelTicket",
            { currentSystem: JSON.stringify(data.currentSystem) },
            (raffles) => {
              raffelTicketAdaptor.setAll(
                raffleInitialState,
                Object.values(raffles.entities!) as RaffelTicket[]
              );
            }
          )
        );
      },
    }),
  }),
});

export const {
  useAddToBagMutation,
  useGetBagItemsQuery,
  useGetItemsBagQuery,
  useGetRaffelBagQuery,
} = extendedBagApi;
