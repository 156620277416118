import { apiSlice } from "../../../redux/api/apiSlice";
import { store } from "../../../redux/store";
import { editAdmin } from "../slice/authSlice";
import { AuthUser, ILoginParam } from "../types/types";

export const extendApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<Promise<AuthUser>, ILoginParam>({
      query: (loginparam: ILoginParam) => ({
        url: "/authadmin/login",
        credentials: "include",
        method: "POST",
        body: {
          ...loginparam,
        },
      }),
    }),

    updateUser: builder.mutation<Promise<AuthUser>, Partial<AuthUser>>({
      query: (user) => ({
        url: "/student",
        method: "PUT",
        credentials: "include",
        body: user,
      }),
      transformResponse: (response: Promise<AuthUser>) => {
        store.dispatch(editAdmin(response));
        return response;
      },
      invalidatesTags: [{ type: "Student", id: "LIST" }],
    }),
  }),
});

export const { useLoginMutation, useUpdateUserMutation } = extendApiSlice;
