import React, { useState } from "react";
import {
  View,
  Text,
  Dimensions,
  ImageBackground,
  ScrollView,
  FlatList,
  Platform,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { images } from "../../../constants";
import ProfileSettingHeaderContainer from "../../profileSetting/container/ProfileSettingHeaderContainer";
import { AuthUser, StudentSystemType } from "../../auth/types/types";

import AccountTab from "../../../components/AccountTab/AccountTab";
import { EntityState } from "@reduxjs/toolkit";
import { Shop } from "../../shop/types/types";
import ShopItemPresentation from "../../shop/presentation/ShopItemPresentation";
import { RaffelTicket } from "../../raffelTicket/types/types";
import RaffelTicketListPresentation from "../../raffelTicket/presentation/RaffelTicketListPresentation";
import PrizeHeaderPresentation from "../../Prizes/presentation/PrizeHeaderPresentation";
import BagTabPresentation from "./BagTabPresentation";
import AllBagContainer from "../container/AllBagContainer";
import AllItemsBagContainer from "../container/AllItemsBagContainer";
import AllRaffelBagContainer from "../container/AllRaffelBagContainer";

const { height } = Dimensions.get("window");
type BagPresentationProp = {
  gem: number;
  freeTicket: number;
  onShop: () => void;
};
const BagPresentation: React.FC<BagPresentationProp> = ({
  gem,
  onShop,
  freeTicket,
}) => {
  const [bagState, setBagState] = useState<number>(0);

  const onChangeStaste = (state: number) => {
    setBagState(state);
  };

  const renderTabItems = () => {
    if (bagState === 0) {
      return <AllBagContainer />;
    } else if (bagState === 1) {
      return <AllItemsBagContainer />;
    } else if (bagState === 2) {
      return <AllRaffelBagContainer />;
    }
  };
  return (
    <>
      <View
        style={styles.shopContainer}
      >
        <ImageBackground
         source={images.experienceTop}
        style={styles.cardHeader}
        resizeMode="cover"
        borderBottomLeftRadius={40}
        borderBottomRightRadius={40}
      ></ImageBackground>
        <View style={styles.shopPageImage}>
          <PrizeHeaderPresentation
            gem={gem}
            shop
            onShop={onShop}
            freeTicket={freeTicket}
          />
          <BagTabPresentation onChangeStatus={onChangeStaste}>
            <>{renderTabItems()}</>
          </BagTabPresentation>
        </View>
      </View>
    </>
  );
};
const { styles } = StyleSheet.create({
  shopContainer: {
    flex: 1,
    backgroundColor: "#fff",
    top: 0,
  },
  cardHeader: {
    width: "100%",
    height: Platform.OS === "android" ? 130 : 160,
    backgroundColor: "#1766C1",
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 100,
  },
  raffleContainer: {
    flex: 1,
    maxHeight: height - 420,
  },
  backProfileImage: {
    width: "100%",
    height: "120%",
    paddingTop: 20,
    position: "absolute",
  },
  shopPageImage: {
    marginTop: 0,
    flex: 1,

  },
});
export default BagPresentation;
