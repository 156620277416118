import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, Text, Platform } from "react-native";
import StyleSheet from "react-native-media-query";
import { Image } from "expo-image";
import { FONTS, SIZES, images } from "../../../constants";
import { FontAwesome5 } from "@expo/vector-icons";
import { ExperienceCard } from "../types/types";
import ExperienceDetailsPresentation from "./ExperienceDetailsPresentation";
import { GradeReward } from "../../gradeReward/types/GradeReward";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { MaterialCommunityIcons } from "@expo/vector-icons";
type ExperienceItemPresentationProps = {
  item: Partial<ExperienceCard> | null;
  onItemClick?: (card: Partial<ExperienceCard>) => void;
  openDetailsHandler?: () => void;
  passDue?: boolean;
  complete?: boolean;
  completeHandler?: () => void;
  onOpenReviePopupMission?: (item: Partial<ExperienceCard>) => void;
  itemGrade?: GradeReward;
  disableLoading: boolean;
  onDisableLoading: () => void;
  onDisableLoadingOff: () => void;
};
const ExperienceItemPresentation: React.FC<ExperienceItemPresentationProps> = ({
  item,
  onItemClick,
  openDetailsHandler,
  passDue,
  onOpenReviePopupMission,
  complete,
  itemGrade,
  disableLoading,
  onDisableLoading,
  onDisableLoadingOff,
}) => {
  const student = useAppSelector(selectAdmin);
  const [month, setMonth] = useState("");
  const [day, setDay] = useState(0);
  const [year, setYear] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [itemState, setItemState] = useState(null);
  const [loadImage, setLoadImage] = useState(false);
  const [retakeState, setRetakeState] = useState(false);
  const [hasPhotoState, setHasPhotoState] = useState(null);
  const [desiable, setDisable] = useState(false);
  const [projectImage, setPorjectImage] = useState<string | null>(null);
  const systemSelected = useAppSelector(selectDefaultAccount);

  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (item) {
      const getMonthFromDate = new Date(item!.endDate!).getMonth();
      const getDayFromDate = new Date(item!.endDate!).getDate();
      const getYearfromDate = new Date(item!.endDate!).getFullYear();

      setMonth(months[getMonthFromDate]);
      setDay(getDayFromDate);
      setYear(getYearfromDate);
    }
  }, []);

  useEffect(() => {
    if (student) {
      const projectReport = student.projectReport.find(
        (item) => item?.project === systemSelected?.project
      );
      const project = student?.systemProject?.find(
        (it) => it._id === projectReport?.project
      );
      if (project?.image !== "https://api.gradify.app/static/download.ff.png") {
        setPorjectImage(project?.image!);
      } else {
        setPorjectImage("https://api.gradify.app/static/icons/gradegy.png");
      }
    }
  }, [student]);

  useEffect(() => {
    return () => {
      try {
        onDisableLoadingOff();
      } catch (error) {}
    };
  }, []);

  let nextEventDate;
  if (item) {
    nextEventDate = new Date(
      new Date(String(item?.endDate)).setDate(
        new Date(String(item?.endDate)).getDate() + 2
      )
    );
  }

  let expireEventDate;
  if (item) {
    expireEventDate = new Date(
      new Date(String(item?.endDate)).setDate(
        new Date(String(item?.endDate)).getDate() +
          (item?.availableDays! ? item?.availableDays : 7)
      )
    );
  }

  return (
    <>
      {(nextEventDate &&
        item?.experienceType?.title === "Events" &&
        new Date().getTime() > nextEventDate?.getTime()) ||
      (item?.experienceType?.title === "Gradegy" &&
        new Date().getTime() > expireEventDate?.getTime()!) ? (
        <></>
      ) : (
        <>
          <TouchableOpacity
            style= {(Platform.OS === "web") ? [ styles.expItemContainer, styles.expItemContainerWeb ] : styles.expItemContainer}
            disabled={disableLoading}
            onPress={() => {
              if (itemGrade) return void 0;
              if (
                item?.experienceType?.title === "Mission" &&
                item?.missionRevive &&
                passDue
              ) {
                onOpenReviePopupMission!(item);
              } else {
                onItemClick!(item!);
                openDetailsHandler!();
              }
            }}
          >
            <View style={styles.iconContainer}>
              <Image style={styles.image} source={images.dotExp} />

              <Image
                style={styles.expIcon}
                onLoad={() => {}}
                source={{
                  uri: item ? item!.experienceType!.icon! : projectImage!,
                  // item
                  // //   ? item!.experienceType!.icon!
                  //   : `https://api.gradify.app/static/file-1691439946373-164035177.png`,
                }}
              />
            </View>

            <View style={styles.content}>
              <Text numberOfLines={2} style={styles.title}>
                {item ? item.title : itemGrade?._id}
              </Text>
              {!complete && (
                <Text numberOfLines={2} style={styles.subTitle}>
                  {item?.subTitle}
                </Text>
              )}

              {/* Grade Item Completed */}
              {itemGrade ? (
                <Text style={styles.subTitle}>
                  COMPLETED{" "}
                  <FontAwesome5 name="check-double" size={14} color="#1FC3A3" />
                </Text>
              ) : (
                complete &&
                !itemGrade && (
                  <Text style={styles.subTitle}>{item!.subTitle}</Text>
                )
              )}

              <Text style={styles.Date}>
                {itemGrade ? (
                  ""
                ) : (
                  <>
                    {month} {day} {year}
                  </>
                )}
              </Text>
            </View>
            <View style={styles.state}>
              <Text></Text>
            </View>
            {false && (
              <View style={styles.completedIcon}>
                <Image
                  source={images.completeIcon}
                  style={styles.completeImage}
                />
              </View>
            )}

            {passDue && (
              <View style={styles.completedIcon}>
                {item?.experienceType?.title === "Mission" &&
                item.missionRevive ? (
                  <Image source={images.hotspotRev} style={styles.hotspotRev} />
                ) : (
                  <Image
                    source={images.passDueIcon}
                    style={styles.completeImage}
                  />
                )}
              </View>
            )}

            {/* Photo Delete my profile */}
            {complete && item?.completionType?.title === "Photo" && (
              <View style={styles.RetakePhoto}>
                <MaterialCommunityIcons
                  style={{ marginLeft: -10 }}
                  name="camera-retake"
                  size={32}
                  color="black"
                />
              </View>
            )}
            <View style={styles.xp}>
              <Text style={styles.xpStyle}>XP</Text>
              <Text style={styles.xpNumber}>
                {item
                  ? item?.xpCompletion
                  : Number(itemGrade?.course_completion_percentage) * 100}
              </Text>
            </View>

            {/* {retakeState && (
        <ReTakePhotoModal open={undefined} onClose={undefined}
        />
      )} */}
          </TouchableOpacity>
        </>
      )}
    </>
  );
};

const { styles } = StyleSheet.create({
  expItemContainer: {
    paddingRight: 10,
    paddingBottom: 2,
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
  },
  expItemContainerWeb: {

    width: 550,
  },
  iconContainer: {
    padding: 15,
    position: "relative",
  },
  hotspotRev: {
    alignSelf: "center",
    width: 85,
    height: 30,
  },
  expIcon: {
    width: 50,
    height: 50,
    borderRadius: 0,
    top: 0,
    left: -5,
    "@media (max-width: 350px)": {
      width: 30,
      height: 30,
      top: 3,
    },
  },
  content: {
    marginTop: 10,
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderBottomColor: "#BABABA",
    paddingBottom: 10,
    width: "100%",
    flex: 4,
    "@media (max-width: 350px)": {
      flex: 6,
    },
  },
  RetakePhoto: {
    marginTop: 20,
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderBottomColor: "#BABABA",
    padding: 0,
    paddingBottom: 10,
    flex: 1,
    alignItems: "flex-end",
  },
  xp: {
    width: "20%",
    marginTop: 20,
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderBottomColor: "#BABABA",
    padding: 0,
    paddingBottom: 10,
    flex: 1,
    alignItems: "flex-end",
    paddingRight: 10,
    "@media (max-width: 350px)": {
      flex: 2,
    },
    top: 0,
  },
  state: {
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderBottomColor: "#BABABA",
    marginTop: 20,
    padding: 0,
    flex: 1,
  },
  xpStyle: {
    color: "#000",
    ...FONTS.h3,
    fontSize: 18,
    marginLeft: 4,
    "@media (max-width: 350px)": {
      fontSize: 15,
    },
  },
  xpNumber: {
    color: "#FF4C00",
    ...FONTS.h3,

    marginTop: -5,
  },
  title: {
    ...FONTS.h3,
    fontSize: 15,
  },
  subTitle: {
    ...FONTS.body3,
    fontSize: 13,
    lineHeight: 15,
  },
  Date: {
    ...FONTS.body5,
    fontSize: 12,
    lineHeight: 18,
  },
  image: {
    position: "absolute",
    left: 33,
    top: 0,
    flex: 1,
    width: 3,
    height: 98,
  },
  completedIcon: {
    borderBottomWidth: 2,
    flex: 1,
    borderStyle: "solid",
    borderBottomColor: "#BABABA",
    minWidth: 50,
    paddingRight: 20,
    justifyContent: "center",
    "@media (max-width: 350px)": {
      minWidth: 30,
      flex: 0.5,
    },
  },
  completeImage: {
    alignSelf: "center",
    width: 15,
    height: 15,
  },
});
export default ExperienceItemPresentation;
