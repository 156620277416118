import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  ImageBackground,
  Image,
  Platform,
} from "react-native";
import StyleSheet from "react-native-media-query";

import { FONTS, SIZES, images } from "../../../constants";
import { ExperienceCard } from "../types/types";
import ExperienceDetailsPresentation from "./ExperienceDetailsPresentation";
type ExperienceItemPresentationProps = {
  item: Partial<ExperienceCard>;
  onItemClick?: (hotspotGroupId: string) => void;
  openDetailsHandler?: () => void;
  passDue?: boolean;
  complete?: boolean;
  completeHandler?: () => void;
  loadPassDueHotspotHanldler: (hotspotGroupId: string) => Promise<number>;
};
const ExperienceItemHotspotPresentation: React.FC<
  ExperienceItemPresentationProps
> = ({
  item,
  onItemClick,
  openDetailsHandler,
  passDue,
  complete,
  loadPassDueHotspotHanldler,
}) => {
  const [month, setMonth] = useState("");
  const [day, setDay] = useState(0);
  const [year, setYear] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [itemState, setItemState] = useState(null);
  const [loadImage, setLoadImage] = useState(false);
  const [retakeState, setRetakeState] = useState(false);
  const [hasPhotoState, setHasPhotoState] = useState(null);
  const [showPass, setShowPass] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const res = await loadPassDueHotspotHanldler(item._id as string);
      console.log(res);
      setShowPass(res);
    })();
  }, []);

  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const getMonthFromDate = new Date(item!.endDate!).getMonth();
    const getDayFromDate = new Date(item!.endDate!).getDate();
    const getYearfromDate = new Date(item!.endDate!).getFullYear();

    setMonth(months[getMonthFromDate]);
    setDay(getDayFromDate);
    setYear(getYearfromDate);
  }, [item]);
  return (
    <>
      {/* showPass > 0 && new Date() < new Date(item.endExpireDate!) */}
      {showPass > 0 && new Date() < new Date(item.endExpireDate!) && (
        <TouchableOpacity
          style={styles.expItemContainer}
          onPress={() => {
            onItemClick!(item._id! as string);
            // openDetailsHandler();
          }}
        >
          <ImageBackground
            source={images.HotspotBack}
            resizeMode="contain"
             style={(Platform.OS === "web") ? styles.hotspotBackWeb : styles.hotspotBack}
          >
            <View style={(Platform.OS === "web") ? styles.contentWeb : styles.content}>
              <View style={styles.box}>
                <Text style={styles.title}>{item!.title}</Text>
                <Text style={styles.subTitle}>{item!.subTitle}</Text>
                <Text style={styles.Date}>
                  {" "}
                  {month} {day}, {year}{" "}
                </Text>
              </View>

              <Image source={images.hotspotRev} style={styles.hotspotRev} />
            </View>
          </ImageBackground>
        </TouchableOpacity>
      )}
    </>
  );
};

const { styles } = StyleSheet.create({
  hotspotBack: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    resizeMode: "cover",
    height: 82,
  },

  hotspotBackWeb:{
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    resizeMode: "cover",
    height: 115,
  },
  hotspotRev: {
    alignSelf: "center",
    width: 85,
    height: 30,
  },
  box: {
    justifyContent: "center",
  },
  expItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
  },

  content: {
    width: "82%",
    height: 58,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    paddingRight: 30,
  },
  contentWeb: {
    width: "82%",
    height: 68,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    paddingRight: 30,
  },
  xp: {
    width: 85,
    height: 30,
    backgroundColor: "green",
  },

  title: {
    fontFamily: "Roboto-Bold",
    fontSize: 13,
    color: "#000",
    fontWeight: "bold",
  },
  subTitle: {
    ...FONTS.body3,
    fontSize: 10,
    lineHeight: 15,
  },
  Date: {
    ...FONTS.body5,
    fontSize: 10,
    lineHeight: 10,
    paddingTop: 3,
  },
  image: {
    position: "absolute",
    left: 33,
    top: 0,
    flex: 1,
    width: 3,
    height: 98,
  },
  completedIcon: {
    flex: 1,
    minWidth: 50,
    paddingRight: 20,
    justifyContent: "center",
    "@media (max-width: 350px)": {
      minWidth: 30,
      flex: 0.5,
    },
  },
  completeImage: {
    alignSelf: "center",
    width: 15,
    height: 15,
  },
});
export default ExperienceItemHotspotPresentation;
