import React, { useRef, useEffect, useState } from "react";
import {
  View,
  Modal,
  Dimensions,
  Animated,
  Text,
  Easing,
  Pressable,
  ImageBackground,
  TouchableHighlight,
  Platform,
} from "react-native";
import { scale, verticalScale, moderateScale } from 'react-native-size-matters';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { Image } from "expo-image";
import StyleSheet from "react-native-media-query";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialIcons } from "@expo/vector-icons";

import LottieView from "lottie-react-native";

import { images } from "../../constants";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../navigation/types";

const { width } = Dimensions.get("window");

type SevenDaysPopupProps = {
  openPopup: boolean;
  setOpenPopup: (state: boolean) => void;
  days: number;
  onAddReward: () => void;
  onCloseSevenDay: () => void;
  setOpenGrade: () => void;
};

const SevenDayPopup: React.FC<SevenDaysPopupProps> = ({
  openPopup,
  setOpenPopup,
  days,
  onAddReward,
  onCloseSevenDay,
  setOpenGrade,
}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const Animat0 = useRef(new Animated.Value(0)).current;
  const Animate1 = useRef(new Animated.Value(0)).current;
  const Animate2 = useRef(new Animated.Value(0)).current;
  const Animate3 = useRef(new Animated.Value(0)).current;
  const Animate4 = useRef(new Animated.Value(0)).current;
  const Animate5 = useRef(new Animated.Value(0)).current;
  const Animate6 = useRef(new Animated.Value(0)).current;
  const Animate7 = useRef(new Animated.Value(0)).current;
  const scaleAnim = useRef(new Animated.Value(0)).current;

  const [animationState, setAnimationState] = useState(Animat0);
  const [loding, setLoading] = useState<boolean>(false);

  const SelectCurrentItem = () => {
    Animated.timing(animationState, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true,
    }).start();
  };

  const defaultOptions = {
    autoplay: true,
  };
  useEffect(() => {
    Animate1.setValue(0);
    Animate2.setValue(0);
    Animate3.setValue(0);
    Animate4.setValue(0);
    Animate5.setValue(0);
    Animate6.setValue(0);
    Animate7.setValue(0);
  }, []);

  useEffect(() => {
    SelectCurrentItem();
  }, [animationState]);

  //   useEffect(() => {
  //     [...Array(loginCount).keys()].forEach((item, index) => {
  //       animationObject[`animation${index}`].current.play();
  //     });

  //     if (backAnimations && backAnimations?.current)
  //       backAnimations.current.play();
  //   }, []);

  return (
    <>
      <Modal
        transparent={true}
        visible={true}
        animationType="fade"
        onRequestClose={() => setOpenPopup(false)}
      >
        <Pressable
          style={styles.modelContainer}
          onPress={(event) => {
            if (event.target == event.currentTarget) {
              setOpenPopup(false);
            }
          }}
        >
          <LinearGradient
            colors={["#0C435C", "#316D82"]}
            style={styles.modalImageContainer}
            start={{ x: 0.5, y: 0 }}
            end={{ x: 0.5, y: 1 }}
          >
            <View style={styles.sevenDayPopupTitleContainer}>
              <View style={styles.sevenDayPopupTitleImageContainer}>
                <Image
                  source={images.SevenDaysAgo}
                  style={styles.sevenDayLogo}
                />
              </View>

              <Text style={styles.sevenDayTitle}>Daily Rewards</Text>
            </View>

            <View style={styles.sevenDayCheckContent}>
              <Animated.View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  maxWidth:"100%"
                }}
              >
                <TouchableHighlight
                  activeOpacity={Animate4 as any}
                  underlayColor={"white"}
                  style={[
                    styles.sevenDayItem,

                    days === 1
                      ? styles.sevenDayNotDisable
                      : styles.sevenDayDisable,

                    { zIndex: days === 1 ? 1 : 0 },
                  ]}
                  disabled={days === 1 ? (loding ? true : false) : true}
                  onPress={() => {
                    setAnimationState(Animate1);
                    setLoading(true);
                    onAddReward();
                    setTimeout(() => {
                      onCloseSevenDay();
                      setLoading(false);
                      setOpenGrade();
                    }, 2000);
                  }}
                >
                  <>
                    <ImageBackground
                      resizeMode="contain"
                      source={images.SevenDaysItemBackground}
                      style={styles.sevenDayItemTop}
                    >
                      <Text style={styles.sevenDayItemTitleText}>Day 1</Text>
                    </ImageBackground>

                    <View style={styles.sevenDayItemContent}>
                      <Animated.View
                        style={[
                          styles.sevenDayItems,
                          {
                            borderColor: "#46E6FF",
                            zIndex: Animate1,
                            transform: [
                              {
                                translateY: Animate1.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, 90],
                                }),
                              },
                              {
                                translateX: Animate1.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, 90],
                                }),
                              },
                              {
                                scaleX: Animate1.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2],
                                }),
                              },
                              {
                                scaleY: Animate1.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2],
                                }),
                              },
                            ],
                          },
                        ]}
                      >
                        <Text
                                style={(Platform.OS === "web") ? [
                                  styles.sevenDayItemText,
                                  styles.sevenDayItemTextWeb,
                                  { color: "#46E6FF" },
                                ] : [
                                  styles.sevenDayItemText,
                                  { color: "#46E6FF" },
                                ]}
                  
                        >
                          600
                        </Text>
                        <Image
                          contentFit="contain"
                          source={images.SevenDaysGemImage}
                          style={styles.sevenDayItemIcon}
                        />
                      </Animated.View>
                    </View>
                  </>
                </TouchableHighlight>

                <TouchableHighlight
                  activeOpacity={Animate4 as any}
                  underlayColor={"white"}
                  style={[
                    styles.sevenDayItem,

                    days === 2
                      ? styles.sevenDayNotDisable
                      : styles.sevenDayDisable,

                    { zIndex: days === 2 ? 1 : 0 },
                  ]}
                  disabled={days === 2 ? (loding ? true : false) : true}
                  onPress={() => {
                    setAnimationState(Animate2);
                    setLoading(true);
                    onAddReward();
                    setTimeout(() => {
                      onCloseSevenDay();
                      setLoading(false);
                      setOpenGrade();
                    }, 2000);
                  }}
                >
                  <>
                    <ImageBackground
                      resizeMode="contain"
                      source={images.SevenDaysItemBackground}
                      style={styles.sevenDayItemTop}
                    >
                      <Text style={styles.sevenDayItemTitleText}>Day 2</Text>
                    </ImageBackground>

                    <View style={styles.sevenDayItemContent}>
                      <Animated.View
                        style={[
                          styles.sevenDayItems,
                          {
                            borderColor: "red",
                            zIndex: Animate2,
                            transform: [
                              {
                                translateY: Animate2.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, 80],
                                }),
                              },
                              {
                                scaleX: Animate2.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                              {
                                scaleY: Animate2.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                            ],
                          },
                        ]}
                      >
                        <Text
                                     style={(Platform.OS === "web") ? [
                                      styles.sevenDayItemText,
                                      styles.sevenDayItemTextWeb,
                                      { color: "red" },
                                    ] : [
                                      styles.sevenDayItemText,
                                      { color: "red" },
                                    ]}
                 
                        >
                          10
                        </Text>
                        <Image
                          contentFit="contain"
                          source={images.SevenDaysXpImage}
                          style={styles.sevenDayItemIcon}
                        />
                      </Animated.View>
                    </View>
                  </>
                </TouchableHighlight>

                <TouchableHighlight
                  activeOpacity={Animate4 as any}
                  underlayColor={"white"}
                  style={[
                    styles.sevenDayItem,

                    days === 3
                      ? styles.sevenDayNotDisable
                      : styles.sevenDayDisable,
                    { zIndex: days === 3 ? 1 : 0 },
                  ]}
                  disabled={days === 3 ? (loding ? true : false) : true}
                  onPress={() => {
                    setAnimationState(Animate3);
                    setLoading(true);
                    onAddReward();
                    setTimeout(() => {
                      onCloseSevenDay();
                      setLoading(false);
                      setOpenGrade();
                    }, 2000);
                  }}
                >
                  <>
                    <ImageBackground
                      resizeMode="contain"
                      source={images.SevenDaysItemBackground}
                      style={styles.sevenDayItemTop}
                    >
                      <Text style={styles.sevenDayItemTitleText}>Day 3</Text>
                    </ImageBackground>

                    <View style={styles.sevenDayItemContent}>
                      <Animated.View
                        style={[
                          styles.sevenDayItems,
                          {
                            borderColor: "#8e1616",
                            zIndex: Animate3,
                            transform: [
                              {
                                translateY: Animate3.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 100],
                                }),
                              },
                              {
                                translateX: Animate3.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, -110],
                                }),
                              },
                              {
                                scaleX: Animate3.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                              {
                                scaleY: Animate3.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                            ],
                          },
                        ]}
                      >
                        <Text

                          style={(Platform.OS === "web") ? [
                            styles.sevenDayItemText,
                            styles.sevenDayItemTextWeb,
                            { color: "#8e1616" },
                          ] : [
                            styles.sevenDayItemText,
                            { color: "#8e1616" },
                          ]}
                
                        >
                          1
                        </Text>
                        <Image
                          contentFit="contain"
                          source={images.SevenDaysReviveImage}
                          style={styles.sevenDayItemIcon}
                        />
                      </Animated.View>
                    </View>
                  </>
                </TouchableHighlight>

                <TouchableHighlight
                  activeOpacity={Animate4 as any}
                  underlayColor={"white"}
                  style={[
                    styles.sevenDayItem,

                    days === 4
                      ? styles.sevenDayNotDisable
                      : styles.sevenDayDisable,

                    { zIndex: days === 4 ? 1 : 0 },
                  ]}
                  disabled={days === 4 ? (loding ? true : false) : true}
                  onPress={() => {
                    setAnimationState(Animate4);
                    setLoading(true);
                    onAddReward();
                    setTimeout(() => {
                      onCloseSevenDay();
                      setLoading(false);
                      setOpenGrade();
                    }, 2000);
                  }}
                >
                  <>
                    <ImageBackground
                      resizeMode="contain"
                      source={images.SevenDaysItemBackground}
                      style={styles.sevenDayItemTop}
                    >
                      <Text style={styles.sevenDayItemTitleText}>Day 4</Text>
                    </ImageBackground>

                    <View style={styles.sevenDayItemContent}>
                      <Animated.View
                        style={[
                          styles.sevenDayItems,
                          {
                            borderColor: "#d4dd4d",
                            zIndex: Animate4,
                            transform: [
                              {
                                translateY: Animate4.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, 1],
                                }),
                              },
                              {
                                translateX: Animate4.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, 110],
                                }),
                              },
                              {
                                scaleX: Animate4.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                              {
                                scaleY: Animate4.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                            ],
                          },
                        ]}
                      >
                        <Text
                                style={(Platform.OS === "web") ? [
                                styles.sevenDayItemText,
                                styles.sevenDayItemTextWeb,
                                { color: "#d4dd4d" },
                              ] : [
                                styles.sevenDayItemText,
                                { color: "#d4dd4d" },
                              ]}
                  
                        >
                          1
                        </Text>
                        <Image
                          contentFit="contain"
                          source={images.SevenDaysFreeTicketImage}
                          style={styles.sevenDayItemIcon}
                        />
                      </Animated.View>
                    </View>
                  </>
                </TouchableHighlight>

                <TouchableHighlight
                  underlayColor={"white"}
                  disabled={days === 5 ? (loding ? true : false) : true}
                  activeOpacity={Animate4 as any}
                  style={[
                    styles.sevenDayItem,

                    days === 5
                      ? styles.sevenDayNotDisable
                      : styles.sevenDayDisable,
                    { zIndex: days === 5 ? 1 : 0 },
                  ]}
                  onPress={() => {
                    setAnimationState(Animate5);
                    setLoading(true);
                    onAddReward();
                    setTimeout(() => {
                      onCloseSevenDay();
                      setLoading(false);
                      setOpenGrade();
                    }, 2000);
                  }}
                >
                  <>
                    <ImageBackground
                      resizeMode="contain"
                      source={images.SevenDaysItemBackground}
                      style={styles.sevenDayItemTop}
                    >
                      <Text style={styles.sevenDayItemTitleText}>Day 5</Text>
                    </ImageBackground>

                    <View style={styles.sevenDayItemContent}>
                      <Animated.View
                        style={[
                          styles.sevenDayItems,
                          {
                            borderColor: "#46E6FF",
                            zIndex: Animate5,
                            transform: [
                              {
                                translateY: Animate5.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, 1],
                                }),
                              },
                              {
                                scaleX: Animate5.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                              {
                                scaleY: Animate5.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                            ],
                          },
                        ]}
                      >
                        <Text
                            style={(Platform.OS === "web") ? [
                            styles.sevenDayItemText,
                            styles.sevenDayItemTextWeb,
                            { color: "#46E6FF" },
                          ] : [
                            styles.sevenDayItemText,
                            { color: "#46E6FF" },
                          ]}
                      
                        >
                          1200
                        </Text>
                        <Image
                          contentFit="cover"
                          source={images.SevenDaysGemImage}
                          style={styles.sevenDayItemIcon}
                        />
                      </Animated.View>
                    </View>
                  </>
                </TouchableHighlight>

                <TouchableHighlight
                  activeOpacity={Animate4 as any}
                  underlayColor={"white"}
                  style={[
                    styles.sevenDayItem,

                    days === 6
                      ? styles.sevenDayNotDisable
                      : styles.sevenDayDisable,

                    { zIndex: days === 6 ? 1 : 0 },
                  ]}
                  disabled={days === 6 ? (loding ? true : false) : true}
                  onPress={() => {
                    setAnimationState(Animate6);
                    setLoading(true);
                    onAddReward();
                    setTimeout(() => {
                      onCloseSevenDay();
                      setLoading(false);
                      setOpenGrade();
                    }, 2000);
                  }}
                >
                  <>
                    <ImageBackground
                      resizeMode="contain"
                      source={images.SevenDaysItemBackground}
                      style={styles.sevenDayItemTop}
                    >
                      <Text style={styles.sevenDayItemTitleText}>Day 6</Text>
                    </ImageBackground>

                    <View style={styles.sevenDayItemContent}>
                      <Animated.View
                        style={[
                          styles.sevenDayItems,
                          {
                            borderColor: "#000",
                            zIndex: Animate6,
                            transform: [
                              {
                                translateY: Animate6.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, 1],
                                }),
                              },
                              {
                                translateX: Animate6.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [0, -100],
                                }),
                              },
                              {
                                scaleX: Animate6.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                              {
                                scaleY: Animate6.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [1, 2.5],
                                }),
                              },
                            ],
                          },
                        ]}
                      >
                        <Text
                        
                          style={(Platform.OS === "web") ? [
                            styles.sevenDayItemText,
                            styles.sevenDayItemTextWeb,
                            { color: "#000000" },
                          ] : [
                            styles.sevenDayItemText,
                            { color: "#000000" },
                          ]}
                        >
                          1
                        </Text>
                        <Image
                          contentFit="contain"
                          source={images.SevenDaysFusesImage}
                          style={styles.sevenDayItemIcon}
                        />
                      </Animated.View>
                    </View>
                  </>
                </TouchableHighlight>

                <TouchableHighlight
                  activeOpacity={Animate4 as any}
                  underlayColor={"white"}
                  style={[
                    days === 0
                      ? styles.sevenDayNotDisable
                      : styles.sevenDayDisable,

                    { zIndex: days === 0 ? 1 : 0 },
                  ]}
                  disabled={days === 0 ? (loding ? true : false) : true}
                  onPress={() => {
                    setLoading(true);
                    onAddReward();
                    setAnimationState(Animate7);
                    setTimeout(() => {
                      onCloseSevenDay();
                      setLoading(false);
                      setOpenGrade();
                      navigation.navigate("PrizeWheelFortune");
                    }, 2000);
                  }}
                >
                  <>
                    <View
                      style={[styles.sevenDayItem, styles.sevenDayLastItem]}
                    >
                      <ImageBackground
                        resizeMode="contain"
                        source={images.SevenDaysLastItemBackgournd}
                        style={styles.sevenDayLastItemTop}
                      >
                        <Text style={styles.sevenDayItemTitleText}>Day 7</Text>
                      </ImageBackground>

                      <View>
                        <Animated.View
                          style={[
                            styles.sevenDayLastItemContent,
                            {
                              borderColor: "#000",
                              zIndex: Animate7,
                              transform: [
                                {
                                  translateY: Animate7.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [0, -130],
                                  }),
                                },
                                {
                                  translateX: Animate7.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [0, 1],
                                  }),
                                },
                                {
                                  scaleX: Animate7.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.2],
                                  }),
                                },
                                {
                                  scaleY: Animate7.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.2],
                                  }),
                                },
                              ],
                            },
                          ]}
                        >
                          <View
                            style={[
                              styles.sevenDayItems,
                              { borderColor: "red" },
                            ]}
                          >
                            <Text
                            
                              style={(Platform.OS === "web") ? [
                                styles.sevenDayItemText,
                                styles.sevenDayItemTextWeb,
                                { color: "red" },
                              ] : [
                                styles.sevenDayItemText,
                                { color: "red" },
                              ]}
                            >
                              25
                            </Text>
                            <Image
                              contentFit="contain"
                              source={images.SevenDaysXpImage}
                              style={styles.sevenDayItemIcon}
                            />
                          </View>

                          <View style={[styles.sevenDayItems]}>
                            <Image
                              contentFit="contain"
                              source={images.Wheel7Days}
                              style={{ width: moderateScale(36), height: moderateScale(36) }}
                            />
                          </View>

                          <View
                            style={[
                              styles.sevenDayItems,
                              { borderColor: "#46E6FF" },
                            ]}
                          >
                            <Text
                          
                              style={(Platform.OS === "web") ? [
                                styles.sevenDayItemText,
                                styles.sevenDayItemTextWeb,
                                { color: "#46E6FF" },
                              ] : [
                                styles.sevenDayItemText,
                                { color: "#46E6FF" },
                              ]}
                            >
                              1
                            </Text>
                            <Image
                              contentFit="contain"
                              source={images.SevenDaysGem}
                              style={styles.sevenDayItemIcon}
                            />
                          </View>
                        </Animated.View>
                      </View>
                    </View>
                  </>
                </TouchableHighlight>
              </Animated.View>
            </View>
            <View style={styles.sevenDayCheckTextContainer}>
              <Animated.View></Animated.View>
            </View>
          </LinearGradient>
        </Pressable>
      </Modal>
    </>
  );
};

const { styles } = StyleSheet.create({
  sevenDayDoneButtonText: {
    color: "#fff",
    fontSize: 27,

    paddingLeft: 15,
    paddingRight: 15,
    lineHeight: 32,
    "@media (max-width: 350px)": {
      fontSize: 24,
    },
  },
  sevenDayItemTop: {
    width: "100%",
    flex: 1,
  },
  sevenDayLastItemTop: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  sevenDayItem: {
    width: 100,
    height: 100,
    margin: 4,
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 3,
    borderRadius: 15,
    backgroundColor: "#fff",
    marginTop: 8,
    "@media (max-width: 360px)": {
      width: 80,
      height: 80,
    },
  },
  sevenDayLastItem: {
    width: 316,
    height: 105,
    "@media (max-width: 360px)": {
      width: 250,
      height: 85,
    },
  },
  sevenDayItems: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderWidth: 4,
    borderRadius: 10,
    padding: 4,
    backgroundColor: "#fff",
  },
  sevenDayItemIcon: {
    width: 20,
    height: 20,
    resizeMode: "contain",
  },
  sevenDayItemText: {
    fontSize: moderateScale(17),
    paddingRight: 8,
  },
  sevenDayItemTextWeb:{
    fontSize:18,
  },
  sevenDayItemContent: {
    justifyContent: "center",
    height: 62,
    "@media (max-width: 360px)": {
      height: 49,
    },
  },
  sevenDayLastItemContent: {
    justifyContent: "space-around",
    height: 67,
    flexDirection: "row",
    "@media (max-width: 360px)": {
      height: 54,
    },
    // justifyContent: "space-around",
  },
  sevenDayDisable: {
    opacity: 0.3,
    pointerEvent: "none",
  },
  sevenDayNotDisable: {},
  sevenDayItemTitleText: {
    textShadowColor: "#000",
    textShadowOffset: { width: 2, height: -1 },
    textShadowRadius: 2,
    color: "#fff",
    fontSize: moderateScale(20),
    lineHeight: moderateScale(23),
    padding: 3,
    paddingLeft: moderateScale(10),
  },
  sevenDayPopupTitleContainer: {
    flexDirection: "row",
  },
  outsideModal: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "red",
  },
  sevenDayDoneButton: {
    position: "absolute",
    right: 0,
    backgroundColor: "#F4284F",
    borderRadius: 24,
    zIndex: 200,
  },
  //   sevenDayDoneText: {
  //     fontSize: 28,
  //     alignSelf: "center",
  //     "@media (max-width: 350px)": {
  //       fontSize: 25,
  //     },
  //   },
  sevenDayDoneCoontainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },
  spinContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 20,
    borderWidth: 5,
    padding: 2,
    paddingLeft: 20,
    marginLeft: -13,
    borderStyle: "solid",
    borderColor: "#fff",
    shadowColor: "#000",
    backgroundColor: "#4ED386",

    overflow: "hidden",
    fontSize: 20,
    "@media (max-width: 350px)": {
      fontSize: 17,
    },
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.4,
    shadowRadius: 4.62,
    elevation: 1,
    color: "#fff",
    width: "90%",
  },
  sevenDayDoneText: {
    zIndex: 0,
    alignSelf: "flex-start",

    fontSize: 20,
    color: "#fff",
    "@media (max-width: 350px)": {
      fontSize: 17,
    },
  },
  popupClose: {
    position: "absolute",
    top: 10,
    right: 20,
    zIndex: 10,
  },
  sevenDayCheckAnimattion: {
    borderStyle: "solid",
    borderColor: "#fff",
    borderWidth: 1,
    borderRadius: 20,
    width: 32,
    height: 32,
    margin: 3,
    marginTop: 10,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  sevenDayCheckTextContainer: {
    position: "relative",
    padding: 25,
    paddingBottom: 20,
    paddingTop: 15,
  },
  sevenDayWheel: {
    position: "absolute",
    right: -20,
    top: -10,
  },
  sevenDayCheckText: {
    borderRadius: 15,
    borderWidth: 5,
    padding: 3,
    paddingLeft: 6,
    marginRight: 20,
    borderStyle: "solid",
    borderColor: "#fff",
    shadowColor: "#000",
    backgroundColor: "#4ED386",

    overflow: "hidden",
    fontSize: 15,

    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.4,
    shadowRadius: 4.62,
    elevation: 1,
    color: "#fff",
    width: "90%",
    alignSelf: "flex-end",
    justifyContent: "center",
    right: 10,
    top: 10,
  },
  sevenDayCheckContent: {
    flexDirection: "row",
    justifyContent: "center",
  },
  userContent: {
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    width: 77,
  },

  modalImageContainer: {
    position: "relative",
    maxWidth: 374,
    maxHeight: 445,

    width: "95%",
    backgroundColor: "#ffffff",
    // backgroundColor: "#85c9e8",
    // backgroundColor: "#5e5e5e",
    borderRadius: 15,
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    marginTop: "3%",
    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
  },

  modelContainer: {
    backgroundColor: "#000000aa",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    maxWidth: "100%",
    width: width,
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    maxWidth: "100%",
    width: width,
    alignSelf: "center",
    borderRadius: 20,
  },
  sevenDayTitle: {
    
    fontSize: moderateScale(20),
    width: wp('45%'),
    minWidth:"75%",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
    borderColor: "#fff",
    borderWidth: 5,
    lineHeight: moderateScale(40),

    marginTop: 8,
    marginLeft: 8,
    paddingLeft: 20,
    borderRadius: 10,
    "@media  (max-width: 700px)": {
      fontSize: moderateScale(25),
    },
  },
  sevenDayLogo: {
    width: 50,
    height: 50,
  },
  sevenDayPopupTitleImageContainer: {
    backgroundColor: "#fff",
    padding: 4,
  },
  sevenDayDesc: {
    fontSize: 13,
    color: "#fff",
    alignItems: "center",
    textAlign: "center",

    "@media (max-width: 350px)": {
      fontSize: 12,
    },
  },
});

export default SevenDayPopup;
