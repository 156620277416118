import React, { useState } from "react";
import { View } from "react-native";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import BadgeSystemListPresentation from "../presentation/BadgeSystemListPresentation";
import { useGetAllBadgesQuery } from "../api/apiBadgeSystem";
import BadgetSystemDetailContainerPresentation from "../presentation/BadgetSystemDetailContainerPresentation";
import { BadgeSystem } from "../type/type";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";

const BadgeSystemAllListContainer = () => {
  const accountSelected = useAppSelector(selectDefaultAccount);
  const {
    data: allBadgesList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllBadgesQuery({
    currentSystem: JSON.stringify(accountSelected),
  });

  const [itemBadge, setItemBadge] = useState<BadgeSystem | null>(null);
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const onItemDetail = (item: BadgeSystem | null) => {
    setOpenDetails(true);
    setItemBadge(item);
  };
  const onClose = () => {
    setOpenDetails(false);
  };
  let content;
  if (isLoading) {
    content = (
      <View style={{ height: "80%", justifyContent: "center" }}>
        <LoadingScreen iconSize={50} />
      </View>
    );
  } else if (isSuccess && allBadgesList) {
    content = (
      <>
        <View>
          <BadgeSystemListPresentation
            badgesList={allBadgesList!}
            onItemDetail={onItemDetail}
          />
        </View>

        {openDetails && (
          <BadgetSystemDetailContainerPresentation
            openPopup={openDetails}
            onClose={onClose}
            item={itemBadge}
          />
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default BadgeSystemAllListContainer;
