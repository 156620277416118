import { EntityId, EntityState } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { AuthUser, Project, StudentSystemType } from "../../auth/types/types";
import { RevivGem } from "../types/types";
import { store } from "../../../redux/store";
import { editAdmin } from "../../auth/slice/authSlice";

export const revivGemApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRevivGemStudent: builder.query<
      RevivGem,
      {
        currentSystem: string;
      }
    >({
      query: (data) => `/revive-gem?currentSystemString=${data.currentSystem}`,
      providesTags: ["RevieGem"],
    }),
    addReviveGem: builder.mutation<
      {
        amount: number;
        student: EntityId;
        project: EntityId;
      },
      {
        amount: number;
        student: EntityId;
        project: EntityId;
      }
    >({
      query: (data) => ({
        url: `/revive-gem`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["RevieGem"],
    }),
    addReviveGemByShop:builder.mutation<EntityState<AuthUser>,{
      currentSystem:string
    }>({
      query:(data) => ({
        url:`/revive-gem/shoprevive?currentSystem=${data.currentSystem}`,
        method:"POST",
        body:data
      }),
      transformResponse:(response:AuthUser):any => {
        store.dispatch(editAdmin(response));
        return response
      },
      invalidatesTags:["Student","RevieGem"]
      
    })
  }),
});

export const { useGetRevivGemStudentQuery, useAddReviveGemMutation,useAddReviveGemByShopMutation } =
  revivGemApi;
