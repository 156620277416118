import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import HomeScreen from "../screens/HomeScreen";
import ExperienceMapScreen from "../screens/ExperienceMapScreen";
import { BottomTabNavigatorParamList } from "./types";
import FeedScreen from "../screens/FeedScreen";
import TimerScreen from "../screens/TimerScreen";
import {
  FontAwesome5,
  MaterialCommunityIcons,
  Feather,
  FontAwesome,
  MaterialIcons,
} from "@expo/vector-icons";
import { COLORS } from "../constants";
import TabIcon from "../components/TabIcon";
// import PermissionsButton from "../screens/StandAloneApp";
// import BackgroundFetchScreen from "../screens/StandAloneApp";

const Tab = createBottomTabNavigator<BottomTabNavigatorParamList>();
const Tabs = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarShowLabel: false,
        headerShown: false,
        tabBarStyle: {
          backgroundColor: COLORS.white,
          height: 80,

          shadowRadius: 5,
          shadowOffset: {
            width: 0,
            height: -1,
          },
          shadowColor: "#000",
          elevation: 2,
          shadowOpacity: 0.2,
        },
      }}
    >
      <Tab.Screen
        name="Profile"
        component={HomeScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => (
            <TabIcon
              focused={focused}
              Icon={<FontAwesome5 name="user" size={24} color="#727272" />}
              focusedIcon={<FontAwesome5 name="user" size={24} color="black" />}
              title=""
            />
          ),
        }}
      />
      <Tab.Screen
        name="ExperienceMap"
        component={ExperienceMapScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => (
            <TabIcon
              focused={focused}
              Icon={<Feather name="compass" size={24} color="#727272" />}
              focusedIcon={<Feather name="compass" size={24} color="#000" />}
              title=""
            />
          ),
        }}
        initialParams={{ item: null, openPopup: false }}
      />
      <Tab.Screen
        name="Feed"
        component={FeedScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => (
            <TabIcon
              focused={focused}
              Icon={<FontAwesome name="feed" size={24} color="#727272" />}
              focusedIcon={<FontAwesome name="feed" size={24} color="#000" />}
              title=""
            />
          ),
        }}
      />
      <Tab.Screen
        name="Timer"
        component={TimerScreen}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabIcon
              focused={focused}
              Icon={<MaterialIcons name="timer" size={24} color="#727272" />}
              focusedIcon={
                <MaterialIcons name="timer" size={24} color="#000" />
              }
              title=""
            />
          ),
        }}
      />
    </Tab.Navigator>
  );
};

export default Tabs;
