import React, { useState } from "react";
import PreviwPictruePresentation from "../presentation/PreviewPictruePresentation";
import { ExperienceCard } from "../../experience/types/types";
import { StudentSystemType } from "../../auth/types/types";
import { CameraCapturedPicture, ImageType } from "expo-camera";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import axios, { AxiosError } from "axios";
import {
  useAddNewPhotoMutation,
  useReTakePhotoMutation,
} from "../api/experiencePhotoApiSlice";
import { useEditOrAddExperienceCompletionForStudentMutation } from "../../experienceComplete/api/experienceCompleteApiSlice";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { ExperiencePhoto } from "../types/types";
import { ReviveCard } from "../../reviveCard/types/type";
import { useUpdateReviveMutation } from "../../reviveCard/api/reviveCardApi";
type PreviewPictureContainerProps = {
  item: Partial<ExperienceCard>;
  currentSystem: Partial<StudentSystemType>;
  photo: CameraCapturedPicture;
  from?: string;
  prevItem?: Partial<ExperiencePhoto>;
  reviveItem?: Partial<ReviveCard> | null;
};
const PreviewPictureContainer: React.FC<PreviewPictureContainerProps> = ({
  item,
  currentSystem,
  photo,
  from,
  prevItem,
  reviveItem,
}) => {
  // console.log("revive Item", reviveItem);
  const systemSelected = useAppSelector(selectDefaultAccount);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [addNewPhoto, { isLoading, isError, isSuccess }] =
    useAddNewPhotoMutation();
  const [
    editOrAddExperienceCompletionForStudent,
    {
      isLoading: addViewLoading,
      isError: addViewError,
      isSuccess: addViewSueccess,
    },
  ] = useEditOrAddExperienceCompletionForStudentMutation();
  const [
    reTakePhoto,
    {
      isLoading: reTakeLoading,
      isError: reTakeError,
      isSuccess: reTakeSuccess,
    },
  ] = useReTakePhotoMutation();
  const [
    updateRevive,
    {
      isLoading: updateReviveLoading,
      isError: updateReviveError,
      isSuccess: updaterReviveSuccess,
    },
  ] = useUpdateReviveMutation();
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const onSubmit = async () => {
    setLoadingButton(true);
    setDisableButton(true);
    const formData = new FormData();

    formData.append("file", {
      uri: photo.uri,
      type: "image/jpg",
      name: "image.jpg",
    } as any);
    try {
      const response = await axios.post(
        `https://api.gradify.app/api/upload-image/upload`,
        //`http://172.29.10.75:6800/api/upload-image/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.uri) {
        if (!prevItem) {
          await addNewPhoto({
            expPhoto: {
              experience: item!,
              photo: response.data.uri,
              project: currentSystem.project,
              category: item!.category,
            },
            currentSystem: JSON.stringify(currentSystem),
          }).unwrap();
          await editOrAddExperienceCompletionForStudent({
            experience: item!,
            complete: true,
            currentSystem: systemSelected!,
          }).unwrap();
          if (reviveItem) {
            await updateRevive({
              _id: reviveItem?._id,
              complete: true,
            }).unwrap();
          }
        } else {
          await reTakePhoto({
            id: prevItem._id! as string,
            photo: response.data.uri,
          }).unwrap();
        }
        setLoadingButton(false);

        navigation.navigate("ExperienceMap", {
          item: null,
          openPopup: false,
        });
      }
    } catch (error: any) {
      setDisableButton(false);
      setLoadingButton(false);
    }
  };

  const onCancel = () => {
    if (from === "cam") {
      navigation.navigate("Camera", {
        item,
        currentSystem,
      });
    } else if (from === "exp") {
      navigation.navigate("ExperienceMap", {});
    }
  };
  return (
    <PreviwPictruePresentation
      photo={photo}
      onCancel={onCancel}
      onSubmit={onSubmit}
      buttonLoading={loadingButton}
      disableButton={disableButton}
    />
  );
};

export default PreviewPictureContainer;
