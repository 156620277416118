import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { TimerType } from "./types";
import { initialState } from "../../onBoarding/slice/onBoardingSlice";
import { AuthUser } from "../../auth/types/types";

export const TimerAdaptor = createEntityAdapter<TimerType>({
  selectId: (data) => data.id,
});

export interface TimerInitialState extends EntityState<TimerType> {}

export const timerInitalState: TimerInitialState =
  TimerAdaptor.getInitialState();

export const extendedTimerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInitialTimerData: builder.query<
      EntityState<TimerType>,
      {
        currentSystem: string;
      }
    >({
      query: (data) => `timer?currentSystem=${data.currentSystem}`,
      transformResponse: (response: TimerType) => {
        return TimerAdaptor.setOne(timerInitalState, response);
      },
      providesTags: ["Timer"],
    }),

    doMakeGem: builder.mutation<
      EntityState<AuthUser>,
      {
        currentSystem: string;
      }
    >({
      query: (data) => ({
        url: `/timer/makegem?currentSystem=${data.currentSystem}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Student", "Timer"],
    }),

    doMakeOtherPoint: builder.mutation<
      EntityState<AuthUser>,
      {
        currentSystem: string;
        min: number;
      }
    >({
      query: (data) => ({
        url: `/timer/makeother?currentSystem=${data.currentSystem}&min=${data.min}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Student", "Timer"],
    }),
  }),
});

export const {
  useGetInitialTimerDataQuery,
  useLazyGetInitialTimerDataQuery,
  useDoMakeGemMutation,
  useDoMakeOtherPointMutation,
} = extendedTimerApi;
