import { EntityState } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  changeXpsAndGems,
  selectAdmin,
  selectDefaultAccount,
} from "../../auth/slice/authSlice";
import {
  useGetAllActiveExperienceQuery,
  useLazyGetAllActiveExperienceQuery,
} from "../api/experienceApiSlice";
import { ExperienceCard } from "../types/types";
import ExperienceListPresentation from "../presentation/ExperienceListPresentation";
import ExperienceDetailsPresentation from "../presentation/ExperienceDetailsPresentation";
import { useEditOrAddExperienceCompletionForStudentMutation } from "../../experienceComplete/api/experienceCompleteApiSlice";
import { Text, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";

import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import {
  useGetAllReviveQuery,
  useLazyGetAllReviveQuery,
  useUpdateReviveMutation,
} from "../../reviveCard/api/reviveCardApi";
import { ReviveCard } from "../../reviveCard/types/type";
import * as webBrowser from "expo-web-browser";
import ExperienceReviveListPresentation from "../presentation/ExperienceReviveListPresentation";
import ExperienceGradeListPresentation from "../presentation/ExperienceGradeListPresentation";
import ExperienceGradeDetailPresentation from "../presentation/ExperienceGradeDetailPresentation";
import { useGetAllCourseGradeWithXpQuery } from "../../gradeReward/api/gradeRewardapi";
import { GradeReward } from "../../gradeReward/types/GradeReward";
type ExperienceListActiveContainerProps = {
  itemExp: any;
  openPopup: boolean | undefined;
  isBack?: boolean | undefined;
};
const ExperienceListActiveContainer: React.FC<
  ExperienceListActiveContainerProps
> = ({ itemExp, openPopup, isBack }) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const systemSelected = useAppSelector(selectDefaultAccount);
  const student = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();

  const [getAllActiveExperience, { isLoading, isError, isSuccess }] =
    useLazyGetAllActiveExperienceQuery();

  const [
    getAllRevive,
    { isLoading: reviveGem, isError: reviveError, isSuccess: reviveSuccess },
  ] = useLazyGetAllReviveQuery();

  const [expList, setExpList] = useState<EntityState<ExperienceCard> | null>(
    null
  );

  const [projectImage, setPorjectImage] = useState<string | null>(null);

  const [reviveList, setReviveList] = useState<EntityState<ReviveCard> | null>(
    null
  );

  const [
    editOrAddExperienceCompletionForStudent,
    {
      isLoading: addViewLoading,
      isError: addViewError,
      isSuccess: addViewSueccess,
    },
  ] = useEditOrAddExperienceCompletionForStudentMutation();

  const {
    data: activeList,
    isLoading: activeLoaDING,
    isSuccess: activeSuccess,
    isError: activeError,
  } = useGetAllActiveExperienceQuery({
    currentSystem: JSON.stringify(systemSelected!),
  });

  const {
    data: reList,
    isLoading: reListLoading,
    isError: reListError,
    isSuccess: reListSuccess,
  } = useGetAllReviveQuery({
    currentSystem: JSON.stringify(systemSelected),
  });

  const [
    updateRevive,
    {
      isLoading: updateReviveLoading,
      isError: updateReviveError,
      isSuccess: updaterReviveSuccess,
    },
  ] = useUpdateReviveMutation();

  const {
    data: gradeCourses,
    isLoading: gradeCoursesLoading,
    isError: gradeCoursesError,
    isSuccess: gradeCoursesSuccess,
  } = useGetAllCourseGradeWithXpQuery({
    currentSystem: JSON.stringify(systemSelected),
  });

  const [completeLoading, setCompleteLoading] = useState<boolean>(false);
  const [disableComplete, setDisableComplete] = useState<boolean>(false);
  const [disabloading, setDisableLoading] = useState<boolean>(false);

  const [openDetails, setOpenDetails] = useState<boolean>(
    openPopup ? openPopup : false
  );
  const [item, setItem] = useState<Partial<ExperienceCard> | null>(
    itemExp ? itemExp : null
  );
  const [reviveItem, setReviveItem] = useState<Partial<ReviveCard> | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [revive, setRevive] = useState<boolean>(false);
  const [gradeCourseTotal, setGradeCourseTotal] = useState<
    GradeReward[] | null
  >(null);
  const [openGradePop, setOpenGradePop] = useState<boolean>(false);

  //

  useEffect(() => {
    if (isBack) {
      onDisableLoadingOff();
    }
  }, [isBack]);

  useEffect(() => {
    setExpList(activeList!);
  }, [activeList]);

  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);

        const rList = await getAllRevive({
          currentSystem: JSON.stringify(systemSelected),
        }).unwrap();
        const list = await getAllActiveExperience({
          currentSystem: JSON.stringify(systemSelected!),
          sortParams: "-updatedAt",
        }).unwrap();

        setExpList(list);
        setReviveList(rList);
        setLoading(false);
      } catch (error) {}
    })();
  }, [reList]);

  useEffect(() => {
    setOpenDetails(openPopup!);
    setItem(itemExp);
  }, [openPopup, itemExp]);

  useEffect(() => {
    if (gradeCourses) {
      const scores = Object.values(gradeCourses.entities) as GradeReward[];
      const result = scores.filter(
        (item) => Number(item.course_completion_percentage) !== 100
      );
      setGradeCourseTotal(result);
    }
  }, [gradeCourses]);

  useEffect(() => {
    if (student) {
      const projectReport = student.projectReport.find(
        (item) => item?.project === systemSelected?.project
      );
      const project = student?.systemProject?.find(
        (it) => it._id === projectReport?.project
      );
      if (project?.image !== "https://api.gradify.app/static/download.ff.png") {
        setPorjectImage(project?.image!);
      } else {
        setPorjectImage("https://api.gradify.app/static/icons/gradegy.png");
      }
    }
  }, [student]);

  const onDisableLoading = () => {
    setDisableLoading(true);
  };
  const onSetOpenPopup = (status: boolean) => {
    setOpenGradePop(status);
  };

  const onItemClickHanlder = async (
    card: Partial<ExperienceCard>,
    isRevive?: boolean
  ) => {
    setItem(card);
    // setRevive(false);

    try {
      const response = await editOrAddExperienceCompletionForStudent({
        experience: card!,
        complete: false,
        currentSystem: systemSelected!,
      }).unwrap();
    } catch (error) {}
  };

  const onItemClickHandlerRevive = async (
    rItem: Partial<ReviveCard> | null
  ) => {
    if (rItem) {
      setReviveItem(rItem);
      setRevive(true);
    } else {
      setReviveItem(null);
      setRevive(false);
    }
  };

  const openDetailsHandler = () => {
    setOpenDetails(true);
  };

  const closeDetailsHandler = async () => {
    setOpenDetails(false);
  };

  const onDisableLoadingOff = () => {
    setDisableLoading(false);
  };

  const completeHandler = async () => {
    setCompleteLoading(true);
    setDisableComplete(true);
    if (item!.completionType!.title === "Student") {
      try {
        const list = await editOrAddExperienceCompletionForStudent({
          experience: item!,
          complete: true,
          currentSystem: systemSelected!,
        }).unwrap();

        if (revive) {
          await updateRevive({
            _id: reviveItem?._id,
            complete: true,
          }).unwrap();
        }

        closeDetailsHandler();
      } catch (error) {
        // console.log("revive error", error);
      }
    } else if (item!.completionType!.title === "Photo") {
      closeDetailsHandler();
      try {
        if (revive) {
          await updateRevive({
            _id: reviveItem?._id,
            complete: true,
          }).unwrap();
        }
        navigation.navigate("Camera", {
          item: item!,
          currentSystem: systemSelected!,
        });
      } catch (error) {
        // console.log("revive error", error);
      }
    } else if (item!.completionType?.title === "Link") {
      try {
        const list = await editOrAddExperienceCompletionForStudent({
          experience: item!,
          complete: true,
          currentSystem: systemSelected!,
        }).unwrap();
        if (revive) {
          await updateRevive({
            _id: reviveItem?._id,
            complete: true,
          }).unwrap();
        }

        // Linking.openURL(item!.button_link_url!);

        await webBrowser.openBrowserAsync(item!.button_link_url!);

        closeDetailsHandler();
      } catch (error) {
        // console.log("revive error", error);
      }
    } else if (item!.completionType!.title === "Admin") {
      try {
        if (revive) {
          await updateRevive({
            _id: reviveItem?._id,
            complete: true,
          }).unwrap();
        }
      } catch (error) {
        // console.log("revive error", error);
      }
    }
    setCompleteLoading(false);
    setDisableComplete(false);
    setDisableLoading(true);
  };

  const onCloseGrade = () => {
    setOpenGradePop(false);
  };
  let content;

  if (loading) {
    content = (
      <View style={{ height: "80%", justifyContent: "center" }}>
        <LoadingScreen iconSize={50} />
      </View>
    );
  } else if (isSuccess && reviveSuccess) {
    content = (
      <>
        {!!expList && (
          <>
            {/*Grade Item List */}
            <ExperienceGradeListPresentation
              gradeScoreList={gradeCourseTotal!}
              onSetOpenPopup={onSetOpenPopup}
              image={projectImage!}
            />

            <View>
              {reviveList?.ids.length! > 0 && (
                <ExperienceReviveListPresentation
                  cards={reviveList!}
                  onItemClick={onItemClickHanlder}
                  openDetailsHandler={openDetailsHandler}
                  onItemClickHandlerRevive={onItemClickHandlerRevive}
                />
              )}

              <ExperienceListPresentation
                cards={expList!}
                padding={100}
                onItemClick={onItemClickHanlder}
                openDetailsHandler={openDetailsHandler}
                disableLoading={disabloading}
                onDisableLoading={onDisableLoading}
                onDisableLoadingOff={onDisableLoadingOff}
              />
            </View>

            {openDetails && (
              <ExperienceDetailsPresentation
                open={openDetails}
                item={item}
                onClose={closeDetailsHandler!}
                completeHandler={completeHandler}
                completeLoading={completeLoading}
                disableComplete={disableComplete}
                onDisableLoading={onDisableLoading}
                onDisableLoadingOff={onDisableLoadingOff}
                reviveItem={reviveItem}
              />
            )}

            {/*Grade popup */}

            {openGradePop && (
              <ExperienceGradeDetailPresentation
                itemList={gradeCourses!}
                onSetOpenPopup={onSetOpenPopup}
                image={projectImage!}
                onClose={onCloseGrade}
              />
            )}
          </>
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default ExperienceListActiveContainer;
