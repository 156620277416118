import React, { useEffect, useState } from "react";
import { Text } from "react-native";
type CountDownTimerProps = {
  drawDate: Date;
};
const CountDownTimer: React.FC<CountDownTimerProps> = ({ drawDate }) => {
  const [days, setDays] = useState<number | null>(null);
  const [hours, setHours] = useState<number | null>(null);
  const [min, setMin] = useState<number | null>(null);
  const [second, setSecond] = useState<number | null>(null);
  const [showCont, setShowCount] = useState<boolean>(true);

  useEffect(() => {
    let date = drawDate?.getTime() - new Date().getTime();
    const day = date / (1000 * 60 * 60 * 24);
    setDays(Math.floor(day));
    const remainDay = date % (1000 * 60 * 60 * 24);
    const hour = remainDay / (1000 * 60 * 60);
    setHours(Math.floor(hour));
    const remainHours = remainDay % (1000 * 60 * 60);
    const m = remainHours / (1000 * 60);
    setMin(Math.floor(m));
    const reminM = remainHours % (1000 * 60);
    setSecond(Math.floor(reminM / 1000));
  }, [drawDate]);

  return (
    <Text style={{ fontWeight: "700", 
    fontFamily: "HelveticaBold",
    fontSize:16,
    marginRight:9
    }}>
      {days}day, {hours}hrs, {min}min
    </Text>
  );
};

export default CountDownTimer;
