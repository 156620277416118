import React from "react";
import { View, ImageBackground, TouchableOpacity, Image } from "react-native";
import StyleSheet from "react-native-media-query";
import { LinearGradient } from "expo-linear-gradient";
import PostLike from "../../../components/PostLike/PostLike";
import { COLORS, images } from "../../../constants";
import { ExperiencePhoto } from "../../cameraFeatures/types/types";

type BadgesExperienceItemPresentationProps = {
  item: ExperiencePhoto;
  onPhotoClick?: (item: Partial<ExperiencePhoto> | null) => void;
  applyLike?: (item: Partial<ExperiencePhoto>) => void;
  onPhotoCommentClick?: (experiencePhoto: Partial<ExperiencePhoto>) => void;
};
const BadgesExperienceItemPresentation: React.FC<
  BadgesExperienceItemPresentationProps
> = ({ item, onPhotoClick, applyLike, onPhotoCommentClick }) => {
  return (
    <TouchableOpacity
      style={{
        width: "33%",
        marginLeft: 2,
        marginRight: 2,
        marginBottom: 2,
        borderRadius: 4,

        justifyContent: "space-around",
      }}
      onPress={() => {
        onPhotoCommentClick!(item);
      }}
    >
      <View>
        <ImageBackground
          source={{ uri: item?.photo }}
          style={styles.image}
          resizeMode="cover"
        >
          <LinearGradient
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              paddingBottom: 10,
            }}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
            colors={[COLORS.transparent, COLORS.black]}
          >
            {item.photoData?.length! > 0 && (
              <View style={{ flex: 1, padding: 8, paddingLeft: 10 }}>
                <Image
                  source={images.groupImage}
                  style={styles.groupImageIcon}
                />
              </View>
            )}
            <View style={{ bottom: 8, height: 40, position: "absolute" }}>
              <PostLike item={item} applyLike={applyLike!} />
            </View>
          </LinearGradient>
        </ImageBackground>
      </View>
    </TouchableOpacity>
  );
};

const { styles } = StyleSheet.create({
  image: {
    width: "100%",
    justifyContent: "space-between",
    borderRadius: 5,
    overflow: "hidden",
    height: 125,
    backgroundColor: "#5e5e5e",
  },
  groupImageIcon: {
    width: 22,
    height: 22,
  },
});

export default BadgesExperienceItemPresentation;
