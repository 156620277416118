import React, { FC, useEffect, useState } from "react";
import { View, Text, Image, StyleSheet, TouchableOpacity } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import {
  ExperiencePhoto,
  Review,
} from "../../features/cameraFeatures/types/types";
import { set } from "date-fns";
type PostLikeProps = {
  item: Partial<ExperiencePhoto>;
  applyLike: (item: Partial<ExperiencePhoto>) => void;
};
const PostLike: React.FC<PostLikeProps> = ({ item, applyLike }) => {
  const [itemlike, setItemLike] = useState<boolean>();

  useEffect(() => {
    setItemLike(item!.like?.like);
  }, [item!, item!.like?.like]);
  return (
    <>
      <View style={styles.likeContent}>
        <TouchableOpacity
          onPress={() => {
            setItemLike(!itemlike);
            applyLike(item);
          }}
        >
          {itemlike ? (
            <AntDesign name="like1" size={24} color="red" />
          ) : (
            <AntDesign name="like2" size={24} color="white" />
          )}
        </TouchableOpacity>
        <View style={styles.like}>
          <Text style={styles.likeNumber}>
            {item.reviews!.length > 0 ? item.reviews![0].likeCount : 0}
          </Text>
          <Text style={styles.likeStyle}>Like</Text>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  likeNumber: {
    fontSize: 16,
    color: "#fff",
  },
  likeStyle: {
    fontSize: 16,
    color: "#fff",
    marginLeft: 3,
  },

  likeContent: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: 14,
    marginTop: 10,
    flex: 1,
  },

  like: {
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
    marginLeft: 5,
    height: "100%",
    textAlign: "center",
    marginTop: 4,
  },
});
export default PostLike;
