import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { GradeReward } from "../types/GradeReward";

const gradeRewardAdaptor = createEntityAdapter<GradeReward>({
  selectId: (data) => data.id,
});

interface GradeRewardInitialState extends EntityState<GradeReward> {}

const initialState: GradeRewardInitialState =
  gradeRewardAdaptor.getInitialState();

export const gradeRewardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCourseGradeWithXp: builder.query<
      EntityState<GradeReward>,
      {
        currentSystem: string;
      }
    >({
      query: (data) =>
        `/course-enrollment-grade/getCourse?currentSystemString=${data.currentSystem}`,
      transformResponse: (response: GradeReward[]) => {
        return gradeRewardAdaptor.setAll(initialState, response);
      },
      providesTags: ["GradeReward", "Student"],
    }),

    updateCourseStudentId: builder.mutation<
      EntityState<GradeReward>,
      {
        id: EntityId;
        xp: number;
        currentSystem: string;
        completegrade: number;
      }
    >({
      query: (data) => ({
        url: `/course-enrollment-grade/getCourse/${data.id}?xp=${data.xp}&currentSystemString=${data.currentSystem}&completegrade=${data.completegrade}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: GradeReward) => {
        return gradeRewardAdaptor.updateOne(initialState, {
          id: response.id,
          changes: response,
        });
      },
      invalidatesTags: ["GradeReward", "Student"],
    }),
  }),
});

export const {
  useGetAllCourseGradeWithXpQuery,
  useUpdateCourseStudentIdMutation,
} = gradeRewardApiSlice;
