import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import React, { useState } from "react";
import {
  View,
  Text,
  Dimensions,
  TextInput,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { HomeScreenNavigationProps } from "../../../navigation/types";


const { width, height } = Dimensions.get("window");

type YouthPresentationProps = {};

const YouthPresentation: React.FC<YouthPresentationProps> = ({}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const [value, setValue] = useState(null);
  const [isFocus, setIsFocus] = useState(false);

  return (
    <>
      <View style={styles.notehstarContent}>
        <View style={styles.notehstarContentBox}>
          <View style={styles.youthBox}>
            <Text style={styles.youthBoxTextTitle}>This is a first step in taking back your tomorrow</Text>
          </View>

          <View>
            <View style={[styles.youthBox, { paddingBottom: 20 }]}>
              <Text style={styles.youthBoxText}>
              Are you ready to build your future?
              </Text>
            </View>

          </View>
          
        </View>
        
        <View
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",

                position:"absolute",
                bottom:22
              }}
            >
              <TouchableOpacity
                style={styles.letsGoButton}
                onPress={() => {
                  navigation.navigate("Home");
                }}
              >
                <Text style={styles.letsGoButtonText}>Yes!</Text>
              </TouchableOpacity>
            </View>
      </View>
    </>
  );
};

const { styles } = StyleSheet.create({
  youthBoxText: {
    fontFamily: "HelveticaBold",
    textAlign: "center",
    color: "#fff",
    fontSize: 48,
    lineHeight:50,
    maxWidth:"90%",
    marginLeft:"5%"
  },
  youthBoxTextTitle:{
    fontFamily: "RajdahaniMedium",
    textAlign: "center",
    color: "#fff",
    fontSize: 32,
    lineHeight:50,
    maxWidth:"100%",

  },
  youthBox: {
    backgroundColor: "rgba(0,0,0,0.68)",
    maxWidth: "96%",
    marginLeft: "2%",
    marginTop: "8%",
    padding: 12,
    borderRadius: 8,
  },

  letsGoButtonText: {
    textAlign: "center",
    fontSize: 30,
    color: "#fff",
    fontWeight: "900",
  },
  letsGoButton: {
    width: 220,
    height: 46,
    textAlign: "center",
    backgroundColor: "#5CFF88",
    color: "#fff",
    borderRadius: 8,
    marginBottom: 0,
  },

  notehstarContent: {
    width: "100%",
    marginLeft: 0,
    flex:1
  },
  notehstarContentBox: {
    justifyContent: "space-between",
    height: height - 200,
  
  },
  notehstarContentBoxInput: {
    borderColor: "#707070",
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 8,
    fontSize: 18,
    fontFamily: "RajdahaniMedium",
    color: "#3E3F5E",
    margin: 25,
  },
});

export default YouthPresentation;
