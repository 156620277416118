import React from "react";
import {
  TouchableOpacity,
  View,
  Text,
  ImageBackground,
  Image,
} from "react-native";
import StyleSheet from "react-native-media-query";
import { FONTS, images } from "../../../constants";
import { ExperienceCard } from "../types/types";
type ExperienceHotspotPopupItemProps = {
  item: ExperienceCard;
  onClickItem: (item: ExperienceCard) => void;
};
const ExperienceHotspotPopupItem: React.FC<ExperienceHotspotPopupItemProps> = ({
  item,
  onClickItem,
}) => {
  return (
    <TouchableOpacity
      style={styles.expItemContainer}
      onPress={() => {
        onClickItem(item);
      }}
    >
      <ImageBackground
        source={images.hotspotPopupItemBack}
        resizeMode="contain"
        style={styles.hotspotBack}
      >
        <View style={styles.content}>
          <View style={styles.box}>
            <Text numberOfLines={1}  style={styles.title}> {item.title} </Text>
            <Text numberOfLines={1} style={styles.subTitle}>
              {item.subTitle}
            </Text>
            <Text style={styles.Date}>
              {new Date(item.endDate).toLocaleDateString("en-US")}
            </Text>
          </View>

          <Image source={images.hotspotRev} style={styles.hotspotRev} />
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

const { styles } = StyleSheet.create({
  hotspotBack: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    resizeMode: "cover",
    height: 70,
  },
  hotspotRev: {
    alignSelf: "center",
    width: 85,
    height: 30,
  },
  box: {
    maxWidth: "65%",
    justifyContent: "center",
  },
  expItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
  },

  content: {
    width: "84%",
    height: 58,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    paddingRight: 20,
  },
  xp: {
    width: 85,
    height: 30,
    backgroundColor: "green",
  },

  title: {
    fontFamily: "Roboto-Bold",
    fontSize: 13,
    color: "#000",
    fontWeight: "bold",
  },
  subTitle: {
    ...FONTS.body3,
    fontSize: 10,
    lineHeight: 15,
  },
  Date: {
    ...FONTS.body5,
    fontSize: 10,
    lineHeight: 10,
    paddingTop: 3,
  },
  image: {
    position: "absolute",
    left: 33,
    top: 0,
    flex: 1,
    width: 3,
    height: 98,
  },
  completedIcon: {
    flex: 1,
    minWidth: 50,
    paddingRight: 20,
    justifyContent: "center",
    "@media (max-width: 350px)": {
      minWidth: 30,
      flex: 0.5,
    },
  },
  completeImage: {
    alignSelf: "center",
    width: 15,
    height: 15,
  },
});
export default ExperienceHotspotPopupItem;
