import React, { useEffect, useState } from "react";
import TeamTabItemPresentation from "../presentation/TeamTabItemPresentation";
import {
  useGetTeamCategoryScoreQuery,
  useGetTeamScoreQuery,
} from "../api/teamScoreApi";
import { useAppSelector } from "../../../redux/hooks";
import { selectDefaultAccount } from "../../auth/slice/authSlice";
import { AuthUser } from "../../auth/types/types";
type TeamTabItemContainerProps = {
  user: AuthUser;
  month: string;
  showCat: boolean;
};
const TeamTabItemContainer: React.FC<TeamTabItemContainerProps> = ({
  user,
  month,
  showCat,
}) => {
  const account = useAppSelector(selectDefaultAccount);

  const [projectTitle, setProjectTitle] = useState<string | null>(null);
  const [categoryTitle, setCategoryTitle] = useState<string | null>(null);

  const {
    data: teamScoreData,
    isSuccess,
    isError,
    isLoading,
  } = useGetTeamScoreQuery(
    {
      currentSystem: JSON.stringify(account!),
      month: month,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  //useGetTeamCategoryScoreQuery
  const {
    data: teamScoreDataCategory,
    isSuccess: isSuccessCategory,
    isError: isErrorCategory,
    isLoading: isLoadingCategory,
  } = useGetTeamCategoryScoreQuery(
    {
      currentSystem: JSON.stringify(account!),
      month: month,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (user && account) {
      const findProject = user.systemProject.find(
        (item) => item._id === account.project
      );
      if (account.categories?.length === 0 && account.category) {
        const findCategory = user.systemCategories.find(
          (item) => item._id === account.category
        );
        setCategoryTitle(findCategory?.title!);
      }
      if (findProject) {
        setProjectTitle(findProject?.title!);
      }
    }
  }, [user, account]);
  return (
    <>
      {isSuccess && (
        <>
          {showCat ? (
            <>
              <TeamTabItemPresentation
                projectTitle={projectTitle!}
                categoryTitle={categoryTitle}
                account={account!}
                data={teamScoreDataCategory?.entities[1]!}
                showCat={showCat}
              />
            </>
          ) : (
            <>
              <TeamTabItemPresentation
                projectTitle={projectTitle!}
                categoryTitle={categoryTitle}
                account={account!}
                data={teamScoreData?.entities[1]!}
                showCat={showCat}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default TeamTabItemContainer;
