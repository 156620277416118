import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { View, Text, ScrollView, SafeAreaView } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { LeaderBoard } from "../../leaderborad/types/type";
import StyleSheet from "react-native-media-query";
import HomeHeaderPresentation from "./HomeHeaderPresentation";
import { AuthUser, StudentSystemType } from "../../auth/types/types";
import HomeXpChartPresentation from "./HomeXpChartPresentation";
import LeaderBoardListPresentation from "./LeaderBoardItemPresentation";
import AccountTab from "../../../components/AccountTab/AccountTab";
import LeaderItemPresentation from "./LeaderItemPresentation";
import { Image } from "expo-image";
import { images } from "../../../constants";
type HomePresentationProps = {
  leaderBoardList: EntityState<LeaderBoard>;
  student: AuthUser;
  currentSystem: Partial<StudentSystemType>;
  studentXp: number;
  studentLevel: number;
  onSelecteTab: (systemSelect: Partial<StudentSystemType>) => void;
  isLoading: boolean;
  isSuccess: boolean;
  setThisMonth: React.Dispatch<React.SetStateAction<string>>;
};
const HomePresentation: React.FC<HomePresentationProps> = ({
  leaderBoardList,
  student,
  currentSystem,
  studentLevel,
  studentXp,
  onSelecteTab,
  isLoading,
  isSuccess,
  setThisMonth,
}) => {
  return (
    <View style={styles.profileContainer}>
      <FlatList
        data={Object.values(leaderBoardList!.entities!) as LeaderBoard[]}
        keyExtractor={(item) => `${item._id}`}
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <>
            <HomeHeaderPresentation
              student={student}
              currentSystem={currentSystem}
            />
            <HomeXpChartPresentation
              studentLevel={studentLevel}
              studentXp={studentXp}
            />

            <LeaderBoardListPresentation setThisMonth={setThisMonth}>
              <AccountTab
                currentSystem={currentSystem}
                onSelecteTab={onSelecteTab}
              >
                <View
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 15,
                    width: "100%",
                    flexGrow: 1,
                    height: "100%",
                    overflow: "scroll",
                  }}
                >
                  {isLoading && (
                    <View
                      style={{
                        backgroundColor: "white",
                        borderRadius: 15,
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        source={images.Spinner}
                        style={{
                          width: 50,
                          height: 50,
                        }}
                      />
                    </View>
                  )}
                  {isSuccess && (
                    <FlatList
                      data={
                        Object.values(
                          leaderBoardList!.entities!
                        ) as LeaderBoard[]
                      }
                      keyExtractor={(item) => `${item._id}`}
                      keyboardDismissMode="on-drag"
                      showsVerticalScrollIndicator={false}
                      contentContainerStyle={{}}
                      renderItem={({ item, index }) => (
                        <LeaderItemPresentation rank={index + 1} item={item} />
                      )}
                    />
                  )}
                </View>
              </AccountTab>
            </LeaderBoardListPresentation>
          </>
        }
        contentContainerStyle={{
          flexGrow: 1,
        }}
        renderItem={({ item }) => (
          <></>
          // <View style={{ flex: 1 }}>
          //   <Text>{item.fullName}</Text>
          // </View>
        )}
        ListFooterComponent={
          <View
            style={{
              marginBottom: 100,
            }}
          ></View>
        }
      />
    </View>
  );
};
const { styles } = StyleSheet.create({
  profileContainer: {
    flex: 1,
    backgroundColor: "#fff",
    "@media (max-width: 350px)": {
      zIndex: 2,
    },
    height: 550,
    // marginTop: isAndroid ? 25 : 0,
  },
});
export default HomePresentation;
