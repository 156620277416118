import React, { useEffect, useState } from "react";
import BagPresentation from "../prensetation/BagPresentation";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../../navigation/types";
import AccountTab from "../../../components/AccountTab/AccountTab";
import BagTabPresentation from "../prensetation/BagTabPresentation";
import { useAppSelector } from "../../../redux/hooks";
import { selectAdmin, selectDefaultAccount } from "../../auth/slice/authSlice";
import { useGetCountFreeTicketQuery } from "../../freeTicket/api/freeTicketApi";

const BagContainer = () => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const accountSelected = useAppSelector(selectDefaultAccount);
  const user = useAppSelector(selectAdmin);
  const [gems, setGems] = useState<number>(0);
  const {
    data: freeTicket,
    isLoading,
    isError,
    isSuccess,
  } = useGetCountFreeTicketQuery({
    currentSystem: JSON.stringify(accountSelected!),
  });
  useEffect(() => {
    if (user) {
      const projectReport = user.projectReport.find(
        (item) => item.project === accountSelected!.project
      );

      if (projectReport) {
        setGems(projectReport.gems);
      }
    }
  }, [accountSelected, user]);

  const onShop = () => {
    navigation.navigate("Prizes");
  };
  return (
    <>
      <BagPresentation
        gem={gems}
        freeTicket={freeTicket?.freeTicketNumber!}
        onShop={onShop}
      />
    </>
  );
};

export default BagContainer;
