import React, { useState, useEffect, useRef } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import {
  View,
  Text,
  Animated,
  TouchableOpacity,
  Image,
  Dimensions,
  Modal,
  AppState,
  Platform,
} from "react-native";
import CircularSlider from "../../components/CircularSlider";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { images } from "../../constants";
import { TextStroke } from "../../components/TextStroke/TextStroke";
import StyleSheet from "react-native-media-query";
import LottieView from "lottie-react-native";
import { useAppSelector } from "../../redux/hooks";
import { selectAdmin } from "../auth/slice/authSlice";
import * as BackgroundFetch from "expo-background-fetch";
import * as TaskManager from "expo-task-manager";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { useCreateNewMutation } from "../timerfocuse/api/timerfocuseApi";
let timerId: any;
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});
const BACKGROUND_FETCH_TASK = "background-fetch";
TaskManager.defineTask(BACKGROUND_FETCH_TASK, async ({ data, error }) => {
  // console.log("run in main background");
  if (error) {
    // console.log(error);
    return BackgroundFetch.BackgroundFetchResult.Failed;
  }
  if (data) {
    // console.log("run in background");
    await Notifications.scheduleNotificationAsync({
      content: {
        title: "Timer Eneded",
        body: "Timer has ended",
        data: { data: "goes here" },
      },
      trigger: { seconds: 2 },
    });
    return BackgroundFetch.BackgroundFetchResult.NewData;
  }

  // Be sure to return the successful result type!
});

async function registerBackgroundFetchAsync() {
  await BackgroundFetch.setMinimumIntervalAsync(0);
  return BackgroundFetch.registerTaskAsync(BACKGROUND_FETCH_TASK, {
    minimumInterval: 0, // 15 minutes
    stopOnTerminate: true, // android only,
    startOnBoot: false, // android only
  });
}

async function unregisterBackgroundFetchAsync() {
  setTimeout(() => {
    clearTimeout(timerId);
  }, 1000);
  // return BackgroundFetch.unregisterTaskAsync(BACKGROUND_FETCH_TASK);
}

const startValue = new Animated.Value(0.4),
  startWorld = new Animated.Value(0.4),
  startApple = new Animated.Value(0.4),
  startMath = new Animated.Value(0.4),
  startShape = new Animated.Value(0.4),
  startRadical = new Animated.Value(0.4),
  startMoon = new Animated.Value(0.4),
  startYoutube = new Animated.Value(0.4),
  startMask = new Animated.Value(0.4),
  startBook = new Animated.Value(0.4),
  startPen = new Animated.Value(0.4),
  startGroup = new Animated.Value(0.4);

const { height } = Dimensions.get("window");
const transitionValueLight = new Animated.Value(0);
const transitionValueCloude = new Animated.Value(0);

const duration = 30000;
let maxScaleVal = 0.9;

if (height < 720) {
  maxScaleVal = 0.6;
}

type TimerSoloTabProps = {
  isMakeGem: boolean;
  onDoMakeGem: () => void;
  isSliderMakeGemOpen: boolean;
  onSetIsSliderMakeGemOpen: (status: boolean) => void;
  min: number;
  onMin: (minutes: number) => void;
  onDoMakeOtherPoints: () => void;
  onOpenEndTimer: (status: boolean) => void;
  focuseCrystal: number;
  fuses: number;
};
let count = 0;

const TimerSoloTab: React.FC<TimerSoloTabProps> = ({
  isMakeGem,
  onDoMakeGem,
  isSliderMakeGemOpen,
  onSetIsSliderMakeGemOpen,
  min,
  onMin,
  onDoMakeOtherPoints,
  onOpenEndTimer,
  focuseCrystal,
  fuses,
}) => {
  const [createNew, { isLoading, isSuccess, isError }] = useCreateNewMutation();
  const user = useAppSelector(selectAdmin);
  const backAnimations = React.createRef<LottieView>();
  const lockProgress = useRef(new Animated.Value(0)).current;

  const [appState, setAppState] = useState(AppState.currentState);
  const [value, setValue] = useState(50);
  const [timerDone, setTimerDone] = useState(true);
  const [timerStart, setTimerStart] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [openClaimPopup, setOpenClaimPopup] = useState(false);
  const [fadeAnim] = useState(new Animated.Value(0));
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [isRegistered, setIsRegistered] = React.useState(true);
  const [status, setStatus] = React.useState<any>(null);
  const [expoPushToken, setExpoPushToken] = useState<string | undefined>("");
  const [notification, setNotification] =
    useState<Notifications.Notification | null>();
  const notificationListener = useRef<Notifications.Subscription>();
  const responseListener = useRef<Notifications.Subscription>();

  const lockAnimation = React.createRef();

  React.useEffect(() => {
    registerForPushNotificationsAsync().then((token) =>
      setExpoPushToken(token)
    );

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification);
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        // console.log(response);
      });

    // return () => {
    //   Notifications.removeNotificationSubscription(notificationListener.current);
    //   Notifications.removeNotificationSubscription(responseListener.current);
    // };
  }, []);

  useEffect(() => {
    const subscription = AppState.addEventListener(
      "change",
      async (nextAppState) => {
        if (nextAppState === "active") {
          await unregisterBackgroundFetchAsync();
        }
        if (nextAppState === "background") {
          let ti = 0;
          timerId = setTimeout(() => {
            ti = 2000;
            // console.log("timer set");
            if (appState === "active" && ti === 2000) {
              //task run
              setOpenPopup(true);
              setPopupMessage("You loose timer");
            }
          }, 20000);
        }
      }
    );

    return () => {
      // console.log("romeve");
      subscription.remove();
    };
  }, [appState]);

  useEffect(() => {
    const convertSecond = minutes * 60 + seconds;
    // console.log(convertSecond);
    if (timerStart) {
      let myInterval = setInterval(() => {
        if (currentValue > 0) {
          setCurrentValue(currentValue - 1);
        }

        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (!timerDone) {
              clearInterval(myInterval);
              setTimerDone(true);
              setTimerStart(false);
              setMinutes(0);
              setSeconds(0);
              setCurrentValue(0);
              setValue(50);
              setCurrentItem(0);
              setOpenClaimPopup(true);
            } else {
              setMinutes(0);
              setSeconds(0);
            }
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);

      if (convertSecond === 0) {
        count++;
        // console.log("count", count);
        if (count === 2) {
          count = 0;
          onOpenEndTimer(true);
        }
      }
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [seconds, minutes]);

  useEffect(() => {
    if (timerStart) {
      // @ts-ignore
      if (lockAnimation && lockAnimation?.current) lockAnimation.current.play();
    } else {
      // @ts-ignore
      if (lockAnimation && lockAnimation?.current)
        // @ts-ignore
        lockAnimation.current.pause();
    }
  }, [timerStart]);

  // const checkStatusAsync = async (nextAppState?: AppStateStatus) => {
  //   if (nextAppState === "background") {
  //     try {
  //       // console.log(nextAppState);

  //       await registerBackgroundFetchAsync();
  //     } catch (error) {
  //       // console.log(error);
  //     }
  //   }
  //   if (nextAppState === "active") {
  //     // console.log(nextAppState);

  //     await unregisterBackgroundFetchAsync();
  //   }
  // };

  const timerStartEvent = async (value: any) => {
    // await registerBackgroundFetchAsync()
    if (isMakeGem) {
      if (focuseCrystal > 6000 && fuses > 0) {
        const today = new Date().toLocaleDateString("US-en");
        // console.log(today);
        // const userId = user?._id;
        // const timePerDay = 1;
        //[{date:---,,userId:---,timePerDays:1}]
        const daysSystem = await AsyncStorage.getItem("@daySystem");
        const daySystem = daysSystem ? JSON.parse(daysSystem) : null;
        // console.log(daySystem);
        //when it should item to array storage
        let obj = {
          date: new Date().toLocaleDateString("US-en"),
          day: 1,
          userId: user?._id,
          timePerDay: 1,
        };
        if (!daySystem) {
          obj = {
            date: new Date().toLocaleDateString("US-en"),
            day: 1,
            userId: user?._id,
            timePerDay: 1,
          };
        } else if (daySystem.date === today) {
          obj = {
            date: new Date().toLocaleDateString("US-en"),
            day: daySystem.day,
            userId: user?._id,
            timePerDay: daySystem.timePerDay + 1,
          };
        } else if (daySystem.date !== today) {
          obj = {
            date: new Date().toLocaleDateString("US-en"),
            day: daySystem.day + 1,
            userId: user?._id,
            timePerDay: 1,
          };
        }

        if (obj.timePerDay < 7) {
          if (isMakeGem) {
            // console.log("run this one");
            if (0 < value && value <= 24) {
              value = 100;
            }
            if (24 < value && value <= 50) {
              value = 50;
            }
          }

          setTimerStart(true);
          setTimerDone(false);
          value = Math.round(value / 1.66) * 60;
          var d = Number(value);
          var m = Math.floor((d % 3601) / 60);
          var s = Math.floor((d % 3600) % 60);
          var mDisplay = m > 0 ? m : 0;
          var sDisplay = s > 0 ? s : 0;
          onMin(mDisplay);
          setMinutes(mDisplay);
          setSeconds(sDisplay);
          setCurrentValue(value);
          setValue(value);
          setPopupMessage("");
        } else {
          setOpenPopup(true);
          setPopupMessage(
            "You're out of electricity today, recharge and come back tomorrow"
          );
        }
      } else {
        // try {
        //   await createNew({
        //     duration: min,
        //     gemMade: 0,
        //     fusesEarned: 0,
        //     fusesUsed: 1,
        //     setTime: min,
        //     shardesEarned: 0,
        //     shardesUsed: 6000,
        //     timerSuccessFully: false,
        //     student: user!,
        //     startTimer: new Date(),
        //     endTimer: new Date(),
        //   });
        // } catch (error) {
        //   console.log(error);
        // }
        alert("You don't have enough Crystals/ Fuses");
      }
    } else {
      const today = new Date().toLocaleDateString("US-en");
      // console.log(today);
      // const userId = user?._id;
      // const timePerDay = 1;
      //[{date:---,,userId:---,timePerDays:1}]
      const daysSystem = await AsyncStorage.getItem("@daySystem");
      const daySystem = daysSystem ? JSON.parse(daysSystem) : null;
      // console.log(daySystem);
      //when it should item to array storage
      let obj = {
        date: new Date().toLocaleDateString("US-en"),
        day: 1,
        userId: user?._id,
        timePerDay: 1,
      };
      if (!daySystem) {
        obj = {
          date: new Date().toLocaleDateString("US-en"),
          day: 1,
          userId: user?._id,
          timePerDay: 1,
        };
      } else if (daySystem.date === today) {
        obj = {
          date: new Date().toLocaleDateString("US-en"),
          day: daySystem.day,
          userId: user?._id,
          timePerDay: daySystem.timePerDay + 1,
        };
      } else if (daySystem.date !== today) {
        obj = {
          date: new Date().toLocaleDateString("US-en"),
          day: daySystem.day + 1,
          userId: user?._id,
          timePerDay: 1,
        };
      }

      if (obj.timePerDay < 7) {
        if (isMakeGem) {
          // console.log("run this one");
          if (0 < value && value <= 24) {
            value = 100;
          }
          if (24 < value && value <= 50) {
            value = 50;
          }
        }

        setTimerStart(true);
        setTimerDone(false);
        value = Math.round(value / 1.66) * 60;
        var d = Number(value);
        var m = Math.floor((d % 3601) / 60);
        var s = Math.floor((d % 3600) % 60);
        var mDisplay = m > 0 ? m : 0;
        var sDisplay = s > 0 ? s : 0;
        onMin(mDisplay);
        setMinutes(mDisplay);
        setSeconds(sDisplay);
        setCurrentValue(value);
        setValue(value);
        setPopupMessage("");
      } else {
        setOpenPopup(true);
        setPopupMessage(
          "You're out of electricity today, recharge and come back tomorrow"
        );
      }
    }
  };
  const timerEndEvent = () => {
    setTimerStart(false);
    setTimerDone(true);
    setMinutes(0);
    setSeconds(0);
    setCurrentValue(0);
    setValue(50);
    setCurrentItem(0);
  };

  return (
    <>
      <View style={styles.backTimerImage}>
        <Image
          source={images.backtimer}
          resizeMode="cover"
          style={styles.personImage}
        />

        <View style={styles.timerContainer}>
          {timerStart ? null : (
            <>
              <CircularSlider
                trackWidth={height < 720 ? 22 : 22}
                trackRadius={height < 720 ? 135 : 135}
                trackTintColor={"#D0C9C9"}
                trackColor={"#4ED486"}
                thumbColor={"#4ED486"}
                value={value}
                onChange={setValue}
                timerHalfStart={isMakeGem}
              />
            </>
          )}
        </View>
 
        {(Platform.OS === "web") ?   <></> :  
                   <LottieView
                   style={styles.lottieStyle}
                   //@ts-ignore
                   ref={lockAnimation}
                   loop={true}
                   source={images.TimerAnimation}
                   progress={lockProgress}
                 />}
  
      </View>

      {isSliderMakeGemOpen ? (
        <>
          <View style={(Platform.OS === "web") ? [styles.switerItem, styles.switerItemWeb] : styles.switerItem}>
            <Image style={styles.item} source={images.item} />
            <Text
              style={
                isMakeGem && focuseCrystal >= 6000 && fuses > 0
                  ? [styles.itemText, styles.itemTextEnable]
                  : [styles.itemText, styles.itemTextDisable]
              }
            >
              6000
            </Text>
          </View>

          <View style={(Platform.OS === "web") ? [styles.switerfuze, styles.switerfuzeContrainer] : styles.switerfuze}>
            <Image style={styles.fuze} source={images.fuze} />
            <Text
              style={
                isMakeGem
                  ? [styles.itemText, styles.itemTextEnable]
                  : [styles.itemText, styles.itemTextDisable]
              }
            >
              1
            </Text>
          </View>
        </>
      ) : isMakeGem ? (
        <>
          <View   style={(Platform.OS === "web") ? [styles.switerItem, styles.switerItemWeb] : styles.switerItem}>
            <Image style={styles.item} source={images.item} />
            <Text
              style={
                isMakeGem && focuseCrystal >= 6000 && fuses > 0
                  ? [styles.itemText, styles.itemTextEnable]
                  : [styles.itemText, styles.itemTextDisable]
              }
            >
              6000
            </Text>
          </View>

          <View style={(Platform.OS === "web") ? [styles.switerfuze, styles.switerfuzeContrainer] : styles.switerfuze}>
            <Image style={styles.fuze} source={images.fuze} />
            <Text
              style={
                isMakeGem
                  ? [styles.itemText, styles.itemTextEnable]
                  : [styles.itemText, styles.itemTextDisable]
              }
            >
              1
            </Text>
          </View>
        </>
      ) : null}
      <View style={{ marginTop: 25 }}>
        <TextStroke stroke={2} color={"#FFF"}>
          {minutes === 0 && seconds === 0 && !timerStart ? (
            <Text style={styles.timerNumber}>
              {isMakeGem
                ? 0 < value && value <= 24
                  ? 60
                  : 24 < value && value <= 50
                  ? 30
                  : Math.round(value / 1.66)
                : Math.round(value / 1.66)}
              :00
            </Text>
          ) : (
            <Text style={styles.timerNumber}>
              {" "}
              {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </Text>
          )}
        </TextStroke>
      </View>

      {timerDone ? (
        <TouchableOpacity
          style={[
            height < 720
              ? [styles.resButtonTimer, styles.startButtonTimer]
              : styles.startButtonTimer,
          ]}
          onPress={() => {
            timerStartEvent(value);
          }}
        >
          <Text style={styles.startButtonTimerText}>Start</Text>
        </TouchableOpacity>
      ) : null}
      {timerStart ? (
        <TouchableOpacity
          style={[
            height < 720
              ? [styles.endButtonTimer, styles.resButtonTimer]
              : styles.endButtonTimer,
          ]}
          onPress={async () => {
            setOpenPopup(true);
            try {
              await createNew({
                duration: min,
                gemMade: 0,
                fusesEarned: 0,
                fusesUsed: 1,
                setTime: min,
                shardesEarned: 0,
                shardesUsed: 6000,
                timerSuccessFully: false,
                student: user!,
                startTimer: new Date(),
                endTimer: new Date(),
              });
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Text
            style={[
              height < 720
                ? [styles.endButtonTimerText, styles.resButtonTimerText]
                : styles.endButtonTimerText,
            ]}
          >
            End
          </Text>
        </TouchableOpacity>
      ) : null}

      <Modal visible={openPopup} animationType="fade">
        <View style={styles.TimerStopPopupContainer}>
          <View style={styles.TimerStopPopupContent}>
            <Text
              style={{
                fontSize: 15,
                lineHeight: 22,
                fontWeight: "500",
                maxWidth: "80%",
                textAlign: "center",
                marginBottom: 15,
              }}
            >
              {popupMessage}
            </Text>
            <View
              style={[
                styles.TimerStopPopup,
                popupMessage !== "" && styles.TimerStopPopupBack,
              ]}
            >
              {popupMessage === "" && (
                <TouchableOpacity
                  onPress={() => setOpenPopup(false)}
                  style={styles.TimerStopPopupContinue}
                >
                  <Text style={styles.TimerStopPopupContinueText}>
                    Continue{" "}
                  </Text>
                  <Text style={styles.TimerStopPopupTimerText}>Timer !</Text>
                </TouchableOpacity>
              )}

              <TouchableOpacity
                onPress={async () => {
                  timerEndEvent(), setOpenPopup(false);
                  //this is here to code
                  try {
                    await createNew({
                      duration: min,
                      gemMade: 0,
                      fusesEarned: 0,
                      fusesUsed: 1,
                      setTime: min,
                      shardesEarned: 0,
                      shardesUsed: 6000,
                      timerSuccessFully: false,
                      student: user!,
                      startTimer: new Date(),
                      endTimer: new Date(),
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
                style={[
                  styles.TimerStopPopupEnd,
                  popupMessage !== "" && styles.TimerStopPopupEndStyle,
                ]}
              >
                <Text style={styles.TimerStopPopupEndText}>End</Text>
                <Text style={styles.TimerStopPopupTimerText}>Timer !</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal visible={false} animationType="slide">
        <View style={styles.TimerDonePopupContainer}>
          <View style={styles.TimerDonePopupContent}>
            <View style={styles.TimerDonePopup}>
              <View
                style={[
                  styles.TimerDoneRapperPopup,
                  styles.TimerDoneTitleRapperPopup,
                ]}
              >
                <Image
                  style={styles.TimerDoneImagePopup}
                  resizeMode="contain"
                  source={images.pointImage}
                />
                <Text style={styles.TimerDoneTitlePopup}>Points Earned </Text>
              </View>
              <View style={styles.TimerDoneRapperPopup}>
                <Text style={styles.TimerDoneValuePopup}>Location:</Text>
                <Text style={styles.TimerDoneKeyPopup}>library</Text>
              </View>
              <View style={styles.TimerDoneRapperPopup}>
                <Text style={styles.TimerDoneValuePopup}>Bonus</Text>
                <Text style={styles.TimerDoneKeyPopup}>x 1.75</Text>
              </View>
              <View style={styles.TimerDoneRapperPopup}>
                <Text style={styles.TimerDoneValuePopup}>Time:</Text>
                <Text style={styles.TimerDoneKeyPopup}>30min</Text>
              </View>
              <View
                style={[
                  styles.TimerDoneRapperPopup,
                  styles.TimerDoneTotalPopup,
                ]}
              >
                <Text style={styles.TimerDoneValuePopup}>Total Points:</Text>
                <Text
                  style={[
                    styles.TimerDoneKeyPopup,
                    styles.TimerDoneTotalKeyPopup,
                  ]}
                >
                  423XP
                </Text>
              </View>
              <View style={styles.TimerDoneRapperPopup}>
                <View style={styles.TimerDoneRappersPopup}>
                  <Image
                    style={styles.TimerDoneImagePopup}
                    resizeMode="contain"
                    source={images.coinImage}
                  />
                  <Text style={styles.TimerDoneValuePopup}>College Coin</Text>
                </View>
                <Text style={styles.TimerDoneKeyPopup}>7</Text>
              </View>
              <View style={styles.TimerDoneRapperPopup}>
                <View style={styles.TimerDoneRappersPopup}>
                  <Image
                    style={styles.TimerDoneImagePopup}
                    resizeMode="contain"
                    source={images.focusPowerImage}
                  />
                  <Text style={styles.TimerDoneValuePopup}>Focus Power</Text>
                </View>
                <Text style={styles.TimerDoneKeyPopup}>3</Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  setMinutes(0);
                  setSeconds(0);
                  setOpenClaimPopup(false);
                }}
                style={styles.TimerDonePopupEnd}
              >
                <Text style={styles.TimerDonePopupEndText}>Claim Points</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};
async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return;
    }
    // Learn more about projectId:
    // https://docs.expo.dev/push-notifications/push-notifications-setup/#configure-projectid
    token = (
      await Notifications.getExpoPushTokenAsync({
        projectId: "your-project-id",
      })
    ).data;
    // console.log(token);
  } else {
    alert("Must use physical device for Push Notifications");
  }

  return token;
}
const { styles } = StyleSheet.create({
  item: {
    width: 20,
    height: 30,
    resizeMode: "contain",
    "@media (max-height: 720px)": {
      width: 17,
      height: 17,
    },
  },
  fuze: {
    width: 36,
    height: 20,
    resizeMode: "contain",
    "@media (max-height: 720px)": {
      width: 17,
      height: 17,
    },
  },
  itemText: {
    fontFamily: "HelveticaBold",
    fontSize: 20,
    color: "#fff",
    paddingLeft: 4,
    "@media (max-height: 720px)": {
      fontSize: 16,
    },
  },
  itemTextDisable: {
    color: "#5A5A5A",
  },
  itemTextEnable: {
    color: "#fff",
  },
  switerItem: {
    minWidth: 100,
    height: 39,
    borderColor: "#46E6FF",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    right: 65,
    top: 20,
    backgroundColor: "#000",
    "@media (max-height: 720px)": {
      height: 23,
      minWidth: 70,
      right: 85,
      top: 15,
    },
  },
  switerItemWeb:{
    right: 110,
  },
  switerfuze: {
    minWidth: 95,
    height: 39,
    marginLeft: 10,
    borderColor: "#fff",
    borderWidth: 2,
    borderStyle: "solid",
    flexDirection: "row",
    borderRadius: 50,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    right: 35,
    top: 75,
    backgroundColor: "#000",
    "@media (max-height: 720px)": {
      height: 23,
      minWidth: 60,
      right: 70,
      top: 48,
    },
  },
  switerfuzeContrainer:{
    right:75
  },
  lottieStyle: {
    width: 52,
    height: 52,
    bottom: 4,

    left: 2,
    "@media (max-height: 720px)": {
      width: 30,
      height: 30,
      bottom: 4,

      left: 2,
    },
  },
  TimerDoneTotalKeyPopup: {
    fontSize: 42,
  },
  TimerDoneImagePopup: {
    marginRight: 10,
  },
  TimerDoneTotalPopup: {
    marginBottom: "10%",
    marginTop: "10%",
  },
  TimerDonePopupEnd: {
    width: 254,
    height: 52,
    borderRadius: 8,
    backgroundColor: "#5CFF88",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowColor: "#5CFF88",
    shadowOpacity: 0.5,
    shadowRadius: 3.84,
    elevation: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginBottom: 42,
    marginTop: "10%",
  },
  TimerDonePopupEndText: {
    fontFamily: "Roboto-Bold",
    fontSize: 25,
    color: "#fff",
  },
  TimerDoneRappersPopup: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  TimerDoneValuePopup: {
    color: "#fff",
    fontSize: 32,
  },
  TimerDoneKeyPopup: {
    color: "#fff",
    fontSize: 30,
  },
  TimerDoneTitlePopup: {
    color: "#fff",
    fontSize: 44,
  },

  TimerDonePopup: {
    width: "95%",
    justifyContent: "space-between",
    flex: 1,
  },
  TimerDoneRapperPopup: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  TimerDoneTitleRapperPopup: {
    justifyContent: "center",
    marginTop: 35,
  },
  TimerDonePopupContainer: {
    flex: 1,
    backgroundColor: "rgba(225,225,225,0.9)",
    justifyContent: "center",
    alignItems: "center",
  },
  TimerDonePopupContent: {
    width: "90%",
    height: "95%",
    maxHeight: 690,
    backgroundColor: "#34C38F",
    borderColor: "#fff",
    borderWidth: 3,
    borderRadius: 12,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  TimerStopPopupContinue: {
    width: "70%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  TimerStopPopupEndText: {
    fontSize: 16,

    color: "#fff",
    backgroundColor: "#F4284F",
  },
  TimerStopPopupTimerText: {
    fontSize: 11,

    color: "#fff",
  },
  TimerStopPopupContinueText: {
    fontSize: 16,

    color: "#fff",
  },

  TimerStopPopupEnd: {
    width: "30%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F4284F",
    borderRadius: 8,
    borderTopLeftRadius: 14,
    borderBottomLeftRadius: 14,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3.84,
    shadowColor: "#F4274F",
    elevation: 5,
  },
  TimerStopPopupEndStyle: {
    width: "40%",
    borderRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  TimerStopPopupBack: {
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },
  TimerStopPopup: {
    backgroundColor: "#4ED386",
    height: 45,
    width: "80%",
    borderRadius: 8,
    flexDirection: "row",
  },
  TimerStopPopupContainer: {
    flex: 1,
    backgroundColor: "rgba(225,225,225,0.9)",
    justifyContent: "center",
    alignItems: "center",
  },
  TimerStopPopupContent: {
    width: "90%",
    height: 138,
    backgroundColor: "#fff",
    borderRadius: 12,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  personImage: {
    width: 280,
    height: 280,
    resizeMode: "stretch",
    position: "absolute",
    top: 20,
    "@media (max-height: 720px)": {
      width: 160,
      height: 160,
      top: 25,
    },
  },
  timerworldImage: {
    position: "absolute",
    bottom: 230,
    left: 30,
  },
  resTimerworldImage: {
    bottom: 150,
    left: 23,
  },

  resTimerContainer: {
    top: -10,
  },
  scaleStyle: {
    top: 30,
  },
  timerlightImage: {
    position: "absolute",
    bottom: 200,
    left: 90,
  },
  resTimerlightImage: {
    bottom: 125,
    left: 75,
  },
  timerappleImage: {
    position: "absolute",
    bottom: 210,
    left: 95,
  },
  resTimerappleImage: {
    bottom: 130,
    left: 75,
  },
  timermathImage: {
    position: "absolute",
    bottom: 185,
    left: 75,
  },
  resTimermathImage: {
    bottom: 115,
    left: 60,
  },
  timershapeImage: {
    position: "absolute",
    bottom: 248,
    left: 100,
  },
  resTimershapeImage: {
    bottom: 150,
    left: 70,
  },
  timermoonImage: {
    position: "absolute",
    bottom: 260,
    left: 80,
  },
  resTimermoonImage: {
    bottom: 155,
    left: 60,
  },
  cloudImage: {
    position: "absolute",
    bottom: 110,
    left: 40,
    zIndex: 0,
  },
  resCloudImage: {
    bottom: 40,
    left: 20,
  },

  timerradicalImage: {
    position: "absolute",
    bottom: 275,
    left: 57,
  },
  resTimerradicalImage: {
    bottom: 165,
    left: 47,
  },
  timeryoutubeImage: {
    position: "absolute",
    bottom: 300,
    left: 78,
  },
  resTimeryoutubeImage: {
    bottom: 170,
    left: 58,
  },
  timermaskImage: {
    position: "absolute",
    bottom: 200,
    left: 110,
  },
  resTimermaskImage: {
    bottom: 110,
    left: 80,
  },
  timerbookImage: {
    position: "absolute",
    bottom: 230,
    left: 125,
  },
  resTimerbookImage: {
    bottom: 130,
    left: 90,
  },
  timerpenImage: {
    position: "absolute",
    bottom: 225,
    left: 65,
  },
  resTimerpenImage: {
    bottom: 135,
    left: 60,
  },
  timergroupImage: {
    position: "absolute",
    bottom: 150,
    left: 73,
  },
  resTimergroupImage: {
    bottom: 80,
    left: 53,
  },
  timerContainer: {
    position: "absolute",
    zIndex: 2,
    top: 0,
  },
  image: {
    width: "100%",
    height: 147,
    position: "relative",
    overflow: "hidden",
    borderRadius: 8,
    borderWidth: 3,
    borderColor: "#fff",
  },
  backProfilePic: {
    width: "100%",
    height: 144,
    borderRadius: 12,
    overflow: "hidden",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  backProfileImage: {
    width: "100%",
    height: 145,
  },
  backTimerImage: {
    width: 320,
    height: 270,
    alignContent: "center",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "25%",
    position: "relative",
    marginBottom: 15,
    marginTop: 15,
    "@media (max-height: 720px)": {
      width: 160,
      height: 160,
      marginTop: 5,
    },
  },
  resBackTimerImage: {
    width: 200,
    height: 170,
  },
  timerNumber: {
    fontSize: 90,
    paddingTop: 20,
    lineHeight: 90,
    color: "#77D5FC",

    fontFamily: "RajdahaniBold",
    "@media (max-height: 720px)": {
      fontSize: 50,
      lineHeight: 50,
    },
  },
  resTimerNumber: {
    fontSize: 50,
    paddingTop: 0,
  },
  startButtonTimer: {
    width: 182,
    height: 50,
    borderRadius: 12,
    backgroundColor: "#4ED386",
    alignItems: "center",
    justifyContent: "center",
    marginTop: wp("8%"),

    "@media (max-height: 720px)": {
      width: 120,
      height: 40,
      marginTop: wp("5%"),
    },
  },
  startButtonTimerText: {
    fontSize: 55,
    color: "#fff",
    fontFamily: "RajdahaniBold",
    lineHeight: 62,
    "@media (max-height: 720px)": {
      fontSize: 35,
      lineHeight: 45,
    },
  },
  endButtonTimer: {
    width: 110,
    height: 50,
    borderRadius: 12,
    backgroundColor: "#23D2E2",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "8%",
    marginBottom: 100,
  },
  resButtonTimer: {
    width: 70,
    height: 35,
    marginTop: 20,
  },
  endButtonTimerText: {
    fontSize: 35,
    color: "#fff",
  },
  resButtonTimerText: {
    fontSize: 20,
  },
  setTimerText: {
    fontSize: 28,
    color: "#AFB0C0",
    marginTop: -20,
  },
  resSetTimerText: {
    fontSize: 15,
    marginTop: -15,
  },
});
export default TimerSoloTab;
