import React from "react";
import { useAppSelector } from "../redux/hooks";
import {
  selectAdmin,
  selectDefaultAccount,
} from "../features/auth/slice/authSlice";
import BadgeSystemMainContainer from "../features/badgeSystem/container/BadgeSystemMainContainer";
import ProfileSettingHeaderPresentation from "../features/profileSetting/presentation/ProfileSettingHeaderPresentation";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  BadgeSystemScreenRouteProp,
  HomeScreenNavigationProps,
} from "../navigation/types";
import { View } from "react-native";
import { useGetAllBadgesQuery } from "../features/badgeSystem/api/apiBadgeSystem";

const BadgeSystemScreen = () => {
  const user = useAppSelector(selectAdmin);
  const accountSelected = useAppSelector(selectDefaultAccount);
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const route = useRoute<BadgeSystemScreenRouteProp>();
  const { student, active, xTwoButton, itemBadge, popUpOpen } = route.params;

  const {
    data: allBadgesList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllBadgesQuery({
    currentSystem: JSON.stringify(accountSelected),
  });

  const onGoAccountSetting = () => {
    navigation.navigate("Setting");
  };

  const onGoBack = () => {
    navigation.goBack();
  };

  return (
    <>
    <View     
      style={{
                backgroundColor:"#fff"
            }}>
        <View
            style={{
              borderBottomLeftRadius: 40,
              borderBottomRightRadius: 40,
              height: 150,
              overflow: "hidden",
            }}
          >
            <ProfileSettingHeaderPresentation
              backgroundProfile={student?.backgroundBanner!}
              goAccountSetting={onGoAccountSetting}
              goBackHandler={onGoBack}
              showSetting={true}
            />
      </View>
    </View>


      <BadgeSystemMainContainer
        active={active}
        xTowButton={xTwoButton}
        itemBadge={itemBadge}
        popUpOpen={popUpOpen}
      />
    </>
  );
};

export default BadgeSystemScreen;
