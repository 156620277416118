import React, { useRef, useEffect } from "react";
import {
  View,
  Modal,
  StyleSheet,
  Image,
  Dimensions,
  Animated,
  Text,
  TouchableOpacity,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import LottieView from "lottie-react-native";
// import wheel7day from "../../assets/image/wheel-in-7-day.png";
import changeSVGColor from "@killerwink/lottie-react-native-color";
import backAnimation from "../../../assets/images/lottie/92235-confetti.json";
import { images } from "../../constants";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProps } from "../../navigation/types";
import { useAddRewardMutation } from "../../features/sevenDays/api/sevenDaysApi";
import { useAppSelector } from "../../redux/hooks";
import { selectDefaultAccount } from "../../features/auth/slice/authSlice";
// import backAnimation from "../../assets/image/lottie/92235-confetti.json";
// import { useDispatch } from "react-redux";
// import { AddXpByPrizeWheelAction } from "../../actions/PrizeWheel/addXpAction/add-xp-action";
// import { addGemByPrizeWheel } from "../../actions/PrizeWheel/addGem/add-gem.action";
// import { addFreeTicket } from "../../actions/PrizeWheel/addFreeTickets/add-free-tickeet.action";
// import { buyShopItemActionPrizeWheel } from "../../actions/PrizeWheel/addShop/add-shop-action";
// import { addToBagActionPrizeWheel } from "../../actions/PrizeWheel/addRaffleTicket/add-raffle-ticket.action";

const { width } = Dimensions.get("window");
type PrizeWheelPopupWinProps = {
  PopupType: any;
  setShowPopup: any;
};
const PrizeWheelPopupWin: React.FC<PrizeWheelPopupWinProps> = ({
  PopupType,
  setShowPopup,
}) => {
  const navigation = useNavigation<HomeScreenNavigationProps>();
  const systemSelected = useAppSelector(selectDefaultAccount);
  // const dispatch = useDispatch();
  const backAnimations = React.createRef<any>();
  const backProgress = useRef(new Animated.Value(0)).current;

  const [
    addReward,
    { isLoading: awardLoading, isError: awardError, isSuccess: awardSuccess },
  ] = useAddRewardMutation();
  useEffect(() => {
    if (backAnimations && backAnimations?.current)
      (backAnimations.current as any).play();
  }, []);

  // const onClaimHandler = () => {
  //   switch (PopupType.type) {
  //     case "Gem":
  //       const gemAmount = PopupType.popupValue;
  //       dispatch(addGemByPrizeWheel(gemAmount));
  //       navigation.navigate("Profile");
  //       break;
  //     case "XP":
  //       const amount = PopupType.popupValue;
  //       dispatch(AddXpByPrizeWheelAction(amount));
  //       navigation.navigate("Profile");
  //       break;
  //     case "FreeTicket":
  //       dispatch(addFreeTicket());
  //       navigation.navigate("Profile");
  //       break;

  //     case "Shop":
  //       const itemId = PopupType.id;
  //       dispatch(buyShopItemActionPrizeWheel(itemId));
  //       navigation.navigate("Profile");
  //       break;
  //     case "RaffleTicket":
  //       const raffleItemId = PopupType.id;
  //       dispatch(addToBagActionPrizeWheel(raffleItemId));
  //       navigation.navigate("Profile");
  //       break;
  //   }
  // };
  return (
    <>
      <Modal transparent={true} visible={true} animationType="fade">
        <View style={styles.modelContainer}>
          <LottieView
            style={{
              width: "100%",
              flexGrow: 1,
              height: "100%",
              position: "absolute",
              zIndex: 1,
            }}
            ref={backAnimations}
            loop
            source={changeSVGColor(backAnimation, "#000")}
            progress={backProgress}
          />

          <View style={styles.imageContainer}>
            <LinearGradient
              colors={["#13C4B8", "#fff"]}
              style={styles.modalImageContainer}
              start={{ x: 0.5, y: 0 }}
              end={{ x: 0.5, y: 1 }}
            >
              <Text style={styles.title}>Winner</Text>

              <View style={styles.content}>
                {/* Gem */}

                {PopupType.type == "Gem" && (
                  <View style={styles.itemContent}>
                    <Text style={styles.itemNumber}>
                      {PopupType.popupValue}
                    </Text>
                    <Image
                      source={images.SevenDaysGem}
                      style={styles.gemImage}
                      resizeMode="contain"
                    />
                  </View>
                )}

                {/* xp */}
                {PopupType.type === "XP" && (
                  <View style={styles.itemContent}>
                    <Text style={styles.itemNumber}>
                      {PopupType.popupValue}
                    </Text>
                    <Image
                      source={images.SevenDaysXpImage}
                      style={styles.gemImage}
                      resizeMode="contain"
                    />
                  </View>
                )}

                {/* ticket */}
                {PopupType.type === "FreeTicket" && (
                  <View>
                    <Image
                      source={images.SevenDaysFreeTicketImage}
                      style={styles.ticketImage}
                      resizeMode="contain"
                    />
                  </View>
                )}

                {/* {PopupType.type === "RaffleTicket" && (
                  <View style={{ transform: [{ rotate: "-20deg" }] }}>
                    <Image
                      source={images.Ticket}
                      style={styles.ticketImage}
                      resizeMode="contain"
                    />
                    <View style={styles.ticketTitle}>
                      <Text numberOfLines={2} style={styles.ticketTitleText}>
                        FREE Ticket
                      </Text>
                    </View>
                    <View style={styles.ticketValue}>
                      <Text numberOfLines={2} style={styles.ticketValueText}>
                        Good Forever
                      </Text>
                    </View>
                  </View>
                )}  */}

                {/* shop item */}

                {/* {PopupType.type === "Shop" && (
                  <View style={styles.shopPopup}>
                    <Image
                      source={{ uri: PopupType.image }}
                      style={styles.popupImage}
                    />
                    <View style={styles.contentItems}>
                      <View style={styles.titleBox}>
                        <Text style={styles.popupTitle} numberOfLines={1}>
                          {PopupType.value}
                        </Text>
                        <Text style={styles.dueDate}>243 Left</Text>
                      </View>
                      <View style={styles.popupShopItemCoinContainer}>
                        <Text style={styles.popupShopItemCoin}>Free</Text>
                        <Image
                          source={images.gemImage}
                          style={styles.popupShopItemCoinImg}
                        />
                      </View>
                    </View>
                    <Text style={styles.description}>
                      {PopupType.description}
                    </Text>
                  </View>
                )} */}
              </View>

              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  onPress={async () => {
                    setShowPopup(false);
                    switch (PopupType.type) {
                      case "Gem":
                        try {
                          await addReward({
                            currentSystem: systemSelected!,
                            day: 8,
                            value: Number(PopupType.value),
                            gem: "gem",
                          }).unwrap();
                        } catch (error) {}
                        break;
                      case "XP":
                        try {
                          await addReward({
                            currentSystem: systemSelected!,
                            day: 2,
                            value: Number(PopupType.value),
                          }).unwrap();
                        } catch (error) {}
                        break;
                      case "FreeTicket":
                        try {
                          await addReward({
                            currentSystem: systemSelected!,
                            day: 4,
                            value: Number(PopupType.value),
                          }).unwrap();
                        } catch (error) {}
                        break;
                    }
                    navigation.navigate("Home");
                  }}
                  style={styles.button}
                >
                  <Text style={styles.buttonText}>Claim</Text>
                </TouchableOpacity>
              </View>
            </LinearGradient>
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  description: {
    fontSize: 26,
    maxWidth: "116%",
    alignItems: "center",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 30,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
    fontFamily: "RajdahaniMedium",
  },
  // popupShopItemCoinImg: {
  //   position: "absolute",
  //   right: -15,
  // },
  // popupShopItemCoin: {
  //   color: "#fff",
  //   fontSize: 18,
  //   fontFamily: "HelveticaBold",
  //   paddingLeft: 15,
  //   paddingRight: 20,
  // },
  // popupShopItemCoinContainer: {
  //   backgroundColor: "#000",
  //   borderRadius: 40,
  //   flexDirection: "row",
  //   minWidth: 60,
  //   justifyContent: "flex-start",
  //   alignItems: "center",
  //   textAlign: "center",
  //   borderWidth: 2,
  //   borderColor: "#fff",
  //   height: 25,
  //   position: "relative",
  //   top: 20,
  // },
  // dueDate: {
  //   fontFamily: "RajdahaniBold",
  //   width: "100%",
  //   fontSize: 15,
  //   textAlign: "left",
  //   padding: 3,
  //   backgroundColor: "#0BC4B8",
  //   color: "#fff",
  //   fontWeight: "bold",
  // },
  // popupTitle: {
  //   backgroundColor: "#3E3F5E",
  //   color: "#fff",
  //   fontSize: 15,
  //   fontFamily: "RajdahaniBold",
  //   width: "100%",
  //   textAlign: "left",
  //   padding: 7,
  // },
  // titleBox: {
  //   minWidth: 150,
  //   borderRadius: 8,
  //   overflow: "hidden",
  //   textAlign: "center",
  //   alignItems: "center",
  //   borderWidth: 3,
  //   borderColor: "#fff",
  //   maxWidth: 200,
  // },
  // contentItems: {
  //   flexDirection: "row",
  //   borderRadius: 8,
  //   overflow: "hidden",
  //   position: "absolute",
  //   top: 80,

  //   width: "100%",

  //   paddingLeft: 15,
  //   paddingRight: 15,

  //   justifyContent: "space-between",
  // },
  // popupImage: {
  //   backgroundColor: "#e2e2e2",
  //   height: 111,
  // },
  // shopPopup: {
  //   backgroundColor: "#fff",

  //   padding: 0,
  //   borderRadius: 10,
  //   overflow: "hidden",
  //   width: "90%",
  //   maxWidth: 347,
  //   position: "relative",
  // },
  // ticketImage: {
  //   maxWidth: "100%",
  //   justifyContent: "center",
  //   alignContent: "center",
  //   height: 270,
  //   transform: [{ rotate: "-20deg" }],
  // },
  // xpImage: {
  //   right: -115,
  //   width: 160,
  //   height: 160,
  // },
  // xpContent: {
  //   width: "50%",
  //   height: 75,
  //   marginRight: "23%",
  // },
  // itemContent: {
  //   height: 80,
  //   backgroundColor: "#000",
  //   borderRadius: 42,
  //   width: "60%",
  //   justifyContent: "center",
  //   textAlign: "center",
  //   alignContent: "center",
  //   alignItems: "center",
  //   position: "relative",
  //   marginRight: "15%",
  //   marginTop: "10%",
  //   marginBottom: "10%",
  // },
  // itemNumber: {
  //   fontSize: 80,
  //   color: "#fff",
  //   fontFamily: "RajdahaniBold",
  //   lineHeight: 90,
  // },
  // gemImage: {
  //   position: "absolute",
  //   right: -100,
  // },
  // buttonText: {
  //   fontSize: 40,
  //   color: "#fff",
  //   fontFamily: "RajdahaniBold",
  // },
  popupShopItemCoinImg: {
    position: "absolute",
    right: -15,
  },
  popupShopItemCoin: {
    color: "#fff",
    fontSize: 18,
    fontFamily: "HelveticaBold",
    paddingLeft: 15,
    paddingRight: 20,
  },
  popupShopItemCoinContainer: {
    backgroundColor: "#000",
    borderRadius: 40,
    flexDirection: "row",
    minWidth: 60,
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    borderWidth: 2,
    borderColor: "#fff",
    height: 25,
    position: "relative",
    top: 20,
  },
  dueDate: {
    fontFamily: "RajdahaniBold",
    width: "100%",
    fontSize: 15,
    textAlign: "left",
    padding: 3,
    backgroundColor: "#0BC4B8",
    color: "#fff",
    fontWeight: "bold",
  },
  popupTitle: {
    backgroundColor: "#3E3F5E",
    color: "#fff",
    fontSize: 15,
    fontFamily: "RajdahaniBold",
    width: "100%",
    textAlign: "left",
    padding: 7,
  },
  titleBox: {
    minWidth: 150,
    borderRadius: 8,
    overflow: "hidden",
    textAlign: "center",
    alignItems: "center",
    borderWidth: 3,
    borderColor: "#fff",
    maxWidth: 200,
  },
  contentItems: {
    flexDirection: "row",
    borderRadius: 8,
    overflow: "hidden",
    position: "absolute",
    top: 80,

    width: "100%",

    paddingLeft: 15,
    paddingRight: 15,

    justifyContent: "space-between",
  },
  popupImage: {
    backgroundColor: "#e2e2e2",
    height: 111,
  },
  shopPopup: {
    backgroundColor: "#fff",

    padding: 0,
    borderRadius: 10,
    overflow: "hidden",
    width: "90%",
    maxWidth: 347,
    position: "relative",
  },
  ticketImage: {
    maxWidth: "100%",
    justifyContent: "center",
    alignContent: "center",
  },
  xpImage: {
    position: "absolute",
    width: 20,
    height: 20,
  },
  xpContent: {
    width: "50%",
    height: 75,
    marginRight: "23%",
  },
  itemContent: {
    height: 80,
    backgroundColor: "#000",
    borderRadius: 42,
    width: "70%",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    position: "relative",
    marginRight: "15%",
    marginTop: "10%",
    marginBottom: "10%",
  },
  itemNumber: {
    fontSize: 80,
    color: "#fff",
    fontFamily: "RajdahaniBold",
    lineHeight: 90,
  },
  gemImage: {
    position: "absolute",
    right: 10,
    width: 40,
    height: 40,
  },
  buttonText: {
    fontSize: 40,
    color: "#fff",
    fontFamily: "RajdahaniBold",
  },
  // button: {
  //   fontSize: 28,
  // },
  sevenDayDoneCoontainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    borderRadius: 20,
    padding: 2,
    paddingLeft: 20,
    marginLeft: -13,
    backgroundColor: "#4ED386",
    fontFamily: "RajdahaniBold",
    overflow: "hidden",
    fontSize: 20,
    color: "#fff",
    width: "70%",
    height: 54,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },

  buttonContainer: {
    position: "relative",
    padding: 25,
    paddingBottom: 20,
    paddingTop: 15,
    alignItems: "center",
  },
  sevenDayWheel: {
    position: "absolute",
    right: 0,
    top: -3,
  },
  ticketValue: {
    position: "absolute",
    transform: [{ rotate: "267deg" }],
    right: 0,
    top: 135,
    width: 100,
    height: 40,
    justifyContent: "center",
    textAlign: "center",
  },
  ticketValueText: {
    width: "100%",
    textAlign: "center",
    fontSize: 12,
    fontFamily: "RajdahaniBold",
    lineHeight: 19,
    fontWeight: "700",
  },
  ticketTitle: {
    position: "absolute",
    transform: [{ rotate: "270deg" }],
    right: 57,
    top: 115,
    width: 50,
    height: 80,
    justifyContent: "center",
  },
  ticketTitleText: {
    width: "100%",
    textAlign: "center",
    fontSize: 19,
    fontFamily: "RajdahaniBold",
    lineHeight: 19,
    fontWeight: "700",
  },
  content: {
    flexDirection: "row",
    justifyContent: "center",
  },
  userContent: {
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    width: 77,
  },

  modalImageContainer: {
    position: "relative",
    maxWidth: 374,
    maxHeight: 526,

    width: "95%",
    backgroundColor: "#5e5e5e",
    borderRadius: 15,
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    marginTop: "3%",
    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 1,
  },

  modelContainer: {
    backgroundColor: "#000000aa",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    maxWidth: "100%",
    width: width,
    alignSelf: "center",
    zIndex: 3,
  },
  title: {
    fontSize: 45,
    color: "#fff",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "Roboto_700Bold",
    paddingTop: "3%",
  },
});

export default PrizeWheelPopupWin;
