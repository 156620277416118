import React from "react";
import {
  ScrollView,
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  Image
} from "react-native";
import StyleSheet from "react-native-media-query";
import {
  Ionicons,
  FontAwesome,
  FontAwesome5,
  SimpleLineIcons,
} from "@expo/vector-icons";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FONTS, images } from "../../../constants";
const { height } = Dimensions.get("window");
type ProfileSettingListMenuProps = {
  onProfileSettingClick: () => void;
  onPrizesClick: () => void;
  onFriendsClick: () => void;
  onTeamAndScore: () => void;
  onBadgesClick: () => void;
  onBadgeSystemClick: () => void;
  onNotehstarClick: () => void;
};
const ProfileSettingListMenu: React.FC<ProfileSettingListMenuProps> = ({
  onProfileSettingClick,
  onPrizesClick,
  onFriendsClick,
  onTeamAndScore,
  onBadgesClick,
  onBadgeSystemClick,
  onNotehstarClick,
}) => {
  return (
    <ScrollView style={styles.profileSettingContent}>
      <View style={styles.profileSettingBox}>
        <View style={styles.profileContent}>
        <MaterialCommunityIcons name="account-cog" size={37} color="black" />
        
        </View>

        <TouchableOpacity
          style={styles.profileNameContent}
          onPress={onProfileSettingClick}
        >
          <Text style={styles.profileSettingTitle}>Profile Setting</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.profileSettingBox}>
        <View style={styles.profileContent}>
          <Ionicons name="md-person" size={35} />
        </View>

        <TouchableOpacity
          style={styles.profileNameContent}
          onPress={onBadgesClick}
        >
          <Text style={styles.profileSettingTitle}>My Profile</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.profileSettingBox}>
        <View style={styles.profileContent}>
          <FontAwesome5 name="user-friends" size={40} />
        </View>

        <View style={styles.profileNameContent}>
          <TouchableOpacity onPress={onFriendsClick}>
            <Text style={styles.profileSettingTitle}>Friends</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.profileSettingBox}>
        <View style={styles.profileContent}>
          <FontAwesome name="trophy" size={40} color="black" />
        </View>

        <TouchableOpacity
          style={styles.profileNameContent}
          onPress={onTeamAndScore}
        >
          <Text style={styles.profileSettingTitle}>Teams and Schools</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.profileSettingBox}>
        <View style={styles.profileContent}>
          <SimpleLineIcons name="badge" size={35} color={"#000"} />
        </View>

        <TouchableOpacity
          style={styles.profileNameContent}
          onPress={onBadgeSystemClick}
        >
          <Text style={[styles.profileSettingTitle]}>Badges</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.profileSettingBox}>
        <View style={styles.profileContent}>
          <FontAwesome5 name="store" size={35} />
        </View>

        <TouchableOpacity
          style={styles.profileNameContent}
          onPress={onPrizesClick}
        >
          <Text style={[styles.profileSettingTitle]}>Shop</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.profileSettingBox}>
        <View style={styles.profileContent}>
      
          <Image
                source={images.notehstarIcon}
                style={{
                  width: 42,
                  height: 42,
                }}
              />
        </View>

        <TouchableOpacity
          style={styles.profileNameContent}
          onPress={onNotehstarClick}
        >
          <Text style={[styles.profileSettingTitle]}>My Northstar </Text>
        </TouchableOpacity>
      </View>
      {/* <View style={styles.profileSettingBox}>
                        <View style={styles.profileContent}>
                            <Ionicons name="md-chatbubbles" size={35}   />
                        </View>

                        <View style={styles.profileNameContent}>
                            <Text style={[styles.profileSettingTitle,styles.disableStyle]}>Surveys</Text>
                            <View style={styles.LockBox}>
                                    <Image style={styles.LockImage} source={LockImage} />
                                    <Text style={styles.LockNumber}>2</Text>
                            </View>
                        </View>
                          
                    </View>
 
                    <View style={styles.profileSettingBox}>
                        <View style={styles.profileContent}>

                            <FontAwesome5 name="ticket-alt" size={35} />
                        </View>

                        <TouchableOpacity style={styles.profileNameContent} disabled={true}  onPress={() => navigation.navigate('tickets')}>
                            <Text style={[styles.profileSettingTitle,styles.disableStyle]}>Tickets</Text>
                            <View style={styles.LockBox}>
                                    <Image style={styles.LockImage} source={LockImage} />
                                    <Text style={styles.LockNumber}>2</Text>
                            </View>
                        </TouchableOpacity>
                    </View> */}
    </ScrollView>
  );
};

const { styles } = StyleSheet.create({
  profileSettingContainer: {
    width: "100%",
    backgroundColor: "#F8FEFF",
  },
  profileInfoShapeContainer: {
    top: -20,
    "@media (max-width: 350px)": {
      top: -60,
      transform: [{ scale: 0.8 }],
    },
  },
  disableStyle: {
    color: "#B1ADAD",
  },
  LockBox: {
    padding: 3,
    backgroundColor: "#34C38F",
    borderRadius: 5,
    position: "relative",
  },
  LockImage: {
    width: 37,
    height: 34,
  },
  LockNumber: {
    position: "absolute",
    fontSize: 14,
    color: "#34C38F",
    fontFamily: "RajdahaniBold",
    top: 15,
    left: 17,
  },
  profileSettingBox: {
    color: "#fff",
    width: "96%",
    flexDirection: "row",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
    "@media (max-width: 350px)": {
      paddingTop: 5,
    },
  },
  GreenBox: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#34C38F",
    borderRadius: 4,
    color: "#fff",
  },
  profileContent: {
    paddingBottom: 15,
    paddingRight: 10,
    minWidth: 50,
    "@media (max-width: 350px)": {
      transform: [{ scale: 0.7 }],
    },
  },
  profileSettingTitle: {
    paddingLeft: 20,
    fontSize: 24,
    fontFamily: "RajdahaniBold",
    "@media (max-width: 350px)": {
      fontSize: 20,
      paddingLeft: 10,
    },
  },
  profileSettingImage: {
    borderRadius: 50,
    height: 68,
    width: 68,
  },
  EditText: {
    color: "#fff",
    fontSize: 16,
    padding: 4,
  },
  EditShape: {
    backgroundColor: "#fff",
    borderRadius: 50,
    height: 18,
    width: 18,
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    marginRight: 4,
  },
  EditImage: {
    height: 12,
    width: 12,
    marginTop: 3,
  },
  profileInfo: {
    alignContent: "center",
    justifyContent: "center",
  },
  backProfilePic: {
    width: "100%",
    height: 80,
  },
  backProfileImage: {
    width: "100%",
    backgroundColor: "#e3e3e3",
    height: 180,
    "@media (max-width: 350px)": {
      height: 120,
    },
  },
  profileSettingContent: {
    width: "100%",
    shadowColor: "#000",
    alignSelf: "center",
    shadowOffset: {
      width: 0,
      height: -12,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6.84,
    backgroundColor: "white",
    elevation: 7,
    borderRadius: 20,
    paddingTop: 30,
    marginTop: -20,
    paddingLeft: 20,
    paddingRight: 20,
    flex: 1,
    minHeight: height - 175,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    "@media (max-width: 350px)": {
      minHeight: height - 100,
    },
  },
  profileNameContent: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderBottomColor: "#9D9A9A",
    paddingBottom: 17,
    alignItems: "center",
    "@media (max-width: 350px)": {
      paddingBottom: 12,
    },
  },
  profileTeamPic: {},
  profileTeamPicContainer: {
    marginBottom: 13,
    position: "absolute",
    top: 100,
    left: 0,
    justifyContent: "flex-start",
    flexDirection: "row",
    marginLeft: "1%",
    zIndex: 9999,
  },

  profilePicContainer: {
    alignItems: "center",
    width: "100%",
  },
  profileRd: {
    width: 46,
    height: 46,
    borderRadius: 50,
    alignItems: "center",
    textAlign: "center",
    marginRight: 6,
    justifyContent: "center",
    backgroundColor: "#0BC4B8",
    flexDirection: "row",
  },
  profileRdNumber: {
    color: "#fff",
    fontSize: 18,
  },
  profileRdText: {
    color: "#fff",
  },
});
export default ProfileSettingListMenu;
