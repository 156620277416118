import React from "react";
import ProfileAccountSettingTextPresentation from "../presentation/ProfileAccountSettingTextPresentation";



const ProfileAccountSettingTextContainer= () => {

  return (
    <ProfileAccountSettingTextPresentation/>
  );
};

export default ProfileAccountSettingTextContainer;
