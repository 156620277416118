import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { AuthUser } from "../../auth/types/types";
import { IResponseList } from "../../../redux/types/types";
import { Friend } from "../types/type";

const friendsAdaptor = createEntityAdapter<AuthUser>({
  selectId: (student) => student._id,
});

interface FriendsInitialState extends EntityState<AuthUser> {}

const initialState: FriendsInitialState = friendsAdaptor.getInitialState();

export const extendedFriendsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSuggetionFriends: builder.query<
      EntityState<AuthUser>,
      {
        currentSystem: string;
        term?: string;
        page?: string;
        limit?: string;
        sort?: string;
      }
    >({
      query: (data) =>
        `/friends/studenlist?currentSystem=${data.currentSystem}&page=${data.page}&limit=${data.limit}&sort=${data.sort}&term=${data.term}`,
      transformResponse: (response: IResponseList<AuthUser[]>) => {
        const studentListData = response.data;
        const studentListMetaData = response.metadata;
        return friendsAdaptor.setAll(initialState, studentListData);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Friends",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Friends" as const, id })),
      ],
    }),

    requestdList: builder.query<
      EntityState<AuthUser>,
      {
        currentSystem: string;
        page?: string;
        limit?: string;
        sort?: string;
      }
    >({
      query: (data) =>
        `/friends/requestList?currentSystem=${data.currentSystem}&page=${data.page}&limit=${data.limit}&sort=${data.sort}`,
      transformResponse: (response: IResponseList<AuthUser[]>) => {
        return friendsAdaptor.setAll(initialState, response.data);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Friends",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Friends" as const, id })),
      ],
    }),
    friendsList: builder.query<
      EntityState<AuthUser>,
      {
        currentSystem: string;
        page?: string;
        limit?: string;
        sort?: string;
      }
    >({
      query: (data) =>
        `/friends/friendList?currentSystem=${data.currentSystem}&page=${data.page}&limit=${data.limit}&sort=${data.sort}`,
      transformResponse: (response: IResponseList<AuthUser[]>) => {
        return friendsAdaptor.setAll(initialState, response.data);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Friends",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Friends" as const, id })),
      ],
    }),
    sendRequest: builder.mutation<Friend, Partial<Friend>>({
      query: (friend) => ({
        url: `/friends`,
        method: "PUT",
        body: friend,
      }),

      invalidatesTags: ["Friends"],
    }),

    acceptRequest: builder.mutation<Friend, Partial<Friend>>({
      query: (friend) => ({
        url: `/friends/accept`,
        method: "PUT",
        body: friend,
      }),

      invalidatesTags: ["Friends"],
    }),
  }),
});

export const {
  useGetSuggetionFriendsQuery,
  useSendRequestMutation,
  useRequestdListQuery,
  useAcceptRequestMutation,
  useFriendsListQuery,
} = extendedFriendsApi;
