import React, { FC } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Team } from "../../features/auth/types/types";
import { TeamData } from "../../features/teamsandscore/types/type";
import { useAppSelector } from "../../redux/hooks";
import { selectAdmin } from "../../features/auth/slice/authSlice";

export interface IFTrianglesBox {
  arrowAlign: string;
  teamColor: string;
  item?: TeamData;
  total: number;
  outOfBound: number;
  showAvg?: boolean;
}

const TrianglesBox: FC<IFTrianglesBox> = ({
  arrowAlign,
  teamColor,
  item,
  total,
  outOfBound,
  showAvg,
}) => {
  const admin = useAppSelector(selectAdmin);
  return (
    <View style={styles.TrianglesBoxContainer}>
      {arrowAlign == "left" && (
        <View style={[styles.triangle, styles.arrowLeft]} />
      )}
      <View style={styles.TrianglesBox}>
        <Text style={styles.TrianglesBoxTeam}>{item?.team.title} Team</Text>

        <Text style={styles.TrianglesBoxXp}>
          <Text style={styles.TrianglesBoxAvg}>
            {admin?.systemProject[0].project_type === "Event"
              ? Math.floor(Number(item?.teamTotalXp))
              : Number(item?.teamAvgXp).toFixed(1)}
          </Text>
          {!showAvg ? " AVG" : ""}
        </Text>
        {admin?.systemProject[0].project_type !== "Event" && (
          <Text style={styles.TrianglesBoxPercent}>Out of {outOfBound}</Text>
        )}
      </View>
      {arrowAlign == "right" && (
        <View style={[styles.triangle, styles.arrowRight]} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  TrianglesBox: {
    shadowColor: "#000",
    shadowOffset: {
      width: 10,
      height: 5,
    },
    shadowOpacity: 0.2,
    shadowRadius: 20.84,
    backgroundColor: "white",
    elevation: 3,
    borderRadius: 10,
    padding: 5,
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: "center",
  },

  TrianglesBoxTeam: {
    color: "#34269E",
    fontFamily: "RajdahaniBold",
    fontSize: 20,
  },
  TrianglesBoxPercent: {
    fontFamily: "RajdahaniBold",
    fontSize: 12,
    color: "#3E3F5E",
  },
  TrianglesBoxXp: {
    fontFamily: "RajdahaniBold",
    fontSize: 16,
    color: "#3E3F5E",
  },
  TrianglesBoxContainer: {
    flexDirection: "row",
    alignItems: "center",
    top: 20,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
  },
  arrowLeft: {
    borderTopWidth: 10,
    borderRightWidth: 15,
    borderBottomWidth: 10,
    borderLeftWidth: 0,
    borderTopColor: "transparent",
    borderRightColor: "#fff",
    borderBottomColor: "transparent",
    borderLeftColor: "transparent",
    zIndex: 9999,
  },
  arrowRight: {
    borderTopWidth: 10,
    borderRightWidth: 0,
    borderBottomWidth: 10,
    borderLeftWidth: 15,
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "transparent",
    borderLeftColor: "#fff",
  },
  TrianglesBoxAvg: {
    fontSize: 27,
    fontWeight: "bold",
  },
});

export default TrianglesBox;
