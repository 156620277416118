import React, { useState, useEffect, FC } from "react";
import { View, Text, Image, TouchableOpacity, Animated } from "react-native";
import StyleSheet from "react-native-media-query";
import { ZoomInEasyDown } from "react-native-reanimated";


export interface ILinearChart {
  value: number;
  lastValue: number;
  minValue: number;
  maxValue: number;
  borderType?: boolean;
}

const SmallLinearChart: FC<ILinearChart> = ({
  value,
  lastValue,
  minValue,
  maxValue,
  borderType,
}) => {
  const [percent, setPercent] = useState(0);
  const [lastPercent, setLastPercent] = useState(0);
  const [minVal, setMinVal] = useState(0);
  const [percentText, setPercentText] = useState(0);
  const [lastPercentText, setLastPercentText] = useState(0);
  const [maxVal, setMaxVal] = useState(3500);
  useEffect(() => {
    setMaxVal(maxValue);
    setMinVal(minValue);
    const percentProgress = (value * 100) / maxVal;
    setPercent(percentProgress);
    const lastPercentProgress = (lastValue * 100) / maxVal;
    setLastPercent(lastPercentProgress);
    const textPercentProgress = (value * 100) / maxVal - 5;
    setPercentText(textPercentProgress);
    const lastTextPercentProgress = (lastValue * 100) / maxVal - 5;
    setLastPercentText(lastTextPercentProgress);
  });

  return (
    <View style={styles.containerLinearChart}>
      <View style={[styles.mainLinearProgress, borderType && styles.mainLinearBorderType  ]}>
        {percentText > 10 && lastPercentText > 10 && (
          <Text style={[styles.progressLinearMinVal , borderType && styles.borderTypeText] }>
            {!isNaN(minVal) ? minVal : 0}
          </Text>
        )}
        <View
          style={[styles.progressLastLinear, { width: `${lastPercent}%` } , borderType && styles.borderType  ]}
        ></View>

        <Text style={[styles.progressLinearVal, { width: `${percentText}%` }, borderType && styles.borderTypeText ]}>
          {!isNaN(value) ? value.toFixed(0) : 0}
        </Text>

     {/* avg tag element */}
        {!isNaN(value) && false &&

        <Animated.View style={[styles.avgTagContainer,  { width: `${percentText}%` }]}>

        <TouchableOpacity style={styles.avgTag}>

          <Text style={styles.avgTagText}>AVG</Text>

        </TouchableOpacity>

        </Animated.View>

         }


        <View style={[styles.progressLinear, { width: `${percent}%` }, borderType && styles.borderType  ]}></View>

        <Text
          style={[
            styles.progressLastLinearVal,
            { width: `${lastPercentText}%` }
            , borderType && styles.borderTypeText 
          ]}
        >
          {!isNaN(lastValue) ? lastValue : 0}
        </Text>
        {percentText + 10  < maxVal && lastPercentText + 10  < maxVal && (
              <Text style={[styles.progressLinearMaxVal , borderType && styles.borderTypeText ]}>
                {!isNaN(maxVal) ? maxVal : 0}
              </Text>
            )}
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  progressLinearVal: {
    fontSize: 9,
    fontFamily: "RajdahaniBold",
    color: "#fff",
    position: "absolute",
    left: 8,
    top: 4,
    zIndex: 2,
    textAlign: "right",
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  borderTypeText:{
    top:8,
    fontSize:11
  },
  avgTagContainer:{
    position: "absolute",
    left: 10,
    top: 4,
    zIndex: 2,
    textAlign: "right",
     
      alignItems:"flex-end",
    "@media (max-width: 350px)": {
      top: 7,
    },

  },
  avgTag:{
    width: 26,
    borderColor:"#000",
    borderWidth:2,
    backgroundColor:"#fff",
    borderStyle:"solid",
    borderRadius:4,
    alignItems:"center",
    fontSize: 9,
    color: "#fff",
    marginRight:24
 
  },
  avgTagText:{
   
    fontFamily: "RajdahaniBold",
    fontSize:9,
    lineHeight:12

  },
  progressLastLinearVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    fontFamily: "RajdahaniBold",
    left: 8,
    top: 4,
    zIndex: 0,
    textAlign: "right",
    "@media (max-width: 350px)": {
      top: 7,
    },
  },

  progressLinearMinVal: {
    fontSize: 9,
    fontFamily: "RajdahaniBold",
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 4,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  progressLinearMaxVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    fontFamily: "RajdahaniBold",
    right: 8,
    top: 3,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },

  containerLinearChart: {
    flexDirection: "row",
    borderRadius: 10,
    width: "100%",
    height: 20,
 
    paddingRight: 20,
  },
  mainLinearProgress: {
    width: "100%",
    backgroundColor: "#E3E3E3",
    height: 18,
    borderRadius: 50,
    overflow: "hidden",
    position: "relative",
    "@media (max-width: 350px)": {
      height: 15,
    },
  },
  mainLinearBorderType:{
    height: 35,
      borderWidth:3,
      borderColor:"#000",
      borderStyle:"solid"
  },
  borderType:{
    height: 29,
  },

  progressLinear: {
    overflow: "hidden",
    height: 18,
    backgroundColor: "#4ED486",
    borderRadius: 50,
    zIndex: 1,
    position: "absolute",
    left: 0,
    "@media (max-width: 350px)": {
      height: 15,
    },
  },
  progressLastLinear: {
    overflow: "hidden",
    height: 18,
    backgroundColor: "#FF86B9",
    borderRadius: 50,
    "@media (max-width: 350px)": {
      height: 15,
    },
  },
});
export default SmallLinearChart;
