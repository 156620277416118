import { EntityId } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { BadgeSystem } from "../../badgeSystem/type/type";

export const extendBadgeAchivment = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    achiveBadge: builder.mutation<
      {
        student: EntityId;
        badge: EntityId;
        complete: boolean;
      },
      {
        badgeId: EntityId;
      }
    >({
      query: (data) => ({
        url: `/badge-completion/${data.badgeId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BadgeAchivment"],
    }),
    getLastAchives: builder.query<
      {
        student: EntityId;
        badge: BadgeSystem;
        complete: boolean;
      }[],
      {
        student: EntityId;
      }
    >({
      query: (data) => `/badge-completion/${data.student}`,
      transformResponse: (
        response: {
          student: EntityId;
          badge: BadgeSystem;
          complete: boolean;
        }[]
      ) => {
        return response;
      },
      providesTags: ["BadgeAchivment"],
    }),
  }),
});

export const { useAchiveBadgeMutation, useGetLastAchivesQuery } =
  extendBadgeAchivment;
