import React, { useState, useEffect, FC } from "react";
import { View, Text, Image, TouchableOpacity, Animated } from "react-native";
import StyleSheet from "react-native-media-query";



export interface ILinearChart {
  value: number;
  minValue: number;
  maxValue: number;
  borderType?: boolean;
  showXpValue: number;
}

const GradeLinearChart: FC<ILinearChart> = ({
  value,
  minValue,
  maxValue,
  borderType,
  showXpValue
}) => {
  const [percent, setPercent] = useState(0);
  const [minVal, setMinVal] = useState(0);
  const [percentText, setPercentText] = useState(0);
  const [maxVal, setMaxVal] = useState(3500);
  useEffect(() => {
    setMaxVal(maxValue);
    setMinVal(minValue);
    const percentProgress = (value * 100) / maxVal;
    setPercent(percentProgress);
    const textPercentProgress = (value * 100) / maxVal - 5;
    setPercentText(textPercentProgress);
  });
 
  return (
    <View style={styles.containerLinearChart}>
      <View style={[styles.mainLinearProgress, borderType && styles.mainLinearBorderType  ]}>
      {value > showXpValue && (
          <Text style={[styles.progressLinearMinVal , borderType && styles.borderTypeText] }>
            XP
          </Text>
      )}
  

        <Text  style={[styles.progressLinearVal, borderType && styles.borderTypeText ]}>
          {!isNaN(value) ? value.toFixed(0)  : 0}
      
        </Text>




        <View style={[styles.progressLinear, { width: `${percent}%` }, borderType && styles.borderType  ]}></View>

        {/* <Text style={[styles.progressLinearMaxVal , borderType && styles.borderTypeText ]}>
          {!isNaN(maxVal) ? maxVal : 0}
        </Text> */}
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  progressLinearVal: {
    fontSize: 12,
    fontFamily: "RajdahaniBold",
    color: "#fff",
    position: "absolute",
    left: 8,
    top: 2,
    zIndex: 3,
    textAlign: "right",
    textShadowColor: "#000",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 1,
 
  },
  borderTypeText:{
    top:8,
    fontSize:11
  },
  avgTagContainer:{
    position: "absolute",
    left: 10,
    top: 4,
    zIndex: 2,
    textAlign: "right",
     
      alignItems:"flex-end",
    "@media (max-width: 350px)": {
      top: 7,
    },

  },
  avgTag:{
    width: 26,
    borderColor:"#000",
    borderWidth:2,
    backgroundColor:"#fff",
    borderStyle:"solid",
    borderRadius:4,
    alignItems:"center",
    fontSize: 9,
    color: "#fff",
    marginRight:24
 
  },


  progressLinearMinVal: {
    fontSize: 12,
    fontFamily: "RajdahaniBold",
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 2,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  progressLinearMaxVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    fontFamily: "RajdahaniBold",
    right: 8,
    top: 3,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },

  containerLinearChart: {
    flexDirection: "row",
    borderRadius: 10,
    width: "100%",
    height: 20,
 
    paddingRight: 20,
  },
  mainLinearProgress: {
    width: "100%",
    backgroundColor: "#E3E3E3",
    height: 20,
    borderRadius: 50,
    overflow: "hidden",
    position: "relative",
    borderWidth:1,
    borderStyle:"solid",
    borderColor:"#000",
    "@media (max-width: 350px)": {
      height: 15,
    },
  },
  mainLinearBorderType:{
    height: 35,
      borderWidth:3,
      borderColor:"#000",
      borderStyle:"solid"
  },
  borderType:{
    height: 29,
  },

  progressLinear: {
    overflow: "hidden",
    height: 18,
    backgroundColor: "#FF4C00",
    borderRadius: 50,
    zIndex: 1,
    position: "absolute",
    left: 0,
    "@media (max-width: 350px)": {
      height: 15,
    },
  },

});
export default GradeLinearChart;
