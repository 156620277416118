import { Image } from "expo-image";
import { useEffect, useState } from "react";
import {
  ImageBackground,
  Platform,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";

import Svg, { Linecap, Linejoin, Polygon } from "react-native-svg";
import { FONTS, images } from "../constants";
type PolygonProfileProps = {
  xp: number;
  studentPic?: string;
  strokeWidth?: number;
  strokeColor?: string;
  rank?: number;
  rankText?: string;
  ContainerStyle?: ViewStyle;
  localImage?: string;
  showXp?: boolean;
};
const PolygonProfile: React.FC<PolygonProfileProps> = ({
  xp,
  studentPic,
  strokeColor,
  ContainerStyle,
  localImage,
  rank,
  rankText,
  strokeWidth,
  showXp,
}) => {
  const [progress, setProgress] = useState<number | null>(null);
  const [loadImage, setLoadImage] = useState<boolean>(false);

  useEffect(() => {
    const result = xp / 3500;

    const level = Math.floor(result);

    const percent = Number((result - level).toFixed(2)) * 100;

    setProgress(539 * (percent / 100) + 538);
  }, [xp]);
  return (
    <View style={[styles.profilePicContainer, ContainerStyle]}>
      <Svg  style={(Platform.OS === "web") ? styles.profilePicSvgWeb : styles.profilePicSvg}>
        <Polygon
          points="95 20,15 75,15 150,95 205,175 150,175 75"
          fill="rgba(52, 52, 52,0)"
          stroke="#F8FEFF"
          strokeWidth={28}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Polygon
          points="95 20,15 75,15 150,95 205,175 150,175 75"
          fill="rgba(52, 52, 52,0)"
          stroke="#DEE5ED"
          strokeWidth={`${strokeWidth ? strokeWidth : 20}`}
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <Polygon
          points="95 20,15 75,15 150,95 205,175 150,175 75"
          fill="transparent"
          strokeWidth={`${strokeWidth ? strokeWidth : 20}`}
          strokeLinecap="round"
          strokeDasharray={538}
          stroke={`${strokeColor ? strokeColor : "#2ED2E2"}`}
          strokeDashoffset={progress!}
          strokeLinejoin="round"
        />
      </Svg>

      <Image
        source={studentPic ? studentPic! : images.ProfileAvatar!}
        style={(Platform.OS === "web") ? styles.profilePicWeb : styles.profilePic}
        onLoad={() => setLoadImage(true)}
      />
      {showXp && (
        <ImageBackground
          source={images.xpLevelBackground!}
          resizeMode="contain"
          style={styles.image}
        >
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <View style={{ alignItems: "flex-end" }}>
              <Text style={styles.rank}>
                {rank ? rank : Math.floor(xp / 3500)}
              </Text>
            </View>
            <View style={{ alignItems: "flex-start" }}>
              <Text style={styles.rankText}>{rankText}</Text>
            </View>
          </View>
        </ImageBackground>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  profilePicContainer: {
    width: 230,
    height: 225,
    position: "relative",
    zIndex: 1000,
    paddingLeft: 20,
  },
  profilePicPlaceholder: {
    position: "absolute",
    width: 150,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    left: 40,
    top: 37,
    zIndex: 20,
    borderRadius: 50,
  },
  profilePic: {
    width: 140,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    backgroundColor: "rgb(255,255,255)",
    top: -187,
    left: 25,
    overflow: "hidden",
    borderRadius: 30,
  },
  profilePicWeb:{
    width: 140,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    backgroundColor: "rgb(255,255,255)",
    top: 36,
    zIndex: -1,
    left: 25,
    overflow: "hidden",
    borderRadius: 30,
  },
  profilePicSvg: {
    zIndex: 21,
  },
  profilePicSvgWeb : {
    position: "absolute",
    height: "100%"
  },
  image: {
    resizeMode: "contain",
    justifyContent: "center",
    width: 54,
    position: "absolute",
    height: 55,
    left: 165,
    top: 130,
    zIndex: 9999,
  },
  rank: {
    color: "#fff",
    alignSelf: "center",
    ...FONTS.h1,
    fontSize: 18,
  },
  rankText: {
    marginTop: 3,
    color: "#fff",
    alignSelf: "center",
    ...FONTS.body3,
  },
});

export default PolygonProfile;
