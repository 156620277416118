import React from "react";
import { ImageBackground, View } from "react-native";
import StyleSheet from "react-native-media-query";
type BackPictureProfileProps = {
  backBanner: string;
};
const BackPictrueProfile: React.FC<BackPictureProfileProps> = ({
  backBanner,
}) => {
  return (
    <View style={styles.backProfilePic}>
      <ImageBackground
        source={{ uri: backBanner! }}
        style={styles.backProfileImage}
      ></ImageBackground>
    </View>
  );
};

const { styles } = StyleSheet.create({
  backProfilePic: {
    width: "100%",
    height: 200,
    borderRadius: 12,
    overflow: "hidden",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    "@media (max-width: 350px)": {
      height: 100,
      borderRadius: 0,
    },
  },
  backProfileImage: {
    width: "100%",
    height: 200,
    backgroundColor: "#e3e3e3",
  },
});
export default BackPictrueProfile;
