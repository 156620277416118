import React from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Text,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
const { height } = Dimensions.get("window");
import StyleSheet from "react-native-media-query";
import { FONTS } from "../../../constants";
type SettingMenuPresentationProps = {
  switchAccountHandler: () => void;
  logoutHanlder: () => void;
};
const SettingMenuPresentation: React.FC<SettingMenuPresentationProps> = ({
  switchAccountHandler,
  logoutHanlder,
}) => {
  return (
    <View style={styles.profileSettingContent}>
      <ScrollView style={{ flexGrow: 1, flex: 1 }}>
        <View style={styles.profileSettingBox}>
          <View style={styles.profileContent}>
            <MaterialCommunityIcons
              name="account-switch-outline"
              size={35}
              color="black"
              style={{ right: -8 }}
            />
          </View>

          <TouchableOpacity
            style={styles.profileNameContent}
            onPress={switchAccountHandler}
          >
            <Text style={styles.profileSettingTitle}>Switch Account</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.profileSettingBox}>
          <View style={styles.profileContent}>
            <MaterialCommunityIcons
              name="fingerprint"
              size={35}
              color="black"
              style={{ transform: [{ rotate: "230deg" }] }}
            />
          </View>

          <TouchableOpacity
            style={styles.profileNameContent}
            onPress={() => {}}
          >
            <Text style={styles.profileSettingTitle}>Change Password</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.profileSettingBox}>
          <View style={styles.profileContent}>
            <MaterialCommunityIcons
              name="logout"
              size={35}
              color="black"
              style={{ transform: [{ rotateY: "180deg" }] }}
            />
          </View>

          <TouchableOpacity
            style={styles.profileNameContent}
            onPress={logoutHanlder}
          >
            <Text style={styles.profileSettingTitle}>Logout</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
};

const { styles } = StyleSheet.create({
  profileSettingContainer: {
    width: "100%",
    backgroundColor: "#F8FEFF",
  },
  profileTitle: {
    width: "100%",
    ...FONTS.body2,
    fontSize: 52,
    textAlign: "center",
    marginTop: 70,
    "@media (max-width: 350px)": {
      fontSize: 30,
      marginTop: 30,
    },
  },
  disableStyle: {
    color: "#B1ADAD",
  },
  LockBox: {
    padding: 3,
    backgroundColor: "#34C38F",
    borderRadius: 5,
    position: "relative",
  },
  LockImage: {
    width: 37,
    height: 34,
  },
  LockNumber: {
    position: "absolute",
    color: "#34C38F",
    ...FONTS.body2,
    fontSize: 14,
    top: 15,
    left: 17,
  },
  profileSettingBox: {
    color: "#fff",
    width: "96%",
    flexDirection: "row",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
  },
  GreenBox: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#34C38F",
    borderRadius: 4,
    color: "#fff",
  },
  profileContent: {
    paddingBottom: 15,
    paddingRight: 10,
    minWidth: 50,
  },
  profileSettingTitle: {
    paddingLeft: 20,
    ...FONTS.body2,
    fontSize: 24,
    "@media (max-width: 350px)": {
      paddingLeft: 10,
      fontSize: 20,
    },
  },
  profileSettingImage: {
    borderRadius: 50,
    height: 68,
    width: 68,
    "@media (max-width: 350px)": {
      height: 50,
      width: 50,
    },
  },
  EditText: {
    color: "#fff",
    fontSize: 16,
    padding: 4,
  },
  EditShape: {
    backgroundColor: "#fff",
    borderRadius: 50,
    height: 18,
    width: 18,
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    marginRight: 4,
  },
  EditImage: {
    height: 12,
    width: 12,
    marginTop: 3,
  },
  profileInfo: {
    alignContent: "center",
    justifyContent: "center",
  },
  backProfilePic: {
    width: "100%",
    height: 80,
  },
  backProfileImage: {
    width: "100%",
    height: 140,
    "@media (max-width: 350px)": {
      height: 100,
    },
  },
  profileSettingContent: {
    width: "100%",
    shadowColor: "#000",
    alignSelf:"center",
    shadowOffset: {
      width: 0,
      height: -12,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6.84,
    backgroundColor: "white",
    elevation: 7,
    borderRadius:20,
    paddingTop:30,
    marginTop:-20,
    paddingLeft:20,
    paddingRight:20,
    flex:1,
    minHeight:height - 175,
    borderBottomLeftRadius:0,
    borderBottomRightRadius:0,
    '@media (max-width: 350px)': {
        minHeight:height - 100,

       },

  },
  profileNameContent: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderBottomColor: "#9D9A9A",
    paddingBottom: 17,
    alignItems: "center",
  },
  profileTeamPic: {},
  profileTeamPicContainer: {
    marginBottom: 13,
    position: "absolute",
    top: 100,
    left: 0,
    justifyContent: "flex-start",
    flexDirection: "row",
    marginLeft: "1%",
    zIndex: 9999,
  },

  profilePicContainer: {
    alignItems: "center",
    width: "100%",
  },
  profileRd: {
    width: 46,
    height: 46,
    borderRadius: 50,
    alignItems: "center",
    textAlign: "center",
    marginRight: 6,
    justifyContent: "center",
    backgroundColor: "#0BC4B8",
    flexDirection: "row",
  },
  profileRdNumber: {
    color: "#fff",
    fontSize: 18,
  },
  profileRdText: {
    color: "#fff",
  },
});
export default SettingMenuPresentation;
