import React from "react";
import YouthMainContainer from "../features/Youth/container/YouthMainContainer";

const YouthScreen = () => {
  return (
    <>
      <YouthMainContainer />
    </>
  );
};

export default YouthScreen;