import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    // baseUrl: "http://192.168.43.184:6800/api",
    // baseUrl: `http://192.168.1.101:6800/api`,
    baseUrl: "https://api.gradify.app/api",
    credentials: "include",
    keepalive: true,
  }),

  tagTypes: [
    "ExperienceCard",
    "CompletionStudent",
    "ExperiencePhoto",
    "Student",
    "LeaderBoard",
    "ProfileChart",
    "Shop",
    "RaffelTicket",
    "Unlock",
    "Bag",
    "Friends",
    "Login",
    "TeamAndScore",
    "Review",
    "SevenDays",
    "CategoryLeaderboard",
    "FreeTicket",
    "RevieGem",
    "ReviveCard",
    "AcademicScore",
    "PhotoCount",
    "FriendsCount",
    "ReviewCount",
    "LoginCount",
    "BadgeSystem",
    "BadgeAchivment",
    "Comment",
    "CommentLike",
    "ProfileRank",
    "CommentLikeCount",
    "Grade",
    "GradeAvgScore",
    "LearnerSetting",
    "NotificationCenter",
    "GradeReward",
    "NorhtStar",
    "MyWhy",
    "Timer",
    "FoucseTimer",
  ],
  endpoints: (builder) => ({}),
});
