import React from "react";
import ProfileSettingMenuTextPresentation from "../presentation/ProfileSettingMenuTextPresentation";


const ProfileSettingMenuTextContainer= () => {

  return (
    <ProfileSettingMenuTextPresentation/>
  );
};

export default ProfileSettingMenuTextContainer;
