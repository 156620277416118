import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../redux/api/apiSlice";
import { CategoryLeaderboard } from "../types/type";
import { StudentSystemType } from "../../auth/types/types";
import { IResponseList } from "../../../redux/types/types";
import { store } from "../../../redux/store";
import { setTotal } from "../slice/categoryLeaderBoardSlice";

const categoryLeaderboardAdaptor = createEntityAdapter<CategoryLeaderboard>({
  selectId: (categoryLeaderboard) => categoryLeaderboard._id,
});

interface CategoryLeaderboardInitialState
  extends EntityState<CategoryLeaderboard> {}

const initialState: CategoryLeaderboardInitialState =
  categoryLeaderboardAdaptor.getInitialState();

export const extendCategoryLeaderBoardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategoryLeaderBoard: builder.query<
      EntityState<CategoryLeaderboard>,
       {
         currentSystem: string;
          month:string;
      }
        
    >({
      query: (data) =>
        `/category/leaderboard?currentSystem=${data.currentSystem}&month=${data.month}&sort=-xpAvg`,
      transformResponse: (response: IResponseList<CategoryLeaderboard[]>) => {
        const categoryLeaderboardData = response.data;
        const categoryLeaderBoardTotal = response.total;
        store.dispatch(setTotal(categoryLeaderBoardTotal!));

        const cateoryLeaderBoardMetaData = response.metadata;
        return categoryLeaderboardAdaptor.setAll(
          initialState,
          categoryLeaderboardData
        );
      },
      providesTags: (result, error, arg) => [
        {
          type: "CategoryLeaderboard",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({
          type: "CategoryLeaderboard" as const,
          id,
        })),
      ],
    }),
  }),
});

export const { useGetCategoryLeaderBoardQuery } = extendCategoryLeaderBoardApi;
