import React, { useState, useEffect, FC } from "react";
import { View, Text, Image } from "react-native";
import StyleSheet from "react-native-media-query";
import { images } from "../../constants";

export interface IXpLinearChart {
  xp: number;
}

const XpLinearChart: FC<IXpLinearChart> = ({ xp }) => {
  const [level, setLevel] = useState(0);
  const [percent, setPercent] = useState(0);
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(3500);
  useEffect(() => {
    const result = xp / 3500;
    const levelNumber = Math.floor(result);
    setLevel(levelNumber);

    const percentProgress = Number((result - levelNumber).toFixed(2)) * 100;
    setPercent(percentProgress);
    setMaxVal((levelNumber + 1) * 3500);
    setMinVal(levelNumber * 35000);
  }, [xp]);
  return (
    <View style={styles.xpContainerChart}>
      <View style={{ width: "35%", maxWidth: 110 }}>
        <Image
          source={images.xpLevelBackground}
          style={styles.levelImageStyle}
        />
        <Text style={styles.levelText}>LEVEL</Text>
        <Text style={styles.level}>{!isNaN(level) ? level : 0}</Text>
      </View>
      <View
        style={{
          width: "60%",
          maxWidth: 380,
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Text style={styles.exp}>{xp} XP </Text>
        <View style={styles.mainProgress}>
          <Text style={styles.progressMinVal}>
            {!isNaN(minVal) ? minVal : 0}
          </Text>
          <View style={[styles.progress, { width: `${percent}%` }]}></View>
          <Text style={styles.progressMaxVal}>
            {!isNaN(maxVal) ? maxVal : 0}
          </Text>
        </View>
      </View>
    </View>
  );
};

const { styles } = StyleSheet.create({
  levelImageStyle: {
    top: 5,
    width: 80,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 350px)": {
      //   transform: [{ scale: 0.8 }],
    },
  },
  progressMinVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    left: 10,
    top: 10,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },
  progressMaxVal: {
    fontSize: 9,
    color: "#fff",
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 2,
    "@media (max-width: 350px)": {
      top: 7,
    },
  },

  xpContainerChart: {
    flexDirection: "row",
    borderRadius: 10,
    width: "100%",
    height: 100,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 20,
    backgroundColor: "white",
    marginTop: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    justifyContent: "space-around",
    elevation: 5,
  },
  mainProgress: {
    width: "100%",
    backgroundColor: "#E3E3E3",
    height: 30,
    borderRadius: 50,
    overflow: "hidden",
    "@media (max-width: 350px)": {
      height: 25,
    },
  },
  progress: {
    overflow: "hidden",
    height: 30,
    backgroundColor: "#4ED486",
    borderRadius: 50,
    "@media (max-width: 350px)": {
      height: 25,
    },
  },
  exp: {
    fontSize: 22,
    paddingBottom: 5,
    "@media (max-width: 350px)": {
      fontSize: 20,
    },
  },
  level: {
    position: "absolute",
    top: 30,
    left: 11,
    color: "white",
    fontSize: 33,
    fontWeight: "bold",
    width: 60,
    textAlign: "center",
    "@media (max-width: 350px)": {
      fontSize: 31,
    },
  },
  levelText: {
    position: "absolute",
    top: 22,
    left: 23,
    color: "white",
    fontSize: 11,
    fontFamily:"HelveticaBold",

    // "@media (max-width: 350px)": {
    //   fontSize: 10,
    //   top: 15,
    //   left: 38,
    // },
  },
});
export default XpLinearChart;
